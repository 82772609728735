import React, { useState } from "react";
import { Label, ListBox, ListBoxItem, Popover, Select, SelectValue } from "react-aria-components";
import Button from "../NewButton";
import useTableContext from "./useTableContext";

function PerPageSelector() {
  let [selectedKeys, setSelectedKeys] = useState("10");



  const { setPage, setPerPage, perPage } = useTableContext();

  let options = [
    { id: 10, name: "10" },
    { id: 25, name: "25" },
    { id: 50, name: "50" },
    { id: 75, name: "75" },
    { id: 100, name: "100" },
    { id: 500, name: "500" },
    { id: -1, name: "Tous" },
  ];

  return (
    <>
      <Select
        className="react-aria-Select flex items-center"
        selectedKey={perPage}
        onSelectionChange={(newKey) => {
          //@ts-ignore
          setPerPage((oldKey) => {
            if (newKey !== oldKey) {
              //@ts-ignore
              setPage(1); // reset pagination
            }

            return newKey;
          });
        }}
      >
        <Label className="pr-2 text-sm font-normal">Affichage</Label>
        <Button>
          <SelectValue />
          <span aria-hidden="true">▼</span>
        </Button>
        <Popover>
          <ListBox
            items={options}
            className={"bg-white border border-[#DADADA] rounded-md overflow-hidden"}
          >
            {(item) => <ListBoxItem>{item.name}</ListBoxItem>}
          </ListBox>
        </Popover>
      </Select>
    </>
  );
}

export default PerPageSelector;
