import axios from "axios";
import { useState } from "react";
import { useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil";
import { axiosPrivate, axiosPublic } from "../api/axios";
import { AUTH_END_POINT, BEARER_TOKEN_COOKIE_NAME } from "../constants/constant";
import authAtom from "../recoil/auth";
import refreshTokenAtom from "../recoil/auth/refreshToken";
import bearerTokenAtom from "../recoil/auth/bearerToken";
import Cookies from "js-cookie";

export function useAuth() {
  const [auth, setAuth] = useRecoilState(authAtom);
  const setRefreshToken = useSetRecoilState(refreshTokenAtom);
  const setBearerToken = useSetRecoilState(bearerTokenAtom);

  const reset = useResetRecoilState(authAtom);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function login(credentials: { email: string; password: string, persistentLogin: boolean }) {
    setIsLoading(true);
    try {
      const response = await axiosPublic.post<LoginResponse>(
        AUTH_END_POINT.LOGIN,
        JSON.stringify(credentials)
      );

      if (response.status === 200) {
        setBearerToken({ token: response.data?.bearer });
        setIsLoading(false);

      
        if (response.data?.refresh_token) {
          setRefreshToken({
            token: response.data.refresh_token,
            persistentLogin: credentials.persistentLogin
          });
        }

        setAuth(response.data);
      }

      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setIsLoading(false);
        throw error;
      }
    }
  }

  async function logout() {
    try {
      await axiosPrivate.get(AUTH_END_POINT.LOGOUT);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setIsLoading(false);
      }
    }

    reset();
    setBearerToken(null);
    setRefreshToken(null);
  }

  return { auth, setAuth, login, logout, isLoading };
}
