import { atom } from "recoil";
import Cookies from 'js-cookie';
import { REFRESH_TOKEN_COOKIE_NAME, REFRESH_TOKEN_EXPIRATION_TIME } from "../../constants";


export interface RefreshTokenAtom {
  token: string;
  persistentLogin: boolean
}

export const defaultState: RefreshTokenAtom = {
  token: '',
  persistentLogin: false
};

const refreshTokenAtom = atom<RefreshTokenAtom|null>({
  key: "refreshTokenAtom",
  default: defaultState,
  // @ts-ignore
  effects_UNSTABLE: [
    ({ onSet, setSelf }) => {

      const refreshToken = Cookies.get(REFRESH_TOKEN_COOKIE_NAME);

      onSet((value) => {
        const options:Cookies.CookieAttributes = {};

        if (value?.persistentLogin) {

          const date = new Date();
          date.setTime(date.getTime() + (REFRESH_TOKEN_EXPIRATION_TIME * 1000));
          options.expires = date;
        }

        if (value === null) {
          Cookies.remove(REFRESH_TOKEN_COOKIE_NAME);
          return;
        }

        Cookies.set(REFRESH_TOKEN_COOKIE_NAME, value.token, options);
      });

      setSelf({ token: refreshToken ?? '', persistentLogin: false });
    },
  ]
});

export default refreshTokenAtom;
