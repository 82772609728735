import { memo, useCallback, useEffect, useRef } from "react";
import { Modal } from "../../modals";
import { useTranslation } from "react-i18next";
import { axiosPrivate } from "../../../api/axios";
import { LoadingIcon } from "../../LoadingIcon";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { EditableField } from "../../../components/EditableField";
import {
  Input,
  Select,
  TertiaryBadge,
  SwitchController,
  InputController,
} from "../../core";
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from "react-hook-form";
import { useCommonOptions } from "../../../hooks";
import { HiPhone } from "react-icons/hi";
import { flatten } from "../../../utils";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchQuickView } from "../../../api";
import { toast } from "react-toastify";
import { QuickView } from "../../../types/employee";
import { useParams } from "react-router-dom";
dayjs.extend(relativeTime);

export interface Props {
  className?: string;
  handleClose: () => void;
  url: string | { company_id: string; registration_id: string };
}

interface FormData extends QuickView {}

const QuickViewModal = ({ className = "", handleClose, url = "" }: Props) => {
  const toastId = useRef<any>(null);
  const queryClient = useQueryClient();
  const submitRef = useRef<HTMLInputElement>(null);
  const methods = useForm<FormData>();
  const { companyId } = useParams();

  const query = useQuery({
    queryKey: ["quick_view", url],
    queryFn: () => fetchQuickView(url),
  });

  const post = useMutation({
    mutationFn: ({
      url,
      ...data
    }: {
      url: string;
      field_name: string;
      value: number | string;
    }) => axiosPrivate.post(url, data),
    onMutate: () => {
      toastId.current === null
        ? (toastId.current = toast.loading(`${t("Toaster.pending")}`, {
            autoClose: 2000,
          }))
        : toast.update(toastId.current, {
            render: `${t("Toaster.success")}`,
            type: "success",
            isLoading: false,
            autoClose: 2000,
          });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["quick_view", url] });
      toast.update(toastId.current, {
        render: `${t("Toaster.success")}`,
        type: "success",
        isLoading: false,
        autoClose: 2000,
      });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: `${t("Toaster.DefaultError")}`,
        type: "error",
        isLoading: false,
        autoClose: 2000,
      });
    },
  });

  const data = useWatch({ control: methods.control });
  const { t } = useTranslation();
  const {
    civilities_options,
    family_situation_options,
    ethnicities_options,
    eye_colors_options,
    hair_types_options,
    hair_colors_options,
    hair_lengths_options,
  } = useCommonOptions();

  // Update form values after fetch request
  useEffect(() => {
    query.data && methods.reset(query.data);
  }, [query.data]);

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    if (!methods.formState.isDirty) return;
    const flattedData = flatten({
      ...data,
      profile: {
        ...data.profile,
        birthday: dayjs(data.profile.birthday).format(),
        retirement_date: dayjs(data.profile.retirement_date).format(),
      },
    });
    const changedField = Object.keys(flatten(methods.formState.dirtyFields));

    post.mutate({
      url: data.field_update_uri,
      field_name: changedField[0],
      value: flattedData[changedField[0]],
    });
  };

  const handleClickAway = useCallback(() => {
    submitRef.current?.click();
  }, [submitRef]);

  return (
    <Modal
      open={typeof url === "string" && url !== ""}
      title={`${data?.profile?.first_name} ${data?.profile?.last_name}`}
      close={handleClose}
    >
      {data ? (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <input className="hidden" type="submit" ref={submitRef} />
            <ul className={`${className} flex flex-col`}>
              <h2 className="mb-2 text-3xl">
                {t("EmployeeManagementPage.QuickView.Profile")}
              </h2>
              <div className="mb-3 grid grid-cols-1 gap-3 md:grid-cols-2 [&_li_span]:flex [&_li_span]:font-semibold">
                <EditableField
                  input={
                    <Select
                      options={family_situation_options}
                      {...methods.register("profile.family_situation")}
                    />
                  }
                  onClickAway={handleClickAway}
                >
                  <li>
                    <span>
                      {t("ProfileForm.MoreInformationsForm.family_situation")}
                    </span>
                    {family_situation_options &&
                      family_situation_options.find(
                        ({ value }) => data.profile?.family_situation === value,
                      )?.label}
                  </li>
                </EditableField>
                <EditableField
                  input={
                    <Select
                      options={civilities_options}
                      {...methods.register("profile.civility")}
                    />
                  }
                  onClickAway={handleClickAway}
                >
                  <li>
                    <span>
                      {t("EmployeeManagementPage.QuickView.civility")}
                    </span>
                    {data.profile?.civility &&
                      civilities_options.find(
                        ({ value }) => data.profile?.civility === value,
                      ).label}
                  </li>
                </EditableField>
                <li>
                  <span>{t("Age")}</span>
                  {dayjs(data.profile?.birthday).fromNow()}
                </li>
                <EditableField
                  input={
                    <InputController name="profile.birthday" type="date" />
                  }
                  onClickAway={handleClickAway}
                >
                  <li>
                    <span>{t("EmployeeManagementPage.QuickView.born")}</span>
                    {dayjs(data.profile?.birthday).format(
                      t("dateFormatSimple"),
                    )}
                  </li>
                </EditableField>
                <EditableField
                  input={<InputController name="profile.email" type="email" />}
                  onClickAway={handleClickAway}
                >
                  <li>
                    <span>{t("Email")}</span>
                    {data.profile?.email}
                  </li>
                </EditableField>
                <EditableField
                  input={<InputController name="profile.phone" type="tel" />}
                  onClickAway={handleClickAway}
                >
                  <li>
                    <span className="flex items-center gap-1">
                      <HiPhone className="h-4 w-4" />
                      {t("Phone")}
                    </span>
                    {data.profile?.phone}
                  </li>
                </EditableField>
                <EditableField
                  input={
                    <InputController
                      name="profile.retirement_date"
                      type="date"
                    />
                  }
                  onClickAway={handleClickAway}
                >
                  <li>
                    <span>{t("EmployeeManagementPage.QuickView.retired")}</span>
                    {data.profile?.retirement_date
                      ? dayjs(data.profile?.retirement_date).format(
                          t("dateFormatSimple"),
                        )
                      : "-"}
                  </li>
                </EditableField>

                <EditableField
                  input={
                    <InputController
                      name="profile.social_security_number"
                      type="text"
                    />
                  }
                  onClickAway={handleClickAway}
                >
                  <li>
                    <span>
                      {t("ProfileForm.SocialSafetyForm.social_security_number")}
                    </span>
                    {data.profile?.social_security_number}
                  </li>
                </EditableField>

                <EditableField
                  input={
                    <InputController.Number name="profile.number_of_children" />
                  }
                  onClickAway={handleClickAway}
                >
                  <li>
                    <span>
                      {t("ProfileForm.MoreInformationsForm.number_of_children")}
                    </span>
                    {data.profile?.number_of_children}
                  </li>
                </EditableField>
                <EditableField
                  input={
                    <InputController.Number name="profile.number_of_dependents" />
                  }
                  onClickAway={handleClickAway}
                >
                  <li>
                    <span>
                      {t(
                        "ProfileForm.MoreInformationsForm.number_of_dependents",
                      )}
                    </span>

                    {data.profile?.number_of_dependents}
                  </li>
                </EditableField>
              </div>

              {/* <h3 className="mt-3 mb-2 text-2xl">Adresse</h3>
              <div className="mb-3 grid grid-cols-1 gap-3 md:grid-cols-2 [&_li_span]:flex [&_li_span]:font-semibold">
                <EditableField
                  input={
                    <Input
                      type="text"
                      {...methods.register(
                        "profile.fiscal_address.street_number"
                      )}
                    />
                  }
                  onClickAway={handleClickAway}
                >
                  <li>
                    <span>{t("AddressFields.street_number")}</span>
                    {data.profile?.fiscal_address?.street_number}
                  </li>
                </EditableField>

                <EditableField
                  input={
                    <Input
                      type="text"
                      {...methods.register(
                        "profile.fiscal_address.street_name"
                      )}
                    />
                  }
                  onClickAway={handleClickAway}
                >
                  <li>
                    <span>{t("AddressFields.street_name")}</span>
                    {data.profile?.fiscal_address?.street_name}
                  </li>
                </EditableField>

                <EditableField
                  input={
                    <Input
                      type="text"
                      {...methods.register(
                        "profile.fiscal_address.postal_code"
                      )}
                    />
                  }
                  onClickAway={handleClickAway}
                >
                  <li>
                    <span>{t("AddressFields.postal_code")}</span>
                    {data.profile?.fiscal_address?.postal_code}
                  </li>
                </EditableField>

                <EditableField
                  input={
                    <Input
                      type="text"
                      {...methods.register("profile.fiscal_address.city")}
                    />
                  }
                  onClickAway={handleClickAway}
                >
                  <li>
                    <span>{t("AddressFields.city")}</span>
                    {data.profile?.fiscal_address?.city}
                  </li>
                </EditableField>

                <EditableField
                  input={
                    <Select
                      options={countries_options}
                      {...methods.register("profile.fiscal_address.country")}
                    />
                  }
                  onClickAway={handleClickAway}
                >
                  <li>
                    <span>{t("AddressFields.country")}</span>
                    {data.profile?.fiscal_address?.country}
                  </li>
                </EditableField>
              </div> */}

              <div className="mb-3 grid grid-cols-1 gap-3 md:grid-cols-2 [&_li_span]:flex [&_li_span]:font-semibold">
                <li>
                  <span>
                    {t("EmployeeManagementPage.QuickView.number_off")}
                  </span>
                  -
                </li>
                <li>
                  <span>
                    {t("EmployeeManagementPage.QuickView.last_medic")}
                  </span>
                  -
                </li>
                <li>
                  <span>{t("EmployeeManagementPage.QuickView.end_medic")}</span>
                  -
                </li>
                <li>
                  <span>
                    {t("EmployeeManagementPage.QuickView.specific_sub")}
                  </span>
                  -
                </li>
                <li>
                  <span>{t("EmployeeManagementPage.QuickView.job")}</span>
                  {data?.profession || "-"}
                </li>
                {data.is_intermittent && (
                  <li>
                    <TertiaryBadge outline>
                      #{t("EmployeeManagementPage.QuickView.occasional")}
                    </TertiaryBadge>
                  </li>
                )}
              </div>

              <div className="mb-3 grid grid-cols-1 gap-3 md:grid-cols-2 [&_li_span]:flex [&_li_span]:font-semibold">
                <li>
                  <span>{t("EmployeeManagementPage.QuickView.tags")}</span>
                  {data?.tags?.company_tags?.nb_items !== undefined &&
                  data?.tags?.personal_tags?.nb_items !== undefined
                    ? data.tags.company_tags.nb_items +
                      data.tags.personal_tags.nb_items
                    : "-"}
                </li>
                <li>
                  <span>{t("EmployeeManagementPage.QuickView.set")}</span>
                  {data.sets?.nb_items?.toString() || "-"}
                </li>
                <li>
                  <span>
                    {t("EmployeeManagementPage.QuickView.contract_number")}
                  </span>
                  {data.nb_contracts || "-"}
                </li>
                <li>
                  <span>
                    {t("EmployeeManagementPage.QuickView.e_contract_number")}
                  </span>
                  {data.nb_contracts || "-"}
                </li>
                <li>
                  <span>{t("EmployeeManagementPage.QuickView.dpae")}</span>
                  {`${data.dpae}` || "-"}
                </li>
                <li>
                  <span>
                    {t("EmployeeManagementPage.QuickView.spec_questions")}
                  </span>
                  -
                </li>
                <li>
                  <span>{t("EmployeeManagementPage.QuickView.docs")}</span>
                  {`${data.documents}` || "-"}
                </li>
              </div>

              <h2 className="mt-8 text-3xl">
                {t("EmployeeManagementPage.QuickView.casting")}
              </h2>
              {/* // :::::::::::::::::::: CASTING ::::::::::::::::::: */}

              <h3 className="mb-2 mt-3 text-2xl">
                {t("EmployeeManagementPage.QuickView.skin")}
              </h3>
              <div className="mb-3 grid grid-cols-1 gap-3 md:grid-cols-2 [&_li_span]:flex [&_li_span]:font-semibold">
                <EditableField
                  input={
                    <SwitchController
                      name="casting_profile.appear_in_search_engine"
                      defaultSelected={
                        data.casting_profile?.appear_in_search_engine
                      }
                    />
                  }
                  onClickAway={handleClickAway}
                >
                  <li>
                    <span>
                      {t(
                        "EmployeeManagementPage.QuickView.appear_in_search_engine",
                      )}
                    </span>
                    {data.casting_profile?.appear_in_search_engine
                      ? "Oui"
                      : "Non" || "-"}
                  </li>
                </EditableField>
                <EditableField
                  input={
                    <InputController.Number name="casting_profile.visible_age" />
                  }
                  onClickAway={handleClickAway}
                >
                  <li>
                    <span>
                      {t("EmployeeManagementPage.QuickView.visible_age")}
                    </span>
                    {data.casting_profile?.visible_age || "-"}
                  </li>
                </EditableField>
                <EditableField
                  input={
                    <SwitchController
                      name={"casting_profile.tatoos"}
                      defaultSelected={data.casting_profile?.tatoos}
                    />
                  }
                  onClickAway={handleClickAway}
                >
                  <li>
                    <span>{t("EmployeeManagementPage.QuickView.tattoo")}</span>
                    {data.casting_profile?.tatoos || "-"}
                  </li>
                </EditableField>
                <EditableField
                  input={
                    <Select
                      options={eye_colors_options}
                      {...methods.register("casting_profile.eye_color")}
                    />
                  }
                  onClickAway={handleClickAway}
                >
                  <li>
                    <span>
                      {t("EmployeeManagementPage.QuickView.eye_color")}
                    </span>
                    {(eye_colors_options &&
                      eye_colors_options.find(
                        (ec) => ec.value === data.casting_profile?.eye_color,
                      )?.label) ||
                      "-"}
                  </li>
                </EditableField>
                <EditableField
                  input={
                    <Select
                      options={ethnicities_options}
                      {...methods.register("casting_profile.ethnicity")}
                    />
                  }
                  onClickAway={handleClickAway}
                >
                  <li>
                    <span>
                      {t("EmployeeManagementPage.QuickView.ethnicity")}
                    </span>
                    {(ethnicities_options &&
                      ethnicities_options.find(
                        (e) => e.value === data.casting_profile?.ethnicity,
                      )?.label) ||
                      "-"}
                  </li>
                </EditableField>
                {/* // TODO Signes particuliers */}
                {/* <li className="text-red-500">
                  <span>Particularités physiques</span>
                  {data.casting_profile?.physical_characteristics || "-"}
                </li> */}
              </div>

              <h3 className="mb-2 mt-3 text-2xl">
                {t("EmployeeManagementPage.QuickView.measurements")}
              </h3>
              <div className="mb-3 grid grid-cols-1 gap-3 md:grid-cols-2 [&_li_span]:flex [&_li_span]:font-semibold">
                <EditableField
                  input={
                    <InputController.Number name="casting_profile.chest_size" />
                  }
                  onClickAway={handleClickAway}
                >
                  <li>
                    <span>
                      {t("EmployeeManagementPage.QuickView.chest_size")}
                    </span>
                    {data.casting_profile?.chest_size || "-"}
                  </li>
                </EditableField>

                <EditableField
                  input={
                    <InputController.Number name="casting_profile.hip_size" />
                  }
                  onClickAway={handleClickAway}
                >
                  <li>
                    <span>
                      {t("EmployeeManagementPage.QuickView.hip_size")}
                    </span>
                    {data.casting_profile?.hip_size || "-"}
                  </li>
                </EditableField>

                <EditableField
                  input={
                    <InputController.Number name="casting_profile.waist_size" />
                  }
                  onClickAway={handleClickAway}
                >
                  <li>
                    <span>
                      {t("EmployeeManagementPage.QuickView.waist_size")}
                    </span>
                    {data.casting_profile?.waist_size || "-"}
                  </li>
                </EditableField>

                <EditableField
                  input={<InputController.Number name="casting_profile.size" />}
                  onClickAway={handleClickAway}
                >
                  <li>
                    <span>{t("EmployeeManagementPage.QuickView.size")}</span>
                    {data.casting_profile?.size || "-"}
                  </li>
                </EditableField>

                <EditableField
                  input={
                    <InputController.Number name="casting_profile.weight" />
                  }
                  onClickAway={handleClickAway}
                >
                  <li>
                    <span>{t("EmployeeManagementPage.QuickView.weight")}</span>
                    {data.casting_profile?.weight || "-"}
                  </li>
                </EditableField>

                <EditableField
                  input={
                    <InputController.Number name="casting_profile.shoe_size" />
                  }
                  onClickAway={handleClickAway}
                >
                  <li>
                    <span>
                      {t("EmployeeManagementPage.QuickView.shoe_size")}
                    </span>
                    {data.casting_profile?.shoe_size || "-"}
                  </li>
                </EditableField>
              </div>

              <h3 className="mb-2 mt-3 text-2xl">
                {t("EmployeeManagementPage.QuickView.haircut")}
              </h3>
              <div className="mb-3 grid grid-cols-1 gap-3 md:grid-cols-2 [&_li_span]:flex [&_li_span]:font-semibold">
                <EditableField
                  input={
                    <Select
                      options={hair_lengths_options}
                      {...methods.register("casting_profile.hair_length")}
                    />
                  }
                  onClickAway={handleClickAway}
                >
                  <li>
                    <span>
                      {t("EmployeeManagementPage.QuickView.hair_length")}
                    </span>
                    {data.casting_profile?.hair_length || "-"}
                  </li>
                </EditableField>

                <EditableField
                  input={
                    <Select
                      options={hair_types_options}
                      {...methods.register("casting_profile.hair_type")}
                    />
                  }
                  onClickAway={handleClickAway}
                >
                  <li>
                    <span>
                      {t("EmployeeManagementPage.QuickView.hair_type")}
                    </span>
                    {(hair_types_options &&
                      hair_types_options.find(
                        (e) => e.value === data.casting_profile?.hair_type,
                      )?.label) ||
                      "-"}
                  </li>
                </EditableField>

                <EditableField
                  input={
                    <Select
                      options={hair_colors_options}
                      {...methods.register("casting_profile.hair_color")}
                    />
                  }
                  onClickAway={handleClickAway}
                >
                  <li>
                    <span>
                      {t("EmployeeManagementPage.QuickView.hair_color")}
                    </span>
                    {(hair_colors_options &&
                      hair_colors_options.find(
                        (e) => e.value === data.casting_profile?.hair_color,
                      )?.label) ||
                      "-"}
                  </li>
                </EditableField>
              </div>

              <h3 className="mb-2 mt-3 text-2xl">
                {t("EmployeeManagementPage.QuickView.skills")}
              </h3>
              <div className="mb-3 grid grid-cols-1 gap-3 md:grid-cols-2 [&_li_span]:flex [&_li_span]:font-semibold">
                <EditableField
                  input={
                    <SwitchController
                      name="casting_profile.nude_scenes"
                      defaultSelected={methods.getValues(
                        "casting_profile.nude_scenes",
                      )}
                    />
                  }
                  onClickAway={handleClickAway}
                >
                  <li>
                    <span>
                      {t("EmployeeManagementPage.QuickView.nude_scenes")}
                    </span>
                    {data.casting_profile?.nude_scenes || "-"}
                  </li>
                </EditableField>

                <EditableField
                  input={
                    <SwitchController
                      name="casting_profile.voice_casting"
                      defaultSelected={methods.getValues(
                        "casting_profile.voice_casting",
                      )}
                    />
                  }
                  onClickAway={handleClickAway}
                >
                  <li>
                    <span>
                      {t("EmployeeManagementPage.QuickView.voice_casting")}
                    </span>
                    {data.casting_profile?.voice_casting || "-"}
                  </li>
                </EditableField>

                <EditableField
                  input={
                    <InputController
                      name="casting_profile.look_alike"
                      type="text"
                    />
                  }
                  onClickAway={handleClickAway}
                >
                  <li>
                    <span>
                      {t("EmployeeManagementPage.QuickView.look_alike")}
                    </span>
                    {data.casting_profile?.look_alike || "-"}
                  </li>
                </EditableField>
              </div>

              <div className="mb-3 grid grid-cols-1 gap-3 md:grid-cols-2 [&_li_span]:flex [&_li_span]:font-semibold">
                <EditableField
                  input={
                    <Select
                      options={[]}
                      {...methods.register(
                        "casting_profile.physical_characteristics",
                      )}
                    />
                  }
                  onClickAway={handleClickAway}
                >
                  <li>
                    <span>
                      {t(
                        "EmployeeManagementPage.QuickView.physical_characteristics",
                      )}
                    </span>
                    {data.casting_profile?.physical_characteristics || "-"}
                  </li>
                </EditableField>

                {/* <li>
                  <span>
                    {t("EmployeeManagementPage.QuickView.languages_spoken")}
                  </span>
                  {data.casting_profile?.languages_spoken?.map(
                    (l) => `${l} `
                  ) || "-"}
                </li> */}

                {/* <li>
                  <span>{t("EmployeeManagementPage.QuickView.practiced_sport")}</span>
                  {data.casting_profile?.practiced_sport?.map((s) => `${s} `) ||
                    "-"}
                </li> */}

                {/* <li>
                  <span>
                    {t("EmployeeManagementPage.QuickView.special_talent")}
                  </span>
                  {data.casting_profile?.special_talent?.map((s) => `${s} `) ||
                    "-"}
                </li> */}

                <EditableField
                  input={
                    <SwitchController
                      name="casting_profile.has_car"
                      defaultSelected={methods.getValues(
                        "casting_profile.has_car",
                      )}
                    />
                  }
                  onClickAway={handleClickAway}
                >
                  <li>
                    <span>{t("EmployeeManagementPage.QuickView.has_car")}</span>
                    {data.casting_profile?.has_car ? "Oui" : "Non" || "-"}
                  </li>
                </EditableField>

                <EditableField
                  input={
                    <InputController
                      name="casting_profile.car_details"
                      type="text"
                    />
                  }
                  onClickAway={handleClickAway}
                >
                  <li>
                    <span>
                      {t("EmployeeManagementPage.QuickView.car_details")}
                    </span>
                    {data.casting_profile?.car_details || "-"}
                  </li>
                </EditableField>

                <EditableField
                  input={
                    <SwitchController
                      name="casting_profile.has_motorbike"
                      defaultSelected={methods.getValues(
                        "casting_profile.has_motorbike",
                      )}
                    />
                  }
                  onClickAway={handleClickAway}
                >
                  <li>
                    <span>
                      {t("EmployeeManagementPage.QuickView.has_motorbike")}
                    </span>
                    {data.casting_profile?.has_motorbike ? "Oui" : "Non" || "-"}
                  </li>
                </EditableField>

                <EditableField
                  input={
                    <InputController
                      name="casting_profile.two_wheels_details"
                      type="text"
                    />
                  }
                  onClickAway={handleClickAway}
                >
                  <li>
                    <span>
                      {t("EmployeeManagementPage.QuickView.two_wheels_details")}
                    </span>
                    {data.casting_profile?.two_wheels_details || "-"}
                  </li>
                </EditableField>

                <EditableField
                  input={
                    <SwitchController
                      name="casting_profile.has_scooter"
                      defaultSelected={methods.getValues(
                        "casting_profile.has_scooter",
                      )}
                    />
                  }
                  onClickAway={handleClickAway}
                >
                  <li>
                    <span>
                      {t("EmployeeManagementPage.QuickView.has_scooter")}
                    </span>
                    {data.casting_profile?.has_scooter ? "Oui" : "Non" || "-"}
                  </li>
                </EditableField>

                <EditableField
                  input={
                    <SwitchController
                      name="casting_profile.job_seeker"
                      defaultSelected={methods.getValues(
                        "casting_profile.job_seeker",
                      )}
                    />
                  }
                  onClickAway={handleClickAway}
                >
                  <li>
                    <span>
                      {t("EmployeeManagementPage.QuickView.job_seeker")}
                    </span>
                    {data.casting_profile?.job_seeker ? "Oui" : "Non" || "-"}
                  </li>
                </EditableField>

                {/* <li>
                  <span>{t("EmployeeManagementPage.QuickView.animals")}</span>
                  {data.casting_profile?.animals?.map((s) => s) || "-"}
                </li> */}
              </div>
            </ul>
          </form>
        </FormProvider>
      ) : (
        <LoadingIcon />
      )}
    </Modal>
  );
};

export default memo(QuickViewModal);
