import { Ref, forwardRef, memo } from 'react';
import Label, { LabelWrapperProps } from '../Label';
import type {
    FieldErrorRenderProps,
    TextFieldProps,
} from 'react-aria-components';
import { TextField, Input as AriaInput } from 'react-aria-components';
import cn from 'mxcn';
import Checkbox from '../Checkbox/Checkbox';
import DatePicker from '../DatePicker/DatePicker';

export interface Props extends TextFieldProps {
    className?: string;
    labelsContainerClassName?: string;
    containerClassName?: string;
    labelClass?: string;
    label?: string;
    info?: string;
    note?: string;
    errorLabel?: string;
    placeholder?: string;
    ref?: Ref<HTMLInputElement>;
}

const Input = ({
    className = '',
    labelsContainerClassName = '',
    containerClassName = '',
    labelClass = '',
    info,
    note,
    label,
    errorLabel,
    placeholder,
    ref,
    value,
    ...props
}: Props) => {
    const labelsProps: LabelWrapperProps = {
        title: label,
        info,
        labelClass,
        errorLabel,
        required: props.isRequired,
        note,
        disabled: props.isDisabled,
        labelsContainerClassName,
    };

    return (
        <>
            <TextField
                {...props}
                value={value}
                aria-label={label ?? placeholder}
                className={cn('w-fit', containerClassName)}
            >
                <Label.Wrapper {...labelsProps}>
                    <AriaInput
                        ref={ref}
                        placeholder={placeholder}
                        className={cn(
                            errorLabel
                                ? 'border-mr-orange-400'
                                : 'border-mr-gray-1000',
                            'rounded focus:ring-0 focus:outline-none focus:border-mr-cyan-300 h-[46px] text-base',
                            className,
                            {
                                'disabled:opacity-50': props.isDisabled,
                            },
                            'w-full'
                        )}
                    />
                </Label.Wrapper>
            </TextField>
            {props.type === 'number' &&
                console.warn(
                    'Use InputNumber instead of Input with type number.'
                )}
        </>
    );
};

export default memo(Input);
