import { AxiosError, AxiosRequestConfig } from "axios";
import { AUTH_END_POINT } from "../constants";
import axios, { axiosPrivate, axiosPrivatePatch } from "./axios";
import axiosModule from "axios";




export async function getCompanyUsers(companyId: string) {
    try {
      const response = await axiosPrivate.get(AUTH_END_POINT.COMPANIES.REGISTRATIONS(companyId))

      const res = response?.data['hydra:member'].map((item: any) => `${item.first_name} ${item.last_name}`);
      console.log(res);
      return response?.data;
    } catch (err) {
      if (axiosModule.isAxiosError(err)) {
        const error = err as AxiosError;
        throw error?.response?.status;
      } else {
        throw err;
      }
    }
}