import { Control, Controller, useFormContext } from 'react-hook-form';
import Input, { Props as InputProps } from './Input';
import InputNumber, { Props as InputNumberProps } from './InputNumber';
import { DatePicker, Props as InputDatePickerProps } from '../DatePicker';
import { Checkbox, Props as InputCheckboxProps } from '../Checkbox';
import { useTranslation } from 'react-i18next';

interface BaseFormFieldProps {
    name: string;
    control?: any;
    rules?: any;
}

export type Props = InputProps & BaseFormFieldProps;
export type NumberProps = InputNumberProps & BaseFormFieldProps;
export type CheckboxProps = InputCheckboxProps & BaseFormFieldProps;
export type DatePickerProps = InputDatePickerProps & BaseFormFieldProps;

export function isRequired(rules: any, t: any) {
    const r = { ...rules };

    if (rules && rules.required && typeof rules.required === 'boolean') {
        r.required = { value: true, message: t('required_field') };
    }

    return r;
}

const InputController = ({ name, rules, control, ...props }: Props) => {
    const { t } = useTranslation();
    const methods = useFormContext();
    if (control === undefined) {
        control = methods.control;
    }

    if (props.isRequired || typeof rules !== 'undefined') {
        rules = { ...rules, ...isRequired(rules, t) };
    }

    // if (props.type === 'email') {
    //     rules = {
    //         ...rules,

    //     };
    // }

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field, fieldState: { invalid, error } }) => {
                return (
                    <Input
                        {...(props as InputProps)}
                        {...field}
                        isRequired={rules?.required ?? false}
                        isDisabled={
                            rules?.disabled ?? props.isDisabled ?? false
                        }
                        validationBehavior="aria"
                        isInvalid={invalid}
                        errorLabel={error?.message}
                    />
                );
            }}
        />
    );
};

const NumberController = ({ name, rules, control, ...props }: NumberProps) => {
    const { t } = useTranslation();
    const methods = useFormContext();
    if (control === undefined) {
        control = methods.control;
    }

    if (props.isRequired || typeof rules !== 'undefined') {
        rules = { ...rules, ...isRequired(rules, t) };
    }

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field, fieldState: { invalid, error } }) => {
                return (
                    <InputNumber
                        {...(props as InputNumberProps)}
                        {...field}
                        isRequired={rules?.required ?? false}
                        isDisabled={
                            rules?.disabled ?? props.isDisabled ?? false
                        }
                        validationBehavior="aria"
                        isInvalid={invalid}
                        errorLabel={error?.message}
                    />
                );
            }}
        />
    );
};

const CheckboxController = ({
    name,
    rules,
    control,
    ...props
}: CheckboxProps) => {
    const { t } = useTranslation();
    const methods = useFormContext();
    if (control === undefined) {
        control = methods.control;
    }

    if (props.isRequired || typeof rules !== 'undefined') {
        rules = { ...rules, ...isRequired(rules, t) };
    }

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field, fieldState: { invalid, error } }) => {
                return (
                    <Checkbox
                        {...(props as InputCheckboxProps)}
                        {...field}
                        isRequired={rules?.required ?? false}
                        isDisabled={
                            rules?.disabled ?? props.isDisabled ?? false
                        }
                        validationBehavior="aria"
                        isInvalid={invalid}
                        labelProps={{
                            errorLabel: error?.message,
                            ...props.labelProps,
                        }}
                    />
                );
            }}
        />
    );
};

const DatePickerController = ({
    name,
    rules,
    control,
    ...props
}: DatePickerProps) => {
    const { t } = useTranslation();
    const methods = useFormContext();
    if (control === undefined) {
        control = methods.control;
    }

    if (props.isRequired || typeof rules !== 'undefined') {
        rules = { ...rules, ...isRequired(rules, t) };
    }

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field, fieldState: { invalid, error } }) => (
                <DatePicker
                    {...(props as InputDatePickerProps)}
                    {...field}
                    isRequired={rules?.required ?? false}
                    isDisabled={rules?.disabled ?? props.isDisabled ?? false}
                    validationBehavior="aria"
                    isInvalid={invalid}
                    labelProps={{
                        errorLabel: error?.message,
                        ...props.labelProps,
                    }}
                />
            )}
        />
    );
};

InputController.Number = NumberController;
InputController.Checkbox = CheckboxController;
InputController.DatePicker = DatePickerController;
export default InputController;
