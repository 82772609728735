import { RefObject } from "react";
import { getLoginError } from "./login";
import { Id, toast as reactToastify } from "react-toastify";
import { t } from "i18next";

export { getLoginError, isAboveThan };

function isAboveThan(
  parentRef: RefObject<HTMLElement>,
  childRef: RefObject<HTMLElement>
) {
  if (parentRef && childRef) {
    const cguRefBottom = parentRef?.current?.getBoundingClientRect().bottom;
    const validateCGURefTop = childRef?.current?.getBoundingClientRect().top;

    if (validateCGURefTop && cguRefBottom) {
      return validateCGURefTop <= cguRefBottom;
    }
  }
}

// type FlattenObjectKeys<
//   T extends Record<string, unknown>,
//   Key = keyof T
// > = Key extends string
//   ? T[Key] extends Record<string, unknown>
//     ? `${Key}`
//     : `${Key}`
//   : never;

/**
 * @param obj JSON with n sub levels
 * @returns flat JSON
 */
export const flatten = (obj: any): any =>
  Object.entries(obj).reduce((acc, [key, value]) => {
    if (value === undefined) return acc;
    let sub = null;
    if (value instanceof Object) {
      sub = flatten(value);
    }

    return sub ? { ...acc, ...sub } : { ...acc, [key]: value };
  }, {});

/**
 * Transform company id to company IRI
 * @param id
 * @returns iri
 */
export const setCompanyIRI = (id: string | number) => `/companies/${id}`;

/**
 * Transform company id to company IRI
 * @param id
 * @returns iri
 */
export const setSetIRI = (id: string | number) => `/set/${id}`;

/**
 * Transform company id to company IRI
 * @param id
 * @returns iri
 */
export const setUserIRI = (id: string | number) => `/users/${id}`;

/**
 * Handle toast loading, success and error
 * @param text object to override default text
 */
export const toast = (text?: {
  success?: string;
  error?: string;
  loading?: string;
}) => {
  const id = reactToastify.loading(
    text?.loading ? text?.loading : `${t("Toaster.pending")}`,
    { autoClose: 200, closeOnClick: true, pauseOnHover: false }
  );

  return {
    id: id,
    success: (refId?: Id) =>
      reactToastify.update(refId || id, {
        render: text?.success ? text?.success : `${t("Toaster.success")}`,
        type: "success",
        isLoading: false,
        autoClose: 200,
        closeOnClick: true,
        pauseOnHover: false,
      }),
    error: (refId?: Id) =>
      reactToastify.update(refId || id, {
        render: text?.error ? text?.error : `${t("Toaster.DefaultError")}`,
        type: "error",
        isLoading: false,
        autoClose: 200,
        closeOnClick: true,
        pauseOnHover: false,
      }),
  };
};

/**
 * Random hex color
 */
export const randomHexColor = () =>
  `#${Math.floor(Math.random() * 16777215).toString(16)}`;

export function findMissingElements(array1: string[], array2: string[]) {
  const set2 = new Set(array2);
  return array1.filter((element) => !set2.has(element));
}
