import { useMutation, useQueryClient } from "@tanstack/react-query";
import { patchEG, postEG, removeEG } from "../api";

export function useEmployeeGroup() {
  const queryClient = useQueryClient();

  const post = useMutation({
    mutationFn: (newEg: PostEmployeeGroupParam) => postEG(newEg),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["employee_group"],
      });
      queryClient.invalidateQueries({
        queryKey: ["company_parameters"],
      });
      queryClient.invalidateQueries({
        queryKey: ["companies"],
      });
    },
  });

  const patch = useMutation({
    mutationFn: (newEg: PatchEGParams) => {
      return patchEG(newEg);
    },
    onSuccess: () => {
      // queryClient.invalidateQueries({
      //   queryKey: ["employee_group"],
      // });
      // queryClient.invalidateQueries({
      //   queryKey: ["company_parameters"],
      // });
      // queryClient.invalidateQueries({
      //   queryKey: ["companies"],
      // });
    },
  });

  const remove = useMutation({
    mutationFn: (id: string) => removeEG(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["employee_group"],
      });
      queryClient.invalidateQueries({
        queryKey: ["company_parameters"],
      });
      queryClient.invalidateQueries({
        queryKey: ["companies"],
      });
    },
  });

  return {
    remove,
    post,
    patch,
  };
}
