export const BILLING_FREQUENCIES_FIXTURE: GetBillingFrequencyResponse = {
  "@context": "/contexts/BillingFrequency",
  "@id": "/billing_frequencies",
  "@type": "hydra:Collection",
  "hydra:member": [
    {
      "@id": "/billing_frequencies/1",
      "@type": "BillingFrequency",
      id: 1,
      name: "mensuelle",
      productions: ["/productions/1ed29440-6ce3-6968-92ad-9b63a7c5fad7"],
    },
    {
      "@id": "/billing_frequencies/2",
      "@type": "BillingFrequency",
      id: 2,
      name: "trimestrielle",
      productions: [],
    },
    {
      "@id": "/billing_frequencies/3",
      "@type": "BillingFrequency",
      id: 3,
      name: "annuelle",
      productions: [],
    },
  ],
  "hydra:totalItems": 3,
};
