import { ReactElement, Ref, RefObject, useEffect } from "react";
import { useLocation } from "react-router-dom";

interface Props {
  element: RefObject<HTMLDivElement>;
}

const ScrollToTop = ({ element }: Props) => {
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    if (element.current) {
      element.current.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
};

export default ScrollToTop;
