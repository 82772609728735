import { axiosPrivate, axiosPrivatePatch } from "../api/axios";
import { API_BASE_URL, AUTH_END_POINT } from "../constants";

export async function getProjects() {
  const { data } = await axiosPrivate.get<GetProjectsResponse>(
    `${API_BASE_URL}${AUTH_END_POINT.PROJECT}`
  );

  return data;
}

export async function getProject(id: string): Promise<ProjectForm | undefined> {
  if (id) {
    const { data } = await axiosPrivate.get<Project>(
      `${API_BASE_URL}${AUTH_END_POINT.PROJECT}/${id}`
    );

    const employee_group_options = transformEmployeeGroupReceived(data);

    const project_reconstruction = {
      ...data,
      observers: employee_group_options.observers,
      mail_groups: employee_group_options.mail_groups,
      contract_annexes: employee_group_options.contract_annexes,
      signatories: employee_group_options.signatories,
      sms_groups: employee_group_options.sms_groups,
      employee_groups: employee_group_options.employee_groups_final,
    } as ProjectForm;

    return project_reconstruction;
  }
}

export async function postProject(project: ProjectForm) {
  if (project) {
    project.company.employee_group = transformEmployeeGroupToBeSent(project);

    const response = await axiosPrivate.post<Project>(
      `${API_BASE_URL}${AUTH_END_POINT.PROJECT}`,
      project
    );
    console.log(response);

    return response;
  }
}

export async function patchProject(id: string, patchedProject: ProjectForm) {
  if (id && patchedProject) {
    // TODO : Find a way to delete the old parameters transformed WITHOUT using "delete" from typescript (not liked by Guillaume)
    patchedProject.company.employee_group =
      transformEmployeeGroupToBeSent(patchedProject);

    const response = await axiosPrivatePatch.patch<PatchProjectResponse>(
      `${API_BASE_URL}${AUTH_END_POINT.PROJECT}/${id}`,
      patchedProject
    );

    return response;
  }
}

// utils ------------------------------------------------------------------------
// These functions were made because of the type of employee group on the back
// in the front-end you can't add a new employee group the same way the back want it,
// So we have to do some transformation to make it work
// We go from Employee Groups and all is component like observers and signatories being each independent to a single employee group with all the datas

// TODO : Change Employee Group in back to match the data type in front
export function transformEmployeeGroupToBeSent(
  p: ProjectForm | ProductionForm
): CompanyEmployeeGroup[] {
  const employee_group = p.employee_groups;

  const employee_group_reconstruction: CompanyEmployeeGroup[] =
    employee_group.map((eg) => {
      return {
        contract_annexes: p.contract_annexes
          .map((ca) => {
            if (ca.employee_groups.includes(eg["@id"])) {
              return {
                type: ca.type,
                template: ca.template,
              };
            }
            return [] as ContractAnnexes[];
          })
          .filter(
            (contract_annexes) => contract_annexes !== null
          ) as ContractAnnexes[],
        observers: p.observers
          .map((obs) => {
            if (obs.employee_groups.includes(eg["@id"])) {
              return {
                mail: obs.mail ?? "",
                name: obs.name ?? "",
                validation_required: obs.validation_required ?? false,
              };
            } else {
              return [] as Observer[];
            }
          })
          .filter((observer) => observer !== null) as Observer[],
        sender_mail:
          p.mail_groups.find((mg) => mg.employee_groups.includes(eg["@id"]))
            ?.sender_mail ?? "",
        sender_name:
          p.mail_groups.find((mg) => mg.employee_groups.includes(eg["@id"]))
            ?.sender_name ?? "",
        sender_sms_number:
          p.sms_groups.find((sg) => sg.employee_groups.includes(eg["@id"]))
            ?.sender_sms_number ?? "",
        code: eg.code ?? "",
        "@id": eg["@id"],
        name: eg.name,
      };
    });

  return employee_group_reconstruction;
}

export function transformEmployeeGroupReceived(p: Project | Production): any {
  const employee_groups = p.company.employee_group;

  let employee_groups_codes_observers: string[] = [];
  let employee_groups_codes_mail: string[] = [];
  let employee_groups_codes_annexes: string[] = [];
  let employee_groups_codes_signatories: string[] = [];

  let observers = employee_groups.flatMap((eg: CompanyEmployeeGroup) =>
    eg.observers
      ? eg.observers.map((obs: Observer) => {
          employee_groups_codes_observers.push(eg.code);
          eg.sender_mail && employee_groups_codes_mail.push(eg.code);

          return {
            ...obs,
            employee_groups: employee_groups_codes_observers,
          } as Observer;
        })
      : []
  );

  let mail_groups = employee_groups.flatMap((eg) =>
    eg.sender_mail
      ? {
          employee_groups: employee_groups_codes_mail,
          sender_mail: eg.sender_mail,
          sender_name: eg.sender_name,
        }
      : []
  );

  let contract_annexes = employee_groups.flatMap((eg: CompanyEmployeeGroup) =>
    eg.contract_annexes
      ? eg.contract_annexes.map((ca: ContractAnnexes) => {
          employee_groups_codes_annexes.push(eg.code);

          return {
            ...ca,
            employee_groups: employee_groups_codes_annexes,
          };
        })
      : []
  );

  let signatories = employee_groups.flatMap((eg: CompanyEmployeeGroup) =>
    eg.signatories
      ? eg.signatories.map((sign: Signatories) => {
          employee_groups_codes_signatories.push(eg.code);

          return {
            ...sign,
            employee_groups: employee_groups_codes_signatories,
          };
        })
      : []
  );

  let sms_groups = employee_groups.flatMap((eg) =>
    eg.sender_sms_number
      ? {
          employee_groups: employee_groups_codes_mail,
          sender_sms: eg.sender_sms_number,
        }
      : []
  );

  const hasValidFirstItem = (array: any[], key: string) =>
    array.length > 0 && array[0][key];

  observers = hasValidFirstItem(observers, "mail") ? observers : [];
  mail_groups = hasValidFirstItem(mail_groups, "sender_mail")
    ? mail_groups
    : [];
  contract_annexes = hasValidFirstItem(contract_annexes, "type")
    ? contract_annexes
    : [];
  signatories = hasValidFirstItem(signatories, "signatory_name")
    ? signatories
    : [];
  sms_groups = hasValidFirstItem(sms_groups, "sender_sms") ? sms_groups : [];

  const employee_groups_final =
    employee_groups.map((eg) => ({
      "@id": eg.code,
      name: eg.name,
      code: eg.code,
    })) || [];

  return {
    observers,
    mail_groups,
    contract_annexes,
    signatories,
    sms_groups,
    employee_groups_final,
  };
}
