import { useCallback } from "react";
import { useRecoilState } from "recoil";
import ForgotPasswordModal from "../recoil/ForgotPasswordModal";

export function useForgotPasswordModal() {
  const [{ open: isFPModalOpen }, setFPModal] =
    useRecoilState(ForgotPasswordModal);

  const closeFPModal = useCallback(() => {
    setFPModal({ open: false });
  }, []);

  const openFPModal = useCallback(() => {
    setFPModal({ open: true });
  }, []);

  return { isFPModalOpen, setFPModal, closeFPModal, openFPModal };
}
