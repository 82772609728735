import { memo, ReactNode, useCallback, useState } from "react";
import { IconType } from "react-icons";
import { HiOutlineChevronDown, HiOutlineChevronUp } from "react-icons/hi";
import classNames from "classnames";
export interface Props {
  className?: string;
  childrenClassName?: string;
  children: ReactNode;
  Icon?: IconType;
  title?: string;
}

const Dropdown = ({
  className = "",
  childrenClassName = "",
  children,
  Icon,
  title = "",
}: Props) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const toggle = useCallback(() => {
    setCollapsed((c) => !c);
  }, [setCollapsed]);

  return (
    <div
      className={`${className} flex flex-col rounded bg-white shadow`}
      data-testid="Dropdown"
    >
      <div
        className="flex items-center justify-between bg-mr-gray-200 px-4 py-2 hover:cursor-pointer"
        onClick={toggle}
        data-testid="Dropdown_header"
      >
        <div className="flex items-center gap-3">
          {Icon && (
            <div className="rounded-full bg-white">
              <Icon className="m-2 h-5 w-5" />
            </div>
          )}
          <span className="font-semibold">{title && title}</span>
        </div>

        <div className="rounded-full p-2 hover:bg-gray-200">
          {collapsed ? (
            <HiOutlineChevronDown className="h-5 w-5" />
          ) : (
            <HiOutlineChevronUp className="h-5 w-5" />
          )}
        </div>
      </div>

      <div
        className={classNames('dropdown', { 'open': !collapsed })}
        data-testid="Dropdown_content"
      >
        <div className={classNames('dropdown-inner')}>
          <div className={childrenClassName}>
            {children && children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Dropdown);
