import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { deleteSetUsers, getSetUser, getSetUsers, postSetUsers } from "../api";

export function useSetUsers(id?: string) {
  const queryClient = useQueryClient();
  const setUsersQuery = useQuery({
    queryKey: ["set_users"],
    queryFn: getSetUsers,
  });

  const setUserQuery = useQuery({
    queryKey: ["set_users", id],
    queryFn: () => getSetUser(id as string),
    enabled: Boolean(id),
  });

  const postQuery = useMutation({
    mutationFn: (newSetUser: PostSetUsersParam) => postSetUsers(newSetUser),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["sets"] });
      queryClient.invalidateQueries({ queryKey: ["set_users"] });
    },
  });

  const deleteQuery = useMutation({
    mutationFn: (setId: string) => deleteSetUsers(setId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["sets"] });
      queryClient.invalidateQueries({ queryKey: ["set_users"] });
    },
  });

  return {
    setUsersQuery,
    setUserQuery,
    postQuery,
    deleteQuery,
  };
}
