import { memo } from "react";
import Button, { Props } from "./Button";
import { twMerge } from "tailwind-merge";

const TertiaryButton = ({ children, className = "", ...props }: Props) => (
  <Button
    className={twMerge(
      "bg-mr-gray-900 text-gray-50 hover:enabled:bg-mr-gray-500 disabled:cursor-not-allowed disabled:opacity-50",
      className
    )}
    data-testid="TertiaryButton"
    {...props}
  >
    {children}
  </Button>
);

export default memo(TertiaryButton);
