import {
  MouseEvent,
  ReactNode,
  memo,
  useCallback,
  useRef,
  useState,
} from "react";
import { useClickAway } from "../../hooks";
import { HiCheck, HiPencil } from "react-icons/hi";

export interface Props {
  className?: string;
  children: ReactNode;
  input: ReactNode;
  onClickAway: () => void;
}

const EditableField = ({
  className = "",
  children,
  input,
  onClickAway,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  function handleDoubleClick(e: MouseEvent<HTMLDivElement>) {
    !isEditing && setIsEditing(true);
  }

  function handleClickEdit(e: MouseEvent<HTMLButtonElement>) {
    !isEditing && setIsEditing(true);
  }

  const handleClickAway = useCallback(() => {
    if (isEditing) {
      onClickAway();
      setIsEditing(false);
    }
  }, [onClickAway, isEditing]);
  useClickAway(ref, handleClickAway);

  function handleClickValidate(e: MouseEvent<HTMLButtonElement>) {
    setIsEditing(false);
  }

  return (
    <div
      className={className}
      data-testid="EditableField"
      onDoubleClick={handleDoubleClick}
      ref={ref}
    >
      {isEditing ? (
        <div className="flex items-center gap-2">
          {input}
          <button onClick={handleClickValidate}>
            <HiCheck className="h-4 w-4" />
          </button>
        </div>
      ) : (
        <div className="group flex items-center gap-2">
          {children}
          <button
            className="hidden group-hover:block"
            onClick={handleClickEdit}
          >
            <HiPencil className="h-4 w-4" />
          </button>
        </div>
      )}
    </div>
  );
};

export default memo(EditableField);
