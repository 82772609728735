import Button from "../NewButton";
import { BsArrowCounterclockwise } from "react-icons/bs";

function ResetSettings() {
  return (
    <Button>
        <BsArrowCounterclockwise />
    </Button>
  )
};

export default ResetSettings;
