import React, { memo, useMemo, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import { useForm, useFormContext } from "react-hook-form";
import {
  HiOutlineXCircle,
  HiSearch,
  HiX
} from "react-icons/hi";

import { SecondaryButton } from "../../../components/core";

export interface Props {
  className?: string;
}

const SearchFormFooter = ({ className = "" }: Props) => {

  const { t } = useTranslation();
  const methods = useFormContext();

  const computeFilters = (brutFilters: any) => {
    console.log("SearchFormFooter::test filters", brutFilters);
    let newCurrentFilters: any[] = [];

    Object.entries(brutFilters).map((elem) => {
      if (elem[1] !== "" && elem[1] !== false) {
        newCurrentFilters.push({ key: elem[0], value: elem[1] });
      }
    });

    return newCurrentFilters;
  };

  const formFilters = useMemo(
    () => computeFilters(methods.watch()),
    [methods.watch()]
  );

  const removeFilter = useCallback(
    (key: string) => () => {
      methods.setValue(key, false);
    },
    [methods]
  );

  const handleClickReset = useCallback(() => {
    methods.reset();
  }, [methods]);

  return (<div className={`flex items-center justify-start ${className}`}>
      <div
        className="z-10 ml-5 flex h-12 w-12 shrink-0 cursor-pointer items-center justify-center rounded-l-full bg-mr-cyan-300 shadow hover:bg-mr-cyan-400"
        onClick={handleClickReset}
      >
        <HiOutlineXCircle className="h-5 w-5 text-white" />
      </div>
      <SecondaryButton
        className="my-5 mr-5 shrink-0 rounded-l-none border-l border-white"
        startIcon={<HiSearch />}
        type="submit"
        size="big"
      >
        {`${t("SearchEnginePage.filters.search")} : ${formFilters.length}`}
      </SecondaryButton>
      <div className="m-5 flex flex-wrap gap-2">

      {formFilters &&
        formFilters.map((elem:any, index:number) => (
          <div
            key={index}
            className="flex cursor-pointer items-center rounded border bg-white pr-2"
          >
            <HiX
              className="h-full w-6 p-1 hover:text-mr-cyan-400"
              onClick={removeFilter(elem.key)}
            />
            <div className="mr-2 h-4 w-0 border-r" />
            {t(`SearchEnginePage.filters.badge.${elem.key}`)}
            <div className="ml-2 text-mr-cyan-400">
              {elem.value && elem.value !== true && elem.value}
            </div>
          </div>
        ))}
      </div>
    </div>)
}

export default memo(SearchFormFooter);
