import { API_BASE_URL, AUTH_END_POINT } from "../constants";
import { axiosPrivatePatch } from "./axios";

export async function patchDefaultFileBehaviors({
  id,
  ...dfb
}: PatchDefaultFileBehaviorsParam) {
  const { data } =
    await axiosPrivatePatch.patch<PatchDefaultFileBehaviorsResponse>(
      `${API_BASE_URL}${AUTH_END_POINT.DEFAULT_FILE_BEHAVIORS}/${id}`,
      dfb
    );

  return data;
}
