export const LEGAL_ENTITY_TYPES_FIXTURE: GetLegalEntityTypeResponse = {
  "@context": "/contexts/LegalEntityType",
  "@id": "/legal_entity_types",
  "@type": "hydra:Collection",
  "hydra:member": [
    {
      "@id": "/legal_entity_types/1",
      "@type": "LegalEntityType",
      id: 1,
      name: "EI",
      productions: [],
    },
    {
      "@id": "/legal_entity_types/2",
      "@type": "LegalEntityType",
      id: 2,
      name: "EURL",
      productions: [],
    },
    {
      "@id": "/legal_entity_types/3",
      "@type": "LegalEntityType",
      id: 3,
      name: "SARL",
      productions: [],
    },
    {
      "@id": "/legal_entity_types/4",
      "@type": "LegalEntityType",
      id: 4,
      name: "SASU",
      productions: [],
    },
    {
      "@id": "/legal_entity_types/5",
      "@type": "LegalEntityType",
      id: 5,
      name: "SAS",
      productions: [],
    },
    {
      "@id": "/legal_entity_types/6",
      "@type": "LegalEntityType",
      id: 6,
      name: "SA",
      productions: [],
    },
    {
      "@id": "/legal_entity_types/7",
      "@type": "LegalEntityType",
      id: 7,
      name: "SNC",
      productions: [],
    },
    {
      "@id": "/legal_entity_types/8",
      "@type": "LegalEntityType",
      id: 8,
      name: "SCS",
      productions: [],
    },
    {
      "@id": "/legal_entity_types/9",
      "@type": "LegalEntityType",
      id: 9,
      name: "SCA",
      productions: [],
    },
  ],
  "hydra:totalItems": 9,
};
