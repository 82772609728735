import { useMutation, useQueryClient } from "@tanstack/react-query";
import { patchDefaultFileBehaviors } from "../api";

export function useDefaultFileBehaviors() {
  const queryClient = useQueryClient();

  const patch = useMutation({
    mutationFn: (data: PatchDefaultFileBehaviorsParam) =>
      patchDefaultFileBehaviors(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["company_parameters"] });
      queryClient.invalidateQueries({ queryKey: ["employee_group"] });
    },
  });

  return {
    patch,
  };
}
