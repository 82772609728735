import dayjs from "dayjs";
import { API_BASE_URL, AUTH_END_POINT } from "../constants";
import { axiosPrivate } from "./axios";
import { GetCompanyRegistrations, QuickView } from "../types/employee";
import axiosModule, { AxiosError } from "axios";
import { RegistrationData } from "../types/Registration";

export async function getRegistrationData(compagnyId: string, registrationId: string, signal?: AbortSignal): Promise<RegistrationData> {
  const { data } = await axiosPrivate.get<RegistrationData>(
    AUTH_END_POINT.REGISTRATION.DATA(compagnyId, registrationId),
    {
      signal,
    }
  );

  return data;
}

export async function getRegistration(id: string, signal?: AbortSignal) {
  const { data } = await axiosPrivate.get(
    AUTH_END_POINT.REGISTRATION.ONE(id),
    {
      signal,
    }
  );

  return data;
}


export async function fetchRegistrations(id: string, signal?: AbortSignal) {
  const response = await axiosPrivate.get<GetCompanyRegistrations>(
    AUTH_END_POINT.COMPANIES.REGISTRATIONS(id),
    {
      signal,
    }
  );

  return response.data["hydra:member"];
}

/**
 * Like fetchRegistrations, but with an array params
 * @param params {companyId: string}
 * @returns Set of registrations
 */
export async function getRegistrations(params: any) {
  try {
    const response = await axiosPrivate.get(
      `${API_BASE_URL}${AUTH_END_POINT.COMPANIES.REGISTRATIONS(params.companyId)}`,
      { params }
    ).then(res => {
      /**
       * Date comes from server as String but Typescript doesn't recognize it as a Date
       */
      /* if (res.data["hydra:member"]) {
        res.data["hydra:member"].forEach((dpae: DPAE) => {
          if (dpae.dateNaissanceSalarie) {
            dpae.dateNaissanceSalarie = new Date(dpae.dateNaissanceSalarie);
          }
        })
      } */
      return res;
    });
    return response?.data;
  } catch (err) {
    if (axiosModule.isAxiosError(err)) {
      const error = err as AxiosError;
      throw error?.response?.status;
    } else {
      throw err;
    }
  }
}


export async function fetchQuickView(
  url:
    | {
      company_id: string;
      registration_id: string;
    }
    | string
) {
  const { data } = await axiosPrivate.get<QuickView>(
    `${typeof url === "string"
      ? url
      : AUTH_END_POINT.COMPANIES.QUICK_VIEW(
        url.company_id,
        url.registration_id
      )
    }`
  );

  return {
    ...data,
    profile: {
      ...data.profile,
      birthday: dayjs(data.profile.birthday).format("YYYY-MM-DD"),
      retirement_date: dayjs(data.profile.retirement_date).format("YYYY-MM-DD"),
    },
  };
}
