export const HAIR_COLORS_FIXTURE: GetHairColorsResponse = {
  "@context": "/contexts/HairColor",
  "@id": "/hair_color",
  "@type": "hydra:Collection",
  "hydra:member": [
    {
      "@id": "/hair_colors/1",
      "@type": "HairColor",
      name: "green",
      hexCode: "#FFFFFF",
    },
    {
      "@id": "/hair_colors/2",
      "@type": "HairColor",
      name: "blue",
      hexCode: "#FFFFFF",
    },
  ],
  "hydra:totalItems": 2,
};
