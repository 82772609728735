import { memo } from "react";
import Button, { Props } from "./Button";
import { twMerge } from "tailwind-merge";

const DangerButton = memo(
  ({ children, className = "", ...props }: Props) => (
    <Button
      className={twMerge(
        "bg-red-500 text-gray-50 hover:enabled:bg-red-600 disabled:cursor-not-allowed disabled:opacity-50",
        className
      )}
      data-testid="DangerButton"
      {...props}
    >
      {children}
    </Button>
  )
);

export default memo(DangerButton);
