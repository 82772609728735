import { memo, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export interface Props {
  variant? : "default" | "primary" | "secondary" | "tertiary" | "success";
  size?: "small" | "big";
  outline?: boolean;
  className?: string;
  children?: ReactNode;
}

const Badge = ({
  className = "",
  size = "small",
  variant = "default",
  children,
  outline = false,
  ...props
}: Props) => {

  let badgeClassName = "";

  switch ( variant ) {
    case "primary" :
      badgeClassName = twMerge(
        "border border-mr-orange-400",
        outline ? "bg-transparent text-current" : "bg-mr-orange-400 text-white"
       );
      break;

    case "secondary":
      break;

    case "tertiary":
      badgeClassName = twMerge(
        "border border-mr-gray-900",
        outline ? "bg-transparent text-current" : "bg-mr-gray-900 text-white"
      );
      break;
  }

  return (
    <div
      className={twMerge(
        "inline-flex items-center rounded-full font-medium",
        size === "small" && "px-3 py-1 text-xs",
        size === "big" && "px-9 py-3 text-base",
        outline && "border",
        badgeClassName,
        className
      )}
      data-testid="Badge"
      {...props}
    >
      {children && children}
    </div>
  );
};

export default memo(Badge);
