import { atom } from "recoil";

interface ForgotPasswordModal {
  open: boolean;
}

const ForgotPasswordModal = atom<ForgotPasswordModal>({
  key: "ForgotPasswordModal",
  default: { open: false },
});

export default ForgotPasswordModal;
