
import cn from "mxcn";
import React from "react"
import { Button as AriaButton, type ButtonProps } from 'react-aria-components';

function Button({ children, className, ...props }: ButtonProps) {
  return (
    <AriaButton className={cn('react-aria-Button bg-table-grey flex-none border border-[#DADADA]', className)} {...props}>{children}</AriaButton>
  )
};

export default Button;
