import { cloneDeep } from "lodash";
import { typeContract } from "../types/type_contract.enum.d";
import JSZip from "jszip";

/**
 * An URRSAF Row definition as it is set in their documentation (see below)
 */
export interface UrssafRow {
  id: string,
  start: number,
  end: number
  filledBySystem: boolean,
  blocked: boolean,
  value?: string
}

export function useUrssafExport(rows?: DPAEDetail[]) {
  const log = [] as string[]; // Not implemented yet

  const fromDPAEToURSSAF = (dpae: DPAEDetail, test: boolean = false): string => {
    const currentUrssafRowSet = cloneDeep(UrssafRowSet); // Clone UrssafRowSet as it's the final definition

    /* Quick function to report a Date object to URSSAF format (JJMMYYYY) */
    const fromDateToUrssafDate = function (d: Date) {
      const day = String(d.getDate()).padStart(2, '0'); // jour sur 2 chiffres avec padding
      const month = String(d.getMonth() + 1).padStart(2, '0'); // mois sur 2 chiffres avec padding
      const year = d.getFullYear(); // année sur 4 chiffres

      return day + month + year as string;
    }

    /* Quick function to report a Date object to URSSAF format (JJMMYYYY) */
    const fromDateToUrssafHour = function (d: Date) {
      if (typeof d !== 'object') {
        d = new Date(d);
      }
      return (
        d.getHours().toString().padStart(2, '0') +
        d.getMinutes().toString().padStart(2, '0')) as string;
    }

    /* Main process, bind DPAE field to URSSAF field */
    currentUrssafRowSet.forEach((r) => {
      const length = r.end - r.start + 1; // start & end are cursor positions, so we need to add 1 to get string expected length

      /**
       * URSSAF reserved fields management
       */
      if (r.blocked || r.filledBySystem) {
        r.value = String.fromCharCode(32).repeat(length);
        return;
      }
      /**
       * Application code to be filled with the value 'DUE + 1 blank
       */
      if (r.id === 'C_APPLICATION') {
        r.value = 'DUE' + String.fromCharCode(32);
        return;
      }
      /**
       * Line size EXCLUDING HEADER is 77 characters:
       * Must be filled with 912 if all fields are filled in or if the line is completed with blanks
       */
      if (r.id === 'N_LG_MESS') {
        r.value = '912'
      }
      if (r.id === 'C_VERSION') {
        r.value = test ? 'TST' : '120'
      }
      /**
       * Employee ID
       */
      if (r.id === 'MATRICULE') {
        r.value = dpae.id!;
      }
      /**
       * Check URSSAF Code list document
       */
      if (r.id === 'C_URSSAF') {
        r.value = dpae.codeUrssafEmployeur!;
      }
      /**
       * SIRET number of the establishment.
       * If this number is unknown in the database, it will be created at the time of reading the record
       */
      if (r.id === 'N_SIRET') {
        r.value = dpae.siretEmployeur!;
      }
      /**
       * Name or company name (line 1)
       * Allowed characters: A ...Z, 0 ...9, space, minus (-), and &
       */
      if (r.id === 'L_RAISON_SOC_1') {
        if (!(/^[A-Za-z0-9&\- ]+$/.test(dpae.nomEmployeur!))) {
          log.push('Caractères non autorisés retirés du nom employeur');
        }
        r.value = dpae.nomEmployeur!?.normalize('NFD').toUpperCase().replace(/[^A-Za-z0-9&\- ]/g, "");
      }
      /**
       * Establishment address (1st line).
       * Allowed characters: A ...Z, 0 ...9, space, minus (-), &, period (.), comma (,), and apostrophe (‘)
       */
      if (r.id === 'L_ADR_EMP_1') {
        if (!(/^[A-Za-z0-9&\-',. ]+$/.test(dpae.adresseEmployeur!))) {
          log.push('Caractères non autorisés retirés de l’adresse de l’employeur');
        }
        r.value = dpae.adresseEmployeur!?.normalize('NFD').toUpperCase().replace(/[^A-Za-z0-9&\-',. ]/g, "");
      }
      /**
       * Establishment address (2nd line).
       */
      if (r.id === 'L_ADR_EMP_2') {
        // Not implemented, address 2 not available in DPAE
      }
      /**
       * Establishment Post code
       */
      if (r.id === 'C_POSTAL_EMP') {
        r.value = dpae.codePostalEmployeur!;
      }
      /**
       * Establishment city
       * Allowed characters: A ...Z, 0 ...9, space, minus (-), &, period (.), comma (,), and apostrophe (‘)
       */
      if (r.id === 'L_BUR_DIST_EMP') {
        if (!(/^[A-Za-z0-9&\-',. ]+$/.test(dpae.adresseEmployeur!))) {
          log.push('Unauthorized characters removed from the employer’s city');
        }
        r.value = dpae.villeEmployeur!?.normalize('NFD').toUpperCase().replace(/[^A-Za-z0-9&\-',. ]/g, "");
      }
      /**
       * Employee's last name.
       * Allowed characters: A ...Z, space, minus (-), &, and period (.)
       * Birth name / Spouse name support
       */
      if (r.id === 'L_NOM_PATRO_SAL') {
        let lastname = dpae.nomNaissance && dpae.nom !== dpae.nomNaissance ? dpae.nomNaissance : dpae.nom!;
        if (!(/^[A-Za-z0-9&\-. ]+$/.test(lastname))) {
          log.push('Unauthorized characters removed from the employer’s employee name');
        }
        r.value = lastname.normalize('NFD').toUpperCase().replace(/[A-Za-z0-9&\-. ]/g, "");
      }
      /**
       * Employee's first name.
       * Allowed characters: A ...Z, space, minus (-), &, and period (.)
       */
      if (r.id === 'L_PRENOMS_SAL') {
        if (!(/^[A-Za-z0-9&\-. ]+$/.test(dpae.prenom!))) {
          log.push('Unauthorized characters removed from the employer’s employee name');
        }
        r.value = dpae.prenom!?.normalize('NFD').toUpperCase().replace(/[A-Za-z0-9&\-. ]/g, "");
      }
      /**
       * Social security number
       * 13 characters, the last two of the French social security number are a check key.
       */
      if (r.id === 'N_SECU_SOC') {
        r.value = dpae.numeroSecuriteSociale!.slice(0, 13);
      }
      /**
       * Birthday 
       * format JJMMAAAA.
       */
      if (r.id === 'D_NAISSANCE_SAL') {
        r.value = fromDateToUrssafDate(dpae.dateNaissanceSalarie);
      }
      /**
       * Employee's birthplace (town or country).
       * Allowed characters: A ...Z, 0 ... 9, space, minus (-), comma (,), apostrophe (‘), and period (.)
       */
      if (r.id === 'L_LIEU_NAISS_SAL') {
        if (!(/^[A-Za-z0-9\-.', ]+$/.test(dpae.lieuNaissanceSalarie!))) {
          log.push('L_LIEU_NAISS_SAL: Caractères non autorisés retirés du lieu de naissance du salarié');
        }
        r.value = dpae.lieuNaissanceSalarie!?.normalize('NFD').toUpperCase().replace(/[^A-Za-z0-9\-.', ]/g, "");
      }
      /**
       * Contract start (format JJMMAAAA).
       */
      if (r.id === 'D_EMBAUCHE') {
        r.value = fromDateToUrssafDate(dpae.dateDebutContrat!);
      }
      /**
       * Contract start (format HHMM).
       */
      if (r.id === 'H_EMBAUCHE') {
        r.value = fromDateToUrssafHour(dpae.dateDebutContrat!);
      }
      /**
       * Employer name (line 2).
       */
      if (r.id === 'L_RAISON_SOC_2') {
        // Not implemented, not available in DPAE
      }
      /**
       * Phone
       */
      if (r.id === 'N_TEL_EMP') {
        r.value = dpae.telephoneEmployeur!;
      }
      /**
       * NAF Code 
       */
      if (r.id === 'C_NAF_NN') {
        r.value = dpae.codeNafEmployeur!;
      }
      /**
       * Spouse name
       */
      if (r.id === 'L_NOM_EPX_SAL') {
        r.value = dpae.nomNaissance && dpae.nom !== dpae.nomNaissance ? dpae.nom! : '';
      }
      /**
       * Gender (M ou F).
       */
      if (r.id === 'C_SEXE_SAL') {
        r.value = ['H', 'M'].includes(dpae.sexeSalarie!.value) ? 'M' : 'F';
      }
      /**
       * Department code of birth.
       * In two digits
       */
      if (r.id === 'C_DEPT_NAISS_SAL') {
        r.value = dpae.deptNaissanceSalarieLocalCode!.slice(0, 2).padStart(2, '0');
      }
      /**
       * Code du département de naissance.
       * Sur deux chiffres
       */
      if (r.id === 'C_TYPE_CONTRAT') {
        if (dpae.typeContrat! === typeContract.CDD) {
          r.value = '1';
        }
        if (dpae.typeContrat! === typeContract.CDI) {
          r.value = '2';
        }
        if (dpae.typeContrat! === typeContract.CTT) {
          r.value = '3';
        }
      }
      /**
       * End date of fixed-term contract (format DDMMYYYY).
       * Mandatory for fixed-term contracts
       */
      if (r.id === 'D_FIN_CDD') { // Obligatoire pour les contrats de type CDD.
        r.value = fromDateToUrssafDate(dpae.dateFinContrat);
      }
      /**
       * Try period in days
       */
      if (r.id === 'N_JJ_ESSAI') {
        r.value = dpae.dureePeriodeEssai!;
      }
      /**
      * Occupational health center code
      * This field is mandatory except for a temporary work contract
      * The occupational health codes are in the document containing the Health Service Codes.
      * Here is expected a code of the type MT+ 3 numerical positions. Example: MT542 designating the code of your occupational health center
      * If you have an internal occupational health center at the establishment, enter MT999
      */
      if (r.id === 'C_MT_DCL') {
        r.value = dpae.codeCentreMedecineTravail!;
      }
      /**
       * Cut string if too long to the exepected lenght
       */
      if (r.value && r.value.length > length) {
        log.push(`${r.id} : Value is truncated to ${length} characters (previously ${r.value.length}), ${r.value}`);
        r.value = r.value.substring(0, length);
      }
      /**
       * Pad string if too short ti tge expected length
       */
      if (r.value && r.value.length < length) {
        r.value = r.value.padStart(length, ' ');
      }
      /**
       * Pad string if empty
       */
      if (!r.value) {
        r.value = String.fromCharCode(32).repeat(length);
      }
    })
    const textRow = currentUrssafRowSet.map(r => r.value).join('') + String.fromCharCode(10);
    if (textRow.length > 990) {
      log.push(`Row contains more than 990 characters which is not allowed from URSSAF (${textRow.length})`);
    }
    if (textRow.length < 990) {
      log.push(`Row contains less than 990 characters which is not allowed from URSSAF (${textRow.length})`);
    }
    return textRow;
  }

  return {
    /* Export Rows to a TXT file */
    urssafExport: (filename: string) => {
      /* Loop on each to get an array of TXT rows */
      const urssafRows = rows ? rows.map(r => fromDPAEToURSSAF(r)) : [];

      /* @todo may be improved : log to console if any error messages appears */
      if (log.length) {
        console.log(log);
      }
      /* URSSAF can't take TXT files with more than 100 lines inside */
      const urssafMaxLinesAllowed = 100;
      if (urssafRows.length > urssafMaxLinesAllowed) {
        const zip = new JSZip();

        /* Split them into chunks of urssafMaxLinesAllowed lines */
        const urssafRowsSet = [];
        while (urssafRows.length > 0) {
          urssafRowsSet.push(urssafRows.splice(0, urssafMaxLinesAllowed));
        }
        /* For each chunk, create a TXT file and put it in the zip */
        urssafRowsSet.forEach((rows, index) => {
          zip.file(`${index + 1}.txt`, rows.join(''));
        });

        /* Propose zip to download */
        zip.generateAsync({ type: 'blob' }).then((content) => {
          const blob = new Blob([content], { type: 'application/zip' });
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = filename.replace(/\.(txt|zip)$/i, '') + '.zip';
          a.click();
        });
      } else {
        /* Join them together, no needs to put carriage return since it's already in the line */
        const urssaf = urssafRows.join('');

        /* Propose it to download */
        const blob = new Blob([urssaf], { type: 'text/plain;charset=utf-8' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename.replace('.txt', '') + '.txt';
        a.click();
      }
    },
  }
}


/**
 * Documentation available here : 
 * https://www2.due.urssaf.fr/static_declarant/email/v5.3_FormatDPAE_EMAIL.pdf
 */
const UrssafRowSet = [
  {
    id: 'L_IDENT_EMT',
    start: 1,
    end: 14,
    filledBySystem: true,
    blocked: false
  },
  {
    id: 'C_APPLICATION',
    start: 15,
    end: 18,
    filledBySystem: false,
    blocked: false
  },
  {
    id: 'RESERVE1',
    start: 19,
    end: 33,
    filledBySystem: true,
    blocked: true
  },
  {
    id: 'RESERVE2',
    start: 34,
    end: 34,
    filledBySystem: true,
    blocked: true
  },
  {
    id: 'RESERVE3',
    start: 35,
    end: 64,
    filledBySystem: true,
    blocked: true
  },
  {
    id: 'N_LG_MESS',
    start: 65,
    end: 72,
    filledBySystem: false,
    blocked: false
  },
  {
    id: 'C_VERSION',
    start: 73,
    end: 75,
    filledBySystem: false,
    blocked: false
  },
  {
    id: 'C_RET_AR_LOT',
    start: 76,
    end: 77,
    filledBySystem: true,
    blocked: false
  },
  {
    id: 'MATRICULE',
    start: 78,
    end: 121,
    filledBySystem: false,
    blocked: false
  },
  {
    id: 'D_CREATION',
    start: 122,
    end: 129,
    filledBySystem: false,
    blocked: false
  },
  {
    id: 'H_CREATION',
    start: 130,
    end: 133,
    filledBySystem: true,
    blocked: false
  },
  {
    id: 'C_URSSAF',
    start: 134,
    end: 136,
    filledBySystem: false,
    blocked: false
  },
  {
    id: 'N_SIRET',
    start: 137,
    end: 150,
    filledBySystem: false,
    blocked: false
  },
  {
    id: 'RESERVE4',
    start: 151,
    end: 151,
    filledBySystem: true,
    blocked: true
  },
  {
    id: 'L_RAISON_SOC_1',
    start: 152,
    end: 183,
    filledBySystem: false,
    blocked: false
  },
  {
    id: 'RESERVE5',
    start: 184,
    end: 187,
    filledBySystem: true,
    blocked: true
  },
  {
    id: 'L_ADR_EMP_1',
    start: 188,
    end: 219,
    filledBySystem: false,
    blocked: false
  },
  {
    id: 'L_ADR_EMP_2',
    start: 220,
    end: 251,
    filledBySystem: false,
    blocked: false
  },
  {
    id: 'C_POSTAL_EMP',
    start: 252,
    end: 256,
    filledBySystem: false,
    blocked: false
  },
  {
    id: 'L_BUR_DIST_EMP',
    start: 257,
    end: 283,
    filledBySystem: false,
    blocked: false
  },
  {
    id: 'L_NOM_PATRO_SAL',
    start: 284,
    end: 315,
    filledBySystem: false,
    blocked: false
  },
  {
    id: 'L_PRENOMS_SAL',
    start: 316,
    end: 347,
    filledBySystem: false,
    blocked: false
  },
  {
    id: 'N_SECU_SOC',
    start: 348,
    end: 360,
    filledBySystem: false,
    blocked: false
  },
  {
    id: 'D_NAISSANCE_SAL',
    start: 361,
    end: 368,
    filledBySystem: false,
    blocked: false
  },
  {
    id: 'L_LIEU_NAISS_SAL',
    start: 369,
    end: 392,
    filledBySystem: false,
    blocked: false
  },
  {
    id: 'RESERVE6',
    start: 393,
    end: 412,
    filledBySystem: true,
    blocked: true
  },
  {
    id: 'D_EMBAUCHE',
    start: 413,
    end: 420,
    filledBySystem: false,
    blocked: false
  },
  {
    id: 'H_EMBAUCHE',
    start: 421,
    end: 424,
    filledBySystem: false,
    blocked: false
  },
  {
    id: 'R_DOSSIER',
    start: 425,
    end: 429,
    filledBySystem: true,
    blocked: false
  },
  {
    id: 'C_RETOUR_AR',
    start: 430,
    end: 431,
    filledBySystem: true,
    blocked: false
  },
  {
    id: 'RESERVE7',
    start: 432,
    end: 509,
    filledBySystem: true,
    blocked: true
  },
  {
    id: 'L_RAISON_SOC_2',
    start: 510,
    end: 541,
    filledBySystem: false,
    blocked: false
  },
  {
    id: 'N_TEL_EMP',
    start: 542,
    end: 552,
    filledBySystem: false,
    blocked: false
  },
  {
    id: 'RESERVE8',
    start: 553,
    end: 659,
    filledBySystem: true,
    blocked: true
  },
  {
    id: 'C_ORIG_SAISIE',
    start: 660,
    end: 660,
    filledBySystem: true,
    blocked: false
  },
  {
    id: 'C_NAF_NN',
    start: 661,
    end: 665,
    filledBySystem: false,
    blocked: false
  },
  {
    id: 'RESERVE9',
    start: 666,
    end: 698,
    filledBySystem: true,
    blocked: true
  },
  {
    id: 'L_NOM_EPX_SAL',
    start: 699,
    end: 730,
    filledBySystem: false,
    blocked: false
  },
  {
    id: 'C_SEXE_SAL',
    start: 731,
    end: 731,
    filledBySystem: false,
    blocked: false
  },
  {
    id: 'RESERVE10',
    start: 732,
    end: 827,
    filledBySystem: true,
    blocked: true
  },
  {
    id: 'C_DEPT_NAISS_SAL',
    start: 828,
    end: 829,
    filledBySystem: false,
    blocked: false
  },
  {
    id: 'RESERVE11',
    start: 830,
    end: 864,
    filledBySystem: true,
    blocked: true
  },
  {
    id: 'C_TYPE_CONTRAT',
    start: 865,
    end: 865,
    filledBySystem: false,
    blocked: false
  },
  {
    id: 'D_FIN_CDD',
    start: 866,
    end: 873,
    filledBySystem: false,
    blocked: false
  },
  {
    id: 'RESERVE12',
    start: 874,
    end: 884,
    filledBySystem: true,
    blocked: true
  },
  {
    id: 'N_JJ_ESSAI',
    start: 885,
    end: 887,
    filledBySystem: false,
    blocked: false
  },
  {
    id: 'RESERVE13',
    start: 888,
    end: 937,
    filledBySystem: true,
    blocked: true
  },
  {
    id: 'C_MT_DCL',
    start: 938,
    end: 947,
    filledBySystem: false,
    blocked: false
  },
  {
    id: 'RESERVE14',
    start: 948,
    end: 970,
    filledBySystem: true,
    blocked: true
  },
  {
    id: 'I_CERTIF',
    start: 971,
    end: 971,
    filledBySystem: true,
    blocked: false
  },
  {
    id: 'RESERVE15',
    start: 972,
    end: 973,
    filledBySystem: true,
    blocked: true
  },
  {
    id: 'I_IMMA',
    start: 974,
    end: 974,
    filledBySystem: true,
    blocked: false
  },
  {
    id: 'I_MT',
    start: 975,
    end: 975,
    filledBySystem: true,
    blocked: false
  },
  {
    id: 'I_PMF5',
    start: 976,
    end: 976,
    filledBySystem: true,
    blocked: false
  },
  {
    id: 'RESERVE16',
    start: 977,
    end: 989,
    filledBySystem: true,
    blocked: true
  }
] as UrssafRow[];