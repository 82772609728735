import { AUTH_END_POINT } from "../constants";
import { axiosPrivate } from "./axios";

export async function getSetUser(id: string) {
  const { data } = await axiosPrivate.get<GetSetUser>(
    `${AUTH_END_POINT.SET_USERS}/${id}`
  );

  return data;
}

export async function getSetUsers() {
  const { data } = await axiosPrivate.get<GetSetUsers>(
    `${AUTH_END_POINT.SET_USERS}`
  );

  return data["hydra:member"];
}

export async function postSetUsers(newSetUser: PostSetUsersParam) {
  const { data } = await axiosPrivate.post<PostSetUsers>(
    AUTH_END_POINT.SET_USERS,
    newSetUser
  );

  return data;
}

export async function deleteSetUsers(id: string) {
  const { data } = await axiosPrivate.delete(
    `${AUTH_END_POINT.SET_USERS}/${id}`
  );

  return data;
}
