import classNames from "classnames";
import cn from "mxcn";
import {
  Children,
  ReactElement,
  ReactNode,
  cloneElement,
  useCallback,
  useState,
} from "react";
import { HiOutlineChevronDown, HiOutlineChevronUp } from "react-icons/hi";

interface Props {
  children: ReactElement[] | ReactElement | ReactNode;
  variant?: "small" | "default";
  className?: string;
  dropdown?: boolean;
  collapsed?: boolean;
  contentClassName?: string;
  row?: boolean;
}

const Box = ({ children, variant, className, dropdown }: Props) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const toggle = useCallback(() => {
    dropdown && setCollapsed((c) => !c);
  }, [dropdown]);

  return (
    <div
      className={cn(
        `box flex flex-col rounded-[8px] bg-white shadow-box`,
        className,
      )}
    >
      {Children.map(children, (child) => {
        // @ts-ignore
        return child.type === Box.Content || child.type === Box.Title // @ts-ignore
          ? cloneElement(child, { variant, collapsed, dropdown, toggle })
          : // @ts-ignore
            child;
      })}
    </div>
  );
};

Box.Title = ({
  children,
  icon,
  className,
  iconClassName,
  textClassName,
  dropdown,
  collapsed,
  toggle,
}: any) => {
  return (
    <div
      className={cn(
        `box__title flex items-center justify-between rounded-tl-[8px] rounded-tr-[8px] bg-mr-gray-200 px-5 pb-[14px] pt-4  ${dropdown ? "hover:cursor-pointer" : ""}`,
        className,
      )}
      onClick={toggle}
    >
      <div className={cn(`flex items-center justify-start`)}>
        {icon && (
          <div
            className={cn(
              "flex items-center justify-center rounded-full bg-white p-[10px]",
              iconClassName,
            )}
          >
            {cloneElement(icon, { className: "h-4 w-4 text-gray-700" })}
          </div>
        )}
        <p className={cn("ml-4 font-bold text-mr-gray-900", textClassName)}>
          {children}
        </p>
      </div>
      {dropdown && (
        <div className="rounded-full p-2 hover:bg-gray-200">
          {collapsed ? (
            <HiOutlineChevronDown className="h-5 w-5" />
          ) : (
            <HiOutlineChevronUp className="h-5 w-5" />
          )}
        </div>
      )}
    </div>
  );
};

Box.Content = ({
  children,
  contentClassName,
  variant = "default",
  collapsed,
}: Props) => {
  return (
    <div
      className={cn("box__content dropdown", {
        open: !collapsed,
        [`${variant}`]: !collapsed,
      })}
    >
      <div
        className={cn("dropdown-inner gap-8 flex flex-col", contentClassName)}
      >
        {children}
      </div>
    </div>
  );
};

export default Box;
