import React, { memo } from 'react';
import { ToggleButton as AriaToggleButton, ToggleButtonProps } from 'react-aria-components';
import './ToggleButton.css';

export interface Props extends ToggleButtonProps {
    className?: string;
    children: React.ReactNode;
}



const ToggleButton = (
    {
        className = "",
        children,
        ...props
    }: Props,
) => {

    return (
        <AriaToggleButton {...props} className={`${className} react-aria-ToggleButton border border-mr-gray-1000`}>{children}</AriaToggleButton>
    );
};

export default memo(ToggleButton);
