import {
  Checkbox as AriaCheckbox,
  type CheckboxProps,
} from "react-aria-components";
import "./Checkbox.css";
import cn from "mxcn";
import Label, { LabelWrapperProps } from "../Label";
import { ReactNode, Ref } from "react";

export interface Props extends CheckboxProps {
  labelProps?: Omit<LabelWrapperProps, "children, required, disabled">;
  ref?: Ref<HTMLLabelElement>;
  containerClassName?: string;
}

function Checkbox({
  children,
  className,
  containerClassName,
  labelProps,
  ref,
  ...props
}: Props) {
  const extraProps: { defaultSelected?: boolean } = {};

  if (props.value) {
    extraProps.defaultSelected = Boolean(props.value);
  }

  return (
    <>
      <AriaCheckbox
        ref={ref}
        className={cn("react-aria-Checkbox group", className)}
        {...props}
        {...extraProps}
      >
        {({ isIndeterminate }) => (
          <>
            {labelProps?.title && (
              <Label.Title
                disabled={props.isDisabled}
                required={props.isRequired}
                className={cn(labelProps?.labelClass, "text-sm")}
                info={labelProps?.info}
              >
                {labelProps?.title}
              </Label.Title>
            )}
            <span className={cn(containerClassName)}>
              <span className="checkbox group-disabled:opacity-50">
                <svg viewBox="0 0 18 18" aria-hidden="true">
                  {isIndeterminate ? (
                    <rect x={1} y={7.5} width={15} height={3} />
                  ) : (
                    <polyline points="1 9 7 14 15 4" />
                  )}
                </svg>
              </span>
              {/* @ts-ignore */}
              {children as ReactNode}
            </span>
            {labelProps?.note && <Label.Note>{labelProps?.note}</Label.Note>}
            {labelProps?.errorLabel && (
              <Label.Error className="mt-0">
                {labelProps?.errorLabel}
              </Label.Error>
            )}
          </>
        )}
      </AriaCheckbox>
    </>
  );
}

export default Checkbox;
