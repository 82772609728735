import cn from 'mxcn';

const FormSection = ({ columns = 2, children, className }: any) => {
    return (
        <div
            className={cn(
                `FormSection grid grid-cols-${columns % 2 === 0 && columns > 2 ? columns / 2 : columns} xl:grid-cols-${columns} gap-6`,
                className
            )}
        >
            {children}
        </div>
    );
};

FormSection.Divider = ({ children, className }: any) => {
    return (
        <div
            className={cn(
                `FormSection__divider h-[1px] w-full bg-mr-gray-100 my-2`,
                className
            )}
        >
            {children}
        </div>
    );
};

export default FormSection;
