import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { memo } from "react";

import { DashboardProductionDirector } from "../../components/DashboardProductionDirector";

export interface Props {}

const DashboardProductionPage = ({}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{`${t("DashboardComedian.pageTitle")} - ${t("MyRole")}`}</title>
      </Helmet>

      <DashboardProductionDirector key="DashboardProductionDirector" />

      {/* TODO : use a real condition */}
      {/* {auth?.roles?.find((role) => role === "ROLE_USER") && (
        <DashboardProductionManager key="DashboardProductionManager" />
      )} */}
    </>
  );
};

export default memo(DashboardProductionPage);
