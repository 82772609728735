import { memo, MouseEvent, ReactNode } from "react";
import { HiPlusCircle, HiXCircle } from "react-icons/hi";
import { Button } from "../Button";

export interface Props {
  className?: string;
  headers: any[];
  rows: ReactNode[][];
  addRow?: (e: MouseEvent<HTMLButtonElement>) => void;
  addColumn?: (e: MouseEvent<HTMLButtonElement>) => void;
  removeColumn?: (e: MouseEvent<HTMLButtonElement>) => void;
}

const Table = ({
  className = "",
  headers = [],
  rows = [],
  addRow,
  addColumn,
  removeColumn,
}: Props) => {
  const dropShadow = `after:content-[" "] after:absolute after:top-0  after:h-full after:w-[20px]`;
  const dropShadowRight = `${dropShadow} after:right-[-20px] after:shadow-[inset_14px_0_20px_-20px_rgb(0,0,0,0.4)]`;
  const dropShadowLeft = `${dropShadow} after:right-[0px] after:shadow-[inset_-14px_0_20px_-20px_rgb(0,0,0,0.4)]`;

  return (
    <div className="relative">
      {/* LAYOUT OF ABSOLUTE ELEMENT */}
      {addColumn && (
        <Button
          className="absolute -right-2 top-2 z-10 bg-white !p-0"
          onClick={addColumn}
          data-testid="addColumn"
        >
          <HiPlusCircle className="h-7 w-7 text-mr-cyan-400 hover:text-mr-cyan-500" />
        </Button>
      )}
      {addRow && (
        <Button
          className="absolute -bottom-2 left-10 z-10 !p-0"
          onClick={addRow}
          data-testid="addRow"
        >
          <HiPlusCircle className="h-7 w-7 text-mr-cyan-400 hover:text-mr-cyan-500" />
        </Button>
      )}
      <div className="overflow-x-scroll">
        <table className={`${className} table-fixed`} data-testid="Table">
          <thead>
            <tr className="divide-x">
              {headers &&
                headers.map((header, idx) => (
                  <th
                    key={idx}
                    className={`${idx === 0
                      ? `${dropShadowRight} sticky left-0 z-10 min-w-[200px] bg-gray-900 text-left`
                      : "bg-mr-cyan-400"
                      } ${idx === headers.length - 1
                        ? `${dropShadowLeft} sticky left-0 min-w-[200px] p-3 text-sm`
                        : ""
                      } group p-3 text-white`}
                  >
                    <div
                      className={`flex items-center ${idx === 0 ? "justify-start" : "relative justify-center"
                        }`}
                    >
                      {header.name}
                      {removeColumn && idx !== 0 && (
                        <Button
                          className="absolute inset-y-0 right-5 hidden !p-0 group-hover:flex"
                          onClick={removeColumn}
                          value={idx}
                          data-testid="removeColumn"
                        >
                          <HiXCircle className="h-5 w-5 text-gray-100 hover:text-gray-200" />
                        </Button>
                      )}
                    </div>
                  </th>
                ))}
            </tr>
          </thead>

          <tbody className={`divide-y divide-gray-300`}>
            {rows.length > 0 ? (
              rows.map((cells, idx) => (
                <tr key={idx} className="divide-x">
                  {cells.map((cell, idx) => (
                    <td
                      key={idx}
                      className={`${idx === 0
                        ? `${dropShadowRight} sticky z-20 left-0 min-w-[200px] bg-white p-3 text-sm`
                        : ""
                        } ${idx === cells.length - 1
                          ? `${dropShadowLeft} sticky left-0 min-w-[200px] bg-white p-3 text-sm`
                          : ""
                        } group bg-white p-3`}
                    >
                      {cell}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr className="divide-x">
                {headers &&
                  headers.map((_, idx) => (
                    <td
                      key={idx}
                      className={`${idx === 0
                        ? `${dropShadowRight} sticky left-0 min-w-[200px] bg-white p-3 text-sm`
                        : ""
                        } ${idx === headers.length - 1
                          ? `${dropShadowLeft} sticky left-0 min-w-[200px] bg-white p-3 text-sm`
                          : ""
                        } group bg-white p-3`}
                    >
                      <div
                        className={`flex items-center ${idx === 0
                          ? "justify-start"
                          : "relative justify-center"
                          }`}
                      >
                        -
                      </div>
                    </td>
                  ))}
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default memo(Table);
