import React from "react";
import useTableContext from "./useTableContext";

function CurrentTablePage() {
  const { page, totalItems, perPage, totalItemsDisplayed } = useTableContext();

  var startIndex = (page - 1) * perPage + 1;
  var endIndex = Math.min(startIndex + totalItemsDisplayed - 1, totalItems);

  return (
    <>
      {totalItems > 0 && (
        <div className="inline-block rounded-full bg-table-grey px-[8px] pb-[4px] pt-[6px] text-[11px] font-semibold text-[#777777]">
          {startIndex} - {endIndex} sur {totalItems}
        </div>
      )}
    </>
  );
}

export default CurrentTablePage;
