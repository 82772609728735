import classNames from "classnames";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

function LangSelector() {
  const { i18n } = useTranslation();

  useEffect(() => {
    console.log(i18n.language);
  }, [i18n.language]);

  const handleLang = (lang = "fr") => {
    i18n.changeLanguage(lang);
  };

  return (
    <>
      <button
        className={classNames({
          "font-bold underline": i18n.language === "fr",
        })}
        onClick={() => handleLang("fr")}
      >
        FR
      </button>
      <button
        className={classNames({
          "font-bold underline": i18n.language === "en",
        })}
        onClick={() => handleLang("en")}
      >
        EN
      </button>
    </>
  );
}

export default LangSelector;
