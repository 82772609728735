import { memo } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { BsArrowBarRight, BsArrowBarLeft } from "react-icons/bs";
import { MenuItem as MenuItemType } from "../../types/menu";
import { SubSideNavbar } from "../SubSideNavbar";
import { useMenu } from "../../hooks";
import { SecondaryTooltip } from "../Tooltip";
import cn from "mxcn";

// --------------------------------------
// -            MenuItem
// --------------------------------------
interface MenuItemProps extends MenuItemType {
  className?: string;
  isItemActive: boolean;
}

const MenuItem = memo(
  ({
    className = "",
    isItemActive = false,
    to,
    i18nKey,
    Icon,
  }: MenuItemProps) => {
    const { t } = useTranslation();
    const navLinkClassName =
      "mx-2 flex w-auto items-center px-3 py-2 leading-4 hover:bg-gray-500 hover:text-white cursor-pointer [&_*]:cursor-pointer";
    const navLinkActiveClassName = `${navLinkClassName} text-mr-cyan-300`;

    return (
      <li className={`${className} hover:bg-gray-500 hover:text-white`}>
        <NavLink
          className={({ isActive }) =>
            isActive || isItemActive ? navLinkActiveClassName : navLinkClassName
          }
          to={to}
        >
          <Icon className="h-4 w-4" />
          <label className="menu_item__label flex-1 overflow-hidden text-ellipsis">
            {t(i18nKey)}
          </label>
        </NavLink>
      </li>
    );
  },
);

// --------------------------------------
// -            SideNavbar
// --------------------------------------
export interface Props {
  className?: string;
}

const SideNavbar = ({ className = "" }: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const {
    menu: { isCollapsed, menus },
    toggleCollapseMenu,
  } = useMenu();
  const parentPathname = `${location.pathname.split("/")[0]}${
    location.pathname.split("/")[1]
  }`;

  return (
    <>
      <div
        className={cn(
          "SideNavbar",
          "flex h-full shrink-0 flex-col bg-gray-700 pb-7 text-xs font-light text-gray-300 transition-all relative z-10",
          isCollapsed ? "w-[56px]" : "w-[170px]",
          className,
        )}
        data-testid="SideNavbar"
      >
        <div>
          {menus.map(({ title, items }, idx) => (
            <div className="mt-8" key={`menu_${idx}`}>
              {!isCollapsed && (
                <p
                  className="mb-2 px-6 text-left text-xs font-bold uppercase"
                  style={{ fontSize: 11 }}
                >
                  {title}
                </p>
              )}
              <ul>
                {items &&
                  items.map((menuItem, key) => (
                    <SecondaryTooltip
                      key={key}
                      direction="right"
                      enabled={isCollapsed}
                      text={t(menuItem.i18nKey)}
                    >
                      <MenuItem
                        className={`${
                          isCollapsed ? "[&_label]:hidden" : "[&_svg]:mr-2"
                        }`}
                        key={key}
                        isItemActive={menuItem.to === parentPathname}
                        {...menuItem}
                      />
                    </SecondaryTooltip>
                  ))}
              </ul>
            </div>
          ))}
        </div>

        <div className="mt-auto flex flex-col items-center gap-1">
          <button
            className="mx-auto rounded-full bg-gray-900 p-2 text-mr-cyan-300 shadow-md hover:bg-black"
            onClick={toggleCollapseMenu}
            data-testid="ToggleCollapse"
          >
            {isCollapsed ? (
              <BsArrowBarRight className="h-5 w-5" />
            ) : (
              <BsArrowBarLeft className="h-5 w-5" />
            )}
          </button>
          <span className="font-bold text-gray-400">
            {isCollapsed ? t("Expand") : t("Collapse")}
          </span>
        </div>
      </div>
      <SubSideNavbar />
    </>
  );
};

export default memo(SideNavbar);
