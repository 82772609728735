import { Modal, useModal } from "../../components/modals";
import { BsFilePdf, BsTrash } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import {
  AlertWarning,
  Button,
  PrimaryButton,
  TertiaryBadge,
} from "../../components/core";
import { BiSolidLeftArrowAlt } from "react-icons/bi";
import {
  deleteExtractedFile,
  deleteExtractedFilePage,
} from "../../api/deposited_files";
import { Link, generatePath } from "react-router-dom";
import { ROUTE_PATH } from "../../constants";
import { is } from "cypress/types/bluebird";

export interface Props {
  open?: boolean;
  onClose?: () => void;
  id?: string;
  title?: string;
  content?: string;
  infos?: string;
  fileId?: string;
  fileName?: string;
  isExtractedFilePage?: boolean;
  onConfirm?: () => void;
}

function ConfirmModal({
  open = false,
  isExtractedFilePage = false,
  onClose = () => {
    open = false;
  },
  onConfirm = () => {},
  title = "",
  content = "",
  id = "",
  infos = "",
  fileName = "",
  fileId = "",
}: Props) {
  const { t } = useTranslation();

  const handleDeleteExtractedFilePage = () => {
    deleteExtractedFilePage(id).then(() => {
      onConfirm();
      onClose();
      return;
    });
  };

  const handleDeleteExtractedFile = () => {
    deleteExtractedFile(id).then(() => {
      onConfirm();
      onClose();
      return;
    });
  };

  return (
    <Modal close={onClose} open={open} title={title}>
      {content && <p className="mb-6">{content}</p>}
      <AlertWarning>{infos}</AlertWarning>

      <TertiaryBadge outline className="mt-8">
        <BsFilePdf size="16" className="cursor-pointer mr-2" />
        <Link
          to={generatePath(ROUTE_PATH.FILE, { fileId: fileId })}
          target="_blank"
        >
          {fileName}
        </Link>
      </TertiaryBadge>
      <div className="mt-8 flex gap-4">
        <Button onClick={onClose} startIcon={<BiSolidLeftArrowAlt />}>
          Annuler
        </Button>
        {isExtractedFilePage ? (
          <PrimaryButton
            startIcon={<BsTrash />}
            onClick={handleDeleteExtractedFilePage}
          >
            Confirmer la suppression
          </PrimaryButton>
        ) : (
          <PrimaryButton
            startIcon={<BsTrash />}
            onClick={handleDeleteExtractedFile}
          >
            Confirmer la suppression
          </PrimaryButton>
        )}
      </div>
    </Modal>
  );
}

export default ConfirmModal;
