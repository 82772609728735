import { useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";
import ProfilePictureModal from "../recoil/ProfilePictureModal";
import { useAuth } from "./";

export function useProfilePicture() {
  const [{ open: isProfilePictureModalOpen }, setProfilePicture] =
    useRecoilState(ProfilePictureModal);
  const { auth } = useAuth();

  useEffect(() => {
    auth && closeProfilePictureModal();
  }, [auth]);

  const closeProfilePictureModal = useCallback(() => {
    setProfilePicture({ open: false });
  }, []);

  const openProfilePictureModal = useCallback(() => {
    !auth && setProfilePicture({ open: true });
  }, []);

  return { isProfilePictureModalOpen, setProfilePicture, closeProfilePictureModal, openProfilePictureModal };
}
