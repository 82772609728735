export function getIdByIriSelector(iri: string): string {
  return iri ? iri.replace(/\/.*\//, "") : "";
}

interface socialNumberData {
  gender: string;
  year: string;
  month: string;
  departement: string;
}

export function getGenderSocialNumber(wssn: string): string {
  if (wssn === undefined || wssn.length === 0) {
    return "";
  }

  switch (wssn[0]) {
    case "1": 
      return "man";
    case "2":
      return "woman";
    default:
      return "error";
  }
}

export function getBirthYearSocialNumber(wssn: string): string {
  if (wssn === undefined || wssn.length === 0) {
    return "";
  }

  if (!wssn[1] && !wssn[2]) {
    return "";
  }

  const currentYear = new Date().getFullYear().toString();
  if (
    parseInt(`${wssn[1]}${wssn[2]}`) >
    parseInt(`${currentYear[2]}${currentYear[3]}`)
  ) {
    return `19${wssn[1]}${wssn[2]}`;
  } else {
    return `20${wssn[1]}${wssn[2]}`;
  }
}

export function getBirthMonthSocialNumber(wssn: string): string {
  if (wssn === undefined || wssn.length === 0) {
    return "";
  }

  if (!wssn[3] && !wssn[4]) {
    return "";
  }

  if (parseInt(`${wssn[3]}${wssn[4]}`) > 12) {
    return "error";
  }

  return wssn[3] && wssn[4] ? `${wssn[3]}${wssn[4]}` : "";
}

export function getDepartementSocialNumber(wssn: string): string {
  if (wssn === undefined || wssn.length === 0) {
    return "";
  }

  return wssn[5] && wssn[6] ? `${wssn[5]}${wssn[6]}` : "";
}

export function getDataBySocialNumber(wssn: string): socialNumberData {
  const ssnInfo: socialNumberData = {
    gender: "",
    year: "",
    month: "",
    departement: "",
  };

  if (wssn === undefined) {
    return ssnInfo;
  }

  ssnInfo.gender = getGenderSocialNumber(wssn[0]);

  if (ssnInfo.gender !== undefined && ssnInfo.gender !== "error") {
    ssnInfo.year = getBirthYearSocialNumber(wssn);
  }

  if (wssn[3] !== undefined && wssn[4] !== undefined) {
    ssnInfo.month = getBirthMonthSocialNumber(wssn);
  }

  if (wssn[5] !== undefined && wssn[6] !== undefined) {
    ssnInfo.departement = getDepartementSocialNumber(wssn);
  }

  return ssnInfo;
}

export function setLocalStorage(key: string, data: {}) {
  localStorage.setItem(key, JSON.stringify(data));
}

export function getLocalStorage(key: string) {
  const localStorageData = localStorage.getItem(key);
  if (
    typeof localStorageData === "string" &&
    localStorageData !== "undefined"
  ) {
    return JSON.parse(localStorageData);
  }
  return "{}";
}
