import { axiosPrivate } from "../api/axios";
import { API_BASE_URL, AUTH_END_POINT } from "../constants";

export async function getApplications(id: string) {
  const response = await axiosPrivate.get<GetApplications>(
    `${API_BASE_URL}${AUTH_END_POINT.APPLICATIONS(id)}`
  );

  return response;
}

export async function patchApplicationState(company_id: string, registration_id: string, data: ApplicationStatus) {
  console.log(data);
  const response = await axiosPrivate.patch<PatchApplicationState>(
    `${API_BASE_URL}${AUTH_END_POINT.REGISTRATION.DATA(company_id, registration_id)}`, 
    data
  );

  return response;
}
