import { memo } from "react";
import { Outlet } from "react-router-dom";

import { SideNavbar } from "../../components/SideNavbar";
import { TopNavbar } from "../../components/TopNavbar";

export interface Props {}

const NavLayout = ({}: Props) => {
  return (
    <div data-testid="NavLayout" className="h-full w-full">
      <TopNavbar />
      <div className="flex h-full w-full pt-12">
        <SideNavbar />
        <Outlet />
      </div>
    </div>
  );
};

export default memo(NavLayout);
