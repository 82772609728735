import { AUTH_END_POINT } from "../constants/constant";
import { axiosPrivate } from "./axios";

export async function resetPassword(email: string) {
  if (email) {
    try {
      const response = await axiosPrivate.post(AUTH_END_POINT.RESET_PASSWORD, {
        email,
      });

      return response.status;
    } catch (error) {
      console.log("error", error);
    }
  }
}
