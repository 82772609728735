import { atom } from "recoil";
import Cookies from 'js-cookie';
import { BEARER_TOKEN_COOKIE_NAME, BEARER_TOKEN_EXPIRATION_TIME } from "../../constants";
import { axiosPrivate } from "../../api/axios";


export interface BearerTokenAtom {
  token: string;
}

export const defaultState: BearerTokenAtom = {
  token: '',
};

const BearerTokenAtom = atom<BearerTokenAtom|null>({
  key: "BearerTokenAtom",
  default: defaultState,
  // @ts-ignore
  effects_UNSTABLE: [
    ({ onSet, setSelf }) => {

      const BearerToken = Cookies.get(BEARER_TOKEN_COOKIE_NAME);

      onSet((value) => {
        const date = new Date();
        date.setTime(date.getTime() + (BEARER_TOKEN_EXPIRATION_TIME * 1000));

        const options:Cookies.CookieAttributes = {
          expires: date
        };

        if (value === null) {
          
          delete axiosPrivate.defaults.headers.common['Authorization'];
          Cookies.remove(BEARER_TOKEN_COOKIE_NAME);
          return;
        }

        axiosPrivate.defaults.headers.common['Authorization'] = 'Bearer ' + value.token;
        Cookies.set(BEARER_TOKEN_COOKIE_NAME, value.token, options);
      });

      setSelf({ token: BearerToken ?? '' });
    },
  ]
});

export default BearerTokenAtom;
