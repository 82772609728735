import { faker } from "@faker-js/faker";

const newSignatory = () => ({
  first_name: faker.person.firstName(),
  last_name: faker.person.lastName(),
  age: faker.number.int(100),
  as: faker.person.jobType(),
  has_signed: faker.datatype.boolean(),
});

const newSignaturePadContractFixture = (): SignaturePadContract => ({
  "@id": `/contract/${faker.string.uuid()}`,
  id: faker.string.uuid(),
  first_name: faker.person.firstName(),
  last_name: faker.person.lastName(),
  age: faker.number.int(100),

  employee_group: {
    id: faker.string.uuid(),
    name: "Comédiens",
  } as EmployeeGroup,
  jobTitle: faker.person.jobTitle(),
  template: "template",
  type: "type",
  salary: faker.number.int(1000),
  start_date: `${faker.date.soon()}`,
  end_date: `${faker.date.future()}`,
  duration: faker.number.int(100),
  signature_state: {
    state: faker.helpers.arrayElement(["signed", "refused", "waiting"]),
    signatories: faker.helpers.arrayElements([
      newSignatory(),
      newSignatory(),
      newSignatory(),
    ]),
  },
  observers: [
    {
      first_name: faker.person.firstName(),
      last_name: faker.person.lastName(),
      email: faker.internet.email(),
    },
  ],
  send_date: faker.date.past().toDateString(),
  action_edit: "action_edit",
  profile_picture: faker.image.avatar(),
  pdf_url: "https://www.africau.edu/images/default/sample.pdf",
});

export const SIGNATURE_PAD_CONTRACT_FIXTURE: SignaturePadContract =
  newSignaturePadContractFixture();
export const SIGNATURE_PAD_CONTRACTS_FIXTURE: SignaturePadContract[] = [
  newSignaturePadContractFixture(),
  newSignaturePadContractFixture(),
  newSignaturePadContractFixture(),
  newSignaturePadContractFixture(),
  newSignaturePadContractFixture(),
  newSignaturePadContractFixture(),
  newSignaturePadContractFixture(),
  newSignaturePadContractFixture(),
  newSignaturePadContractFixture(),
];
