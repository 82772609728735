import cn from "mxcn";
import { Button } from "react-aria-components";

interface Props {
  onPress?: () => void;
  children: React.ReactNode;
  isActive?: boolean;
  activeColor?: string;
}

function PaginationButton({ onPress = () => {}, children, isActive = false, activeColor = '#207263' }: Props) {
  return (
    <Button
      className="h-auto px-2"
      onPress={onPress}
    >
      <span className={cn('text-[#666666]', { 'text-black': isActive })}>{children}</span>
    </Button>
  );
}


export default PaginationButton;
