import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAuth, useForgotPasswordModal, useLoginModal } from '../../hooks';
import { getLoginError } from '../../utils';
import {
    AlertDanger,
    FormSection,
    Input,
    InputController,
    PrimaryButton,
} from '../core';
import { LoadingIcon } from '../LoadingIcon';
import Form from '../core/Form/Form';
import InlineCheckbox from '../core/InlineCheckbox/InlineCheckbox';

export interface Props {
    className?: string;
    onSuccess?: () => void;
}

interface IFormInput {
    email: string;
    password: string;
    persistentLogin: boolean;
}

const LoginForm = ({ className = '', onSuccess }: Props) => {
    const { t } = useTranslation();
    const { login, isLoading } = useAuth();
    const { closeLoginModal } = useLoginModal();
    const { openFPModal } = useForgotPasswordModal();
    const [status, setStatus] = useState<string>('');
    const methods = useForm<IFormInput>({
        defaultValues: {
            email: '',
            password: '',
            persistentLogin: false,
        },
    });

    const { register, handleSubmit, reset, control } = methods;

    const onSubmit: SubmitHandler<IFormInput> = async (data) => {
        try {
            const response = await login(data);

            if (response?.status === 200) {
                onSuccess && onSuccess();
            }
        } catch (error) {
            const err = error as AxiosError;
            const errorText = getLoginError(err);

            setStatus(errorText);
        }
    };

    const handleClickForgotPassword = useCallback(() => {
        closeLoginModal();
        openFPModal();
    }, [closeLoginModal, openFPModal]);

    return (
        <Form
            methods={methods}
            className={className}
            onSubmit={handleSubmit(onSubmit)}
        >
            <FormSection columns={1} className={'gap-4'}>
                {status && (
                    <AlertDanger className="mx-3 mb-10">
                        {t(
                            `LoginForm.error.status.${status}`,
                            t('Default.error.500')
                        )}
                    </AlertDanger>
                )}
                <InputController
                    name="email"
                    containerClassName="w-full"
                    label={t('LoginFormEmailLabel')}
                    className=""
                    type="email"
                    autoComplete="email"
                    rules={{ required: true }}
                />
                <InputController
                    name="password"
                    containerClassName="w-full"
                    label={t('LoginFormPasswordLabel')}
                    type="password"
                    autoComplete="current-password"
                    rules={{ required: true }}
                />

                {handleClickForgotPassword && (
                    <p
                        className="cursor-pointer text-mr-cyan-400 underline"
                        onClick={handleClickForgotPassword}
                    >
                        {t('LoginFormForgotPassword')}
                    </p>
                )}

                <div className="flex items-center">
                    <InlineCheckbox
                        name="persistentLogin"
                        text={t('LoginFormPersistentLogin')}
                    />
                </div>

                <div className="mt-6 flex items-center justify-center">
                    {isLoading ? (
                        <LoadingIcon className="text-mr-cyan-400" />
                    ) : (
                        <PrimaryButton type="submit" size="big">
                            {t('Login')}
                        </PrimaryButton>
                    )}
                </div>
            </FormSection>
        </Form>
    );
};

export default LoginForm;
