import { Outlet } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import { AppModals } from "../../components/modals";
import "react-toastify/dist/ReactToastify.css";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import { useRef } from "react";
import classNames from "classnames";
import { useRecoilState } from "recoil";
import { debugOptionAtom } from "../../recoil/DebugOptions";
import { ErrorBoundary } from "react-error-boundary";
import { PrimaryButton } from "../../components/core/Button";

export interface Props { }

function fallbackRender({ error, resetErrorBoundary }: any) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
      <PrimaryButton onClick={resetErrorBoundary}>Reset</PrimaryButton>
    </div>
  );
}

const PageLayout = ({ }: Props) => {
  const mainRef = useRef(null);

  const [options] = useRecoilState(debugOptionAtom);

  return (
    <main
      ref={mainRef}
      className={classNames("h-full w-full overflow-y-auto", {
        floating_menu__layout: options.floatingMenu,
      })}
      data-testid="PageLayout"
    >
      <div className="container pb-5 pt-10">
        <ErrorBoundary
          fallbackRender={fallbackRender}
          onReset={(details) => {
            console.log(details);
            // Reset the state of your app so the error doesn't happen again
          }}
        >
          <Outlet />
        </ErrorBoundary>
        <AppModals />
        <ToastContainer
          position="bottom-center"
          autoClose={2000}
          hideProgressBar={true}
          pauseOnHover={false}
          draggable={false}
          theme={"light"}
          transition={Slide}
          closeButton={false}
          closeOnClick={true}
          limit={5}
        />
        <ScrollToTop element={mainRef} />
      </div>
    </main>
  );
};

export default PageLayout;
