import { ReactNode, memo } from "react";
import {Tabs as AriaTabs, TabList, TabsProps} from 'react-aria-components';
import './Tabs.css';

export interface Props extends TabsProps {
  className?: string;
  children?: ReactNode;
  variant?: string;
}


const Tabs = ({ className = "", variant = "default", children, ...props }: Props) => {
  let tabClassName = variant == "vertical" ? "hidden" : "h-3 w-full rounded-tr bg-mr-cyan-400";

  return (
    <>
      <AriaTabs {...props} >
          {children}
      </AriaTabs>
    </>
  );
}



export default memo(Tabs);

// export default DepositedFilesPage;