//https://design.gs.com/components/input/input-number
//https://react-spectrum.adobe.com/react-aria/NumberField.html#button

import { Ref, memo, useState } from "react";
import Label, { LabelWrapperProps } from "../Label";
import type { NumberFieldProps } from "react-aria-components";
import {
  NumberField,
  Input as AriaInput,
  Button as AriaButton,
} from "react-aria-components";
import cn from "mxcn";
import { BsDash, BsPlus } from "react-icons/bs";

export interface Props extends NumberFieldProps {
  className?: string;
  containerClassName?: string;
  labelClass?: string;
  label?: string;
  info?: string;
  unit?: string;
  note?: string;
  errorLabel?: string;
  ref?: Ref<HTMLInputElement>;
  labelsContainerClassName?: string;
  noButtons?: boolean;
}

const InputNumber = ({
  className = "",
  containerClassName = "",
  labelClass = "",
  labelsContainerClassName = "",
  info,
  note,
  unit = "",
  label,
  errorLabel,
  ref,
  noButtons = false,
  ...props
}: Props) => {
  const [isFocused, setFocused] = useState(false);
  const labelsProps: LabelWrapperProps = {
    title: label,
    info,
    labelClass,
    errorLabel,
    required: props.isRequired,
    note,
    disabled: props.isDisabled,
    labelsContainerClassName,
  };

  return (
    <>
      <NumberField
        minValue={0}
        {...props}
        className={cn("flex flex-col group", containerClassName)}
      >
        <Label.Wrapper {...labelsProps}>
          <span className="flex flex-row ">
            <AriaInput
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              ref={ref}
              className={cn(
                "focus:ring-0 focus:outline-none group-disabled:opacity-50 rounded tabular-nums w-full focus:border-mr-cyan-400 h-[46px] rounded-br-none rounded-tr-none border-b-transparent border-l-transparent border-t-transparent",
                props.isInvalid
                  ? "border-mr-orange-400"
                  : "border-mr-gray-1000",
                className,
                {
                  "rounded-br rounded-tr": unit.length < 1 && noButtons,
                },
              )}
            />

            {unit.length > 0 && (
              <span
                className={cn(
                  "group-disabled:opacity-50 font-medium flex items-center border border-l-0 border-mr-gray-1000 bg-opacity-40 px-3 opacity-90",
                  {
                    "border-mr-cyan-400": isFocused,
                    "rounded-br rounded-tr": noButtons,
                  },
                )}
              >
                {unit}
              </span>
            )}

            {!noButtons && (
              <span
                className={cn(
                  "group-disabled:opacity-50 rounded-br rounded-tr flex flex-row border border-l-0 border-mr-gray-1000 bg-opacity-40",
                  {
                    "border-mr-cyan-400": isFocused,
                  },
                )}
              >
                <AriaButton
                  slot="decrement"
                  className="px-3 h-full text-base hover:bg-mr-gray-200 disabled:opacity-50 disabled:bg-mr-gray-300"
                >
                  <BsDash size={16} />
                </AriaButton>
                <AriaButton
                  slot="increment"
                  className={cn(
                    "px-3 h-full disabled:opacity-50 disabled:bg-mr-gray-300 rounded-br rounded-tr text-base border-l border-mr-gray-1000 hover:bg-mr-gray-200 ",
                    {
                      "border-mr-cyan-400": isFocused,
                    },
                  )}
                >
                  <BsPlus size={16} />
                </AriaButton>
              </span>
            )}
          </span>
        </Label.Wrapper>
      </NumberField>
    </>
  );
};

export default memo(InputNumber);
