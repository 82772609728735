export const CAISSE_CONGES_SPECTACLES_FIXTURE = {
  "@context": "/contexts/CaisseCongesSpectacle",
  "@id": "/caisse_conges_spectacles",
  "@type": "hydra:Collection",
  "hydra:member": [
    {
      "@id": "/caisse_conges_spectacles/1",
      "@type": "CaisseCongesSpectacle",
      id: 1,
      name: "test",
      address: "test address for the thingy",
      projects: [],
    },
  ],
  "hydra:totalItems": 1,
};
