import { ReactNode } from "react";
import AlertBase from "./AlertBase";
import { HiOutlineChat } from "react-icons/hi";

export interface Props {
  children: ReactNode;
  className?: string;
}

const Alert = ({ children, className = "" }: Props) => (
  <AlertBase
    className={`${className} border-mr-cyan-200 bg-mr-cyan-100 text-mr-cyan-400`}
    icon={<HiOutlineChat className="h-6 w-6" />}
    data-testid="Alert"
  >
    {children && children}
  </AlertBase>
);

export default Alert;
