import { useQuery } from "@tanstack/react-query";
import { fetchWorkingDaysByDates } from "../api/working_days";

export function useWorkingDays(
  companyId: string,
  workingDaysDateStart: string,
  workingDaysDateStop: string
) {
  const mrWorkingDaysByDates = useQuery({
    queryKey: ["working_days_by_dates"],
    queryFn: () =>
      fetchWorkingDaysByDates(
        companyId as string,
        workingDaysDateStart as string,
        workingDaysDateStop as string
      ),
  });

  return {
    mrWorkingDaysByDates: mrWorkingDaysByDates,
  };
}
