import {memo, useCallback, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../hooks";
import { Link, TertiaryButton } from "../core";
import { HiOutlineX, HiUserCircle } from "react-icons/hi";
import { ROUTE_PATH } from "../../constants";
import ProfileImageEditor from "../ProfileImageEditor/ProfileImageEditor";
import {Modal} from "../modals";

export interface Props {
  user: {
    first_name: string;
    last_name: string;
    profile_picture?: string;
  };
  onSuccess?: () => void;

}

const ProfileCard = ({
  user: { first_name, last_name, profile_picture }, onSuccess
}: Props) => {
  const { t } = useTranslation();
  const { logout } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(
    profile_picture || null
  );
  const [selectedImageAfterUpload, setSelectedImageAfterUpload] = useState<string | null>(null);
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [shouldResetEditor, setShouldResetEditor] = useState(false);


  useEffect(() => {
    setSelectedImageAfterUpload(selectedImage);
  }, [selectedImage]);

  const handleProfileImageUpdate = (imageUrl: string) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(false);
  };

  const handleClickLogout = useCallback(() => {
    logout();
  }, [logout]);

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);


  const handleZoomChange = useCallback((newZoom: number) => {
    setZoom(newZoom);
  }, []);

  const handleRotationChange = useCallback((newRotation: number) => {
    setRotation(newRotation);
  }, []);

  return (
    <div className="w-60 rounded-md bg-gray-50 shadow-lg" data-testid="ProfileCard">
      <div className="flex flex-col items-center border-b py-5">
        <div
          className="block aspect-square w-28 overflow-hidden rounded-full text-mr-cyan-300 cursor-pointer"
          onClick={handleOpenModal}
        >
          {selectedImage ? (
            <img
              className="h-full w-full object-cover"
              src={selectedImage}
              alt="profile picture"
            />
          ) : (
            <HiUserCircle className="h-28 w-28" />
          )}
        </div>

        <div className="font-bold">{`${first_name} ${last_name}`}</div>
      </div>
      <div className="flex flex-col items-center pb-8 pt-6">
        <Link className="mb-2" to={ROUTE_PATH.PROFILE}>
          {t("SeeProfile")}
        </Link>
        <Link className="mb-6" to={ROUTE_PATH.SETTINGS}>
          {t("AccountSettings")}
        </Link>
        <TertiaryButton
          startIcon={<HiOutlineX className="h-6 w-6" />}
          onClick={handleClickLogout}
        >
          {t("Logout")}
        </TertiaryButton>
      </div>

      {isModalOpen && (
        <Modal open={isModalOpen} close={handleCloseModal} title={t("Photo de profil")}>
          <ProfileImageEditor
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            onSubmit={handleProfileImageUpdate}
            onClose={handleCloseModal}
            onZoomChange={handleZoomChange}
            onRotationChange={handleRotationChange}
          />
        </Modal>
      )}
    </div>
  );
};

export default memo(ProfileCard);
