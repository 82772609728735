import cn from "mxcn";
import { Column as AriaColumn, type ColumnProps } from "react-aria-components";
import { BsArrowDownUp, BsSortDownAlt, BsSortUpAlt } from "react-icons/bs";

function Column({className, children, ...props}: ColumnProps) {
  return (
    <AriaColumn
      {...props}
      className={cn("bg-table-black text-white items-center h-[48px] cursor-pointer px-4 sticky top-0 whitespace-nowrap z-10", className)}
    >
      {({ allowsSorting, sortDirection}) => {
        return (
          <div className="flex items-center justify-center">
            {/*@ts-ignore */}
            <div>{typeof children === 'function' ? (values) => children(values): children}</div>
            {allowsSorting && (
              <span
                aria-hidden="true"
                className={cn("sort-indicator text-xs inline-block ml-2", sortDirection && 'text-table-active')}
              >
                {typeof sortDirection !== "undefined" &&
                  (sortDirection === "ascending" ? <BsSortDownAlt color="#5EC4CF"  size={20} /> : <BsSortUpAlt color="#5EC4CF"  size={20} />)}
            
                {typeof sortDirection === "undefined" && (
                  <BsArrowDownUp color="#777777"  size={16} />
                )}
              </span>
            )}
          </div>
        );
      }}
    </AriaColumn>
  );
}

export default Column;
