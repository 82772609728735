import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchTag, fetchTags, patchTag, postTag, removeTag } from "../api";
import { useMemo } from "react";

export function useTags(companyId?: string, tagId?: string) {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: ["tags"],
    queryFn: fetchTags,
  });

  const privateTags = useMemo(
    () => query.data?.filter(({ isPrivate }) => isPrivate) || [],
    [query.data]
  );
  
  const companyTags = useMemo(
    () =>
      (companyId &&
        query.data?.filter(
          ({ isPrivate }) => !isPrivate
        )) ||
      [],
    [query.data]
  );

  const tagQuery = useQuery({
    queryKey: ["tags", tagId],
    queryFn: () => fetchTag(tagId as string),
  });

  const patch = useMutation({
    mutationFn: (newTag: Tag) => patchTag(newTag),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["tags"] });
    },
  });

  const post = useMutation({
    mutationFn: (newTag: Tag) => postTag(newTag),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["tags"] });
    },
  });

  const remove = useMutation({
    mutationFn: (id: string) => removeTag(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["tags"] });
    },
  });

  return {
    tags: query.data,
    privateTags,
    companyTags,
    query,
    tagQuery,
    patch,
    post,
    remove,
  };
}
