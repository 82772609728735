import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchSet, fetchSets, patchSet, postSet, removeSet } from "../api";

export function useSets(companyId: string, setId?: string) {
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey: ["sets"],
    queryFn: () => fetchSets(companyId as string),
    enabled: Boolean(companyId),
  });

  const setQuery = useQuery({
    queryKey: ["sets", setId],
    queryFn: () =>
      fetchSet({ companyId: companyId as string, id: setId as string }),
    enabled: Boolean(setId) && Boolean(companyId),
  });

  const patch = useMutation({
    mutationFn: (newSet: MRSet) => patchSet(newSet),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["sets"] });
    },
  });

  const post = useMutation({
    mutationFn: (newSet: PostSetsParam) => postSet(companyId as string, newSet),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["sets"] });
    },
  });

  const remove = useMutation({
    mutationFn: (id: string) =>
      removeSet({ companyId: companyId as string, id: id as string }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["sets"] });
      queryClient.invalidateQueries({ queryKey: ["set_employee_groups"] });
    },
  });

  return {
    mrSets: query.data,
    setQuery,
    query,
    patch,
    post,
    remove,
  };
}
