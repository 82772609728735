import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../modals';
import Gallery from '../Gallery/Gallery';

export interface Props {
    open?: boolean;
    onClose?: () => void;
    // used to call the gallery for one user
    // user_id: string;
    galleryImages: Gallery[];
}

const GalleryModal = ({
    open = false,
    onClose = () => {},
    galleryImages,
}: Props) => {
    return (
        <Modal open={open} key={open ? 1 : 0} title={'Galerie'} close={onClose}>
            <Gallery images={galleryImages} />
        </Modal>
    );
};

export default memo(GalleryModal);
