import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import PaginationButton from "./PaginationButton";
import PaginationNavigationButton from "./PaginationNavigationButton";

interface Props {
  totalPages: number;
  currentPage: number;
  children: React.ReactNode;
  showLastPagesButtons?: boolean;
  showNavigationButtons?: boolean;
  handleChangePage: (page: string) => void;
}

function PaginationSideButtons({
  totalPages,
  currentPage,
  children,
  showLastPagesButtons = false,
  showNavigationButtons = true,
  handleChangePage,
}: Props) {
  return (
    <div className="flex gap-2 items-center">
      {showLastPagesButtons && (
        <PaginationNavigationButton onPress={() => handleChangePage("1")}>
          {"<<"}
        </PaginationNavigationButton>
      )}
      {showNavigationButtons && (
        <PaginationNavigationButton
          onPress={() =>
            currentPage > 1 && handleChangePage(`${currentPage - 1}`)
          }
        >
          <BsChevronLeft size={12} />
        </PaginationNavigationButton>
      )}
      {children}
      {showNavigationButtons && (
        <PaginationNavigationButton
          onPress={() => {
            console.log("currentPage", currentPage, totalPages)
            currentPage < totalPages && handleChangePage(`${currentPage + 1}`)
          }}
        >
          <BsChevronRight size={12} />
        </PaginationNavigationButton>
      )}
      {showLastPagesButtons && (
        <PaginationNavigationButton onPress={() => handleChangePage(`${totalPages}`)}>
          {">>"}
        </PaginationNavigationButton>
      )}
    </div>
  );
}

export default PaginationSideButtons;
