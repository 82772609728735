import { ReactNode, memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, TertiaryButton } from "../../core";
import { Modal } from "../";

export interface Props {
  className?: { validateBtn?: string };
  title?: string;
  label?: string;
  validateBtn?: string;
  onValidate?: () => void;
  close?: () => void;
  open?: boolean;
  children?: ReactNode;
}

const ValidationModal = ({
  className,
  children,
  close,
  onValidate,
  open,
  title,
  label,
  validateBtn,
}: Props) => {
  const { t } = useTranslation();
  const [agree, setAgree] = useState(false);

  return (
    <Modal
      open={open}
      close={close}
      data-testid="ValidationModal"
      title={title}
      containerClassName="flex flex-col"
    >
      {children && children}
      <label className="mt-3 flex items-center text-gray-500">
        <input
          className="mr-2 rounded border-gray-300"
          type="checkbox"
          checked={agree}
          onChange={() => setAgree((v) => !v)}
        />
        {label ? label : t("ValidationModal.label")}
      </label>

      <div className="mt-5 flex items-center justify-end gap-3">
        <Button
          className={`${className?.validateBtn ? className.validateBtn : ""}`}
          disabled={!agree}
          onClick={onValidate}
        >
          {validateBtn ? validateBtn : t("Validate")}
        </Button>
        <TertiaryButton onClick={close}>{t("Cancel")}</TertiaryButton>
      </div>
    </Modal>
  );
};

export default memo(ValidationModal);
