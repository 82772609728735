import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  deleteFieldEmployeeGroup,
  fetchFieldEmployeeGroup,
  patchFieldEmployeeGroup,
  postFieldEmployeeGroup,
} from "../api";

export function useFieldEmployeeGroups() {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: ["field_employee_groups"],
    queryFn: fetchFieldEmployeeGroup,
  });

  const post = useMutation({
    mutationFn: (data: PostFieldEmployeeGroupParam) =>
      postFieldEmployeeGroup(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["company_parameters"] });
      queryClient.invalidateQueries({ queryKey: ["employee_group"] });
      queryClient.invalidateQueries({ queryKey: ["field_employee_groups"] });
    },
  });

  const patch = useMutation({
    mutationFn: (data: PatchFieldEmployeeGroupParam) =>
      patchFieldEmployeeGroup(data),
    onSuccess: () => {
      // queryClient.invalidateQueries({ queryKey: ["company_parameters"] });
      // queryClient.invalidateQueries({ queryKey: ["employee_group"] });
      // queryClient.invalidateQueries({ queryKey: ["field_employee_groups"] });
    },
  });

  const remove = useMutation({
    mutationFn: (id: string) => deleteFieldEmployeeGroup(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["company_parameters"] });
      queryClient.invalidateQueries({ queryKey: ["employee_group"] });
      queryClient.invalidateQueries({ queryKey: ["field_employee_groups"] });
    },
  });

  return {
    fieldEmployeeGroups: query.data,
    query,
    patch,
    post,
    remove,
    isLoading: patch.isPending || post.isPending || remove.isPending,
    isError: patch.isError || post.isError || remove.isError,
    isSuccess: patch.isSuccess || post.isSuccess || remove.isSuccess,
  };
}
