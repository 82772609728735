export const ETHNICITIES_FIXTURE: GetEthnicitiesResponse = {
  "@context": "/contexts/Ethncity",
  "@id": "/ethncities",
  "@type": "hydra:Collection",
  "hydra:member": [
    {
      "@id": "/ethncities/1",
      "@type": "Ethncity",
      name: "Andamanais",
    },
    {
      "@id": "/ethncities/2",
      "@type": "Ethncity",
      name: "Karen",
    },
  ],
  "hydra:totalItems": 2,
};
