import { AiFillBug } from "react-icons/ai";
import { Modal, useModal } from "../modals";
import FloatingPanel from "./FloatingMenu";
import AutoSave from "./AutoSave";
import PersistantLogin from "./PersistantLogin";

export interface Props {
  open?: boolean;
  onClose?: () => void;
  addEmployee?: boolean;
}

function DebugModal({
  open = false,
  onClose = () => {},
}: Props) {
  return (
    <Modal
      close={onClose}
      open={open}
      title="Debug"
    >
      <h2>Réglages</h2>
      <FloatingPanel />
      <AutoSave />
      <PersistantLogin />
    </Modal>
  );
}


function DebugFeature() {

  const [modal, setOpen] = useModal(<DebugModal />);

  return (
    <>
      {modal}
      <button onClick={() => setOpen(true)}>
        <AiFillBug />
      </button>
    </>
  );

}

export default DebugFeature;
