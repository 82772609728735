import { API_BASE_URL, AUTH_END_POINT } from "../constants";
import { setCompanyIRI } from "../utils";
import { getIdByIriSelector } from "../utils/selectors";
import { axiosPrivate, axiosPrivatePatch } from "./axios";

export async function fetchFields(companyId: string) {
  const { data } = await axiosPrivate.get<GetFieldsResponse>(
    `${API_BASE_URL}${AUTH_END_POINT.FIELDS(companyId)}`
  );

  return data["hydra:member"];
}

export async function fetchField({
  id,
  companyId,
}: {
  id: string;
  companyId: string;
}) {
  const { data } = await axiosPrivate.get<Field>(
    `${API_BASE_URL}${AUTH_END_POINT.FIELDS(companyId)}/${id}`
  );

  return data;
}

export async function patchField(field: PatchFieldsParams) {
  const response = await axiosPrivatePatch.patch<PatchFieldsResponse>(
    `${API_BASE_URL}${AUTH_END_POINT.FIELDS(
      getIdByIriSelector(field.company)
    )}/${getIdByIriSelector(field["@id"])}`,
    field
  );

  return response.data;
}

export async function postFields(companyId: string, data: PostFieldsParam) {
  const response = await axiosPrivate.post<PostFieldsResponse>(
    `${API_BASE_URL}${AUTH_END_POINT.FIELDS(companyId)}`,
    { ...data, company: setCompanyIRI(companyId) }
  );

  return response.data;
}

export async function removeFields({
  id,
  companyId,
}: {
  id: string;
  companyId: string;
}) {
  const response = await axiosPrivate.delete(
    `${API_BASE_URL}${AUTH_END_POINT.FIELDS(companyId)}/${id}`
  );

  return response.data;
}
