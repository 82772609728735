import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Label, { LabelWrapperProps } from '../Label';

interface OptionType {
  value: string;
  label: string;
}

interface GroupType {
  title: string;
  options: OptionType[];
}

interface GroupedSelectProps {
  options: GroupType[];
  value: string;
  onChange: (value: string) => void;
  isDisabled?: boolean;
  required?: boolean;
  isInvalid?: boolean;
}

// TODO : Refactor ce select pour qu'il soit compatible avec React Aria
// https://react-spectrum.adobe.com/react-aria/ListBox.html
const GroupedSelect: React.FC<GroupedSelectProps> = ({
  options, value, onChange, isDisabled, required, isInvalid, ...props
}) => (
  <select
    style={{ width: '250px' }} // Ajout du style inline ici
    value={value}
    onChange={(e) => onChange(e.target.value)}
    disabled={isDisabled}
    required={required}
    aria-invalid={isInvalid}
    {...props}
  >
    {!value && <option value="">{ 'Sélectionner une valeur' }</option>}
    {options.map(group => (
      <optgroup key={group.title} label={group.title}>
        {group.options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </optgroup>
    ))}
  </select>
);




interface GroupedSelectControllerProps {
  name: string;
  rules?: any;
  value?: string;
  options: GroupType[];
  isRequired?: boolean;
  isDisabled?: boolean;
  onChange?: (value: string) => void;
  labelProps?: Omit<LabelWrapperProps, 'children' | 'required' | 'disabled'>;
}

export const GroupedSelectController: React.FC<GroupedSelectControllerProps> = ({
  name, rules, value, options, isRequired, isDisabled, onChange, labelProps, ...props
}) => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  const handleChange = (val: string, field: any) => {
    field.onChange(val);
    if (onChange) onChange(val);
  };

  return (
    <Label.Wrapper {...labelProps}>
      <Controller
        control={control}
        name={name ?? "DEFAULT"}
        rules={rules}
        render={({ field, fieldState: { error, invalid } }) => (
          <div>
            <GroupedSelect
              {...field}
              {...props}
              options={options}
              value={field.value ?? value}
              onChange={(val) => handleChange(val, field)}
              isDisabled={isDisabled}
              required={isRequired}
              isInvalid={invalid}
            />
            {invalid && <div style={{ color: "red" }}>{error?.message}</div>}
          </div>
        )}
      />
    </Label.Wrapper>
  );
};
