import { faker } from "@faker-js/faker";
import { Employee } from "../types/employee";

const newEmployeeFixture = (): Employee => ({
  "@type": "EmployeeListDto",
  "@id": "/.well-known/genid/70d83e66a6a734c6e3ea",
  id: `/users/${faker.string.uuid()}`,
  registration: {
    "@id": "/registrations/1",
    "@type": "Registration",
    user: `/users/${faker.string.uuid()}`,
    abattement: faker.datatype.boolean(),
    employee_group: `/employee_groups/${faker.string.uuid()}`,
    status_actor: faker.datatype.boolean(),
    createdBy: `/users/${faker.string.uuid()}`,
    created_at: "2023-05-09T12:06:58+00:00",
    status_prod: "/inscription_statuses/2",
    current_step: faker.number.int(6),
    company: {
      "@id": "/companies/01880068-56ee-77d3-8b60-7e6f6cd75a18",
      "@type": "Company",
      name: faker.company.name(),
    },
    is_removed: faker.datatype.boolean(),
  },
  registration_method: "TODO",
  positions: {
    nb_items: faker.number.int(50),
    items: ["Position1", "Position1"],
  },
  xotis: faker.datatype.boolean(),
  alerts: {
    actionUrl: faker.internet.url(),
    items: {
      ABATTEMENT_MANQUANT: {
        criticity: "LOW",
        ignored: faker.datatype.boolean(),
        type: "",
      },
      NSS_MANQUANT: {
        type: "",
        criticity: "HIGH",
        ignored: faker.datatype.boolean(),
      },
      ADRESSE_FISCALE_MANQUANT: {
        type: "",
        criticity: "LOW",
        ignored: faker.datatype.boolean(),
      },
    }
  },
  age: faker.number.int(100),
  weight: faker.number.int(100),
  height: faker.number.int(200),
  civility: `/civilities/${faker.number.int(2)}`,
  employee_group: {
    id: faker.string.uuid(),
    name: "Comédiens",
  } as EmployeeGroup,
  first_name: faker.person.firstName(),
  last_name: faker.person.lastName(),
  contracts: {
    nb_contracts: faker.number.int(40),
    nb_working_days: faker.number.int(40),
  },
  tags: {
    company_tags: {
      nb_items: faker.number.int(40),
      items: [
        {
          id: "/tags/2",
          name: "Pro",
        },
      ],
    },
    personal_tags: {
      nb_items: faker.number.int(40),
      items: [
        {
          id: "/tags/1",
          name: "Sympa",
        },
      ],
    },
  },
  sets: {
    nb_items: faker.number.int(40),
    items: [
      {
        id: "/sets/1",
        name: "Paris",
      },
    ],
  },
  created_at: faker.date.anytime(),
  actionInfiltrate:
    "/lienVersInfiltration/018794e7-79bb-7ca6-bdb9-6a456fe05e35",
  actionUpload: "/lienVersUpload",
  actionDunno: "/lienVersAction",
  actionRemove: "/lienVersRemove/018794e7-79bb-7ca6-bdb9-6a456fe05e35",
  actionQuickView: "/quickView",
  profile_picture: faker.image.avatar(),
});

export const EMPLOYEE_FIXTURE: Employee = newEmployeeFixture();
export const EMPLOYEES_FIXTURE: Employee[] = [
  newEmployeeFixture(),
  newEmployeeFixture(),
  newEmployeeFixture(),
  newEmployeeFixture(),
  newEmployeeFixture(),
  newEmployeeFixture(),
  newEmployeeFixture(),
  newEmployeeFixture(),
  newEmployeeFixture(),
];
