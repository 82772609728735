import { memo } from "react";
import { useTranslation } from "react-i18next";
import ForgotPasswordForm from "../../../components/ForgotPasswordForm/ForgotPasswordForm";
import LoginForm from "../../../components/LoginForm/LoginForm";
import { Modal } from "../";
import { useForgotPasswordModal, useLoginModal } from "../../../hooks";
import {useProfilePicture} from "../../../hooks/useProfilePicture";
import ProfileCard from "../../ProfileCard/ProfileCard";

export interface Props {}

const AppModals = ({}: Props) => {
  const { t } = useTranslation();
  const { isLoginModalOpen, closeLoginModal } = useLoginModal();
  const { isProfilePictureModalOpen, closeProfilePictureModal } = useProfilePicture();
  const { isFPModalOpen, closeFPModal } = useForgotPasswordModal();

  const User = {
    first_name: "John",
    last_name: "Doe",
    profile_picture: "profile_picture_url",
  };

  return (
    <>
      {isLoginModalOpen && (
        <Modal
          open={isLoginModalOpen}
          title={t("Login")}
          close={closeLoginModal}
        >
          <LoginForm onSuccess={closeLoginModal} />
        </Modal>
      )}
      {isFPModalOpen && (
        <Modal
          open={isFPModalOpen}
          title={t("ForgotPasswordModalTitle")}
          close={closeFPModal}
        >
          <ForgotPasswordForm />
        </Modal>
      )}
      {isProfilePictureModalOpen && (
        <Modal
          open={isProfilePictureModalOpen}
          title={t("Profile Card")}
          close={closeProfilePictureModal}
        >
          <ProfileCard
            user={User}
            onSuccess={closeProfilePictureModal}
          />
        </Modal>
      )}
    </>
  );
};

export default memo(AppModals);
