import { useState, useEffect } from "react";
import { usePagination, isCurrentPage } from "./usePagination";

import PaginationButton from "./PaginationButton";
import PaginationSideButtons from "./PaginationSideButtons";

/**
 * Copy of https://github.com/CherrySoftMX/react-native-basic-pagination/tree/main
 * heavily adapted
 */
interface Props {
  totalItems: number;
  pageSize?: number;
  pagesToDisplay?: number;
  onPageChange: (page: number) => void;
  showLastPagesButtons?: boolean;
  showNavigationButtons?: boolean;
  currentPage: number;
  dotColor?: string;
}

const Pagination = ({
  totalItems,
  pageSize = 1,
  pagesToDisplay = 3,
  onPageChange,
  showLastPagesButtons = false,
  showNavigationButtons = true,
  currentPage,
  dotColor = "#207263",
}: Props) => {
  const totalPages = Math.ceil(totalItems / pageSize);
  const pagination = usePagination(
    totalItems,
    pageSize,
    pagesToDisplay,
    currentPage
  );
  useEffect(() => {
    if (totalPages === 0) {
      return;
    }


    if (currentPage < 1) {
      onPageChange(1);
      return;
    } else if (currentPage > totalPages) {
      onPageChange(totalPages);
    }
  }, [currentPage, totalPages, onPageChange]);

  
  const handleChangePage = (page: string) => {
    if (page !== "...") {
      onPageChange(parseInt(page, 10));
    }
  };
  return (
    <PaginationSideButtons
      {...{
        totalPages,
        currentPage,
        handleChangePage,
        showLastPagesButtons,
        showNavigationButtons,
      }}
    >
      {pagination.length > 1 &&
        pagination.map((pag, index) => (
          <PaginationButton
            key={index}
            isActive={isCurrentPage(pag, currentPage)}
            onPress={() => handleChangePage(pag)}
            activeColor={dotColor}
          >
            {pag}
          </PaginationButton>
        ))}
    </PaginationSideButtons>
  );
};

export default Pagination;
