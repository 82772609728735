import React, { Suspense } from "react";
import { Routes, Route, ScrollRestoration } from "react-router-dom";
import { DASHBOARD_ORDER_PRIORITY, ROUTE_PATH } from "./constants";
import { useAuth, useRememberMe } from "./hooks";
import RequireAuth from "./components/RequireAuth/RequireAuth";
import NavLayout from "./layouts/Nav/NavLayout";
import PageLayout from "./layouts/Page/PageLayout";
import DashboardStaffPage from "./pages/Dashboards/DashboardStaffPage";
import DashboardProductionPage from "./pages/Dashboards/DashboardProductionPage";
import DashboardUserPage from "./pages/Dashboards/DashboardUserPage";
import DepositedFilesPage from "./pages/DepositedFiles/DepositedFilesPage";
import UserInformations from "./pages/UserInformations/UserInformations";
import FileDownload from "./pages/FileDownload/FileDownload";
import ApplicationsPage from "./pages/Applications/ApplicationsPage";

const PositionManagement = React.lazy(
  () => import("./pages/PositionManagement/PositionManagementPage"),
);
const EmployeeRegistrationPage = React.lazy(
  () => import("./pages/EmployeeRegistration/EmployeeRegistrationPage"),
);
const Login = React.lazy(() => import("./pages/Login/Login"));
const CodeFilmRegistration = React.lazy(
  () => import("./pages/FilmRegistration/CodeFilmRegistration"),
);
const ManualFilmRegistrationPage = React.lazy(
  () => import("./pages/FilmRegistration/ManualFilmRegistrationPage"),
);
const ContractsManagementPage = React.lazy(
  () => import("./pages/ContractsManagement/ContractsManagementPage"),
);
const SignaturePadEndPage = React.lazy(
  () => import("./pages/SignaturePadEnd/SignaturePadEndPage"),
);
const SignaturePadSummaryPage = React.lazy(
  () => import("./pages/SignaturePadSummary/SignaturePadSummaryPage"),
);
const SignaturePadDetailsPage = React.lazy(
  () => import("./pages/SignaturePadDetails/SignaturePadDetailsPage"),
);
const Xotis = React.lazy(() => import("./pages/Exports/Xotis"));
const SignaturePadPage = React.lazy(
  () => import("./pages/SignaturePad/SignaturePadPage"),
);
const UpdateAdministrationParametersPage = React.lazy(
  () =>
    import(
      "./pages/UpdateAdministrationParameters/UpdateAdministrationParametersPage"
    ),
);
const PrepapaiePage = React.lazy(
  () => import("./pages/Prepapaie/PrepapaiePage"),
);
const EmployeeGroupPage = React.lazy(
  () => import("./pages/EmployeeGroup/EmployeeGroupPage"),
);
const ProjectsPage = React.lazy(() => import("./pages/Projects/ProjectsPage"));
const TagsPage = React.lazy(() => import("./pages/Tags/TagsPage"));
const CalendarDay = React.lazy(() => import("./pages/CalendarDay/CalendarDay"));
const SetPage = React.lazy(() => import("./pages/Set/SetPage"));
const BookPage = React.lazy(() => import("./pages/Book/BookPage"));
const SearchEnginePage = React.lazy(
  () => import("./pages/SearchEngine/SearchEnginePage"),
);
const UpdateCastingProfilePage = React.lazy(
  () => import("./pages/UpdateCastingProfile/UpdateCastingProfilePage"),
);
const SetsPage = React.lazy(() => import("./pages/Sets/SetsPage"));
const EmployeeContractCreation = React.lazy(
  () => import("./pages/EmployeeContractCreation/EmployeeContractCreation"),
);

const EmployeeSafeboxPage = React.lazy(() => import("./pages/EmployeeSafebox/EmployeeSafeboxPage"));

const VirtualFolderPage = React.lazy(() => import("./pages/VirtualFolder/VirtualFolderPage"));

const CreateAccountPage = React.lazy(
  () => import("./pages/CreateAccount/CreateAccountPage"),
);
const DashboardsPage = React.lazy(
  () => import("./pages/Dashboards/DashboardsPage"),
);
const HomePage = React.lazy(() => import("./pages/Home/HomePage"));
const ProfilePage = React.lazy(() => import("./pages/Profile/ProfilePage"));
const ResetPasswordPage = React.lazy(
  () => import("./pages/ResetPassword/ResetPasswordPage"),
);
const VerifyEmailPage = React.lazy(
  () => import("./pages/VerifyEmail/VerifyEmailPage"),
);
const CreateProductionPage = React.lazy(
  () => import("./pages/CreateProduction/CreateProductionPage"),
);
const CreateProjectPage = React.lazy(
  () => import("./pages/CreateProject/CreateProjectPage"),
);
const UpdateProjectPage = React.lazy(
  () => import("./pages/UpdateProject/UpdateProjectPage"),
);
const UpdateProductionPage = React.lazy(
  () => import("./pages/UpdateProduction/UpdateProductionPage"),
);
const MessagingPage = React.lazy(
  () => import("./pages/Messaging/MessagingPage"),
);
const EmployeeManagementPage = React.lazy(
  () => import("./pages/EmployeeManagement/EmployeeManagementPage"),
);
const RegisterSettingsPage = React.lazy(
  () => import("./pages/RegisterSettings/RegisterSettingsPage"),
);
const ProjectDashboardPage = React.lazy(
  () => import("./pages/Dashboards/ProjectDashboardPage"),
);
const Calendar = React.lazy(
  () => import("./pages/ProjectCalendar/ProjectCalendar"),
);
const FilmRegistrationPage = React.lazy(
  () => import("./pages/FilmRegistration/FilmRegistrationPage"),
);
const SandboxPage = React.lazy(() => import("./pages/Sandbox/SandboxPage"));
const ProjectDPAEPage = React.lazy(
  () => import("./pages/ProjectDPAE/ProjectDPAEPage"),
);
const TeamManagementPage = React.lazy(
  () => import("./pages/TeamManagement/TeamManagementPage"),
);
const DocumentTemplatesPage = React.lazy(
  () => import("./pages/DocumentTemplates/DocumentTemplatesPage"),
);
const SMSHistoryPage = React.lazy(
  () => import("./pages/SMSHistory/SMSHistoryPage"),
);
const EmployeeOnYourProjectsPage = React.lazy(
  () => import("./pages/EmployeeOnYourProjects/EmployeeOnYourProjectsPage")
);
const FilmographyPage = React.lazy(
  () => import("./pages/Filmography/FilmographyPage")
);
const UserManagement = React.lazy(
  () => import("./pages/UserManagement/UserManagementPage")
);
const IndemnityManagement = React.lazy(
  () => import("./pages/IndemnityManagement/IndemnityManagementPage")
);
const SstManagement = React.lazy(
  () => import("./pages/SstManagement/SstManagementPage")
);
const CompanyManagement = React.lazy(
  () => import("./pages/CompanyManagement/CompanyManagementPage")
);

function App() {
  const { isLoading } = useRememberMe();
  const { auth } = useAuth();

  // console.log("t", auth?.roles);

  return (
    <Routes>
      <Route element={<NavLayout />}>
        <Route element={<PageLayout />}>
          {/* public routes */}
          <Route
            path={ROUTE_PATH.SANDBOX}
            element={
              <Suspense>
                <SandboxPage />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.POSITION_MANAGEMENT}
            element={
              <Suspense>
                <PositionManagement />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.COMPANY_MANAGEMENT}
            element={
              <Suspense>
                <CompanyManagement />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.INDEMNITY_MANAGEMENT}
            element={
              <Suspense>
                <IndemnityManagement />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.SST_MANAGEMENT}
            element={
              <Suspense>
                <SstManagement />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.USER_MANAGEMENT}
            element={
              <Suspense>
                <UserManagement />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.HOME}
            element={
              <Suspense>
                <HomePage />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.CREATE_ACCOUNT}
            element={
              <Suspense>
                <CreateAccountPage />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.VERIFY_EMAIL}
            element={
              <Suspense>
                <VerifyEmailPage />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.RESET_PASSWORD}
            element={
              <Suspense>
                <ResetPasswordPage />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PROFILE}
            element={
              <Suspense>
                <ProfilePage />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.BOOK}
            element={
              <Suspense>
                <BookPage />
              </Suspense>
            }
          />
          {/* <Route path={ROUTE_PATH.PROJECT} element={<div>Projet</div>} /> */}
          <Route
            path={ROUTE_PATH.PROJECT_CALENDAR_DAY}
            element={
              <Suspense>
                <CalendarDay />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PROJECT_EXPORT_XOTIS}
            element={
              <Suspense>
                <Xotis />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.EMPLOYEE_NEW_CONTRACTS}
            element={
              <Suspense>
                <EmployeeContractCreation />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.LOGIN}
            element={
              <Suspense>
                <Login />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.EMPLOYEE_ON_YOUR_PROJECTS}
            element={
              <Suspense>
                <EmployeeOnYourProjectsPage />
              </Suspense>
            }
          />
          {/* protected routes */}
          <Route element={<RequireAuth isLoading={isLoading} />}>
            <Route
              path={ROUTE_PATH.PROFILE}
              element={
                <Suspense>
                  <ProfilePage />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.UPDATE_CASTING_PROFILE}
              element={
                <Suspense>
                  <UpdateCastingProfilePage />
                </Suspense>
              }
            />

            {/* {DASHBOARD_ORDER_PRIORITY.map((DASHBOARD_ROUTE, idx) => (
              <Route
                key={idx}
                path={DASHBOARD_ROUTE}
                element={
                  <Suspense>
                    <DashboardsPage />
                  </Suspense>
                }
              />
            ))} */}

            <Route
              path={ROUTE_PATH.DASHBOARD}
              element={
                <Suspense>
                  <DashboardsPage />
                </Suspense>
              }
            />

            <Route
              path={ROUTE_PATH.DASHBOARD_STAFF}
              element={
                <Suspense>
                  <DashboardStaffPage />
                </Suspense>
              }
            />

            <Route
              path={ROUTE_PATH.DASHBOARD_PRODUCTION}
              element={
                <Suspense>
                  <DashboardProductionPage />
                </Suspense>
              }
            />

            <Route
              path={ROUTE_PATH.DASHBOARD_USER}
              element={
                <Suspense>
                  <DashboardUserPage />
                </Suspense>
              }
            />

            <Route
              path={ROUTE_PATH.PROJECTS}
              element={
                <Suspense>
                  <ProjectsPage />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.DEPOSITED_FILES}
              element={
                <Suspense>
                  <DepositedFilesPage />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.FILM_REGISTRATION}
              element={
                <Suspense>
                  <FilmRegistrationPage />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.ADMINISTRATION_PARAMETERS}
              element={
                <Suspense>
                  <UpdateAdministrationParametersPage />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.FILM_REGISTRATION_MANUAL}
              element={
                <Suspense>
                  <ManualFilmRegistrationPage />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.FILM_REGISTRATION_CODE}
              element={
                <Suspense>
                  <CodeFilmRegistration />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.TEAM_MANAGEMENT}
              element={
                <Suspense>
                  <TeamManagementPage />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.DOCUMENT_TEMPLATES}
              element={
                <Suspense>
                  <DocumentTemplatesPage />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.SMS_HISTORY}
              element={
                <Suspense>
                  <SMSHistoryPage />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.SETS}
              element={
                <Suspense>
                  <SetsPage />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.SET}
              element={
                <Suspense>
                  <SetPage />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.TAGS}
              element={
                <Suspense>
                  <TagsPage />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.EMPLOYEE_GROUP}
              element={
                <Suspense>
                  <EmployeeGroupPage />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.VIRTUAL_FOLDER}
              element={
                <Suspense>
                  <VirtualFolderPage />
                </Suspense>
              }
            />
            {/* PRODUCTION */}
            <Route
              path={ROUTE_PATH.CREATE_PRODUCTION}
              element={
                <Suspense>
                  <CreateProductionPage />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.PRODUCTION_SETTINGS}
              element={
                <Suspense>
                  <UpdateProductionPage />
                </Suspense>
              }
            />
            {/* PROJECTS */}
            <Route
              path={ROUTE_PATH.PROJECT_DASHBOARD}
              element={
                <Suspense>
                  <ProjectDashboardPage />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.PROJECT_CALENDAR}
              element={
                <Suspense>
                  <Calendar />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.PROJECT_DPAE}
              element={<Suspense><ProjectDPAEPage /></Suspense>}
            />
            <Route
              path={ROUTE_PATH.CREATE_PROJECT}
              element={
                <Suspense>
                  <CreateProjectPage />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.PROJECT_SETTINGS}
              element={
                <Suspense>
                  <UpdateProjectPage />
                </Suspense>
              }
            />
            {/* REGISTER SETTINGS */}
            <Route
              path={ROUTE_PATH.REGISTER_SETTING}
              element={
                <Suspense>
                  <RegisterSettingsPage />
                </Suspense>
              }
            />
            {/* MESSAGING */}
            <Route
              path={ROUTE_PATH.MESSAGING}
              element={
                <Suspense>
                  <MessagingPage />
                </Suspense>
              }
            />
            {/* EMPLOYEE MANAGEMENT */}
            <Route
              path={ROUTE_PATH.EMPLOYEE_MANAGEMENT}
              element={
                <Suspense>
                  <EmployeeManagementPage />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.EMPLOYEE_REGISTRATION}
              element={
                <Suspense>
                  <EmployeeRegistrationPage />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.APPLICATIONS}
              element={
                <Suspense>
                  <ApplicationsPage />
                </Suspense>
              }
            />
            {/* SEARCH ENGINE */}
            <Route
              path={ROUTE_PATH.SEARCH_ENGINE}
              element={
                <Suspense>
                  <SearchEnginePage />
                </Suspense>
              }
            />
            {/* CONTRACTS MANAGeMENT */}
            <Route
              path={ROUTE_PATH.CONTRACTS_MANAGEMENT}
              element={
                <Suspense>
                  <ContractsManagementPage />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.SIGNATURE_PAD}
              element={
                <Suspense>
                  <SignaturePadPage />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.SIGNATURE_PAD_DETAILS}
              element={
                <Suspense>
                  <SignaturePadDetailsPage />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.SIGNATURE_PAD_SUMMARY}
              element={
                <Suspense>
                  <SignaturePadSummaryPage />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.SIGNATURE_PAD_END}
              element={
                <Suspense>
                  <SignaturePadEndPage />
                </Suspense>
              }
            />

          </Route>
          {/* PREPAPAIE */}
          <Route
            path={ROUTE_PATH.PREPAPAIE}
            element={
              <Suspense>
                <PrepapaiePage />
              </Suspense>
            }
          />
          {/* EMPLOYEE */}
          <Route path={ROUTE_PATH.EMPLOYEE_SAFEBOX} element={<Suspense><EmployeeSafeboxPage /></Suspense>} />
          <Route
            path={ROUTE_PATH.FILMOGRAPHY}
            element={
              <Suspense>
                <FilmographyPage />
              </Suspense>
            }
          />
          {/* catch all */}
          <Route path={ROUTE_PATH.NOT_FOUND} element={<p>Page Not Found</p>} />
        </Route>
      </Route>
      <Route
        path={ROUTE_PATH.FILE}
        element={<Suspense><FileDownload /></Suspense>}
      />
    </Routes>
  );
}

export default App;
