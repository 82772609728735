import { API_BASE_URL, AUTH_END_POINT } from "../constants";
import { axiosPrivate, axiosPrivatePatch } from "./axios";

export async function getAllCompanies(params = {}) {
  const response = await axiosPrivate.get<GetAllCompaniesResponse>(
    `${AUTH_END_POINT.COMPANIES.ALL_COMPANIES}`,
    {
      params,
    }
  );

  return response.data;
}

export async function getCompany(id: string, signal?: AbortSignal) {
  const { data } = await axiosPrivate.get<GetCompanyResponse>(
    `${API_BASE_URL}${AUTH_END_POINT.COMPANIES.COMPANIES}/${id}`,
    { signal }
  );

  return data;
}

export interface PostBatchEmployeeGroupData {
  registrations: string[];
  employeeGroupId: string;
}
export async function postBatchEmployeeGroup(
  companyId: string,
  { registrations, employeeGroupId }: PostBatchEmployeeGroupData
) {
  const { data } = await axiosPrivate.post<any>(
    `${API_BASE_URL}${AUTH_END_POINT.COMPANIES.BATCH_EMPLOYEE_GROUP(
      companyId
    )}`,
    {
      registrations,
      employee_group: employeeGroupId,
    }
  );

  return data;
}

export async function fetchCompanyParameters(id: string) {
  const { data } = await axiosPrivate.get<CompanyParameters>(
    `${API_BASE_URL}${AUTH_END_POINT.COMPANIES.PARAMETERS(id)}`
  );

  return data;
}

export async function patchCompany(newCompany: PatchCompanyParam) {
  const { data } = await axiosPrivatePatch.patch<PatchEmployeeGroupResponse>(
    `${API_BASE_URL}${AUTH_END_POINT.COMPANIES.COMPANIES}/${newCompany.id}`,
    newCompany
  );

  return data;
}

export async function postRegisterField({
  companyId,
  field,
}: PostRegisterFieldParam) {
  const { data } = await axiosPrivate.post<any>(
    `${API_BASE_URL}${AUTH_END_POINT.COMPANIES.REGISTER_FIELD(companyId)}`,
    { field }
  );

  return data;
}

export async function postUnregisterField({
  companyId,
  fieldName,
}: PostUnregisterFieldParam) {
  const { data } = await axiosPrivate.post<PostUnregisterFieldResponse>(
    `${API_BASE_URL}${AUTH_END_POINT.COMPANIES.UNREGISTER_FIELD(companyId)}`,
    { fieldName }
  );

  return data;
}
