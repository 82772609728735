import { MouseEvent, ReactNode, memo } from "react";
import { SecondaryTooltip } from "../../Tooltip";
import { Badge } from "../../core";
import { Tab as AriaTab } from "react-aria-components";
import cn from "mxcn";

export interface Props {
  // className?: string;
  children?: ReactNode;
  icon?: ReactNode;
  id: string;
  title?: string;
  badge?: number;
  variant?: string;
  selected?: boolean;
  onClickTab?: (e: MouseEvent) => void;
  small?: boolean;
}

const Tab = ({
  children,
  id,
  icon,
  variant
  // badge,
}: Props) => {
  // if ( badge === 0 ) badge = undefined;

  // let containerClassName = className + (variant === "vertical" ? "" : "");
  // let tabContainerClassName = (variant === "vertical" ?
  //   "mb-2 font-semibold border border-gray-100" + ( selected ? " bg-gray-100 rounded-l-md -mr-3 border-r-0" : " rounded-md text-gray-500" ) :
  //   ("mr-3 flex flex-col items-center justify-center rounded-t text-white " + (selected ? " bg-mr-cyan-400" : " bg-mr-gray-900"))
  // ) + (selected ? "" : " cursor-pointer");

  // let tabClassName = (variant === "vertical" ?
  //   "px-4 py-3" + (selected ?
  //     " relative before:block before:absolute before:w-4 before:h-8 before:right-0 before:-top-8 before:rounded-br-lg before:shadow-[0_12px_0_0_rgb(243,244,246)] after:absolute after:w-4 after:h-8 after:right-0 after:-bottom-8 after:rounded-tr-lg after:shadow-[0_-12px_0_0_rgb(243,244,246)]"
  //     : "")
  //   : "flex items-center justify-center p-2 gap-2 overflow-hidden truncate"
  //   );

  // let badgeClassName = "";
  // if ( badge && variant === 'vertical' ) {
  //   tabClassName += ' flex justify-between';
  //   badgeClassName = selected ? "mr-3" : ""
  // }

  return (
    <AriaTab id={id} className={cn({
      'react-aria-Tab': variant !== "vertical",
      'border rounded-md border-mr-cyan-200 px-4 py-3 mr-2 mb-2': variant === "vertical",
    })}>
      {icon && <div className="tab_icon">{icon}</div>}
      {children}
    </AriaTab>
    // <div className={containerClassName} data-testid="Tab">
    //   <SecondaryTooltip
    //     direction="top"
    //     text={title}
    //     enabled={small && !selected ? true : false}
    //   >
    //     <div
    //       className={tabContainerClassName}
    //       onClick={onClickTab}
    //       id={id}
    //     >
    //       <div
    //         className={tabClassName}
    //         data-testid="title"
    //       >
    //         {icon}
    //         <div
    //           className={`hidden lg:block ${small && !selected && "!hidden"}`}
    //         >
    //           {title}
    //         </div>
    //         {badge && <Badge className={badgeClassName} size="small" variant="primary">{badge}</Badge>}
    //       </div>
    //     </div>
    //   </SecondaryTooltip>
    // </div>
  );
};

export default memo(Tab);
