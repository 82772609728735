import React, { memo, useEffect } from 'react';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';
import { API_BASE_URL } from '../../constants';

export interface Props {
    images: Gallery[];
    className?: string;
    imageClassName?: string;
}

const HorizontalGallery = ({ images, className, imageClassName }: Props) => {
    useEffect(() => {
        let lightbox = new PhotoSwipeLightbox({
            gallery: '#my-gallery',
            children: 'a',
            pswpModule: () => import('photoswipe'),
        });
        lightbox.init();

        return () => {
            lightbox.destroy();
        };
    }, []);

    return (
        <div
            className={`${className} pswp-gallery flex flex-row justify-start flex-wrap overflow-x-auto scrollbar-hide`}
            id="my-gallery"
        >
            {images.map((image, idx) => (
                <a
                    key={idx}
                    href={API_BASE_URL + image.file.slice(1)}
                    // data-pswp-width={image.width}
                    // data-pswp-height={image.height} // TODO : Needed from Back
                    target="_blank"
                    rel="noreferrer"
                >
                    <img
                        className={imageClassName}
                        src={API_BASE_URL + image.thumbnailFile.slice(1)}
                        // alt=
                    />
                </a>
            ))}
        </div>
    );
};

export default memo(HorizontalGallery);
