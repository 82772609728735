import { faker } from "@faker-js/faker";

export const EMPLOYEE_GROUP_FIXTURE: EmployeeGroup = {
  "@type": "EmployeeGroup",
  "@id": "/employee_groups/1",
  id: faker.string.uuid(),
  company: "/companies/1ed9e4d3-7c98-6e86-a4d9-ff86dd06e2b3",
  name: "Figurants",
  code: "KZW45",
  createdAt: "2023-01-27T14:16:40+00:00",
  createdBy: "/users/1ed9e4c7-1d68-6d28-9322-e503109c3b1d",
  default_file_behaviors: [
    {
      "@id": "/default_file_behaviors/1",
      "@type": "DefaultFileBehavior",
      id: 1,
      file_type: {
        "@id": "/file_types/1",
        "@type": "FileType",
        name: "generic_not_vault",
      },
      company: "/companies/018996a4-4727-7006-9ddc-9559882df452",
      employee_group: "/employee_groups/018996a4-4727-7006-9ddc-95598927f602",
      is_valid: true,
      is_mandatory: false,
    },
  ],
  parameters: {
    fields_employee_groups: [],
    show_working_days: false,
    show_working_hours: false,
    mandatory_pseudonym: false,
    allow_agent: false,
    mandatory_social_security_number: false,
    ask_for_rebate: false,
    ask_for_dematerialize: false,
  },
};

export const EMPLOYEE_GROUPS_FIXTURE: EmployeeGroup[] = [
  EMPLOYEE_GROUP_FIXTURE,
  {
    ...EMPLOYEE_GROUP_FIXTURE,
    "@id": "/employee_groups/2",
    id: faker.string.uuid(),
  },
  {
    ...EMPLOYEE_GROUP_FIXTURE,
    "@id": "/employee_groups/3",
    id: faker.string.uuid(),
  },
];
