import { ButtonHTMLAttributes, MouseEvent, memo, useState } from "react";
import { Button, ButtonProps, PressEvent, TooltipTrigger } from "react-aria-components";
import { Tooltip } from "../Tooltip/Tooltip";
import { twMerge } from "tailwind-merge";

export interface Props extends ButtonProps {
  title?: string | React.ReactNode,
  onClick?: (e?: PressEvent | MouseEvent | undefined) => void,
  className?: string,
  size?: string, /* @todo not used, to remove, for DPAE retro-compatibility */
  delay?: number
}

const IconButton = ({ children, title, onClick, className, size = "", delay = 0, ...props }: Props) => {
  return (
    <TooltipTrigger delay={delay}>
      <Button 
        onPress={onClick}
        className={twMerge(
        "relative rounded-full hover:rounded-full p-2 aspect-square bg-mr-gray-900 text-gray-50 hover:enabled:bg-mr-gray-500 disabled:cursor-not-allowed disabled:opacity-50",
        className)}
        {...props}>{children}</Button>
      {title && 
        <Tooltip className={"bg-mr-gray-900 px-1 py-0.5 text-gray-50 text-xs max-w-[100px] text-center rounded"}>{title}</Tooltip>
      }
    </TooltipTrigger>
  )
};

export default memo(IconButton);
