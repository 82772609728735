import dayjs from "dayjs";
import { memo } from "react";
import { BiMailSend } from "react-icons/bi";
import { HiOutlineMail, HiOutlineMailOpen } from "react-icons/hi";
import { IoTrashOutline } from "react-icons/io5";
import { Mail as MailType } from "../../../types/messaging";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";
import { removeTagsFromString } from "../../../utils/messaging";

export interface Props {
  className?: string;
  mail: MailType;
  tab?: string;
  status?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onClickTrash?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const Mail = ({
  className = "",
  mail,
  tab = "inBox",
  status = "unSeen",
  onClick,
  onClickTrash,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className={className} data-testid="Mail">
      <div
        className="shadow-mail flex cursor-pointer items-center justify-center gap-2 border-b p-2 hover:z-10"
        onClick={onClick}
        id={mail["@id"]}
      >
        {status === "seen" && (
          <HiOutlineMailOpen
            className="mx-3 shrink-0"
            data-testid="statusIcon"
          />
        )}
        {status === "unSeen" && (
          <HiOutlineMail
            className="mx-3 shrink-0 stroke-mr-cyan-400"
            data-testid="statusIcon"
          />
        )}
        {status === "sent" && (
          <BiMailSend className="mx-3 shrink-0" data-testid="statusIcon" />
        )}

        <div
          className={`mx-3 w-[10%] shrink-0 truncate ${
            status === "unSeen" && "font-bold"
          }`}
          data-testid="expeditor"
        >
          {/* {mail.author && `${mail.author.firstName} ${mail.author.lastName}`} */}
          {mail.author &&
            `${mail.author.userFields[0].value} ${mail.author.userFields[1].value}`}
        </div>
        <div
          className={`mx-3 w-[30%] shrink-0 truncate ${
            status === "unSeen" && "font-bold text-mr-cyan-400"
          }`}
          data-testid="object"
        >
          {mail.subject && mail.subject}
        </div>
        <div
          className="grow-1 w-full truncate"
          title={mail.message}
          data-testid="content"
        >
          {removeTagsFromString(DOMPurify.sanitize(mail.message))}
        </div>
        <div
          className="mx-3 w-[15%] shrink-0 overflow-hidden truncate rounded-full border bg-white px-1 text-center text-xs"
          data-testid="date"
        >
          {mail.date && dayjs(mail.date).format(t("dateFormat"))}
        </div>
        {tab === "inBox" && (
          <button
            onClick={onClickTrash}
            id={mail["@id"]}
            data-testid="trashBtn"
          >
            <IoTrashOutline className="mx-3 shrink-0 hover:stroke-mr-cyan-400" />
          </button>
        )}
      </div>
    </div>
  );
};

export default memo(Mail);
