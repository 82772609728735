import cn from "mxcn";
import { Row as AriaRow } from "react-aria-components";

function Row({className, ...props}: any) {
  return (
    <AriaRow
      {...props}
      className={({ isSelected }) => {
        return cn(
          className,
          "border-y",
          isSelected
            ? "bg-table-green border-[#88C2C8] text-[#121212] font-medium"
            : "odd:bg-white even:bg-table-grey border-[#DADADA] text-[#777777]"
        )
      }}
    >
      {props.children}
    </AriaRow>
  );
}

export default Row;
