import { memo } from 'react';
import { FormProvider, FormProviderProps, UseFormProps } from 'react-hook-form';

export interface Props {
    methods: any; // TODO : Find a way to type this properly
    onSubmit: (data: any) => void;
    children: React.ReactNode;
    className?: string;
}

const Form = ({ methods, onSubmit, children, className }: Props) => {
    return (
        <FormProvider {...methods}>
            <form
                className={className}
                onSubmit={methods.handleSubmit(onSubmit)}
            >
                {children}
            </form>
        </FormProvider>
    );
};

export default memo(Form);
