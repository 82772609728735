import { COMPANYFORM_FIXTURE } from "./company";
import { PRODUCTION_TEAM_FIXTURE } from "./production_team";

export const PRODUCTION_FIXTURE: Production = {
  "@context": "/contexts/Production",
  "@id": "/productions/1ed45845-1123-618a-b5d6-25cac5bbd083",
  "@type": "Production",
  rcs_address: "RCS Address",
  rcs_number: "0123456",
  urssaf_center: "/urssaf_centers/1",
  legal_entity_type: "/legal_entity_types/1",
  social_capital: "109810981",
  productionUsers: [],
  // dpae: "dpae", Doesn't exist for now
  // last_update: "12-08-2022",
  // last_update_user: "Nicolas Pierre",
  team: PRODUCTION_TEAM_FIXTURE,
  projects: [],
  company: COMPANYFORM_FIXTURE,
};

export const PRODUCTION_FORM_FIXTURE: ProductionForm = {
  rcs_address: "RCS Address",
  rcs_number: "0123456",
  urssaf_center: "/urssaf_centers/1",
  legal_entity_type: "/legal_entity_types/1",
  social_capital: "109810981",
  company: COMPANYFORM_FIXTURE,

  employee_groups: [],
  mail_groups: [],
  observers: [],
  contract_annexes: [],
  signatories: [],
  sms_groups: [],
};

export const PRODUCTIONS_REQUEST_FIXTURE: GetProductionsResponse = {
  "@context": "string",
  "@id": "string",
  "@type": "string",
  "hydra:member": [PRODUCTION_FIXTURE, PRODUCTION_FIXTURE, PRODUCTION_FIXTURE],
  "hydra:totalItems": 3,
};
