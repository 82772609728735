import { API_BASE_URL, AUTH_END_POINT } from "../constants";
import { getIdByIriSelector } from "../utils/selectors";
import { axiosPrivate, axiosPrivatePatch } from "./axios";

export async function patchEG(newEG: PatchEGParams) {
  const { data } = await axiosPrivatePatch.patch<PatchEmployeeGroupResponse>(
    `${API_BASE_URL}${AUTH_END_POINT.EMPLOYEE_GROUPS()}/${newEG.id}`,
    newEG
  );

  return data;
}

export async function postEG(newEG: PostEmployeeGroupParam) {
  const { data } = await axiosPrivate.post<PostEmployeeGroupResponse>(
    `${API_BASE_URL}${AUTH_END_POINT.EMPLOYEE_GROUPS(
      getIdByIriSelector(newEG.company)
    )}`,
    newEG
  );

  return data;
}

export const removeEG = async (id: string) =>
  await axiosPrivate.delete(
    `${API_BASE_URL}${AUTH_END_POINT.EMPLOYEE_GROUPS()}/${id}`
  );
