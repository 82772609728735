import { memo } from "react";
import { IoIosClose } from "react-icons/io";
import { AiFillStar } from "react-icons/ai";

export interface ComboItemProps {
  id: string;
  comboItem: string;
  stars: number;
}
export interface ComboItemPropsWithCallback extends ComboItemProps {
  onClick?: (num: number, id: string) => void;
  onClose?: (id: string) => void;
}

const ComboItem = ({
  id,
  comboItem,
  stars,
  onClick = () => {},
  onClose = () => {},
}: ComboItemPropsWithCallback) => (
  <div className="flex items-center justify-between rounded border border-mr-cyan-300 bg-mr-cyan-100 p-2">
    <IoIosClose
      className="h-6 w-6 flex-shrink-0 cursor-pointer drop-shadow-lg hover:fill-gray-500"
      onClick={() => onClose(id)}
    />
    <div className="truncate">{comboItem}</div>
    <div className="flex">
      <AiFillStar
        onClick={() => onClick(1, id)}
        className={`h-6 w-6 cursor-pointer ${
          stars > 0 ? "fill-mr-orange-300" : "fill-mr-gray-100"
        }`}
      />
      <AiFillStar
        onClick={() => onClick(2, id)}
        className={`h-6 w-6 cursor-pointer ${
          stars > 1 ? "fill-mr-orange-300" : "fill-mr-gray-100"
        }`}
      />
      <AiFillStar
        onClick={() => onClick(3, id)}
        className={`h-6 w-6 cursor-pointer ${
          stars > 2 ? "fill-mr-orange-300" : "fill-mr-gray-100"
        }`}
      />
    </div>
  </div>
);

export default memo(ComboItem);
