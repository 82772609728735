import { cloneElement, createElement, useEffect, useRef } from 'react';
import { axiosPrivate } from '../../api/axios';
import { useQuery } from '@tanstack/react-query';
import cn from 'mxcn';

interface Props {
    fileId: string;
    className?: string;
}

function DisplayFileDownload({ fileId, className }: Props) {
    const { data, isLoading } = useQuery({
        queryKey: ['file', fileId],
        queryFn: async () => {
            const res = await axiosPrivate.get(`/files/${fileId}/download`, {
                responseType: 'blob',
            });
            const type = res.headers['content-type'];
            const file = window.URL.createObjectURL(res.data);

            const typeSelector: { [key: string]: string } = {
                'image/jpeg': 'img',
                'image/png': 'img',
                'application/pdf': 'iframe',
                default: 'iframe',
            };

            const handleFileLoaded = (e: any) => {
                console.log('file Loaded');
                window.URL.revokeObjectURL(file);
            };

            const tagName = typeSelector[type] || typeSelector['default'];
            const element = createElement(tagName, {
                src: file,
                className: cn(className, 'w-1/2 h-full object-contain'),
                onLoad: handleFileLoaded,
            });

            return {
                type,
                element: element,
            };
        },
    });

    return <>{!isLoading && data?.element}</>;
}

export default DisplayFileDownload;
