import {
  ForwardedRef,
  InputHTMLAttributes,
  forwardRef,
  useCallback,
  useRef,
} from "react";
import { HiOutlineFolderOpen } from "react-icons/hi";
import { TertiaryButton } from "../Button";
import Input, { Props as InputProps } from "../Input/OldInput";

export interface Props extends InputProps {
  label: string;
}

const FileSelector = (
  { className = "", label, ...props }: Props,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const labelRef = useRef<HTMLLabelElement>(null);

  const handleClickOnFile = useCallback(() => {
    labelRef.current && labelRef.current.click();
  }, [labelRef]);

  return (
    <div className={className} data-testid="FileSelector">
      <label className="cursor-pointer" ref={labelRef}>
        {/* TODO replace by a fileTrigger from React Aria */}
        <Input className="hidden" type="file"
          ref={ref}
          {...props} />
        <TertiaryButton
          startIcon={<HiOutlineFolderOpen className="h-7 w-7" />}
          onClick={handleClickOnFile}
          disabled={props.disabled}
        >
          {label}
        </TertiaryButton>
      </label>
    </div>
  );
};

export default forwardRef(FileSelector);
