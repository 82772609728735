import classNames from "classnames";
import { ButtonHTMLAttributes, Component, memo, ReactNode } from "react";
import { NavLink, useNavigate } from "react-router-dom";

export interface Props {
  as?: string | Component | any;
  href?: string;
  to?: any;
  children: any;
}

const SubSideNavbarCategoryLink = ({
  as = NavLink,
  children,
  to,
  ...props
}: Props) => {
  const navigate = useNavigate();
  const As = as;

  let className: any = "flex items-center gap-2 truncate";

  if (as === NavLink) {
    className = ({ isActive = false }) => {
      return classNames("flex items-center gap-2 truncate", {
        "text-mr-cyan-300": isActive && to,
      });
    };
  }

  return (
    <As className={className} to={to} {...props}>
      {children}
    </As>
  );
};

export default memo(SubSideNavbarCategoryLink);
