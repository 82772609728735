import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { HiOutlineDocumentText, HiOutlinePlusCircle } from "react-icons/hi";

import { useAuth } from "../../../hooks";
import { ROUTE_PATH } from "../../../constants";
import { Button } from "../../core";

export interface Props {
  translationKey: string;
  className?: string;
}

const HeaderDashboard = ({ translationKey, className = "" }: Props) => {
  {
    /* TODO use auth role to display buttons needed */
  }
  const { auth } = useAuth();

  const { t } = useTranslation("translation", { keyPrefix: translationKey });

  const navigate = useNavigate();
  return (
    <div className="header-dashboard">
      <h1 className="mb-7 text-3xl font-bold">{t("pageTitle")}</h1>

      <div className={`${className} text-sm text-[#5D6165]`}>
        <Trans
          t={t}
          i18nKey="dashboardText" // optional -> fallbacks to defaults if not provided
          components={[
            <a className="underline" href="mailto:xxxx@gmail.com">
              Mailto
            </a>,
          ]}
        />
      </div>

      <div className="mt-6 inline-flex gap-2 rounded-full bg-black p-2">
        <Button
          disabled
          onClick={() => {}}
          className={"bg-[#74AF20] pl-[10px] text-white opacity-60"}
          size="medium"
        >
          <HiOutlineDocumentText className="mr-2 h-[24px] w-[18px]" />
          {t("askEstimateButton")}
        </Button>

        <Button
          onClick={() => navigate(ROUTE_PATH.CREATE_PROJECT)}
          className={"bg-[#3AA2AD] pl-[10px] text-white"}
          size="medium"
        >
          <HiOutlinePlusCircle className="mr-2 h-[24px] w-[18px]" />
          {t("newProjectButton")}
        </Button>
        <Button
          onClick={() => navigate(ROUTE_PATH.CREATE_PRODUCTION)}
          className={"bg-[#EA754B] pl-[10px] text-white"}
          size="medium"
        >
          <HiOutlinePlusCircle className="mr-2 h-[24px] w-[18px]" />
          {t("newProductionButton")}
        </Button>
      </div>
    </div>
  );
};

export default memo(HeaderDashboard);
