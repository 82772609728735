export const CIVILITIES_FIXTURE = {
  "@context": "/contexts/Civility",
  "@id": "/civilities",
  "@type": "hydra:Collection",
  "hydra:member": [
    {
      "@id": "/civilities/1",
      "@type": "Civility",
      id: 1,
      name: "Mme",
    },
    {
      "@id": "/civilities/2",
      "@type": "Civility",
      id: 2,
      name: "M.",
    },
  ],
  "hydra:totalItems": 2,
};
