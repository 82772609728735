import { AUTH_END_POINT } from "../constants";
import { axiosPrivate } from "./axios";

export async function getProfile(id: string) {
  const response = await axiosPrivate.get<GetProfileResponse>(
    AUTH_END_POINT.PROFILE(id)
  );

  return response.data;
}

export async function postProfile(id: string, newProfile: Profile) {
  const response = await axiosPrivate.post<PostProfileResponse>(
    AUTH_END_POINT.PROFILE(id),
    newProfile
  );

  return response.data;
}

export async function getAllUsers() {
  const response = await axiosPrivate.get<GetAllUsersResponse>(
    // `${AUTH_END_POINT.USERS}?include=userFields`
    `${AUTH_END_POINT.USERS}?page=1`
  );

  return response.data;
}
