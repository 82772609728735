import DisplayFileDownload from "../../components/DisplayFileDownload/DisplayFileDownload";
import { useRequiredParams } from "../../hooks/useRequiredParams";

const FileDownload = () => {
    const { fileId } = useRequiredParams();


    return (
        <>
            <DisplayFileDownload fileId={fileId}/>
        </>
    )
};

export default FileDownload;