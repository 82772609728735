import { atom } from "recoil";

interface LoginModal {
  open: boolean;
}

const LoginModal = atom<LoginModal>({
  key: "LoginModal",
  default: { open: false },
});

export default LoginModal;
