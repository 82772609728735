// Row list of countries.
// prefer use COUNTRIES_I18N to have array of
// translated countries
const COUNTRIES: {
  id: string;
  fr: string;
  en: string;
}[] = [
  {
    id: "AFG",
    fr: "Afghanistan",
    en: "Afghanistan",
  },
  {
    id: "ALA",
    fr: "Åland",
    en: "Åland Islands",
  },
  {
    id: "ALB",
    fr: "Albanie",
    en: "Albania",
  },
  {
    id: "DZA",
    fr: "Algérie",
    en: "Algeria",
  },
  {
    id: "ASM",
    fr: "Samoa américaines",
    en: "American Samoa",
  },
  {
    id: "AND",
    fr: "Andorre",
    en: "Andorra",
  },
  {
    id: "AGO",
    fr: "Angola",
    en: "Angola",
  },
  {
    id: "AIA",
    fr: "Anguilla",
    en: "Anguilla",
  },
  {
    id: "ATA",
    fr: "Antarctique",
    en: "Antarctica",
  },
  {
    id: "ATG",
    fr: "Antigua-et-Barbuda",
    en: "Antigua and Barbuda",
  },
  {
    id: "ARG",
    fr: "Argentine",
    en: "Argentina",
  },
  {
    id: "ARM",
    fr: "Arménie",
    en: "Armenia",
  },
  {
    id: "ABW",
    fr: "Aruba",
    en: "Aruba",
  },
  {
    id: "AUS",
    fr: "Australie",
    en: "Australia",
  },
  {
    id: "AUT",
    fr: "Autriche",
    en: "Austria",
  },
  {
    id: "AZE",
    fr: "Azerbaïdjan",
    en: "Azerbaijan",
  },
  {
    id: "BHS",
    fr: "Bahamas",
    en: "Bahamas",
  },
  {
    id: "BHR",
    fr: "Bahreïn",
    en: "Bahrain",
  },
  {
    id: "BGD",
    fr: "Bangladesh",
    en: "Bangladesh",
  },
  {
    id: "BRB",
    fr: "Barbade",
    en: "Barbados",
  },
  {
    id: "BLR",
    fr: "Biélorussie",
    en: "Belarus",
  },
  {
    id: "BEL",
    fr: "Belgique",
    en: "Belgium",
  },
  {
    id: "BLZ",
    fr: "Belize",
    en: "Belize",
  },
  {
    id: "BEN",
    fr: "Bénin",
    en: "Benin",
  },
  {
    id: "BMU",
    fr: "Bermudes",
    en: "Bermuda",
  },
  {
    id: "BTN",
    fr: "Bhoutan",
    en: "Bhutan",
  },
  {
    id: "BOL",
    fr: "Bolivie",
    en: "Bolivia (Plurinational State of)",
  },
  {
    id: "BES",
    fr: "Bonaire, Saint-Eustache et Saba",
    en: "Bonaire, Sint Eustatius and Saba",
  },
  {
    id: "BIH",
    fr: "Bosnie-Herzégovine",
    en: "Bosnia and Herzegovina",
  },
  {
    id: "BWA",
    fr: "Botswana",
    en: "Botswana",
  },
  {
    id: "BVT",
    fr: "Île Bouvet",
    en: "Bouvet Island",
  },
  {
    id: "BRA",
    fr: "Brésil",
    en: "Brazil",
  },
  {
    id: "IOT",
    fr: "Territoire britannique de l'océan Indien",
    en: "British Indian Ocean Territory",
  },
  {
    id: "UMI",
    fr: "Îles mineures éloignées des États-Unis",
    en: "United States Minor Outlying Islands",
  },
  {
    id: "VGB",
    fr: "Îles Vierges britanniques",
    en: "Virgin Islands (British)",
  },
  {
    id: "VIR",
    fr: "Îles Vierges des États-Unis",
    en: "Virgin Islands (U.S.)",
  },
  {
    id: "BRN",
    fr: "Brunei",
    en: "Brunei Darussalam",
  },
  {
    id: "BGR",
    fr: "Bulgarie",
    en: "Bulgaria",
  },
  {
    id: "BFA",
    fr: "Burkina Faso",
    en: "Burkina Faso",
  },
  {
    id: "BDI",
    fr: "Burundi",
    en: "Burundi",
  },
  {
    id: "KHM",
    fr: "Cambodge",
    en: "Cambodia",
  },
  {
    id: "CMR",
    fr: "Cameroun",
    en: "Cameroon",
  },
  {
    id: "CAN",
    fr: "Canada",
    en: "Canada",
  },
  {
    id: "CPV",
    fr: "Cap Vert",
    en: "Cabo Verde",
  },
  {
    id: "CYM",
    fr: "Îles Caïmans",
    en: "Cayman Islands",
  },
  {
    id: "CAF",
    fr: "République centrafricaine",
    en: "Central African Republic",
  },
  {
    id: "TCD",
    fr: "Tchad",
    en: "Chad",
  },
  {
    id: "CHL",
    fr: "Chili",
    en: "Chile",
  },
  {
    id: "CHN",
    fr: "Chine",
    en: "China",
  },
  {
    id: "CXR",
    fr: "Île Christmas",
    en: "Christmas Island",
  },
  {
    id: "CCK",
    fr: "Îles Cocos",
    en: "Cocos (Keeling) Islands",
  },
  {
    id: "COL",
    fr: "Colombie",
    en: "Colombia",
  },
  {
    id: "COM",
    fr: "Comores",
    en: "Comoros",
  },
  {
    id: "COG",
    fr: "Congo",
    en: "Congo",
  },
  {
    id: "COD",
    fr: "Congo (Rép. dém.)",
    en: "Congo (Democratic Republic of the)",
  },
  {
    id: "COK",
    fr: "Îles Cook",
    en: "Cook Islands",
  },
  {
    id: "CRI",
    fr: "Costa Rica",
    en: "Costa Rica",
  },
  {
    id: "HRV",
    fr: "Croatie",
    en: "Croatia",
  },
  {
    id: "CUB",
    fr: "Cuba",
    en: "Cuba",
  },
  {
    id: "CUW",
    fr: "Curaçao",
    en: "Curaçao",
  },
  {
    id: "CYP",
    fr: "Chypre",
    en: "Cyprus",
  },
  {
    id: "CZE",
    fr: "République tchèque",
    en: "Czech Republic",
  },
  {
    id: "DNK",
    fr: "Danemark",
    en: "Denmark",
  },
  {
    id: "DJI",
    fr: "Djibouti",
    en: "Djibouti",
  },
  {
    id: "DMA",
    fr: "Dominique",
    en: "Dominica",
  },
  {
    id: "DOM",
    fr: "République dominicaine",
    en: "Dominican Republic",
  },
  {
    id: "ECU",
    fr: "Équateur",
    en: "Ecuador",
  },
  {
    id: "EGY",
    fr: "Égypte",
    en: "Egypt",
  },
  {
    id: "SLV",
    fr: "Salvador",
    en: "El Salvador",
  },
  {
    id: "GNQ",
    fr: "Guinée-Équatoriale",
    en: "Equatorial Guinea",
  },
  {
    id: "ERI",
    fr: "Érythrée",
    en: "Eritrea",
  },
  {
    id: "EST",
    fr: "Estonie",
    en: "Estonia",
  },
  {
    id: "ETH",
    fr: "Éthiopie",
    en: "Ethiopia",
  },
  {
    id: "FLK",
    fr: "Îles Malouines",
    en: "Falkland Islands (Malvinas)",
  },
  {
    id: "FRO",
    fr: "Îles Féroé",
    en: "Faroe Islands",
  },
  {
    id: "FJI",
    fr: "Fidji",
    en: "Fiji",
  },
  {
    id: "FIN",
    fr: "Finlande",
    en: "Finland",
  },
  {
    id: "FRA",
    fr: "France",
    en: "France",
  },
  {
    id: "GUF",
    fr: "Guayane",
    en: "French Guiana",
  },
  {
    id: "PYF",
    fr: "Polynésie française",
    en: "French Polynesia",
  },
  {
    id: "ATF",
    fr: "Terres australes et antarctiques françaises",
    en: "French Southern Territories",
  },
  {
    id: "GAB",
    fr: "Gabon",
    en: "Gabon",
  },
  {
    id: "GMB",
    fr: "Gambie",
    en: "Gambia",
  },
  {
    id: "GEO",
    fr: "Géorgie",
    en: "Georgia",
  },
  {
    id: "DEU",
    fr: "Allemagne",
    en: "Germany",
  },
  {
    id: "GHA",
    fr: "Ghana",
    en: "Ghana",
  },
  {
    id: "GIB",
    fr: "Gibraltar",
    en: "Gibraltar",
  },
  {
    id: "GRC",
    fr: "Grèce",
    en: "Greece",
  },
  {
    id: "GRL",
    fr: "Groenland",
    en: "Greenland",
  },
  {
    id: "GRD",
    fr: "Grenade",
    en: "Grenada",
  },
  {
    id: "GLP",
    fr: "Guadeloupe",
    en: "Guadeloupe",
  },
  {
    id: "GUM",
    fr: "Guam",
    en: "Guam",
  },
  {
    id: "GTM",
    fr: "Guatemala",
    en: "Guatemala",
  },
  {
    id: "GGY",
    fr: "Guernesey",
    en: "Guernsey",
  },
  {
    id: "GIN",
    fr: "Guinée",
    en: "Guinea",
  },
  {
    id: "GNB",
    fr: "Guinée-Bissau",
    en: "Guinea-Bissau",
  },
  {
    id: "GUY",
    fr: "Guyane",
    en: "Guyana",
  },
  {
    id: "HTI",
    fr: "Haïti",
    en: "Haiti",
  },
  {
    id: "HMD",
    fr: "Îles Heard-et-MacDonald",
    en: "Heard Island and McDonald Islands",
  },
  {
    id: "VAT",
    fr: "Saint-Siège",
    en: "Vatican City",
  },
  {
    id: "HND",
    fr: "Honduras",
    en: "Honduras",
  },
  {
    id: "HUN",
    fr: "Hongrie",
    en: "Hungary",
  },
  {
    id: "HKG",
    fr: "Hong Kong",
    en: "Hong Kong",
  },
  {
    id: "ISL",
    fr: "Islande",
    en: "Iceland",
  },
  {
    id: "IND",
    fr: "Inde",
    en: "India",
  },
  {
    id: "IDN",
    fr: "Indonésie",
    en: "Indonesia",
  },
  {
    id: "CIV",
    fr: "Côte d'Ivoire",
    en: "Ivory Coast",
  },
  {
    id: "IRN",
    fr: "Iran",
    en: "Iran (Islamic Republic of)",
  },
  {
    id: "IRQ",
    fr: "Irak",
    en: "Iraq",
  },
  {
    id: "IRL",
    fr: "Irlande",
    en: "Ireland",
  },
  {
    id: "IMN",
    fr: "Île de Man",
    en: "Isle of Man",
  },
  {
    id: "ISR",
    fr: "Israël",
    en: "Israel",
  },
  {
    id: "ITA",
    fr: "Italie",
    en: "Italy",
  },
  {
    id: "JAM",
    fr: "Jamaïque",
    en: "Jamaica",
  },
  {
    id: "JPN",
    fr: "Japon",
    en: "Japan",
  },
  {
    id: "JEY",
    fr: "Jersey",
    en: "Jersey",
  },
  {
    id: "JOR",
    fr: "Jordanie",
    en: "Jordan",
  },
  {
    id: "KAZ",
    fr: "Kazakhstan",
    en: "Kazakhstan",
  },
  {
    id: "KEN",
    fr: "Kenya",
    en: "Kenya",
  },
  {
    id: "KIR",
    fr: "Kiribati",
    en: "Kiribati",
  },
  {
    id: "KWT",
    fr: "Koweït",
    en: "Kuwait",
  },
  {
    id: "KGZ",
    fr: "Kirghizistan",
    en: "Kyrgyzstan",
  },
  {
    id: "LAO",
    fr: "Laos",
    en: "Lao People's Democratic Republic",
  },
  {
    id: "LVA",
    fr: "Lettonie",
    en: "Latvia",
  },
  {
    id: "LBN",
    fr: "Liban",
    en: "Lebanon",
  },
  {
    id: "LSO",
    fr: "Lesotho",
    en: "Lesotho",
  },
  {
    id: "LBR",
    fr: "Liberia",
    en: "Liberia",
  },
  {
    id: "LBY",
    fr: "Libye",
    en: "Libya",
  },
  {
    id: "LIE",
    fr: "Liechtenstein",
    en: "Liechtenstein",
  },
  {
    id: "LTU",
    fr: "Lituanie",
    en: "Lithuania",
  },
  {
    id: "LUX",
    fr: "Luxembourg",
    en: "Luxembourg",
  },
  {
    id: "MAC",
    fr: "Macao",
    en: "Macao",
  },
  {
    id: "MKD",
    fr: "Macédoine",
    en: "North Macedonia",
  },
  {
    id: "MDG",
    fr: "Madagascar",
    en: "Madagascar",
  },
  {
    id: "MWI",
    fr: "Malawi",
    en: "Malawi",
  },
  {
    id: "MYS",
    fr: "Malaisie",
    en: "Malaysia",
  },
  {
    id: "MDV",
    fr: "Maldives",
    en: "Maldives",
  },
  {
    id: "MLI",
    fr: "Mali",
    en: "Mali",
  },
  {
    id: "MLT",
    fr: "Malte",
    en: "Malta",
  },
  {
    id: "MHL",
    fr: "Îles Marshall",
    en: "Marshall Islands",
  },
  {
    id: "MTQ",
    fr: "Martinique",
    en: "Martinique",
  },
  {
    id: "MRT",
    fr: "Mauritanie",
    en: "Mauritania",
  },
  {
    id: "MUS",
    fr: "Île Maurice",
    en: "Mauritius",
  },
  {
    id: "MYT",
    fr: "Mayotte",
    en: "Mayotte",
  },
  {
    id: "MEX",
    fr: "Mexique",
    en: "Mexico",
  },
  {
    id: "FSM",
    fr: "Micronésie",
    en: "Micronesia (Federated States of)",
  },
  {
    id: "MDA",
    fr: "Moldavie",
    en: "Moldova (Republic of)",
  },
  {
    id: "MCO",
    fr: "Monaco",
    en: "Monaco",
  },
  {
    id: "MNG",
    fr: "Mongolie",
    en: "Mongolia",
  },
  {
    id: "MNE",
    fr: "Monténégro",
    en: "Montenegro",
  },
  {
    id: "MSR",
    fr: "Montserrat",
    en: "Montserrat",
  },
  {
    id: "MAR",
    fr: "Maroc",
    en: "Morocco",
  },
  {
    id: "MOZ",
    fr: "Mozambique",
    en: "Mozambique",
  },
  {
    id: "MMR",
    fr: "Myanmar",
    en: "Myanmar",
  },
  {
    id: "NAM",
    fr: "Namibie",
    en: "Namibia",
  },
  {
    id: "NRU",
    fr: "Nauru",
    en: "Nauru",
  },
  {
    id: "NPL",
    fr: "Népal",
    en: "Nepal",
  },
  {
    id: "NLD",
    fr: "Pays-Bas",
    en: "Netherlands",
  },
  {
    id: "NCL",
    fr: "Nouvelle-Calédonie",
    en: "New Caledonia",
  },
  {
    id: "NZL",
    fr: "Nouvelle-Zélande",
    en: "New Zealand",
  },
  {
    id: "NIC",
    fr: "Nicaragua",
    en: "Nicaragua",
  },
  {
    id: "NER",
    fr: "Niger",
    en: "Niger",
  },
  {
    id: "NGA",
    fr: "Nigéria",
    en: "Nigeria",
  },
  {
    id: "NIU",
    fr: "Niue",
    en: "Niue",
  },
  {
    id: "NFK",
    fr: "Île de Norfolk",
    en: "Norfolk Island",
  },
  {
    id: "PRK",
    fr: "Corée du Nord",
    en: "Korea (Democratic People's Republic of)",
  },
  {
    id: "MNP",
    fr: "Îles Mariannes du Nord",
    en: "Northern Mariana Islands",
  },
  {
    id: "NOR",
    fr: "Norvège",
    en: "Norway",
  },
  {
    id: "OMN",
    fr: "Oman",
    en: "Oman",
  },
  {
    id: "PAK",
    fr: "Pakistan",
    en: "Pakistan",
  },
  {
    id: "PLW",
    fr: "Palaos",
    en: "Palau",
  },
  {
    id: "PSE",
    fr: "Palestine",
    en: "Palestine, State of",
  },
  {
    id: "PAN",
    fr: "Panama",
    en: "Panama",
  },
  {
    id: "PNG",
    fr: "Papouasie-Nouvelle-Guinée",
    en: "Papua New Guinea",
  },
  {
    id: "PRY",
    fr: "Paraguay",
    en: "Paraguay",
  },
  {
    id: "PER",
    fr: "Pérou",
    en: "Peru",
  },
  {
    id: "PHL",
    fr: "Philippines",
    en: "Philippines",
  },
  {
    id: "PCN",
    fr: "Îles Pitcairn",
    en: "Pitcairn",
  },
  {
    id: "POL",
    fr: "Pologne",
    en: "Poland",
  },
  {
    id: "PRT",
    fr: "Portugal",
    en: "Portugal",
  },
  {
    id: "PRI",
    fr: "Porto Rico",
    en: "Puerto Rico",
  },
  {
    id: "QAT",
    fr: "Qatar",
    en: "Qatar",
  },
  {
    id: "UNK",
    fr: "Kosovo",
    en: "Republic of Kosovo",
  },
  {
    id: "REU",
    fr: "Réunion",
    en: "Réunion",
  },
  {
    id: "ROU",
    fr: "Roumanie",
    en: "Romania",
  },
  {
    id: "RUS",
    fr: "Russie",
    en: "Russian Federation",
  },
  {
    id: "RWA",
    fr: "Rwanda",
    en: "Rwanda",
  },
  {
    id: "BLM",
    fr: "Saint-Barthélemy",
    en: "Saint Barthélemy",
  },
  {
    id: "SHN",
    fr: "Sainte-Hélène",
    en: "Saint Helena, Ascension and Tristan da Cunha",
  },
  {
    id: "KNA",
    fr: "Saint-Christophe-et-Niévès",
    en: "Saint Kitts and Nevis",
  },
  {
    id: "LCA",
    fr: "Saint-Lucie",
    en: "Saint Lucia",
  },
  {
    id: "MAF",
    fr: "Saint-Martin",
    en: "Saint Martin (French part)",
  },
  {
    id: "SPM",
    fr: "Saint-Pierre-et-Miquelon",
    en: "Saint Pierre and Miquelon",
  },
  {
    id: "VCT",
    fr: "Saint-Vincent-et-les-Grenadines",
    en: "Saint Vincent and the Grenadines",
  },
  {
    id: "WSM",
    fr: "Samoa",
    en: "Samoa",
  },
  {
    id: "SMR",
    fr: "Saint-Marin",
    en: "San Marino",
  },
  {
    id: "STP",
    fr: "Sao Tomé-et-Principe",
    en: "Sao Tome and Principe",
  },
  {
    id: "SAU",
    fr: "Arabie Saoudite",
    en: "Saudi Arabia",
  },
  {
    id: "SEN",
    fr: "Sénégal",
    en: "Senegal",
  },
  {
    id: "SRB",
    fr: "Serbie",
    en: "Serbia",
  },
  {
    id: "SYC",
    fr: "Seychelles",
    en: "Seychelles",
  },
  {
    id: "SLE",
    fr: "Sierra Leone",
    en: "Sierra Leone",
  },
  {
    id: "SGP",
    fr: "Singapour",
    en: "Singapore",
  },
  {
    id: "SXM",
    fr: "Saint Martin (partie néerlandaise)",
    en: "Sint Maarten (Dutch part)",
  },
  {
    id: "SVK",
    fr: "Slovaquie",
    en: "Slovakia",
  },
  {
    id: "SVN",
    fr: "Slovénie",
    en: "Slovenia",
  },
  {
    id: "SLB",
    fr: "Îles Salomon",
    en: "Solomon Islands",
  },
  {
    id: "SOM",
    fr: "Somalie",
    en: "Somalia",
  },
  {
    id: "ZAF",
    fr: "Afrique du Sud",
    en: "South Africa",
  },
  {
    id: "SGS",
    fr: "Géorgie du Sud-et-les Îles Sandwich du Sud",
    en: "South Georgia and the South Sandwich Islands",
  },
  {
    id: "KOR",
    fr: "Corée du Sud",
    en: "Korea (Republic of)",
  },
  {
    id: "ESP",
    fr: "Espagne",
    en: "Spain",
  },
  {
    id: "LKA",
    fr: "Sri Lanka",
    en: "Sri Lanka",
  },
  {
    id: "SDN",
    fr: "Soudan",
    en: "Sudan",
  },
  {
    id: "SSD",
    fr: "Soudan du Sud",
    en: "South Sudan",
  },
  {
    id: "SUR",
    fr: "Surinam",
    en: "Suriname",
  },
  {
    id: "SJM",
    fr: "Svalbard et Jan Mayen",
    en: "Svalbard and Jan Mayen",
  },
  {
    id: "SWZ",
    fr: "Swaziland",
    en: "Swaziland",
  },
  {
    id: "SWE",
    fr: "Suède",
    en: "Sweden",
  },
  {
    id: "CHE",
    fr: "Suisse",
    en: "Switzerland",
  },
  {
    id: "SYR",
    fr: "Syrie",
    en: "Syrian Arab Republic",
  },
  {
    id: "TWN",
    fr: "Taïwan",
    en: "Taiwan",
  },
  {
    id: "TJK",
    fr: "Tadjikistan",
    en: "Tajikistan",
  },
  {
    id: "TZA",
    fr: "Tanzanie",
    en: "Tanzania, United Republic of",
  },
  {
    id: "THA",
    fr: "Thaïlande",
    en: "Thailand",
  },
  {
    id: "TLS",
    fr: "Timor oriental",
    en: "Timor-Leste",
  },
  {
    id: "TGO",
    fr: "Togo",
    en: "Togo",
  },
  {
    id: "TKL",
    fr: "Tokelau",
    en: "Tokelau",
  },
  {
    id: "TON",
    fr: "Tonga",
    en: "Tonga",
  },
  {
    id: "TTO",
    fr: "Trinité et Tobago",
    en: "Trinidad and Tobago",
  },
  {
    id: "TUN",
    fr: "Tunisie",
    en: "Tunisia",
  },
  {
    id: "TUR",
    fr: "Turquie",
    en: "Turkey",
  },
  {
    id: "TKM",
    fr: "Turkménistan",
    en: "Turkmenistan",
  },
  {
    id: "TCA",
    fr: "Îles Turques-et-Caïques",
    en: "Turks and Caicos Islands",
  },
  {
    id: "TUV",
    fr: "Tuvalu",
    en: "Tuvalu",
  },
  {
    id: "UGA",
    fr: "Uganda",
    en: "Uganda",
  },
  {
    id: "UKR",
    fr: "Ukraine",
    en: "Ukraine",
  },
  {
    id: "ARE",
    fr: "Émirats arabes unis",
    en: "United Arab Emirates",
  },
  {
    id: "GBR",
    fr: "Royaume-Uni",
    en: "United Kingdom of Great Britain and Northern Ireland",
  },
  {
    id: "USA",
    fr: "États-Unis",
    en: "United States of America",
  },
  {
    id: "URY",
    fr: "Uruguay",
    en: "Uruguay",
  },
  {
    id: "UZB",
    fr: "Ouzbékistan",
    en: "Uzbekistan",
  },
  {
    id: "VUT",
    fr: "Vanuatu",
    en: "Vanuatu",
  },
  {
    id: "VEN",
    fr: "Venezuela",
    en: "Venezuela (Bolivarian Republic of)",
  },
  {
    id: "VNM",
    fr: "Viêt Nam",
    en: "Vietnam",
  },
  {
    id: "WLF",
    fr: "Wallis-et-Futuna",
    en: "Wallis and Futuna",
  },
  {
    id: "ESH",
    fr: "Sahara Occidental",
    en: "Western Sahara",
  },
  {
    id: "YEM",
    fr: "Yémen",
    en: "Yemen",
  },
  {
    id: "ZMB",
    fr: "Zambie",
    en: "Zambia",
  },
  {
    id: "ZWE",
    fr: "Zimbabwe",
    en: "Zimbabwe",
  },
];

export const DEFAULT_COUNTRY = "/countries/1"; // FR
