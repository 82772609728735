import { Controller, useFormContext } from "react-hook-form";
import Select from "./Select";
import { useTranslation } from "react-i18next";
import { isRequired } from "../Input/InputController";

export const SelectController = ({ name, rules, value, ...props }: any) => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  if (typeof name === "undefined") {
    console.error("SelectController", value, name);
  }

  if (props.isRequired || typeof rules !== "undefined") {
    rules = { ...rules, ...isRequired(rules, t) };
  }

  return (
    <Controller
      control={control}
      name={name ?? "DEFAULT"}
      rules={rules}
      render={({ field, fieldState: { error, invalid } }) => {
        return (
          <Select
            {...field}
            {...props}
            required={rules?.required ?? props?.isRequired ?? false}
            disabled={props.isDisabled ?? rules?.disabled ?? false}
            isInvalid={invalid}
            labelProps={{
              ...props.labelProps,
              errorLabel: props?.labelProps?.errorLabel
                ? props.labelProps.errorLabel
                : error?.message,
            }}
            defaultValue={field.value ?? value}
          />
        );
      }}
    />
  );
};
