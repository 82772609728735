import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { AUTH_BASE_URL, BEARER_TOKEN_COOKIE_NAME, BEARER_TOKEN_EXPIRATION_TIME, REFRESH_TOKEN_COOKIE_NAME } from "../constants/constant";
import Cookies from "js-cookie";

export const axiosPublic = axios.create({
  baseURL: AUTH_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
})

export default axiosPublic


const axiosPrivateOptions: AxiosRequestConfig = {
  baseURL: AUTH_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
};


export let axiosPrivate = axios.create(axiosPrivateOptions);

export const axiosPrivatePatch = axios.create({
  baseURL: AUTH_BASE_URL,
  headers: {
    "Content-Type": "application/merge-patch+json"
  },
  withCredentials: true,
});

// Add a request interceptor to refresh the expired token
export const refreshToken = async () => {
  return new Promise(async (resolve, reject) => {
    const refresh_token = Cookies.get(REFRESH_TOKEN_COOKIE_NAME);
    if (typeof refresh_token === 'undefined') {
      reject('No token found');
      return;
    }

    try {
      const resp = await axiosPrivate.post("token_refresh", {
        refresh_token,
      });
      resolve(resp.data);
    } catch (e) {
      // securely remove the refresh token from the cookie while it is invalid
      // Cookies.remove(REFRESH_TOKEN_COOKIE_NAME);
      reject(e);
    }
  });
};


function parseJwt(token: string) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

const handleRefreshTokenRequestInterceptor = (config: AxiosRequestConfig<any>): AxiosRequestConfig<any> => {
  if (Cookies.get(BEARER_TOKEN_COOKIE_NAME)) {

    const { exp } = parseJwt(Cookies.get(BEARER_TOKEN_COOKIE_NAME) ?? '');

    if (exp < Date.now() / 1000) {
      Cookies.remove(BEARER_TOKEN_COOKIE_NAME);
    } else {
      //@ts-ignore
      config.headers['Authorization'] = 'Bearer ' + Cookies.get(BEARER_TOKEN_COOKIE_NAME);
    }
  }

  return config;
}

const handleRefreshTokenInterceptor = async (error: any) => {
  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry && originalRequest.url !== 'token_refresh') {
    originalRequest._retry = true;

    const refresh_token = Cookies.get(REFRESH_TOKEN_COOKIE_NAME);

    if (typeof refresh_token === 'undefined') {
      return Promise.reject(error);
    }

    try {
      const resp: any = await refreshToken();
      const date = new Date();
      date.setTime(date.getTime() + (BEARER_TOKEN_EXPIRATION_TIME * 1000));
      Cookies.set(BEARER_TOKEN_COOKIE_NAME, resp.bearer, { expires: date });

      return axiosPrivate(originalRequest);
    } catch (error) {
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  return Promise.reject(error);
}

const handleMinimumTimeRequestInterceptor = async (config: AxiosRequestConfig) => {
  return {
    ...config,
    p0: performance.now(),
  };
}

const handleMinimumTimeResponseInterceptor = async (response: any) => {
  const minimumDelay = 5000;
  const latency = performance.now() - response.config.p0;
  const shouldNotDelay = minimumDelay < latency;

  if (shouldNotDelay) {
    return response;
  }

  const remainder = minimumDelay - latency;
  const [responseWithDelay] = await Promise.all([
    response,
    new Promise((resolve) => setTimeout(resolve, remainder)),
  ]);

  return responseWithDelay;
}



// axiosPrivate.interceptors.request.use(handleMinimumTimeRequestInterceptor, error => Promise.reject(error));
axiosPrivate.interceptors.request.use(handleRefreshTokenRequestInterceptor, error => Promise.reject(error));
axiosPrivatePatch.interceptors.request.use(handleRefreshTokenRequestInterceptor, error => Promise.reject(error));



// // Add a response interceptor to call refresh token
// axiosPrivate.interceptors.response.use(response => response, handleMinimumTimeResponseInterceptor);
axiosPrivate.interceptors.response.use(response => response, handleRefreshTokenInterceptor);
axiosPrivatePatch.interceptors.response.use(response => response, handleRefreshTokenInterceptor);