import { memo } from "react";
import Badge, {Props} from "./Badge";

const SuccessBadge = ({ 
  className = "",
  children,
  outline = false,
  ...props
 }: Props) => {
  return(
    <Badge
    className={`${className} border border-green-500
    ${outline ? "text-current bg-transparent" : "text-white bg-green-500" }`}
    data-testid="TertiaryBadge"
    {...props}
    >
      {children && children}
    </Badge>
  )
};



export default memo(SuccessBadge);