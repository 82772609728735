import React from "react"

function EmptyResult() {
  return (
    <div className="absolute top-0 left-0 w-full h-full bg-table-grey bg-opacity-40 flex pt-60 justify-center">
      Aucun résultat
    </div>
  )
};

export default EmptyResult;
