export const ROLE = {
  STAFF: "ROLE_SUPERUSER",
  USER: "ROLE_USER",
  AGENT: "ROLE_AGENT",
  COMEDIEN: "ROLE_COMEDIEN",
  RESP_LEGAL: "ROLE_RESP_LEGAL",
  TECHNICIEN: "ROLE_TECHNICIEN",
  CHEF_DE_POSTE: "ROLE_CHEF_DE_POSTE",
  CHARGE_CASTING: "ROLE_CHARGE_CASTING",
  CHARGE_PRODUCTION: "ROLE_CHARGE_PRODUCTION",
  DIRECTEUR_PRODUCTION: "ROLE_DIRECTEUR_PRODUCTION"
} as const;
export type RoleKey = keyof typeof ROLE;
