import { useQuery } from "@tanstack/react-query";
import { SIGNATURE_PAD_CONTRACTS_FIXTURE } from "../fixtures";
import { useRecoilState } from "recoil";
import {
  contractDetailsIdAtom,
  contractsSummaryAtom,
} from "../recoil/SignaturePadTable";
import { useMemo } from "react";

export function useSignaturePad(companyId?: string) {
  const [contractDetailsId, setContractDetailsId] = useRecoilState(
    contractDetailsIdAtom
  );
  const [contractsSummary, setContractsSummary] =
    useRecoilState(contractsSummaryAtom);

  const query = useQuery({
    queryKey: ["signature_pad_contracts"],
    queryFn: () => SIGNATURE_PAD_CONTRACTS_FIXTURE,
    enabled: companyId !== undefined,
  });

  const contractsDetails = useMemo(
    () => query.data?.filter(({ id }) => contractDetailsId.includes(id)) || [],
    [contractDetailsId, query.data]
  );

  const contractsSummaryUrls = useMemo(
    () => contractsSummary.map(({ pdf_url }) => pdf_url),
    [contractsSummary]
  );

  return {
    query,
    contractsDetails,
    contractDetailsId,
    setContractDetailsId,
    contractsSummary,
    setContractsSummary,
    contractsSummaryUrls,
  };
}
