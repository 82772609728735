export const FAMILY_RELATIONSHIPS_FIXTURE = {
  "@context": "/contexts/FamilyRelationship",
  "@id": "/family_relationships",
  "@type": "hydra:Collection",
  "hydra:member": [
    {
      "@id": "/family_relationships/1",
      "@type": "FamilyRelationship",
      id: 1,
      name: "Mère",
    },
    {
      "@id": "/family_relationships/2",
      "@type": "FamilyRelationship",
      id: 2,
      name: "Père",
    },
    {
      "@id": "/family_relationships/3",
      "@type": "FamilyRelationship",
      id: 3,
      name: "Tuteur/Tutrice",
    },
  ],
  "hydra:totalItems": 3,
};
