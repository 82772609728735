import { AxiosError } from "axios";

interface LoginErrorBadCredentials {
  code: number;
  message: string;
}

interface LoginErrorUserUnVerified {
  class: string;
  detail: string;
  status: number;
  title: string;
  trace: any;
  type: string;
}

const defaultError = "Default.error.500";

export function getLoginError(err: AxiosError): string {
  const response = err.response;
  const data: any = response?.data;

  if (response?.status === 401 && data) {
    if ("detail" in data) {
      return handleUnVerifiedUser(err as AxiosError<LoginErrorUserUnVerified>);
    }

    return handleBadCredentials(err as AxiosError<LoginErrorBadCredentials>);
  }

  if (response?.status) {
    return response.status.toString();
  }

  return defaultError;
}

function handleUnVerifiedUser(
  err: AxiosError<LoginErrorUserUnVerified>
): string {
  const response = err.response;
  const data = response?.data;

  if (data?.detail && response?.status) {
    return `${response?.status.toString()}.${data.detail}`;
  }

  return defaultError;
}

function handleBadCredentials(
  err: AxiosError<LoginErrorBadCredentials>
): string {
  const response = err.response;
  const data = err.response?.data;

  if (data?.message && response?.status) {
    return `${response?.status.toString()}.${data?.message}`;
  }

  return defaultError;
}
