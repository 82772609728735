import { API_BASE_URL, AUTH_END_POINT } from "../constants";
import { axiosPrivate, axiosPrivatePatch } from "./axios";

export async function fetchFieldEmployeeGroup() {
  const { data } = await axiosPrivate.get<GetFieldEmployeeGroupResponse>(
    `${API_BASE_URL}${AUTH_END_POINT.FIELDS_EMPLOYEE_GROUPS}`
  );

  return data["hydra:member"];
}

export async function patchFieldEmployeeGroup({
  id,
  ...field
}: PatchFieldEmployeeGroupParam) {
  const { data } =
    await axiosPrivatePatch.patch<PatchFieldEmployeeGroupResponse>(
      `${API_BASE_URL}${AUTH_END_POINT.FIELDS_EMPLOYEE_GROUPS}/${id}`,
      field
    );

  return data;
}

export async function postFieldEmployeeGroup(
  data: PostFieldEmployeeGroupParam
) {
  const response = await axiosPrivate.post<PatchFieldEmployeeGroupResponse>(
    `${API_BASE_URL}${AUTH_END_POINT.FIELDS_EMPLOYEE_GROUPS}`,
    data
  );

  return response.data;
}

export async function deleteFieldEmployeeGroup(id: string) {
  const { data } = await axiosPrivate.delete(
    `${API_BASE_URL}${AUTH_END_POINT.FIELDS_EMPLOYEE_GROUPS}/${id}`
  );

  return data;
}
