export const USE_ADDRESS_FIXTURE = {
  properties: {
    label: "62 Avenue général cartier 73000 Chambéry",
    score: 0.785588951048951,
    housenumber: "62",
    id: "73065_1620_00062",
    name: "62 Avenue général cartier",
    postcode: "73000",
    citycode: "73065",
    x: 926470.54,
    y: 6501328.12,
    city: "Chambéry",
    context: "73, Savoie, Auvergne-Rhône-Alpes",
    type: "housenumber",
    importance: 0.67994,
    street: "Avenue général cartier",
  },
};
