import { ReactNode, memo, useCallback, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { AlertWarning, Tab, Tabs } from "../core";
import { TitledBox } from "../TitledBox";
import { HeaderDashboard, SectionProduction } from "../sections";
import { PRODUCTION_FIXTURE } from "../../fixtures";
import TabList from "../core/TabList/TabList";

export interface Props {
  className?: string;
}

const DashboardProductionDirector = ({ className = "" }: Props) => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState<string>("1");

  const handleChangeTab = useCallback(
    (newTab: string) => {
      if (currentTab !== newTab) {
        setCurrentTab(newTab);
      }
    },
    [currentTab, setCurrentTab],
  );

  {
    /* TEMPORARY DATA */
  }
  const prod_1 = JSON.parse(JSON.stringify(PRODUCTION_FIXTURE));
  const prod_2 = JSON.parse(JSON.stringify(PRODUCTION_FIXTURE));
  const prod_3 = JSON.parse(JSON.stringify(PRODUCTION_FIXTURE));

  prod_1["@id"] = "1";
  prod_1.company.name = "Denis Villeneuve Prod.";
  prod_2["@id"] = "2";
  prod_2.company.name = "Voltron films";
  prod_3["@id"] = "3";
  prod_3.company.name = "Production Inc.";

  const productions = [prod_1, prod_2, prod_3];
  {
    /* END TEMPORARY DATA */
  }

  {
    /* TODO calculate totalContracts using projects contract_in_progress */
  }
  let totalContracts: number = 0;
  let tabs: ReactNode[] = [];
  let sections: ReactNode[] = [];

  {
    /* Loop through productions to populate content */
  }
  productions.map((prod, index) => {
    {
      /* TODO Replace with real data */
    }
    let contracts = 25;

    totalContracts += contracts;

    tabs.push(
      <Tab
        key={index}
        id={prod["@id"]}
        badge={contracts}
        variant="vertical"
        selected={currentTab === prod["@id"]}
        onClickTab={(e) => handleChangeTab(e.currentTarget.id)}
      >{prod.company.name}</Tab>,
    );

    sections.push(
      <SectionProduction
        key={index}
        production={prod}
        className={`${currentTab !== prod["@id"] && "hidden"}`}
      />,
    );

    return true;
  });

  return (
    <>
      <HeaderDashboard translationKey="DashboardProductionDirector" />

      <div className="grid grid-cols-4 mt-8 shadow-md">
        <TitledBox
          className="bg-white col-span-1 p-3 !rounded-l !rounded-r-none"
          titleClassName="text-xl font-bold"
          childContainerClassName=""
          wrapperClassName="sticky top-0"
          title={t("DashboardProductionDirector.productions")}
          icon={<HiOutlineOfficeBuilding className="h-4 w-4" />}
        >
          <Tabs className="mt-4" orientation="vertical">
            <TabList>
              {tabs}
            </TabList>
          </Tabs>

          {totalContracts > 0 && (
            <AlertWarning className="mt-4">
              <span>
                <Trans
                  i18nKey={"DashboardProductionDirector.numberContracts"}
                  values={{
                    count: totalContracts,
                  }}
                >
                  Vous avez un total de
                  <span className="block font-bold">
                    {" "}
                    contrats en attente de signature
                  </span>
                </Trans>
              </span>
            </AlertWarning>
          )}
        </TitledBox>

        <div className="bg-gray-100 col-span-3 px-7 py-5 max-h-100 rounded-r">
          {sections}
        </div>
      </div>
    </>
  );
};

export default memo(DashboardProductionDirector);
