import { useRef } from "react";
import { CompanyHeader } from "../../components/CompanyHeader";
import DropZone from "../../components/core/DropZone/DropZone";
import TabList from "../../components/core/TabList/TabList";
import { AlertWarning, Tab, Tabs } from "../../components/core";
import { TabPanel } from "react-aria-components";
import { BsFileEarmarkFill, BsFiles, BsFillTrash3Fill } from "react-icons/bs";
import { Column } from "../../components/core/Table/TableUI";
import { Cell, Table } from "../../components/core/Table";
import { useModal } from "../../components/modals";
import {
  geExtractedFile,
  uploadDepositedFile,
  startExtraction,
} from "../../api/deposited_files";
import { useAuth } from "../../hooks";
import { LoadingIcon } from "../../components/LoadingIcon";
import { useRequiredParams } from "../../hooks/useRequiredParams";
import { Link, generatePath } from "react-router-dom";
import { ROUTE_PATH } from "../../constants";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import ExtractedFilePageTable from "./ExtractedFilePageTable";
import ExtractedFileTable from "./ExtractedFileTable";

function DepositedFilesPage({}) {
  const { auth } = useAuth();

  const { companyId } = useRequiredParams();

  const refDropzone = useRef();

  const tableRef = useRef();

  const handleDropZoneChange = async (files: File[]) => {
    if (refDropzone.current) {
      (refDropzone.current as any).setLoading(true);
    }

    const formData = new FormData();
    formData.append("file", files[0]);

    await uploadDepositedFile(companyId, formData);

    if (tableRef.current) {
      // @ts-ignore
      tableRef.current.refresh();
    }

    if (refDropzone.current) {
      (refDropzone.current as any).setLoading(false);
    }

    await startExtraction();
  };

  return auth ? (
    <>
      <CompanyHeader
        className="mb-7"
        title={"DÉPÔTS DE DOCUMENTS POUR LES EMPLOYÉS"}
        role={auth.roles[0]}
        companyName={"Indiana Jones"}
        isProject={false}
        IconFilm
      />
      <div className="content bg-white p-11">
        <p className="mb-5 font-medium">
          Permettez à vos salariés de récupérer leurs fiches de paie et AEM de
          manière dématérialisée.
        </p>
        <p className="mb-5 font-medium">
          Déposez ci-dessous les lots générés par Xotis. Notre système se charge
          d'extraire chaque page, les déposer dans le coffre-fort de vos
          salariés et les prévenir par e-mail. Les fichiers acceptés pour le
          moment sont uniquement les suivants, générés par Xotis :
        </p>
        <ul className="list-disc text-sm text-gray-500 ml-4">
          <li>AEM</li>
          <li>Bulletins de salaire</li>
          <li>Attestation de Congés Spectacles</li>
          <li>Attestations fiscales annuelles</li>
        </ul>
        <p className="py-5 font-medium">
          Pour vous guider, retrouvez le tuto ici :{" "}
          <a
            href="https://docs.google.com/document/d/1yu67nqullgE1F8pLsza3mnWWjn4kblYOC0FP1Zr2R0U/edit"
            target="_blank"
            className="font-light text-mr-cyan-400"
          >
            Déposer des fichiers de paie sur MyRole
          </a>
        </p>
        <DropZone
          ref={refDropzone}
          imageType={["application/pdf"]}
          allowsMultiple={false}
          onChange={handleDropZoneChange}
        />

        <div className="my-10">
          <Tabs>
            <TabList ariaLabel="messaging">
              <Tab id="depositedOld" icon={<BsFileEarmarkFill />}>
                Suivi des dépôts précédents
              </Tab>
              <Tab id="allFiles" icon={<BsFiles />}>
                Tous les documents
              </Tab>
            </TabList>
            <TabPanel id="depositedOld">
              <ExtractedFileTable ref={tableRef} />
            </TabPanel>
            <TabPanel id="allFiles">
              <ExtractedFilePageTable ref={tableRef} />
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </>
  ) : (
    <LoadingIcon />
  );
}

export default DepositedFilesPage;
