import { memo, OptionHTMLAttributes, ReactNode, Ref, useState } from 'react';
import Label, { LabelWrapperProps } from '../Label';
import {
    Select as AriaSelect,
    Button,
    ListBox,
    ListBoxItem,
    ListBoxItemProps,
    Popover,
    SelectProps,
    SelectValue,
} from 'react-aria-components';
import { GoTriangleDown } from 'react-icons/go';
import { BsCheck } from 'react-icons/bs';
import cn from 'mxcn';

export interface Props extends SelectProps<any> {
    groupClass?: string;
    labelProps?: Omit<LabelWrapperProps, 'children' | 'required' | 'disabled'>;
    options: OptionHTMLAttributes<HTMLOptionElement>[];
    customPlaceholder?: string;
    value?: string;
    ref?: Ref<HTMLSelectElement>;
    selectedKey?: string;
    containerClassName?: string;
}

const Select = ({
    className = '',
    groupClass = '',
    options,
    customPlaceholder,
    labelProps,
    ref,
    value,
    selectedKey,
    containerClassName,
    ...props
}: Props) => {
    let [open, setOpen] = useState(false);

    const extraAriaSelectProps: any = {};

    if (selectedKey) {
        extraAriaSelectProps['selectedKey'] = selectedKey;
    }

    return (
        <Label.Wrapper
            required={props.isRequired}
            disabled={props.isDisabled}
            {...labelProps}
            labelsContainerClassName={containerClassName}
        >
            <AriaSelect
                placeholder="Sélectionner une valeur"
                isOpen={open}
                onOpenChange={setOpen}
                aria-label={labelProps?.title ?? ''}
                className={cn('w-full', className)}
                defaultSelectedKey={value ?? ''}
                onSelectionChange={(selected) => {
                    // @ts-ignore
                    if (props.onChange) {
                        // @ts-ignore
                        props.onChange(selected);
                    }
                }}
                isInvalid={props.isInvalid}
                {...extraAriaSelectProps}
            >
                {({ isFocused }) => (
                    <>
                        <Button
                            isDisabled={props.isDisabled}
                            className={cn(
                                'disabled:opacity-50 items-center appearance-none rounded flex justify-between border min-w-[240px] h-[46px] text-base focus:outline-none w-full',
                                'border-mr-gray-1000 bg-white',
                                'focus-visible:border-mr-cyan-400 active:border-mr-cyan-400 data-[pressed]:border-mr-cyan-400',
                                {
                                    'border-mr-orange-400': props.isInvalid,
                                    'border-mr-cyan-400': open || isFocused,
                                }
                            )}
                        >
                            <SelectValue className="truncate px-3 py-2" />

                            <span
                                className={cn(
                                    'outline-none px-3 h-full flex items-center text-gray-700 transition border-0 border-solid border-l bg-transparent pressed:bg-mr-cyan-400 pressed:text-white focus-visible:ring-2 ring-black',
                                    'border-l-mr-gray-1000 hover:bg-mr-gray-200 rounded-br rounded-tr',
                                    {
                                        'border-l-mr-orange-400':
                                            props.isInvalid,
                                        'border-l-mr-cyan-400':
                                            open || isFocused,
                                    }
                                )}
                            >
                                <GoTriangleDown size="16" />
                            </span>
                        </Button>
                        <Popover
                            maxHeight={250}
                            className="min-w-[--trigger-width] overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-mr-gray-1000  entering:animate-in entering:fade-in exiting:animate-out exiting:fade-out"
                        >
                            <ListBox className="outline-none p-1">
                                {options &&
                                    options.map(({ value, label }, key) => {
                                        return (
                                            <SelectOption
                                                aria-label={label}
                                                key={key}
                                                id={value as string}
                                                textValue="Backlog"
                                            >
                                                {label ?? value}
                                            </SelectOption>
                                        );
                                    })}
                            </ListBox>
                        </Popover>
                    </>
                )}
            </AriaSelect>
        </Label.Wrapper>
    );
};

export default memo(Select);

interface SelectOptionProps extends ListBoxItemProps {
    children: ReactNode;
}

export function SelectOption(props: SelectOptionProps) {
    return (
        <ListBoxItem
            {...props}
            className="group flex items-center gap-2 cursor-default select-none py-2 pl-4 pr-2 outline-none rounded text-gray-900 focus:bg-mr-cyan-400 focus:text-white"
        >
            {({ isSelected }) => (
                <>
                    <span className="flex-1 flex items-center gap-2 truncate font-normal group-selected:font-medium">
                        {props.children}
                    </span>
                    <span className="w-5 flex items-center text-mr-cyan-400 group-focus:text-white bg-transparent">
                        {isSelected && <BsCheck size="20" />}
                    </span>
                </>
            )}
        </ListBoxItem>
    );
}
