import { useRecoilState } from "recoil";
import { debugOptionAtom } from "../../recoil/DebugOptions";



function PersistantLogin() {
  const [debugOptions, setFloatingMenu] = useRecoilState(debugOptionAtom);

  const handleToggleChange = (ev: any) => {
    setFloatingMenu({
      ...debugOptions,
      persistantLogin: ev.target.checked
    });
  };


  
  return (
    <form>
      <label htmlFor="persistant_login" className="flex items-center gap-2">
        <input type="checkbox" name="persistant_login" id="persistant_login" defaultChecked={debugOptions.persistantLogin} onChange={(ev) => handleToggleChange(ev)}/>
        <span>Authentification persistante</span>
      </label>
    </form>
  );
}


export default PersistantLogin;