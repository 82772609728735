import { ReactNode } from "react";
import { AlertBase } from "./index";
import { HiOutlineExclamationCircle } from "react-icons/hi";

export interface Props {
  children: ReactNode;
  className?: string;
}

const AlertDanger = ({ children, className = "" }: Props) => (
  <AlertBase
    className={`${className} border-red-500 bg-red-500 text-white`}
    icon={<HiOutlineExclamationCircle className="h-6 w-6" />}
    data-testid="AlertDanger"
  >
    {children && children}
  </AlertBase>
);

export default AlertDanger;
