
function Error({ msg }: { msg: Error }) {
  console.error(msg);
  return (
    <div className="absolute top-0 left-0 w-full h-full bg-opacity-40 flex items-center justify-center bg-table-grey">
      <span>Une erreur est survenue</span>
    </div>
  )
};

export default Error;
