import { memo } from "react";
import RadioButton, { Props as RadioButtonProps } from "./RadioButton";
import { Control, Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { isRequired } from "../Input/InputController";

export interface Props extends RadioButtonProps {
  name: string;
  rules?: any;
}

const RadioButtonController = ({ name, rules, ...props }: Props) => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  if (props.isRequired || typeof rules !== "undefined") {
    rules = { ...rules, ...isRequired(rules, t) };
  }

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={props.defaultValue}
      rules={rules}
      render={({ field, fieldState: { error, invalid } }) => (
        <RadioButton
          {...(props as RadioButtonProps)}
          {...field}
          isRequired={rules?.required ?? props.isRequired ?? false}
          isDisabled={rules?.disabled ?? props.isDisabled ?? false}
          validationBehavior="aria"
          isInvalid={invalid}
          labelProps={{ errorLabel: error?.message, ...props.labelProps }}
        />
      )}
    />
  );
};

export default memo(RadioButtonController);
