export const URSSAF_CENTERS_FIXTURE: GetUrssafCenterResponse = {
  "@context": "/contexts/UrssafCenter",
  "@id": "/urssaf_centers",
  "@type": "hydra:Collection",
  "hydra:member": [
    {
      "@id": "/urssaf_centers/1",
      "@type": "UrssafCenter",
      id: 1,
      siret: "53510475600015",
      code: "U109",
      national_emitter_number: "113735",
      name: "CDG PAM",
      address_1: "3 RUE GAETAN RONDEAU",
      address_2: "",
      postal_code: "44000",
      city: "NANTES",
      productions: ["/productions/1ed29440-6ce3-6968-92ad-9b63a7c5fad7"],
      nationalEmitterNumber: "113735",
      address1: "3 RUE GAETAN RONDEAU",
      address2: "",
      postalCode: "44000",
    },
    {
      "@id": "/urssaf_centers/2",
      "@type": "UrssafCenter",
      id: 2,
      siret: "78861779300013",
      code: "U116",
      national_emitter_number: "112534",
      name: "UR ile-de-France (116) Pole GE",
      address_1: "",
      address_2: "",
      postal_code: "93518",
      city: "MONTREUIL CEDEX",
      productions: [],
      nationalEmitterNumber: "112534",
      address1: "",
      address2: "",
      postalCode: "93518",
    },
    {
      "@id": "/urssaf_centers/3",
      "@type": "UrssafCenter",
      id: 3,
      siret: "78861779300013",
      code: "U117",
      national_emitter_number: "112534",
      name: "URSSAF ILE-DE-FRANCE",
      address_1: "",
      address_2: "",
      postal_code: "93518",
      city: "MONTREUIL CEDEX",
      productions: [],
      nationalEmitterNumber: "112534",
      address1: "",
      address2: "",
      postalCode: "93518",
    },
    {
      "@id": "/urssaf_centers/4",
      "@type": "UrssafCenter",
      id: 4,
      siret: "78299314100038",
      code: "U200",
      national_emitter_number: "274208",
      name: "URSSAF DE CORSE",
      address_1: "BP 901",
      address_2: "",
      postal_code: "20701",
      city: "AJACCIO CEDEX 9",
      productions: [],
      nationalEmitterNumber: "274208",
      address1: "BP 901",
      address2: "",
      postalCode: "20701",
    },
    {
      "@id": "/urssaf_centers/5",
      "@type": "UrssafCenter",
      id: 5,
      siret: "75285582500010",
      code: "U217",
      national_emitter_number: "105171",
      name: "Champagne-Ardenne",
      address_1: "202 Rue des Capucins",
      address_2: "CS 6001",
      postal_code: "51089",
      city: "REIMS CEDEX",
      productions: [],
      nationalEmitterNumber: "105171",
      address1: "202 Rue des Capucins",
      address2: "CS 6001",
      postalCode: "51089",
    },
    {
      "@id": "/urssaf_centers/6",
      "@type": "UrssafCenter",
      id: 6,
      siret: "75285582500010",
      code: "U219",
      national_emitter_number: "105171",
      name: "CDG PAM",
      address_1: "202 Rue des Capucins",
      address_2: "CS 6001",
      postal_code: "51089",
      city: "REIMS CEDEX",
      productions: [],
      nationalEmitterNumber: "105171",
      address1: "202 Rue des Capucins",
      address2: "CS 6001",
      postalCode: "51089",
    },
    {
      "@id": "/urssaf_centers/7",
      "@type": "UrssafCenter",
      id: 7,
      siret: "75366327700014",
      code: "U227",
      national_emitter_number: "158496",
      name: "URSSAF DE PICARDIE",
      address_1: "1 AVENUE DU DANEMARK",
      address_2: "",
      postal_code: "80000",
      city: "AMIENS",
      productions: [],
      nationalEmitterNumber: "158496",
      address1: "1 AVENUE DU DANEMARK",
      address2: "",
      postalCode: "80000",
    },
    {
      "@id": "/urssaf_centers/8",
      "@type": "UrssafCenter",
      id: 8,
      siret: "75366327700014",
      code: "U229",
      national_emitter_number: "158496",
      name: "CDG PAM",
      address_1: "1 AVENUE DU DANEMARK",
      address_2: "",
      postal_code: "80000",
      city: "AMIENS",
      productions: [],
      nationalEmitterNumber: "158496",
      address1: "1 AVENUE DU DANEMARK",
      address2: "",
      postalCode: "80000",
    },
    {
      "@id": "/urssaf_centers/9",
      "@type": "UrssafCenter",
      id: 9,
      siret: "79512003900107",
      code: "U247",
      national_emitter_number: "609028",
      name: "Urssaf Centre Val de Loire",
      address_1: "258 bd duhamel du Monceau",
      address_2: "Parc du Moulin",
      postal_code: "45166",
      city: "Olivet Cédex",
      productions: [],
      nationalEmitterNumber: "609028",
      address1: "258 bd duhamel du Monceau",
      address2: "Parc du Moulin",
      postalCode: "45166",
    },
    {
      "@id": "/urssaf_centers/10",
      "@type": "UrssafCenter",
      id: 10,
      siret: "79512003900016",
      code: "U249",
      national_emitter_number: "609028",
      name: "CDG PAM",
      address_1: "UR COT SS ALL FAM CTRE",
      address_2: "PL DU GENERAL DE GAULLE",
      postal_code: "45000",
      city: "ORLEANS",
      productions: [],
      nationalEmitterNumber: "609028",
      address1: "UR COT SS ALL FAM CTRE",
      address2: "PL DU GENERAL DE GAULLE",
      postalCode: "45000",
    },
    {
      "@id": "/urssaf_centers/11",
      "@type": "UrssafCenter",
      id: 11,
      siret: "79471473300019",
      code: "U267",
      national_emitter_number: "004354",
      name: "Urssaf de Bourgogne",
      address_1: "8 BD GEORGES CLEMENCEAU",
      address_2: "",
      postal_code: "21037",
      city: "DIJON Cedex 9",
      productions: [],
      nationalEmitterNumber: "004354",
      address1: "8 BD GEORGES CLEMENCEAU",
      address2: "",
      postalCode: "21037",
    },
    {
      "@id": "/urssaf_centers/12",
      "@type": "UrssafCenter",
      id: 12,
      siret: "90209799700016",
      code: "U287",
      national_emitter_number: "240834",
      name: "Urssaf de Normandie",
      address_1: "61 rue Pierre Renaudel",
      address_2: "CS 92035",
      postal_code: "76040",
      city: "Rouen Cedex 1",
      productions: [],
      nationalEmitterNumber: "240834",
      address1: "61 rue Pierre Renaudel",
      address2: "CS 92035",
      postalCode: "76040",
    },
    {
      "@id": "/urssaf_centers/13",
      "@type": "UrssafCenter",
      id: 13,
      siret: "53514650090018",
      code: "U311",
      national_emitter_number: "113417",
      name: "Urssaf Midi-Pyrénées-TGE",
      address_1: "166 Rue Pierre et Marie Curie",
      address_2: "LABEGE",
      postal_code: "31061",
      city: "TOULOUSE CEDEX 09",
      productions: [],
      nationalEmitterNumber: "113417",
      address1: "166 Rue Pierre et Marie Curie",
      address2: "LABEGE",
      postalCode: "31061",
    },
    {
      "@id": "/urssaf_centers/14",
      "@type": "UrssafCenter",
      id: 14,
      siret: "75367340900011",
      code: "U317",
      national_emitter_number: "143871",
      name: "Nord-Pas-de-Calais",
      address_1: "293 avenue du President HOOVER",
      address_2: "BP 20001",
      postal_code: "59032",
      city: "LILLE CEDEX",
      productions: [],
      nationalEmitterNumber: "143871",
      address1: "293 avenue du President HOOVER",
      address2: "BP 20001",
      postalCode: "59032",
    },
    {
      "@id": "/urssaf_centers/15",
      "@type": "UrssafCenter",
      id: 15,
      siret: "75333448100011",
      code: "U417",
      national_emitter_number: "394679",
      name: "URSSAF DE LORRAINE,6 RUE PASTEUR",
      address_1: "BP 80580",
      address_2: "",
      postal_code: "57032",
      city: "METZ CEDEX 1",
      productions: [],
      nationalEmitterNumber: "394679",
      address1: "BP 80580",
      address2: "",
      postalCode: "57032",
    },
    {
      "@id": "/urssaf_centers/16",
      "@type": "UrssafCenter",
      id: 16,
      siret: "75333448100011",
      code: "U419",
      national_emitter_number: "394679",
      name: "CDG PAM",
      address_1: "6 RUE PASTEUR",
      address_2: "BP 80580",
      postal_code: "57032",
      city: "METZ CEDEX 1",
      productions: [],
      nationalEmitterNumber: "394679",
      address1: "6 RUE PASTEUR",
      address2: "BP 80580",
      postalCode: "57032",
    },
    {
      "@id": "/urssaf_centers/17",
      "@type": "UrssafCenter",
      id: 17,
      siret: "75357004300012",
      code: "U427",
      national_emitter_number: "114065",
      name: "URSSAF Alsace",
      address_1: "16 Rue Contades",
      address_2: "",
      postal_code: "67300",
      city: "SCHILTIGHEIM",
      productions: [],
      nationalEmitterNumber: "114065",
      address1: "16 Rue Contades",
      address2: "",
      postalCode: "67300",
    },
    {
      "@id": "/urssaf_centers/18",
      "@type": "UrssafCenter",
      id: 18,
      siret: "79507010100071",
      code: "U437",
      national_emitter_number: "122335",
      name: "FRANCHE-COMTE",
      address_1: "CS 41589",
      address_2: "",
      postal_code: "25010",
      city: "BESANCON CEDEX",
      productions: [],
      nationalEmitterNumber: "122335",
      address1: "CS 41589",
      address2: "",
      postalCode: "25010",
    },
    {
      "@id": "/urssaf_centers/19",
      "@type": "UrssafCenter",
      id: 19,
      siret: "79507010100071",
      code: "U438",
      national_emitter_number: "122335",
      name: "CNTFS FRANCHE-COMTE",
      address_1: "TSA 90001",
      address_2: "",
      postal_code: "25010",
      city: "BESANCON CEDEX",
      productions: [],
      nationalEmitterNumber: "122335",
      address1: "TSA 90001",
      address2: "",
      postalCode: "25010",
    },
    {
      "@id": "/urssaf_centers/20",
      "@type": "UrssafCenter",
      id: 20,
      siret: "79512003900081",
      code: "U451",
      national_emitter_number: "609028",
      name: "Urssaf Centre Val de Loire",
      address_1: "Adhésion en lieu unique et GE",
      address_2: "258 bd Duhamel du Monceau",
      postal_code: "45166",
      city: "Olivet Cédex",
      productions: [],
      nationalEmitterNumber: "609028",
      address1: "Adhésion en lieu unique et GE",
      address2: "258 bd Duhamel du Monceau",
      postalCode: "45166",
    },
    {
      "@id": "/urssaf_centers/21",
      "@type": "UrssafCenter",
      id: 21,
      siret: "53510475600015",
      code: "U527",
      national_emitter_number: "113735",
      name: "URSSAF Pays de Loire",
      address_1: "3 RUE GAETAN RONDEAU",
      address_2: "",
      postal_code: "44000",
      city: "NANTES",
      productions: [],
      nationalEmitterNumber: "113735",
      address1: "3 RUE GAETAN RONDEAU",
      address2: "",
      postalCode: "44000",
    },
    {
      "@id": "/urssaf_centers/22",
      "@type": "UrssafCenter",
      id: 22,
      siret: "53510475600015",
      code: "U529",
      national_emitter_number: "113735",
      name: "CDG PAM",
      address_1: "3 RUE GAETAN RONDEAU",
      address_2: "",
      postal_code: "44000",
      city: "NANTES",
      productions: [],
      nationalEmitterNumber: "113735",
      address1: "3 RUE GAETAN RONDEAU",
      address2: "",
      postalCode: "44000",
    },
    {
      "@id": "/urssaf_centers/23",
      "@type": "UrssafCenter",
      id: 23,
      siret: "75375957000017",
      code: "U537",
      national_emitter_number: "154431",
      name: "URSSAF Bretagne",
      address_1: "RUE Robert d Arbrissel",
      address_2: "",
      postal_code: "35052",
      city: "Rennes cedex 9",
      productions: [],
      nationalEmitterNumber: "154431",
      address1: "RUE Robert d Arbrissel",
      address2: "",
      postalCode: "35052",
    },
    {
      "@id": "/urssaf_centers/24",
      "@type": "UrssafCenter",
      id: 24,
      siret: "75364415200013",
      code: "U547",
      national_emitter_number: "196509",
      name: "URSSAF DU POITOU-CHARENTES",
      address_1: "3 AVENUE DE LA REVOLUTION",
      address_2: "",
      postal_code: "86046",
      city: "POITIERS CEDEX 9",
      productions: [],
      nationalEmitterNumber: "196509",
      address1: "3 AVENUE DE LA REVOLUTION",
      address2: "",
      postalCode: "86046",
    },
    {
      "@id": "/urssaf_centers/25",
      "@type": "UrssafCenter",
      id: 25,
      siret: "78366205990022",
      code: "U595",
      national_emitter_number: "143871",
      name: "URSSAF DU NORD - LILLE - TGE",
      address_1: "293 avenue du Président Hoover",
      address_2: "BP 20001",
      postal_code: "59032",
      city: "LILLE CEDEX",
      productions: [],
      nationalEmitterNumber: "143871",
      address1: "293 avenue du Président Hoover",
      address2: "BP 20001",
      postalCode: "59032",
    },
    {
      "@id": "/urssaf_centers/26",
      "@type": "UrssafCenter",
      id: 26,
      siret: "79484650190011",
      code: "U693",
      national_emitter_number: "143065",
      name: "URSSAF RHONE ALPES",
      address_1: "GE - TGE",
      address_2: "6, Rue du 19 MARS 1962",
      postal_code: "69691",
      city: "VENISSIEUX CEDEX",
      productions: [],
      nationalEmitterNumber: "143065",
      address1: "GE - TGE",
      address2: "6, Rue du 19 MARS 1962",
      postalCode: "69691",
    },
    {
      "@id": "/urssaf_centers/27",
      "@type": "UrssafCenter",
      id: 27,
      siret: "78877877700011",
      code: "U727",
      national_emitter_number: "197378",
      name: "URSSAF AQUITAINE",
      address_1: "3 RUE THEODORE BLANC",
      address_2: "QUARTIER DU LAC",
      postal_code: "33084",
      city: "BORDEAUX CEDEX",
      productions: [],
      nationalEmitterNumber: "197378",
      address1: "3 RUE THEODORE BLANC",
      address2: "QUARTIER DU LAC",
      postalCode: "33084",
    },
    {
      "@id": "/urssaf_centers/28",
      "@type": "UrssafCenter",
      id: 28,
      siret: "53514650000017",
      code: "U737",
      national_emitter_number: "113417",
      name: "URSSAF MIDI-PYRENEES",
      address_1: "166, rue Pierre et Marie Curie",
      address_2: "LABEGE",
      postal_code: "31061",
      city: "Toulouse Cedex 9",
      productions: [],
      nationalEmitterNumber: "113417",
      address1: "166, rue Pierre et Marie Curie",
      address2: "LABEGE",
      postalCode: "31061",
    },
    {
      "@id": "/urssaf_centers/29",
      "@type": "UrssafCenter",
      id: 29,
      siret: "75391952100017",
      code: "U747",
      national_emitter_number: "191299",
      name: "Urssaf du Limousin",
      address_1: "11 rue Camille Pelletan",
      address_2: "",
      postal_code: "87047",
      city: "Limoges cedex",
      productions: [],
      nationalEmitterNumber: "191299",
      address1: "11 rue Camille Pelletan",
      address2: "",
      postalCode: "87047",
    },
    {
      "@id": "/urssaf_centers/30",
      "@type": "UrssafCenter",
      id: 30,
      siret: "75391952100017",
      code: "U748",
      national_emitter_number: "191299",
      name: "Urssaf du Limousin",
      address_1: "11 rue Camille Pelletan",
      address_2: "",
      postal_code: "87047",
      city: "Limoges cedex",
      productions: [],
      nationalEmitterNumber: "191299",
      address1: "11 rue Camille Pelletan",
      address2: "",
      postalCode: "87047",
    },
  ],
  "hydra:totalItems": 40,
  "hydra:view": {
    "@id": "/urssaf_centers?page=1",
    "@type": "hydra:PartialCollectionView",
    "hydra:first": "/urssaf_centers?page=1",
    "hydra:last": "/urssaf_centers?page=2",
    "hydra:next": "/urssaf_centers?page=2",
  },
};
