import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../hooks";
import { memo } from "react";
import useFilm from "../../hooks/useFilm";
import { Box } from "../../components/core";

export interface Props {}

const DashboardStaffPage = ({}: Props) => {
  const { t } = useTranslation();
  const { auth } = useAuth();
  const { registrations } = useFilm(undefined);

  return (
    <>
      <Helmet>
        <title>{`${t("DashboardComedian.pageTitle")} - ${t("MyRole")}`}</title>
      </Helmet>

      <Box>
        <Box.Title>Staff</Box.Title>
        <Box.Content>
          <div>Staff dashboard</div>
        </Box.Content>
      </Box>
    </>
  );
};

export default memo(DashboardStaffPage);
