import { faker } from "@faker-js/faker";
import { ROLE } from "../constants";

const USER: User = {
  id: faker.string.uuid(),
  email: faker.internet.email(),
  refresh_token: faker.string.sample(20) ,
  bearer: faker.string.sample(20) ,
  roles: [],
  isVerified: true,
  user_field: {
    first_name: faker.person.firstName(),
    last_name: faker.person.lastName(),
    profile_picture: faker.image.avatar(),
  },
};

export const USER_CHARGE_CASTING_FIXTURE: User = {
  ...USER,
  roles: [ROLE.CHARGE_CASTING],
};

export const USER_COMEDIEN_FIXTURE: User = {
  ...USER,
  roles: [ROLE.COMEDIEN],
};

export const USER_CHARGE_PRODUCTION_FIXTURE: User = {
  ...USER,
  roles: [ROLE.CHARGE_PRODUCTION],
};
