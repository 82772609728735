import { AUTH_END_POINT } from "../constants";
import { axiosPrivate } from "./axios";

export async function getSetEmployeeGroups() {
  const { data } = await axiosPrivate.get<GetSetEmployeeGroups>(
    `${AUTH_END_POINT.SET_EMPLOYEE_GROUP}`
  );

  return data["hydra:member"];
}

export async function postSetEmployeeGroups(
  setEmployeeGroups: PostSetEmployeeGroups
) {
  const { data } = await axiosPrivate.post<PostSetEmployeeGroups>(
    AUTH_END_POINT.SET_EMPLOYEE_GROUP,
    setEmployeeGroups
  );

  return data;
}

export async function deleteSetEmployeeGroups(id: number) {
  const { data } = await axiosPrivate.delete(
    `${AUTH_END_POINT.SET_EMPLOYEE_GROUP}/${id}`
  );

  return data;
}
