import {
    Button,
    Popover,
    PopoverProps,
    ButtonProps,
    DatePicker as AriaDatePicker,
    Label as AriaLabel,
    Group,
    DateInput,
    DateSegment,
    Dialog,
    Calendar,
    Heading,
    CalendarGrid,
    CalendarGridHeader,
    CalendarHeaderCell,
    CalendarGridBody,
    CalendarCell,
    DatePickerProps,
} from 'react-aria-components';
import { CalendarDate, parseDate } from '@internationalized/date';
import {
    GoTriangleDown,
    GoTriangleLeft,
    GoTriangleRight,
} from 'react-icons/go';
import Label, { LabelWrapperProps } from '../Label';
import { BsCalendar, BsCalendar2, BsCalendar3 } from 'react-icons/bs';
import { Ref } from 'react';
import cn from 'mxcn';

export interface Props extends DatePickerProps<any> {
    labelProps?: Omit<LabelWrapperProps, 'children, required, disabled'>;
    info?: string;
    ref?: Ref<HTMLDivElement>;
    containerClassName?: string;
}

export default function DatePicker({
    labelProps,
    info,
    defaultValue,
    ref,
    containerClassName,
    ...inputProps
}: Props) {
    const extraProps: { defaultValue?: CalendarDate } = {};

    if (defaultValue) {
        extraProps['defaultValue'] = parseDate(defaultValue);
    }

    if (inputProps.value) {
        extraProps['defaultValue'] = parseDate(inputProps.value);
    }

    const handleOnChange = (d: any) => {
        if (inputProps.onChange && d) {
            inputProps.onChange(d.toString());
        }
    };

    const handleOnBlur = (e: any) => {
        if (inputProps.onBlur) {
            inputProps.onBlur(e);
        }
    };

    return (
        <AriaDatePicker
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            {...extraProps}
            isRequired={inputProps.isRequired}
            isDisabled={inputProps.isDisabled}
            isInvalid={inputProps.isInvalid}
            className={cn('w-fit', containerClassName)}
        >
            {({ isFocusWithin }) => (
                <>
                    <Label.Wrapper
                        {...labelProps}
                        required={inputProps.isRequired}
                        disabled={inputProps.isDisabled}
                    >
                        <Group
                            className={cn(
                                'flex focus-within:border-mr-cyan-400 rounded border bg-white/90 group-open:bg-white transition pl-3 min-h-[46px] min-w-[240px] text-gray-700 disabled:opacity-50',
                                'border-mr-gray-1000',
                                {
                                    'border-mr-orange-400':
                                        labelProps?.errorLabel,
                                    'border-mr-cyan-400': isFocusWithin,
                                }
                            )}
                        >
                            <DateInput
                                ref={ref}
                                className="flex flex-1 self-center"
                            >
                                {(segment) => (
                                    <DateSegment
                                        segment={segment}
                                        className="px-0.5 tabular-nums outline-none rounded-sm focus:bg-mr-cyan-400 focus:text-white caret-transparent placeholder-shown:italic text-base text-inherit text-[#5d6165]"
                                    />
                                )}
                            </DateInput>
                            <Button
                                className={cn(
                                    'px-3 flex items-center text-gray-700 transition border-0 border-solid border-l  bg-transparent focus-within:border-l-mr-cyan-400',
                                    'border-l-mr-gray-1000 rounded-br rounded-tr hover:bg-mr-gray-200',
                                    {
                                        'border-l-mr-orange-400':
                                            labelProps?.errorLabel,
                                        'border-l-mr-cyan-400': isFocusWithin,
                                    }
                                )}
                            >
                                <BsCalendar3 size="16" />
                            </Button>
                        </Group>
                    </Label.Wrapper>
                    <PickerPopover>
                        <Dialog className="p-6 text-gray-600">
                            <Calendar>
                                <header className="flex items-center gap-1 pb-4 px-1 w-full">
                                    <Heading className="flex-1 font-semibold text-2xl ml-2" />
                                    <RoundButton slot="previous">
                                        <GoTriangleLeft />
                                    </RoundButton>
                                    <RoundButton slot="next">
                                        <GoTriangleRight />
                                    </RoundButton>
                                </header>
                                <CalendarGrid className="border-spacing-1 border-separate">
                                    <CalendarGridHeader>
                                        {(day) => (
                                            <CalendarHeaderCell className="text-xs text-gray-500 font-semibold">
                                                {day}
                                            </CalendarHeaderCell>
                                        )}
                                    </CalendarGridHeader>
                                    <CalendarGridBody>
                                        {(date) => (
                                            <CalendarCell
                                                date={date}
                                                className="w-9 h-9 outline-none cursor-default rounded flex items-center justify-center outside-month:text-gray-300 hover:bg-gray-100 pressed:bg-gray-200 selected:bg-mr-cyan-400 selected:text-white focus-visible:ring ring-mr-cyan-400/70 ring-offset-2"
                                            />
                                        )}
                                    </CalendarGridBody>
                                </CalendarGrid>
                            </Calendar>
                        </Dialog>
                    </PickerPopover>
                </>
            )}
        </AriaDatePicker>
    );
}

function RoundButton(props: ButtonProps) {
    return (
        <Button
            {...props}
            className="w-9 h-9 outline-none cursor-default bg-transparent text-gray-600 border-0 rounded-full flex items-center justify-center hover:bg-gray-100 pressed:bg-gray-200 focus-visible:ring ring-violet-600/70 ring-offset-2"
        />
    );
}

function PickerPopover(props: PopoverProps) {
    return (
        <Popover
            {...props}
            className={({ isEntering, isExiting }) => `
        overflow-auto rounded drop-shadow-lg ring-1 ring-mr-gray-1000 bg-white w-[--trigger-width]
        ${
            isEntering
                ? 'animate-in fade-in placement-bottom:slide-in-from-top-1 placement-top:slide-in-from-bottom-1 ease-out duration-200'
                : ''
        }
        ${
            isExiting
                ? 'animate-out fade-out placement-bottom:slide-out-to-top-1 placement-top:slide-out-to-bottom-1 ease-in duration-150'
                : ''
        }
      `}
        />
    );
}
