import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { axiosPrivate } from "../api/axios";
import { AUTH_END_POINT } from "../constants/constant";
import authAtom from "../recoil/auth";

export function useRememberMe() {
  const [auth, setAuth] = useRecoilState(authAtom);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  let isMounted = false;

  useEffect(() => {
    if (!auth) {
      !isMounted && rememberMe();

      return () => {
        isMounted = true;
      };
    }
  }, []);

  async function rememberMe() {
    setIsLoading(true);

    try {
      const { data } = await axiosPrivate.get<LoginResponse>(
        AUTH_END_POINT.ME,
        {
          timeout: 5000,
        }
      );

      setAuth(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  return { isLoading };
}
