import { createContext, useContext } from "react";
import { TableProviderContextValue } from "./Table";

//@ts-ignore
export const TableQuery = createContext<TableProviderContextValue>({});


export default () => {
  const TableProviderContext = useContext(TableQuery)

  return TableProviderContext;
}