import { t } from "i18next";
import { memo } from "react";
import { BsBuilding } from "react-icons/bs";
import { HiOutlineFilm, HiOutlineUsers, HiOutlineSave } from "react-icons/hi";
import { useParams } from "react-router-dom";
import { useCompany } from "../../hooks";

export interface Props {
  className?: string;
  title?: string;
  companyName?: string;
  productionName?: string;
  role: string;
  isProject: boolean;
  IconFilm?: boolean;
  fromProjectOrProductionId?: string;
}

const CompanyHeader = ({
  className = "",
  title = "",
  companyName = "",
  productionName = "",
  role = "",
  isProject = true,
  IconFilm = false,
  fromProjectOrProductionId,
}: Props) => {

  const { companyId } = useParams();
  const { company } = useCompany((fromProjectOrProductionId ? fromProjectOrProductionId : companyId) as string);

  companyName = company?.name ?? '';
  isProject = company?.companyType.name === "project";
  if (isProject) {
    // @ts-ignore
    productionName = company?.project?.production_company?.name ?? '';
  }

  return (
    <div
      className={`${className} mt-1 flex items-center gap-6`}
      data-testid="CompanyHeader"
    >
      <div className="rounded-full bg-white text-mr-cyan-400">
        {IconFilm ? (
          <HiOutlineSave className="m-5 h-8 w-8" />
        ) : (
          <HiOutlineFilm className="m-5 h-8 w-8" />
        )}
      </div>

      <div className="flex flex-col">
        <h1 className="text-2xl font-bold">{title}</h1>

        <div className="text-4xl font-semibold italic text-mr-cyan-400">
          {companyName && `"${companyName}"`}
        </div>

        <div className="flex gap-4 text-xs text-gray-600">
          {productionName && isProject === true && (
            <div className="flex items-center gap-2">
              <BsBuilding className="h-3 w-3" />
              {productionName}
            </div>
          )}
          {role && (
            <div className="flex items-center gap-2">
              <HiOutlineUsers className="h-3 w-3" />
              {`${t("CompanyHeader.one")} ${role} ${t("on")} ${isProject
                ? t("CompanyHeader.project")
                : t("CompanyHeader.production")
                }`}
            </div>
          )}
        </div>
      </div>
    </div>
  )
};

export default memo(CompanyHeader);
