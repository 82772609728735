import {
  Table as AriaTable,
  Cell,
  TableBody,
  TableHeader,
} from "react-aria-components";
import Checkbox from "../Checkbox/Checkbox";

import { Selection, SortDescriptor } from "react-stately";

import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { ReactNode, useEffect, useState } from "react";
import _ from "lodash";
import useTableContext from "./useTableContext";

import Column from "./Column";
import Row from "./Row";
import Loading from "./Loading";
import EmptyResult from "./EmptyResult";
import Error from "./Error";
import cn from "mxcn";

export interface Column {
  id: string;
  title: string;
  isSorting?: boolean;
  className?: string;
  hidden?: boolean;
}

export interface FetchedData {
  id: string;
  title: string;
  author: string;
  year: number;
}

export interface TableProps {
  id: string;
  queryFn: (args: TableFetchArgs) => Promise<{}> | {};
  renderHeader?: () => ReactNode;
  renderFooter?: () => ReactNode;
  renderLoading?: () => ReactNode;
  renderEmptyResult?: () => ReactNode;
  keyExtractor: (item: any) => string;
  totalItemsExtractor: (data: any) => number;
  dataExtrator: (data: any) => any[];
  renderError?: (msg: Error) => ReactNode;
  selection?: "multiple" | undefined;
  onSelectionChange?: (key: Selection) => void;
  children?: (item: any, columns: Column[]) => ReactNode;
  hideHeader?: boolean;
  className?: string;
}

export interface TableFetchArgs {
  page: number;
  perPage: number;
  sortDescriptor: SortDescriptor | null;
  searchText: string | undefined;
  extraArgs: any;
}

const TableUI = ({
  id,
  queryFn,
  keyExtractor,
  renderFooter,
  renderHeader,
  renderLoading,
  renderEmptyResult,
  renderError,
  dataExtrator,
  totalItemsExtractor,
  onSelectionChange = () => {},
  selection,
  children,
  hideHeader = false,
  className = "",
}: TableProps) => {
  const {
    page,
    perPage,
    columns,
    sortDescriptor,
    setSortDescriptor,
    searchText,
    setTotalItems,
    setTotalItemsDisplayed,
    extraArgs,
  } = useTableContext();

  let [count, setCount] = useState(10);
  let [selectedTableKeys, setSelectedTableKeys] = useState<Selection>(
    new Set(),
  );

  const fetchProjects = (page = 1) => {
    const args = {
      page,
      perPage,
      sortDescriptor,
      searchText,
      extraArgs,
    };

    return queryFn(args);
  };

  const { isPending, error, data, isFetching } = useQuery({
    queryKey: [
      `table_${id}`,
      page,
      perPage,
      sortDescriptor,
      searchText,
      extraArgs,
    ],
    queryFn: async () => {
      const res = await fetchProjects(page);

      if (setTotalItems) {
        //@ts-ignore
        setTotalItems(totalItemsExtractor(res));
      }

      return dataExtrator(res);
    },
    placeholderData: keepPreviousData,
  });

  const handleSelectionEvent = (key: Selection): any => {
    const isGlobalSelection = key === "all" || key.size === 0; // if global check or global uncheck - just threat that as global selection for the displayed data only
    const newSelection = new Set<Selection>(
      // @ts-ignore
      dataList.map((item: any) => keyExtractor(item)),
    ); // why conversion to int ? to map the main render function Row id

    if (selectedTableKeys === "all") {
      return;
    }

    // check if all elements inside the selection are inside the displayed elements or there external selection item (like on other page)
    let isInside = 1;
    selectedTableKeys.forEach((value1) => {
      // @ts-ignore
      isInside &= newSelection.has(value1) ? 1 : 0;
    });

    // check if all elements displayed are inside the selection
    let allDataListItemsAreInside = 1;
    newSelection.forEach((value1) => {
      // @ts-ignore
      allDataListItemsAreInside &= selectedTableKeys.has(value1) ? 1 : 0;
    });

    if (isGlobalSelection && selectedTableKeys.size !== 1) {
      // if global selection and not only one element selected (deselection behavior)
      // check + fin si il y a des elements qui ne sont pas dans les élements courants
      // diff si il manque des elements par rapport à la liste ou si ce sont des elements externes

      // detect if all element inside dataList are inside select
      // if not union
      // if yes difference

      if (allDataListItemsAreInside === 1) {
        //@ts-ignore
        const temp = new Set([...selectedTableKeys]);
        newSelection.forEach((value1) => {
          // @ts-ignore
          temp.delete(value1);
        });

        updateSelection(temp);
      } else {
        updateSelection(
          // @ts-ignore
          new Set<Selection>([...selectedTableKeys, ...newSelection]),
        );
      }
      return;
    }

    // default behavior => row selection
    updateSelection(key);
  };

  const updateSelection = (key: Selection) => {
    onSelectionChange(key);
    setSelectedTableKeys(key);
  };

  //@ts-ignore
  const dataList: FetchedData[] = data ?? [];

  useEffect(() => {
    setTotalItemsDisplayed(dataList.length);
  }, [dataList]);

  const hasSelection = selection === "multiple";

  const renderColumns = () => {
    return columns.flatMap(
      ({
        id,
        title,
        className = "",
        isSorting = false,
        hidden = false,
      }: Column) => {
        if (hidden) {
          return [];
        }
        return [
          <Column
            key={id}
            id={id}
            isRowHeader
            allowsSorting={isSorting}
            className={className}
          >
            {title}
          </Column>,
        ];
      },
    );
  };

  const loadingUI =
    typeof renderLoading === "undefined" ? <Loading /> : renderLoading();
  const emptyResultUI =
    typeof renderEmptyResult === "undefined" ? (
      <EmptyResult />
    ) : (
      renderEmptyResult()
    );
  const errorUI = (error: Error) => {
    return typeof renderError === "undefined" ? (
      <Error msg={error} />
    ) : (
      renderError(error)
    );
  };

  return (
    <>
      <div className="TableHeader">{renderHeader && renderHeader()}</div>

      <div className="relative overflow-scroll rounded-md TableContent">
        <AriaTable
          sortDescriptor={sortDescriptor ?? undefined}
          onSortChange={(dir) => {
            setSelectedTableKeys(new Set([])); // reset selection when sorting

            if (setSortDescriptor) {
              setSortDescriptor(dir);
            }
          }}
          selectionMode={hasSelection ? "multiple" : "none"}
          selectedKeys={selectedTableKeys}
          onSelectionChange={handleSelectionEvent}
          className={className ? className : "table-auto w-full"}
        >
          <TableHeader
            className={cn("react-aria-TableHeader", {
              hidden: hideHeader,
            })}
          >
            {hasSelection && (
              <Column isRowHeader className="sticky top-0 z-10 w-24 whitespace-nowrap">
                <Checkbox slot="selection" />
              </Column>
            )}
            {renderColumns()}
          </TableHeader>

          <TableBody items={dataList}>
            {(item) => (
              <Row id={keyExtractor(item)}>
                {hasSelection && (
                  <Cell>
                    <div>
                      <Checkbox slot="selection" />
                    </div>
                  </Cell>
                )}
                {children && children(item, columns)}
              </Row>
            )}
          </TableBody>
        </AriaTable>
        {!isFetching && !error && dataList.length === 0 && emptyResultUI}
        {isFetching && loadingUI}
        {error && errorUI(error)}
      </div>
      <div className="TableFooter">{renderFooter && renderFooter()}</div>
    </>
  );
};

export default TableUI;
