export const COMPANY_TYPES_FIXTURE: GetCompanyTypesResponse = {
  "@context": "/contexts/CompanyType",
  "@id": "/company_types",
  "@type": "hydra:Collection",
  "hydra:member": [
    {
      "@id": "/company_types/1",
      "@type": "CompanyType",
      id: 1,
      name: "project",
    },
    {
      "@id": "/company_types/2",
      "@type": "CompanyType",
      id: 2,
      name: "production",
    },
  ],
  "hydra:totalItems": 2,
};
