import { ReactNode, memo } from "react";
import { IconType } from "react-icons";
import { twMerge } from "tailwind-merge";

export interface Props {
  className?: string;
  title?: string;
  Icon?: IconType;
  children?: ReactNode;
}

const BlockIconBorder = ({ className = "", title = "", Icon, children }: Props) => {

  return (
    <div className={twMerge(
        'mt-4 relative',
        'before:absolute before:h-full before:w-px before:bg-gray-200 before:left-4 before:top-0 before:z-0',
        className
      )} data-testid="BlockIconBorder">
      <div className="flex items-center text-cyan-500 relative z-10">
        <div className="bg-white rounded-full p-2 mr-4 shadow-md">
          {Icon && <Icon className="h-5 w-5 text-cyan-500" />}
        </div>
        <span className="text-lg font-bold">{title}</span>
      </div>

      <div className="ml-10">
        {children}
      </div>
    </div>
  );
}

export default memo(BlockIconBorder);
