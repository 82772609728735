import { memo, useCallback, useRef, useState } from "react";
import { HiQuestionMarkCircle } from "react-icons/hi";
import { Popover } from "../Popover";

export interface Props {
  className?: string;
  text?: string;
}

const InfoIcon = ({ className = "", text }: Props) => {
  const [isInfoPopoverOpen, setIsInfoPopoverOpen] = useState(false);
  const ref = useRef(null);

  const enterIcon = useCallback(() => {
    setIsInfoPopoverOpen(true);
  }, []);

  const leaveIcon = useCallback(() => {
    setIsInfoPopoverOpen(false);
  }, []);

  return (
    <div
      className={`${className} inline-block`}
      onMouseEnter={enterIcon}
      onMouseLeave={leaveIcon}
      ref={ref}
      data-testid="InfoIcon"
    >
      <HiQuestionMarkCircle className="ml-1 h-5 w-5 cursor-pointer text-mr-orange-400" />
      <Popover
        anchorOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        anchorEl={ref}
        open={isInfoPopoverOpen}
      >
        <div className="rounded bg-mr-gray-100 px-2 py-1">{text}</div>
      </Popover>
    </div>
  );
};

export default memo(InfoIcon);
