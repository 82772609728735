import { HiCalendar, HiUserGroup, HiMail } from "react-icons/hi";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useAuth, useLoginModal } from "../../hooks";
import { SecondaryButton } from "../core";
import ProfilePopover from "../ProfilePopover/ProfilePopover";
import logo from "../../images/logo.png";
import { ReactComponent as LogoSm } from "../../images/logo-sm.svg";
import { Link } from "react-router-dom";
import { ROUTE_PATH } from "../../constants";
import { BsArrowBarLeft, BsArrowBarRight } from "react-icons/bs";
import { useMenu } from "../../hooks";
import LangSelector from "../LangSelector/LangSelector";
import DebugFeature from "../DebugFeature/DebugFeature";

export interface Props {}

const TopNavbar = ({}: Props) => {
  const { t } = useTranslation();
  const { auth } = useAuth();
  const { openLoginModal } = useLoginModal();
  const {
    menu: { isCollapsed, subMenu },
    toggleCollapseSubMenu,
  } = useMenu();

  return (
    <nav
      className="fixed z-20 flex h-12 w-full items-center justify-between bg-white shadow"
      data-testid="TopNavbar"
    >
      <div className="flex h-full">
        <Link
          className={`flex items-center justify-center bg-mr-cyan-300 transition-all ${
            isCollapsed ? "w-[56px]" : "w-[170px]"
          }`}
          to={ROUTE_PATH.HOME}
          data-testid="Logo"
        >
          {isCollapsed ? (
            <LogoSm className="h-5" />
          ) : (
            <img className="h-5" src={logo} alt="Logo MyRole" />
          )}
        </Link>

        {subMenu.item && (
          <div
            className={`flex items-center bg-mr-cyan-400 transition-all ${
              subMenu.isCollapsed
                ? "w-[56px] justify-center"
                : "w-[240px] justify-between px-5"
            }`}
            data-testid="SubMenu"
          >
            {!subMenu.isCollapsed && subMenu.item?.title && (
              <span className="truncate text-white">
                {t(subMenu.item.title)}
              </span>
            )}
            <button
              className="rounded-full bg-white p-2 text-mr-cyan-300 shadow-md hover:bg-gray-200"
              onClick={toggleCollapseSubMenu}
              data-testid="ToggleSubMenuCollapsed"
            >
              {subMenu.isCollapsed ? (
                <BsArrowBarRight className="h-4 w-4" />
              ) : (
                <BsArrowBarLeft className="h-4 w-4" />
              )}
            </button>
          </div>
        )}
      </div>

      <div className="mr-4 flex h-full items-center gap-3">
        <DebugFeature />
        <LangSelector />
        {auth ? (
          <>
            {/* <HiCalendar className="h-6 w-6 cursor-pointer text-gray-900 hover:text-gray-500" />
            <HiUserGroup className="h-6 w-6 cursor-pointer text-gray-900 hover:text-gray-500" /> */}
            <Link to={ROUTE_PATH.MESSAGING}>
              <HiMail className="h-6 w-6 cursor-pointer text-gray-900 hover:text-gray-500" />
            </Link>
            <ProfilePopover
              anchorOrigin={{
                horizontal: "right",
                vertical: "bottom",
              }}
              transformOrigin={{
                horizontal: "right",
                vertical: "top",
              }}
              popoverClassName="pt-5"
            />
          </>
        ) : (
          <SecondaryButton
            data-testid="TopNavbar-Login"
            onClick={openLoginModal}
          >
            {t("Login")}
          </SecondaryButton>
        )}
      </div>
    </nav>
  );
};

export default memo(TopNavbar);
