import ExcelJs from "exceljs";
import { merge } from "lodash";

export interface excelColumn {
  title: string,
  render(row: any): string,
}

export function useExcelExport(
  columns: excelColumn[],
  rows: any[],
  filename: string,
  title: string = "",
  style: any = {}
) {
  const defaultStyles = {
    title: {
      font: {
        bold: true,
        size: 14,
        color: { argb: "ffffffff" },
      },
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "ffea754b" },
      },
      alignment: { 
        vertical: 'center', 
        horizontal: 'center' 
      }
    },
    header: {
      font: {
        bold: true,
        size: 14,
      },
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "00bcd4" },
      },
      border: {
        top: { style: "thin", color: { argb: "00000" } },
        left: { style: "thin", color: { argb: "00000" } },
        bottom: { style: "thin", color: { argb: "00000" } },
        right: { style: "thin", color: { argb: "00000" } }
      }
    },
    row: {
      border: {
        top: { style: "thin", color: { argb: "00000" } },
        left: { style: "thin", color: { argb: "00000" } },
        bottom: { style: "thin", color: { argb: "00000" } },
        right: { style: "thin", color: { argb: "00000" } }
      }
    }
  }
  style = merge(defaultStyles, style);
  
  return {
    excelExport: () => {
      const workbook = new ExcelJs.Workbook();
      const worksheet = workbook.addWorksheet("main");

      // Set workbook title
      const titleRow = worksheet.addRow([title]);
      worksheet.mergeCells(1, 1, 1, columns.length);
      worksheet.addRow([]);
      if (style.title) {
        titleRow.eachCell(cell => Object.assign(cell, style.title));
      }

      // write header
      const headerRow = worksheet.addRow(columns.map((column) => column.title));
      if (style.header) {
        headerRow.eachCell(cell => Object.assign(cell, style.header));
      }

      // write rows
      rows.forEach((row) => {
        const r = worksheet.addRow(columns.map((column) => column.render(row)));
        if (style.row) {
          r.eachCell(cell => Object.assign(cell, style.row));
        }
      })

      // send buffer to download
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename.includes('.xlsx') ? filename : `${filename}.xlsx`;
        a.click();
      });
    },
  };
}
