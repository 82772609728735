import { AxiosError, AxiosRequestConfig } from "axios";
import { AUTH_END_POINT } from "../constants";
import axios, { axiosPrivate, axiosPrivatePatch } from "./axios";
import axiosModule from "axios";


export async function uploadDepositedFile(companyId: string, body: FormData) {
  try {
    const response = await axiosPrivate.post(AUTH_END_POINT.DEPOSITED_FILES.UPLOAD(companyId), body, { timeout: 0, headers: {
      'Content-Type': 'multipart/form-data'
    }})

    return response?.data;
  } catch (err) {
    if (axiosModule.isAxiosError(err)) {
      const error = err as AxiosError;
      throw error?.response?.status;
    } else {
      throw err;
    }
  }
}

export async function startExtraction() {
  try {
    const response = await axiosPrivate.get(AUTH_END_POINT.DEPOSITED_FILES.EXTRACT())
    return response?.data;
  } catch (err) {
    if (axiosModule.isAxiosError(err)) {
      const error = err as AxiosError;
      throw error?.response?.status;
    } else {
      throw err;
    }
  }
}

export async function geExtractedFile(companyId: string) {
  try {
    const response = await axiosPrivate.get(AUTH_END_POINT.DEPOSITED_FILES.LIST(companyId))
    return response?.data;
  } catch (err) {
    if (axiosModule.isAxiosError(err)) {
      const error = err as AxiosError;
      throw error?.response?.status;
    } else {
      throw err;
    }
  }
}

export async function geExtractedFilePage(companyId: string) {
  try {
    const response = await axiosPrivate.get(AUTH_END_POINT.DEPOSITED_FILES.FILE_PAGE_LIST(companyId))
    return response?.data;
  } catch (err) {
    if (axiosModule.isAxiosError(err)) {
      const error = err as AxiosError;
      throw error?.response?.status;
    } else {
      throw err;
    }
  }
}

export async function deleteExtractedFile(id: string) {
  try {
    const response = await axiosPrivate.delete(AUTH_END_POINT.DEPOSITED_FILES.DELETE(id))
    return response;
  } catch (err) {
    if (axiosModule.isAxiosError(err)) {
      const error = err as AxiosError;
      throw error?.response?.status;
    } else {
      throw err;
    }
  }
}

export async function deleteExtractedFilePage(id: string) {
  try {
    const response = await axiosPrivate.delete(AUTH_END_POINT.DEPOSITED_FILES.DELETE_PAGE(id))
    return response?.data;
  } catch (err) {
    if (axiosModule.isAxiosError(err)) {
      const error = err as AxiosError;
      throw error?.response?.status;
    } else {
      throw err;
    }
  }
}