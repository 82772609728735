export const ProjectCardLoader = ({
  className = "",
}: {
  className?: string;
}) => (
  <div
    className={`${className} flex select-none flex-col gap-5 rounded-2xl bg-white p-2 shadow-lg sm:h-64 sm:flex-row sm:p-4`}
  >
    <div className="flex flex-1 flex-col gap-5 sm:p-2">
      <div className="h-14 w-full animate-pulse rounded-2xl bg-gray-200" />
      <div className="grid grid-cols-2 gap-3">
        <div className="h-3 w-full animate-pulse rounded-2xl bg-gray-200" />

        <div className="h-3 w-full animate-pulse rounded-2xl bg-gray-200" />
        <div className="h-3 w-full animate-pulse rounded-2xl bg-gray-200" />
        <div className="h-3 w-full animate-pulse rounded-2xl bg-gray-200" />
      </div>
      <div className="grid grid-cols-3 gap-3">
        <div className="flex items-center gap-3">
          <div className="h-8 w-8 flex-none animate-pulse rounded-full bg-gray-200" />
          <div className="h-3 w-full animate-pulse rounded-2xl bg-gray-200" />
        </div>
        <div className="flex items-center gap-3">
          <div className="h-8 w-8 flex-none animate-pulse rounded-full bg-gray-200" />
          <div className="h-3 w-full animate-pulse rounded-2xl bg-gray-200" />
        </div>
        <div className="flex items-center gap-3">
          <div className="h-8 w-8 flex-none animate-pulse rounded-full bg-gray-200" />
          <div className="h-3 w-full animate-pulse rounded-2xl bg-gray-200" />
        </div>
        <div className="flex items-center gap-3">
          <div className="h-8 w-8 flex-none animate-pulse rounded-full bg-gray-200" />
          <div className="h-3 w-full animate-pulse rounded-2xl bg-gray-200" />
        </div>
        <div className="flex items-center gap-3">
          <div className="h-8 w-8 flex-none animate-pulse rounded-full bg-gray-200" />
          <div className="h-3 w-full animate-pulse rounded-2xl bg-gray-200" />
        </div>
        <div className="flex items-center gap-3">
          <div className="h-8 w-8 flex-none animate-pulse rounded-full bg-gray-200" />
          <div className="h-3 w-full animate-pulse rounded-2xl bg-gray-200" />
        </div>
      </div>
    </div>
  </div>
);
