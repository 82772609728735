import { getApplications, patchApplicationState } from "../api/applications";
import { useMutation, useQuery } from "@tanstack/react-query";

export function useApplications(
  companyId: string
) {
  const {
    isLoading: isApplicationsLoading,
    error: errorOnApplications,
    data: applications,
  } = useQuery({
    queryKey: ["applications", companyId],
    queryFn: () => getApplications(companyId),
  });

  return {
    applications: applications?.data["hydra:member"],
    isApplicationsLoading,
    errorOnApplications,
  };
}

export function useApplicationsMutation() {
  const patch = useMutation({
    mutationFn: ({ company_id, registration_id, data }: { company_id: string; registration_id: string; data: ApplicationStatus }) => patchApplicationState(company_id, registration_id, data),
  });
  return {
    patch,
  };
}