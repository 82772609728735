import { RegisterSettingsData } from "../types/register_settings";
import { CUSTOM_QUESTIONS_FIXTURE } from "./custom_question";

export const REGISTRATION_MODALITY_FIELDS_FIXTURE: RegisterSettingsData = {
  employee_group: [
    {
      id: "/employee_group/uuid",
      name: "Figurants",
      fields: [
        {
          id: "/fields/Figurants/scheduled_days/00",
          name: "scheduled_days",
          required: true,
        },
        {
          id: "/fields/Figurants/scheduled_times/00",
          name: "scheduled_times",
          required: true,
        },
        {
          id: "/fields/Figurants/professional_alias/00",
          name: "professional_alias",
          required: true,
        },
        {
          id: "/fields/Figurants/authorize_employer_to_register_agent/00",
          name: "authorize_employer_to_register_agent",
          required: true,
        },
        {
          id: "/fields/Figurants/mandatory_social_security_number/00",
          name: "mandatory_social_security_number",
          required: true,
        },
        {
          id: "/fields/Figurants/request_agreement_for_business_expense/00",
          name: "request_agreement_for_business_expense",
          required: true,
        },
        {
          id: "/fields/Figurants/request_agreement_for_dematerialization/00",
          name: "request_agreement_for_dematerialization",
          required: true,
        },
        {
          id: "/fields/Figurants/ID/00",
          name: "ID",
          required: false,
        },
        {
          id: "/fields/Figurants/medical_certificate/00",
          name: "medical_certificate",
          required: false,
        },
        {
          id: "/fields/Figurants/carte_vitale/00",
          name: "carte_vitale",
          required: false,
        },
        {
          id: "/fields/Figurants/rib/00",
          name: "rib",
          required: false,
        },
        {
          id: "/fields/Figurants/photos/00",
          name: "photos",
          required: false,
        },
        {
          id: "/fields/Figurants/vehicle_insurance/00",
          name: "vehicle_insurance",
          required: false,
        },
        {
          id: "/fields/Figurants/car_registration_document/00",
          name: "car_registration_document",
          required: false,
        },
        {
          id: "/fields/Figurants/driving_licence/00",
          name: "driving_licence",
          required: false,
        },
        {
          id: "/fields/Figurants/age/00",
          name: "age",
          required: false,
        },
        {
          id: "/fields/Figurants/eyes_color/00",
          name: "eyes_color",
          required: false,
        },
        {
          id: "/fields/Figurants/specific_signs/00",
          name: "specific_signs",
          required: false,
        },
        {
          id: "/fields/Figurants/chest_size/00",
          name: "chest_size",
          required: false,
        },
        {
          id: "/fields/Figurants/hip_size/00",
          name: "hip_size",
          required: false,
        },
        {
          id: "/fields/Figurants/waist_size/00",
          name: "waist_size",
          required: false,
        },
        {
          id: "/fields/Figurants/size/00",
          name: "size",
          required: false,
        },
        {
          id: "/fields/Figurants/weight/00",
          name: "weight",
          required: false,
        },
      ],
    },
    {
      id: "/employee_group/uuid",
      name: "Techniciens",
      fields: [
        {
          id: "/fields/Techniciens/scheduled_days/00",
          name: "scheduled_days",
          required: false,
        },
        {
          id: "/fields/Techniciens/scheduled_times/00",
          name: "scheduled_times",
          required: false,
        },
        {
          id: "/fields/Techniciens/professional_alias/00",
          name: "professional_alias",
          required: false,
        },
        {
          id: "/fields/Techniciens/authorize_employer_to_register_agent/00",
          name: "authorize_employer_to_register_agent",
          required: false,
        },
        {
          id: "/fields/Techniciens/mandatory_social_security_number/00",
          name: "mandatory_social_security_number",
          required: false,
        },
        {
          id: "/fields/Techniciens/request_agreement_for_business_expense/00",
          name: "request_agreement_for_business_expense",
          required: false,
        },
        {
          id: "/fields/Techniciens/request_agreement_for_dematerialization/00",
          name: "request_agreement_for_dematerialization",
          required: false,
        },
        {
          id: "/fields/Techniciens/ID/00",
          name: "ID",
          required: true,
        },
        {
          id: "/fields/Techniciens/medical_certificate/00",
          name: "medical_certificate",
          required: true,
        },
        {
          id: "/fields/Techniciens/carte_vitale/00",
          name: "carte_vitale",
          required: true,
        },
        {
          id: "/fields/Techniciens/rib/00",
          name: "rib",
          required: true,
        },
        {
          id: "/fields/Techniciens/photos/00",
          name: "photos",
          required: true,
        },
        {
          id: "/fields/Techniciens/vehicle_insurance/00",
          name: "vehicle_insurance",
          required: true,
        },
        {
          id: "/fields/Techniciens/car_registration_document/00",
          name: "car_registration_document",
          required: true,
        },
        {
          id: "/fields/Techniciens/driving_licence/00",
          name: "driving_licence",
          required: true,
        },
        {
          id: "/fields/Techniciens/age/00",
          name: "age",
          required: true,
        },
        {
          id: "/fields/Techniciens/eyes_color/00",
          name: "eyes_color",
          required: true,
        },
        {
          id: "/fields/Techniciens/specific_signs/00",
          name: "specific_signs",
          required: true,
        },
        {
          id: "/fields/Techniciens/chest_size/00",
          name: "chest_size",
          required: true,
        },
        {
          id: "/fields/Techniciens/hip_size/00",
          name: "hip_size",
          required: true,
        },
        {
          id: "/fields/Techniciens/waist_size/00",
          name: "waist_size",
          required: true,
        },
        {
          id: "/fields/Techniciens/size/00",
          name: "size",
          required: true,
        },
        {
          id: "/fields/Techniciens/weight/00",
          name: "weight",
          required: true,
        },
      ],
    },
    {
      id: "/employee_group/uuid",
      name: "Autres",
      fields: [
        {
          id: "/fields/Autres/scheduled_days/00",
          name: "scheduled_days",
          required: true,
        },
        {
          id: "/fields/Autres/scheduled_times/00",
          name: "scheduled_times",
          required: true,
        },
        {
          id: "/fields/Autres/professional_alias/00",
          name: "professional_alias",
          required: true,
        },
        {
          id: "/fields/Autres/authorize_employer_to_register_agent/00",
          name: "authorize_employer_to_register_agent",
          required: true,
        },
        {
          id: "/fields/Autres/mandatory_social_security_number/00",
          name: "mandatory_social_security_number",
          required: true,
        },
        {
          id: "/fields/Autres/request_agreement_for_business_expense/00",
          name: "request_agreement_for_business_expense",
          required: true,
        },
        {
          id: "/fields/Autres/request_agreement_for_dematerialization/00",
          name: "request_agreement_for_dematerialization",
          required: true,
        },
        {
          id: "/fields/Autres/ID/00",
          name: "ID",
          required: false,
        },
        {
          id: "/fields/Autres/medical_certificate/00",
          name: "medical_certificate",
          required: false,
        },
        {
          id: "/fields/Autres/carte_vitale/00",
          name: "carte_vitale",
          required: false,
        },
        {
          id: "/fields/Autres/rib/00",
          name: "rib",
          required: false,
        },
        {
          id: "/fields/Autres/photos/00",
          name: "photos",
          required: false,
        },
        {
          id: "/fields/Autres/vehicle_insurance/00",
          name: "vehicle_insurance",
          required: false,
        },
        {
          id: "/fields/Autres/car_registration_document/00",
          name: "car_registration_document",
          required: false,
        },
        {
          id: "/fields/Autres/driving_licence/00",
          name: "driving_licence",
          required: false,
        },
        {
          id: "/fields/Autres/age/00",
          name: "age",
          required: false,
        },
        {
          id: "/fields/Autres/eyes_color/00",
          name: "eyes_color",
          required: false,
        },
        {
          id: "/fields/Autres/specific_signs/00",
          name: "specific_signs",
          required: false,
        },
        {
          id: "/fields/Autres/chest_size/00",
          name: "chest_size",
          required: false,
        },
        {
          id: "/fields/Autres/hip_size/00",
          name: "hip_size",
          required: false,
        },
        {
          id: "/fields/Autres/waist_size/00",
          name: "waist_size",
          required: false,
        },
        {
          id: "/fields/Autres/size/00",
          name: "size",
          required: false,
        },
        {
          id: "/fields/Autres/weight/00",
          name: "weight",
          required: false,
        },
      ],
    },
  ],

  legal_representative_2: true,
  legal_representatives_marital_status: true,
  legal_representatives_birthday: true,
  legal_representatives_birthplace: true,
  legal_representatives_nationality: true,
  id_card: false,
  residence_permit: false,
  tax_notice: false,
  custom_fields: CUSTOM_QUESTIONS_FIXTURE,
};
