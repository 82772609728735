import { Dispatch, SetStateAction, forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { TableQuery } from "./useTableContext";
import { SortDescriptor } from "react-stately";
import { Column, TableProps } from "./TableUI";
import TableUI from "./TableUI";
// import { useState } from "@uidotdev/usehooks";
import { Filter } from "./Filters";
import { useQueryClient } from "@tanstack/react-query";

export interface TableProviderContextValue {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  perPage: number;
  setTotalItems: Dispatch<SetStateAction<number>>;
  setPerPage: Dispatch<SetStateAction<number>>;
  sortDescriptor: SortDescriptor | null;
  setSortDescriptor: Dispatch<SetStateAction<SortDescriptor | null>>;
  setColumns: Dispatch<SetStateAction<Column[]>>;
  setSearchText: Dispatch<SetStateAction<string>>;
  columns: Column[];
  searchText: string;
  totalItems: number;
  totalItemsDisplayed: number;
  setTotalItemsDisplayed: Dispatch<SetStateAction<number>>;
  currentFilter: Filter | undefined;
  setCurrentFilter: Dispatch<SetStateAction<Filter | undefined>>;
  extraArgs: any;
  setExtraArgs: Dispatch<SetStateAction<any>>;
}

interface TableProviderProps extends TableProps {
  columns: Column[];
}

interface SelectedFilter{
  id: string;
  value: string;
}

function Table({ columns, ...props }: TableProviderProps, ref: any) {

  const queryClient = useQueryClient();
  useImperativeHandle(ref, () => ({
    refresh() {
      queryClient.invalidateQueries({ queryKey: [`table_${props.id}`] });
    }
  }));

  // todo: concat with company id if available (different config per project ??? ask christophe)
  const localKey = `table_${props.id}`;

  const key = (name: string) => {
    return localKey + "_" + name;
  }

  const [totalItems, setTotalItems] = useState<number>(0);
  const [totalItemsDisplayed, setTotalItemsDisplayed] = useState<number>(0);

  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor | null>(null);
  const [displayedColumns, setColumns] = useState<Column[]>(columns);
  const [searchText, setSearchText] = useState<string>('');
  const [extraArgs, setExtraArgs] = useState<any>({});
  
  const [currentFilter, setCurrentFilter] = useState<Filter|undefined>();
  const [selectedFiltersValues, setFiltersValues] = useState<SelectedFilter[]|undefined>();

  const providerValue: TableProviderContextValue = {
    page,
    totalItems,
    totalItemsDisplayed,
    perPage,
    sortDescriptor,
    searchText,
    columns: displayedColumns,
    setPage,
    setTotalItems,
    setPerPage,
    setSortDescriptor,
    setColumns,
    setSearchText,
    setTotalItemsDisplayed,
    currentFilter,
    setCurrentFilter,
    extraArgs,
    setExtraArgs,
  };

  return (
    <TableQuery.Provider value={providerValue}>
      <TableUI {...props} />
    </TableQuery.Provider>
  );
}

export default forwardRef(Table);
