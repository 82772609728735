import { useTranslation } from "react-i18next";
import { memo } from "react";
import { Alert, Input, InputController } from "../core";
import { useFormContext } from "react-hook-form";

export interface Props {
  variant?: string;
}

const GeneralFilters = ({ variant = "default" }: Props) => {
  const { t } = useTranslation();
  const { register, control } = useFormContext();

  const border = variant === "light" ? "" : "border-x";
  const isLight = variant === "light";

  return (
    <div className={`flex bg-mr-cyan-100 text-mr-gray-900 ${border}`}>
      <div className="h-full w-full p-5">
        <InputController
          name="key_words"
          placeholder={t("SearchEnginePage.filters.general.searchByKeywords")}
          label={t("SearchEnginePage.filters.general.keyWords")}
        />
        <div className="mb-5 mt-1 flex flex-wrap items-center">
          <label className="flex cursor-pointer items-center gap-2 rounded p-1 text-sm hover:bg-mr-gray-100">
            <input
              className="rounded focus:outline-none"
              type="checkbox"
              {...register("is_last_name")}
            />
            {t("LastName")}
          </label>
          <label className="flex cursor-pointer items-center gap-2 rounded p-1 text-sm hover:bg-mr-gray-100">
            <input
              className="rounded outline-none"
              type="checkbox"
              {...register("is_first_name")}
            />
            {t("FirstName")}
          </label>
          <label className="flex cursor-pointer items-center gap-2 rounded p-1 text-sm hover:bg-mr-gray-100">
            <input
              className="rounded outline-none"
              type="checkbox"
              {...register("is_email")}
            />
            {t("Email")}
          </label>
          <label className="flex cursor-pointer items-center gap-2 rounded p-1 text-sm hover:bg-mr-gray-100">
            <input
              className="rounded outline-none"
              type="checkbox"
              {...register("is_pseudo")}
            />
            {t("SearchEnginePage.filters.general.pseudo")}
          </label>
          <label className="flex cursor-pointer items-center gap-2 rounded p-1 text-sm hover:bg-mr-gray-100">
            <input
              className="rounded outline-none"
              type="checkbox"
              {...register("is_hobbies")}
            />
            {t("SearchEnginePage.filters.general.hobbies")}
          </label>
          <label className="flex cursor-pointer items-center gap-2 rounded p-1 text-sm hover:bg-mr-gray-100">
            <input
              className="rounded outline-none"
              type="checkbox"
              {...register("is_job")}
            />
            {t("SearchEnginePage.filters.general.job")}
          </label>
          <label className="flex cursor-pointer items-center gap-2 rounded p-1 text-sm hover:bg-mr-gray-100">
            <input
              className="rounded outline-none"
              type="checkbox"
              {...register("is_talent")}
            />
            {t("SearchEnginePage.filters.general.talent")}
          </label>
        </div>
        <div>
          <div className="font-bold">
            {t("SearchEnginePage.filters.general.gender")}
          </div>
          <div className="flex items-center">
            <label className="flex cursor-pointer items-center gap-2 rounded p-1 text-sm hover:bg-mr-gray-100">
              <input
                className="rounded outline-none"
                type="checkbox"
                {...register("is_man")}
              />
              {t("SearchEnginePage.filters.general.m")}
            </label>
            <label className="flex cursor-pointer items-center gap-2 rounded p-1 text-sm hover:bg-mr-gray-100">
              <input
                className="rounded outline-none"
                type="checkbox"
                {...register("is_woman")}
              />
              {t("SearchEnginePage.filters.general.w")}
            </label>
          </div>
        </div>
      </div>
      {!isLight && (
        <div className="h-full w-full">
          <div className="m-5 rounded border bg-white p-5 shadow-lg">
            <div className="mb-5 flex items-center gap-3">
              <div className="font-bold">
                {t("SearchEnginePage.filters.general.youSearch")}
              </div>
              <label className="flex cursor-pointer items-center gap-2 rounded p-1 text-sm hover:bg-mr-gray-100">
                <input
                  className="rounded outline-none"
                  type="checkbox"
                  {...register("is_comedian")}
                />
                {t("Roles.Comedian")}
              </label>
              <label className="flex cursor-pointer items-center gap-2 rounded p-1 text-sm hover:bg-mr-gray-100">
                <input
                  className="rounded outline-none"
                  type="checkbox"
                  {...register("is_technician")}
                />
                {t("Roles.Technician")}
              </label>
            </div>
            <Alert className="mb-5">
              {t("SearchEnginePage.filters.general.youSearchInfo")}
            </Alert>
            <div className="border-t pt-5">
              <label className="flex cursor-pointer items-center gap-2 rounded p-1 text-sm hover:bg-mr-gray-100">
                <input
                  className="rounded outline-none"
                  type="checkbox"
                  {...register("is_only_new")}
                />
                {t("SearchEnginePage.filters.general.onlyNew")}
              </label>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(GeneralFilters);
