import { forwardRef, useImperativeHandle, useRef } from "react";
import { geExtractedFile } from "../../api/deposited_files";
import { Column } from "../../components/core/Table/TableUI";
import { useModal } from "../../components/modals";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import { useRequiredParams } from "../../hooks/useRequiredParams";
import { Cell, Table } from "../../components/core/Table";
import { AlertWarning } from "../../components/core";
import { Link, generatePath } from "react-router-dom";
import { BsFillTrash3Fill } from "react-icons/bs";
import { ROUTE_PATH } from "../../constants";
import { useQueryClient } from "@tanstack/react-query";



interface ExtractedFile {
    id: string;
    exported_files: string[];
    filename: string;
    createdAt: string;
    doctype: string;
    owner: string;
    numberOfExportedDocuments: number;
    fileId: string;
}


function ExtractedFileTable(props: any, ref: any) {

    const { companyId } = useRequiredParams();

    
    const columns: Column[] = [
        { id: "id", title: "ID", hidden: true, isSorting: false, className: "w-24" },
        { id: "file_type", title: "Fichier", isSorting: false },
        { id: "deposited_by", title: "Déposé par", isSorting: false },
        { id: "exported_files", title: "Documents exportés" },
        { id: "trash", title: "Supprimer", className: "w-12"},
    ];
    
    const queryFn = async () => {
        const ExtractedFile = await geExtractedFile(companyId);
        return ExtractedFile;
    };

    const [deleteModal, setOpenDeleteModal] = useModal(<ConfirmModal />);

    const handleDeleteExtractedFile = (id: string, fileId: string, fileName: string, numberOfExportedDocuments: number) => {
        const infos = numberOfExportedDocuments === 1 ? "Le document exporté des employés sera également supprimé." : `Les ${numberOfExportedDocuments} documents exportés des employés seront également supprimés.`;
        setOpenDeleteModal(true, 
        { id: id, 
        fileId: fileId, 
        fileName: fileName, 
        title:"SUPPRIMER TOUS LES DOCUMENTS EXPORTÉS", 
        content: "En confirmant, vous supprimerez le document ci-dessous ainsi que tous les documents exportés auxquels les employés ont accès ! Ils ne pourront plus y acccéder via leur profil. Cette opération est irréversible !",
        infos: infos,
        onConfirm: () => {
            if (ref.current) {
            // @ts-ignore
            ref.current.refresh();
            }
        }
        }); 
    }

    return (
    <>
        {deleteModal}
        <Table
            ref={ref}
            id="ExtractedFile"
            hideHeader
            columns={columns}
            queryFn={queryFn}
            keyExtractor={(item) => item.id}
            dataExtrator={(data) => data['hydra:member']}
            totalItemsExtractor={(data) => data['hydra:totalItems']}
            renderEmptyResult={() => (
            <AlertWarning className="mt-4">
                Vous n'avez pas encore déposé de fichiers
            </AlertWarning>
            )}
        >
            {(item: ExtractedFile) => {
            return (
                <>
                <Cell columnId="file_type" className={"text-left"}> 
                    Bulletin de salaire
                    <br/>
                    <Link to={generatePath(ROUTE_PATH.FILE, { fileId: item.fileId})} target="_blank" className="text-mr-cyan-400">{item.filename}</Link>
                </Cell>
                <Cell columnId="deposited_by" className={"text-left"}>
                    Déposé par <span className="text-mr-cyan-400">{item.owner}</span>
                    <br />
                    {`Le ${new Date(item.createdAt).toLocaleDateString("fr-FR", { day: '2-digit', month: '2-digit', year: 'numeric' })} 
                    à ${new Date(item.createdAt).toLocaleTimeString("fr-FR", { hour: '2-digit', minute: '2-digit' })}`}
                </Cell>
                <Cell columnId="exported_files">
                    {item.numberOfExportedDocuments === 1 ? (
                        `${item.numberOfExportedDocuments} document exporté`
                    ) : (
                        `${item.numberOfExportedDocuments} documents exportés`
                    )}
                </Cell>
                <Cell columnId="trash" className={"w-16"}>
                {/* <BsFillTrash3Fill onClick={() => handleDeleteExtractedFile(item.id)} /> */}
                <BsFillTrash3Fill onClick={() => handleDeleteExtractedFile(item.id, item.fileId, item.filename, item.numberOfExportedDocuments)} />
                </Cell>
                </>
            );
            }}
        </Table>
    </>
    )

}

export default forwardRef(ExtractedFileTable);