import React, { useState, useRef } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import AvatarEditor from 'react-avatar-editor'

import { HiOutlineX, HiUserCircle, HiUpload } from "react-icons/hi";
import { AlertWarning, FileSelector, SecondaryButton, TertiaryButton } from "../core";

export interface Props {
  className?: string;
  onSuccess?: () => void;
  selectedImage: string | null;
  setSelectedImage: React.Dispatch<React.SetStateAction<string | null>>;
  onSubmit: (imageUrl: string) => void;
  onClose: () => void;
  onZoomChange: (zoom: number) => void;
  onRotationChange: (rotation: number) => void;
}

type FormData = {
  file: FileList;
};

const ProfileImageEditor = ({
  className = "",
  selectedImage,
  setSelectedImage,
  onClose,
  onZoomChange,
  onRotationChange,
  onSubmit
}: Props) => {
  const { t } = useTranslation();
  const { register, handleSubmit, reset } = useForm<FormData>();
  const editor = useRef<AvatarEditor | null>(null);
  const [zoom, setZoom] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [fileSelectorKey, setFileSelectorKey] = useState(0);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);

  const handleImageSubmit = () => {
    if (editor.current) {
      const canvas = editor.current.getImage() as HTMLCanvasElement;
      const canvasScaled = editor.current.getImageScaledToCanvas() as HTMLCanvasElement;
      const previewDataURL = canvasScaled.toDataURL();
      setPreviewImage(previewDataURL);
      const imageUrl = canvas.toDataURL();
      setCroppedImage(imageUrl);
      onSubmit(imageUrl);
      setZoom(1);
      setRotate(0);
    }
  };

  const handleZoomChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newZoom = parseFloat(event.target.value);
    setZoom(newZoom);
    onZoomChange(newZoom);
  };

  const handleRotateLeft = () => {
    setRotate((prevRotate) => prevRotate - 90);
    onRotationChange(rotate - 90);
  };

  const handleRotateRight = () => {
    setRotate((prevRotate) => prevRotate + 90);
    onRotationChange(rotate + 90);
  };

  const handleDeleteImage = () => {
    setSelectedImage(null);
    setPreviewImage(null);
    setCroppedImage(null);
  };

  const handleFormSubmit: SubmitHandler<FormData> = (data) => {
    reset(data);
    setFileSelectorKey((prevKey) => prevKey + 1);
  };

  const handleImageSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        setErrorMessage("La dimension de votre photo est supérieure à 5 Mo.");
        setFileSelectorKey((prevKey) => prevKey + 1);
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          setCroppedImage(null); 
          setSelectedImage(reader.result as string);
          setErrorMessage(null);
          setPreviewImage(null);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  return (
    <div className={`flex flex-row ${className}`}>
      <div className="bg-mr-cyan-300 rounded-md" style={{ height: "250px" }}>
        {selectedImage ? (
          <AvatarEditor
            ref={editor}
            borderRadius={75}
            image={selectedImage}
            width={150}
            height={150}
            border={50}
            color={[210, 210, 210, 0.6]}
            scale={zoom}
            rotate={rotate}
          />
        ) : (
          <div className="w-150 h-150">
            <HiUserCircle className="h-60 w-60 text-white flex justify-center items-center" />
          </div>
        )}
      </div>
      <div className="flex flex-col">
        <p className="text-gray-900 ml-5">Sélectionnez votre photo de profil.</p>
        <p className="text-gray-900 ml-5">
          Format <strong>.jpg</strong> ou <strong>.png</strong>, 5Mo maximum.
        </p>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="flex flex-row">
            <FileSelector
              className="mt-5 ml-5"
              key={fileSelectorKey}
              label="Parcourir"
              {...register("file", { required: true })}
              onChange={handleImageSelect}
            />

            <TertiaryButton
              className="mt-5 ml-2 h-10 bg-red"
              startIcon={<HiOutlineX className="h-7 w-7" />}
              onClick={handleDeleteImage}
            >
              {t("Supprimer")}
            </TertiaryButton>
          </div>
        </form>

        {errorMessage && (
          <div className="mt-2 ml-5">
            <AlertWarning>{errorMessage}</AlertWarning>
          </div>
        )}

        <p className="rounded-t-md bg-mr-cyan-300 px-3 h-8 ml-5 mt-3 pt-1 text-white">
          <strong>Recadrage</strong>
        </p>
        <div className="flex flex-row items-center ml-5 bg-mr-cyan-200 px-3">
          <label htmlFor="zoom" className="mr-2 mt-3 font-bold">
            Zoom:
          </label>
          <input
            className="w-full mt-3 accent-mr-cyan-300 bg-white"
            id="zoom"
            type="range"
            min="1"
            max="5"
            step="0.01"
            value={zoom}
            onChange={handleZoomChange}
          />
        </div>
        <div className="flex flex-row justify-between ml-5 bg-mr-cyan-200 pt-3 pl-2 pb-2 rounded-b-md">
          <button
            className="mr-2 bg-mr-cyan-300 px-2 py-2 rounded-md font-bold"
            onClick={handleRotateLeft}
          >
            Rotation Gauche
          </button>
          <button
            className="mr-2 bg-mr-cyan-300 px-2 py-2 rounded-md font-bold"
            onClick={handleRotateRight}
          >
            Rotation Droite
          </button>
        </div>
        <div className="flex justify-center">
          <SecondaryButton
            startIcon={<HiUpload className="h-6 w-6" />}
            type="submit"
            size="big"
            className="mt-5"
            disabled={!selectedImage}
            onClick={handleImageSubmit}
          >
            {t("Enregistrer")}
          </SecondaryButton>
        </div>
      </div>
    </div>
  );
};

export default ProfileImageEditor;
