import type {TooltipProps} from 'react-aria-components';
import { Tooltip as AriaTooltip, OverlayArrow } from 'react-aria-components';
import './Tooltip.css';
import { twMerge } from 'tailwind-merge';

interface Props extends TooltipProps {
  children: React.ReactNode,
  className?: string
}
 
export function Tooltip({ children, ...props }: Props) {
  return (
    <AriaTooltip
      className={twMerge('react-aria-Tooltip', props.className)}
      {...props}>
      <OverlayArrow>
        <svg width={8} height={8} viewBox="0 0 8 8">
          <path d="M0 0 L4 4 L8 0" />
        </svg>
      </OverlayArrow>
      {children}
    </AriaTooltip>
  );
}