export const CUSTOM_QUESTION_FIXTURE: Field = {
  "@id": "/custom/0",
  id: 23,
  linked_to: "",
  company: "",
  mandatory: true,
  field_type: "/field_types/1",
  name: "name",
  short_name: "short_name",
  default_value: "default_value",
  placeholder: "placeholder",
  tooltip: "",
  notes: "note",
};

export const CUSTOM_QUESTIONS_FIXTURE: Field[] = [
  CUSTOM_QUESTION_FIXTURE,
  {
    ...CUSTOM_QUESTION_FIXTURE,
    "@id": "/custom/1",
  },
  {
    ...CUSTOM_QUESTION_FIXTURE,
    "@id": "/custom/2",
  },
];
