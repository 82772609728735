export const EYE_COLORS_FIXTURE: GetEyeColorsResponse = {
  "@context": "/contexts/EyeColor",
  "@id": "/eye_colors",
  "@type": "hydra:Collection",
  "hydra:member": [
    {
      "@id": "/eye_colors/1",
      "@type": "EyeColor",
      name: "green",
      hexCode: "#FFFFFF",
    },
    {
      "@id": "/eye_colors/2",
      "@type": "EyeColor",
      name: "blue",
      hexCode: "#FFFFFF",
    },
  ],
  "hydra:totalItems": 2,
};
