import { memo, useMemo } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import {
  HiOutlineCalendar,
  HiOutlineFilm,
  HiOutlineSpeakerphone,
  HiOutlineUser,
  HiOutlineUsers,
} from "react-icons/hi";
import { BiMoney } from "react-icons/bi";
import { BsVectorPen } from "react-icons/bs";
import { BsBriefcase, BsTags } from "react-icons/bs";
import { Trans, useTranslation } from "react-i18next";
import { IconType } from "react-icons";
import { PROJECT_CARD_POPOVER_MENU, ROLE } from "../../constants";
import { MenuPopover } from "../MenuPopover";
import { useAuth, useCommonOptions } from "../../hooks";
import { formatShort } from "../../utils/calendar";
import { AlertWarning, PrimaryButton } from "../core";

export interface Props {
  className?: string;
  project: Project;
  variant?: string;
  status?: string;
}

const ProjectCard = ({ className = "", project, variant = "default", status = "completed" }: Props) => {
  const { auth } = useAuth();
  const { t } = useTranslation();
  const projectId =
    project["@id"].split("/")[project["@id"].split("/").length - 1];
  const { conventions } = useCommonOptions();
  const tableData: [string, { label: string; text: string }][] = useMemo(
    () =>
      Object.entries({
        agreement: {
          label: t("ProjectCard.agreement"),
          text:
            conventions?.find((c) => c["@id"] === project.company.agreement)
              ?.name || "",
        },
        object_number: {
          label: t("ProjectCard.object_number"),
          text: project.object_number,
        },
        siret: {
          label: t("ProjectCard.siret"),
          text: project?.company.siret,
        },
        director: {
          label: t("ProjectCard.director"),
          text: project.director,
        },
        production_manager: {
          label: t("ProjectCard.production_manager"),
          text: project.production_manager,
        },
      }),
    [
      project.company.agreement,
      project.object_number,
      project.company.siret,
      project.director,
      project.production_manager,
      conventions,
    ]
  );

  const details: [string, { value: string; text?: string; Icon: IconType }][] =
    useMemo(
      () =>
        Object.entries({
          profile: {
            value: t("ProjectCard.profile.text", {
              count: project.profile || 0,
            }),
            text: t("ProjectCard.profile.end_text"),
            Icon: HiOutlineUsers,
          },
          employee: {
            value: t("ProjectCard.employee.text", {
              count: project.employee || 0,
            }),
            text: t("ProjectCard.employee.end_text", {
              count: project.employee || 0,
            }),
            Icon: HiOutlineUser,
          },
          shot: {
            value: t("ProjectCard.shot.text", { count: project.shot || 0 }),
            text: t("ProjectCard.shot.end_text"),
            Icon: HiOutlineSpeakerphone,
          },
          bulletin: {
            value: t("ProjectCard.bulletin.text", {
              count: project.bulletin || 0,
            }),
            Icon: BiMoney,
          },
          contract_in_progress: {
            value: t("ProjectCard.contract_in_progress.text", {
              count: project.contract_in_progress || 0,
            }),
            text: t("ProjectCard.contract_in_progress.end_text"),
            Icon: BsBriefcase,
          },
          contract_done: {
            value: t("ProjectCard.contract_done.text", {
              count: project.contract_done || 0,
            }),
            text: t("ProjectCard.contract_done.end_text", {
              count: project.contract_done || 0,
            }),
            Icon: BsBriefcase,
          },
          days_off: {
            value: t("ProjectCard.days_off.text", {
              count: project.days_off || 0,
            }),
            text: t("ProjectCard.days_off.end_text"),
            Icon: HiOutlineCalendar,
          },
          aem: {
            value: t("ProjectCard.aem.text", { count: project.aem || 0 }),
            Icon: BsTags,
          },
        }),
      [
        project.employee,
        project.profile,
        project.shot,
        project.bulletin,
        project.contract_in_progress,
        project.contract_done,
        project.days_off,
        project.aem,
      ]
    );

  // TODO use show keys for "dashboard" variant
  const showKeysDashboard = [1, 4, 5];

  const baseColor = project.label === 'Film' ? 'cyan' : 'orange';

  return (
    <div
      className={`${className} rounded-md bg-white shadow-sm`}
      data-testid="ProjectCard"
    >
      {/* HEADER */}
      <div className={`flex flex-row items-center justify-between rounded-t-md bg-mr-${baseColor}-400 p-4 text-white relative`}>
        <div className="flex items-center gap-3">
          <HiOutlineFilm className="h-6 w-6" />
          <span className="text-lg font-bold">{project.company.name}</span>
          <span className={`rounded-full bg-mr-${baseColor}-200/40 px-3 text-sm`}>
            {project.label}
          </span>
        </div>
        {variant === 'default' && (
          <div className="flex flex-row items-center gap-3">
            {project?.last_update && project?.last_update_user && (
              <span className="hidden text-xs sm:block">
                {t("ProjectCard.Update", {
                  date: project.last_update,
                  name: project.last_update_user,
                })}
              </span>
            )}

            {auth?.roles.find((role) => role === ROLE.CHARGE_PRODUCTION || role === 'ROLE_USER') && (
              <MenuPopover
                className={`rounded-full bg-white p-2 text-mr-${baseColor}-400 shadow-md hover:text-gray-900`}
                list={PROJECT_CARD_POPOVER_MENU({
                  projectId,
                  companyId: project.company.id || "",
                })}
                Icon={AiOutlineMenu}
                popoverProps={{
                  className: "",
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                }}
              />
            )}
          </div>
        )}

        {variant === 'dashboard' && (
          <div className={`
            bg-mr-${baseColor}-500 absolute right-0 top-0 bottom-0 rounded-tr-md flex items-center pl-6 pr-4
            before:w-2 before:h-2 before:absolute before:-left-1 before:rotate-45 before:bg-mr-${baseColor}-500`}>
            <div>
              <div className="text-sm leading-none">{t("ProjectCard.status")}</div>
              <div className="font-bold leading-tight">{t(`ProjectCard.status_${status}`)}</div>
            </div>
          </div>
        )}
      </div>
      {/* HEADER END */}

      {/* CONTENT */}
      {variant === 'dashboard' && (
        <div className={`flex items-center bg-mr-${baseColor}-100 text-mr-${baseColor}-400 font-bold p-4`}>
          <HiOutlineCalendar className="h-5 w-5 font-normal" />
          <div className="ml-2">
            <Trans
              i18nKey={"ProjectCard.shooting_date"}
              values={{
                "start_shooting": formatShort(new Date(project.start_shooting), true),
                "end_shooting": formatShort(new Date(project.end_shooting), true),
                "interpolation": { escapeValue: false }
              }}>
              Tournage du <span className={`text-mr-${baseColor}-500`}>start_shooting</span> au <span className={`text-mr-${baseColor}-500`}>end_shooting</span>
            </Trans>
          </div>
        </div>
      )}

      <div className="rounded-b-md p-7">
        {variant === 'default' && (
          <div className="mb-5 grid grid-flow-row grid-rows-3 gap-x-6 divide-y rounded md:grid-flow-col">
            {tableData &&
              tableData.map(([_, { label, text }], key) => (
                <div
                  key={key}
                  className="flex gap-1 items-center bg-gray-100 py-1 px-5"
                >
                  <span className="col-span-1 text-gray-500">{label}</span>
                  <span className="col-span-2">{text}</span>
                </div>
              ))}
          </div>
        )}

        <ul className={`${variant === 'dashboard' ? 'mb-5' : 'my-5'} grid gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4`}>
          {details &&
            details.map(([_, { Icon, value, text }], key) => {

              if (variant === 'dashboard' && showKeysDashboard.indexOf(key) < 0) return;

              return (
                <li
                  key={key}
                  className="flex flex-row items-center gap-2 break-words text-gray-500"
                >
                  <span className={`object-cover p-2 rounded-full border-4 border-mr-${baseColor}-300/50`}>
                    <Icon className="h-5 w-5" />
                  </span>
                  <div className="text-sm leading-tight flex flex-col">
                    <span className={`font-bold text-mr-${baseColor}-400 text-sm leading-tight`}>{value} </span>
                    {text && text}
                  </div>
                </li>
              )
            }
            )}
        </ul>

        {/* TODO : check that project.contract_in_progress is the correct variable here */}
        {variant === 'dashboard' && project.contract_in_progress > 0 && (
          <AlertWarning className="flex items-center mt-4">
            <div className="grow flex items-center justify-between">
              {t('ProjectCard.number_contracts', { count: project.contract_in_progress })}
              <PrimaryButton
                href="#"
                startIcon={<BsVectorPen className="h-5 w-5" />}
                size="medium">
                {t('ProjectCard.sign_contracts', { count: project.contract_in_progress })}
              </PrimaryButton>
            </div>
          </AlertWarning>
        )}
      </div>
      {/* CONTENT END */}

      {/* Invisible div to force add tailwind dynamic classes */}
      <div className="hidden border-mr-cyan-300/50 border-mr-orange-300/50 bg-mr-orange-200/40 bg-mr-cyan-200/40 before:bg-mr-orange-500 before:bg-mr-cyan-500"></div>
    </div>
  );
};

export default memo(ProjectCard);
