import { AUTH_END_POINT } from "../constants";
import { axiosPrivate } from "./axios";

export async function getUrssafCenters() {
  const response = await axiosPrivate.get<GetUrssafCenterResponse>(
    AUTH_END_POINT.URSSAF_CENTERS
  );

  return response.data["hydra:member"];
}

export async function getCaisseCongesSpectacles() {
  const response = await axiosPrivate.get<GetCaisseCongesSpectaclesResponse>(
    AUTH_END_POINT.CAISSE_CONGES_SPECTACLE
  );

  return response.data["hydra:member"];
}

export async function getCompanyTypes() {
  const response = await axiosPrivate.get<GetCompanyTypesResponse>(
    AUTH_END_POINT.COMPANY_TYPES
  );

  return response.data["hydra:member"];
}

export async function getBillingFrequency() {
  const response = await axiosPrivate.get<GetBillingFrequencyResponse>(
    AUTH_END_POINT.BILLING_FREQUENCIES
  );

  return response.data["hydra:member"];
}

export async function getConventions() {
  const response = await axiosPrivate.get<GetConventionsResponse>(
    AUTH_END_POINT.CONVENTIONS
  );

  return response.data["hydra:member"];
}

export async function getStreetSuffixes() {
  const response = await axiosPrivate.get<GetStreetSuffixResponse>(
    AUTH_END_POINT.STREET_SUFFIXES
  );

  return response.data["hydra:member"];
}

export async function getFieldTypes() {
  const response = await axiosPrivate.get<GetFieldTypesResponse>(
    AUTH_END_POINT.FIELD_TYPES
  );

  return response.data["hydra:member"];
}

export async function getCivilities() {
  const response = await axiosPrivate.get<GetCivilitiesResponse>(
    AUTH_END_POINT.CIVILITIES
  );

  return response.data["hydra:member"];
}

export async function getLegalEntityTypes() {
  const response = await axiosPrivate.get<GetLegalEntityTypeResponse>(
    AUTH_END_POINT.LEGAL_ENTITY_TYPES
  );

  return response.data["hydra:member"];
}

export async function getCountries() {
  const response = await axiosPrivate.get<GetCountriesResponse>(
    AUTH_END_POINT.COUNTRIES
  );

  return response.data["hydra:member"];
}

export async function getFamilySituations() {
  const response = await axiosPrivate.get<GetFamilySituationsResponse>(
    AUTH_END_POINT.FAMILY_SITUATIONS
  );

  return response.data["hydra:member"];
}

export async function getEyeColors() {
  const response = await axiosPrivate.get<GetEyeColorsResponse>(
    AUTH_END_POINT.EYE_COLORS
  );

  return response.data["hydra:member"];
}

export async function getFamilyRelationships() {
  const response = await axiosPrivate.get<GetFamilyRealtionshipsResponse>(
    AUTH_END_POINT.FAMILY_RELATIONSHIPS
  );

  return response.data["hydra:member"];
}
export async function getEthnicities() {
  const response = await axiosPrivate.get<GetEthnicitiesResponse>(
    AUTH_END_POINT.ETHNICITIES
  );

  return response.data["hydra:member"];
}

export async function getHairColors() {
  const response = await axiosPrivate.get<GetHairColorsResponse>(
    AUTH_END_POINT.HAIR_COLORS
  );

  return response.data["hydra:member"];
}

export async function getHairTypes() {
  const response = await axiosPrivate.get<GetHairTypesResponse>(
    AUTH_END_POINT.HAIR_TYPES
  );

  return response.data["hydra:member"];
}

export async function getHairLengths() {
  const response = await axiosPrivate.get<GetHairLengthsResponse>(
    AUTH_END_POINT.HAIR_LENGTHS
  );

  return response.data["hydra:member"];
}

export async function getColors() {
  const response = await axiosPrivate.get<GetColorsResponse>(
    AUTH_END_POINT.COLORS
  );

  return response.data["hydra:member"];
}

export async function getCommunes() {
  const response = await axiosPrivate.get<GetCommunesResponse>(
    AUTH_END_POINT.COMMUNES
  );

  return response.data["hydra:member"];
}
