import React, { memo } from "react";
import { Control, Controller } from "react-hook-form";
import ToggleButton, { Props as ToggleButtonProps } from "./ToggleButton";

export interface Props extends ToggleButtonProps {
  control: Control<any>;
  name: string;
}

const ToggleButtonController = ({ control, name, ...props }: Props) => {
  return (
    <Controller
      name={name}
      render={({ field }) => (
        <ToggleButton
          defaultSelected={field.value}
          {...(props as ToggleButtonProps)}
          {...field}
        />
      )}
    />
  );
};

export default memo(ToggleButtonController);
