import { ReactNode } from "react";
import { Link as LinkRouter, LinkProps } from "react-router-dom";

export interface Props extends LinkProps {
  startIcon?: ReactNode;
  endIcon?: ReactNode;
}

const Link = ({ to, className, children, startIcon, endIcon }: Props) => (
  <LinkRouter
    className={`flex items-center justify-start gap-1 text-blue-500 hover:underline ${className}`}
    to={to}
    data-testid="Link"
  >
    {startIcon && startIcon}
    {children}
    {endIcon && endIcon}
  </LinkRouter>
);

export default Link;
