import { HiInformationCircle } from 'react-icons/hi';
import { InfoIcon } from './InfoIcon';
import { twMerge } from 'tailwind-merge';
import {
    FieldError,
    Label as AriaLabel,
    LabelProps,
} from 'react-aria-components';
import cn from 'mxcn';

interface Props extends LabelProps {
    inline?: boolean;
}

const Label = ({ className, children, inline = false, ...props }: Props) => {
    return (
        <AriaLabel
            className={cn(
                'text-sm w-fit text-mr-gray-500',
                className,
                inline && 'flex-row gap-2'
            )}
            {...props}
            data-testid="Label"
        >
            {children}
        </AriaLabel>
    );
};

Label.Title = ({ disabled, required, children, info, className }: any) => {
    return (
        <span className={cn('flex w-fit', className)}>
            <span
                className={`mb-2 block font-bold leading-tight text-mr-gray-900 ${
                    disabled ? 'opacity-50' : ''
                }`}
            >
                {children}
                {required && <span className="text-mr-orange-400"> *</span>}
            </span>

            {info && <InfoIcon text={info} />}
        </span>
    );
};

Label.Error = ({ className, children }: any) => {
    return (
        <span
            className={cn('mt-2 text-xs italic text-mr-orange-400', className)}
            data-testid="InputErrorLabel"
        >
            {children}
        </span>
    );
};

Label.Note = ({ children }: any) => {
    return (
        <span
            className="mt-1 flex items-center gap-1 text-xs font-light text-gray-500"
            data-testid="note"
        >
            <HiInformationCircle className="h-5 w-5" />
            {children}
        </span>
    );
};

export interface LabelWrapperProps extends Props {
    label?: string;
    info?: string;
    labelClass?: string;
    errorLabel?: string;
    note?: string;
    required?: boolean;
    disabled?: boolean;
    inline?: boolean;
    labelsContainerClassName?: string;
}

Label.Wrapper = ({
    children,
    title,
    info,
    labelClass,
    errorLabel,
    note,
    required,
    disabled,
    labelsContainerClassName,
    inline,
}: LabelWrapperProps) => {
    return (
        <Label className={labelsContainerClassName} inline={inline}>
            {title && (
                <Label.Title
                    disabled={disabled}
                    required={required}
                    className={labelClass}
                    info={info}
                >
                    {title}
                </Label.Title>
            )}
            {children}

            <Label.Error>{errorLabel && errorLabel}</Label.Error>
            {note && <Label.Note>{note}</Label.Note>}
        </Label>
    );
};

export default Label;
