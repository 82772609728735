import { useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";
import LoginModal from "../recoil/LoginModal";
import { useAuth } from "./";

export function useLoginModal() {
  const [{ open: isLoginModalOpen }, setLoginModal] =
    useRecoilState(LoginModal);
  const { auth } = useAuth();

  useEffect(() => {
    auth && closeLoginModal();
  }, [auth]);

  const closeLoginModal = useCallback(() => {
    setLoginModal({ open: false });
  }, []);

  const openLoginModal = useCallback(() => {
    !auth && setLoginModal({ open: true });
  }, []);

  return { isLoginModalOpen, setLoginModal, closeLoginModal, openLoginModal };
}
