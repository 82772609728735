import {
  Children,
  cloneElement,
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  memo,
  ReactElement,
} from "react";
import { twMerge } from "tailwind-merge";
import Label from "../Label";

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  groupClass?: string;
  labelClass?: string;
  label?: string;
  info?: string;
  note?: string;
  errorLabel?: string;
  unit?: string;
  children?: React.ReactNode;
  variant?: {
    size?: "sm" | "md";
  };
}

const Input = (
  {
    className = "",
    groupClass = "",
    labelClass = "",
    info,
    note,
    label,
    errorLabel,
    unit = "",
    children,
    variant = { size: "md" },
    ...props
  }: Props,
  ref: ForwardedRef<HTMLInputElement>
) => (
  <Label className={groupClass} data-testid="Input">
    {label && (
      <Label.Title
        info={info}
        required={props.required}
        disabled={props.disabled}
      >
        {label}
      </Label.Title>
    )}

    {/* {children && (
        <div
          className={`flex items-center rounded border ${
            errorLabel ? "border-red-500" : "border-mr-gray-1000"
          } ${props.type === "number" ? "bg-mr-gray-100" : ""}`}
        >
          <input
            className={`${className} block w-full appearance-none rounded px-5 py-2 focus:outline-none disabled:cursor-not-allowed disabled:opacity-50 ${
              errorLabel ? "border-red-500" : "border-mr-gray-1000"
            } ${
              props.type === "number"
                ? "border-b-transparent border-l-transparent border-t-transparent"
                : ""
            }`}
            ref={ref}
            type={props.type}
            autoComplete="nope"
            {...props}
          />
          {props.type === "number" && (
            <span data-testid="InputUnit" className="mx-auto my-0 block px-6">
              {unit}
            </span>
          )}
          {cloneElement(children as ReactElement, {
            className: `${
              (children as ReactElement).props.className &&
              (children as ReactElement).props.className
            } ${errorLabel ? "border-red-500" : "border-mr-gray-500"}`,
          })}
        </div>
      )} */}


    <div
      className={`flex rounded ${errorLabel ? "border-red-500" : "border-mr-gray-1000"
        } ${props.type === "number" ? "border-mr-gray-1000 " : ""}`}
    >
      <input
        className={twMerge(
          "block w-full appearance-none rounded border focus:outline-none disabled:cursor-not-allowed disabled:opacity-50",
          (props.type === "number" &&
            unit.length > 0 || Children.count(children) > 0) &&
          "rounded-br-none rounded-tr-none border-b-transparent border-l-transparent border-t-transparent",
          variant.size === "md" && "min-h-[46px] px-5 py-2 text-base",
          variant.size === "sm" && "h-8 px-3 text-sm leading-8",
          errorLabel ? "border-red-500" : "border-mr-gray-1000",
          className
        )}
        ref={ref}
        type={props.type}
        autoComplete="nope"
        {...props}
      />
      {props.type === "number" && unit.length > 0 && (
        <span
          data-testid="InputUnit"
          className="mx-auto my-0 flex items-center rounded-br rounded-tr border border-l-0 border-mr-gray-500 bg-mr-gray-100 bg-opacity-40 px-4 text-base"
        >
          {unit}
        </span>
      )}
      {children}
    </div>


    {errorLabel && <Label.Error>{errorLabel}</Label.Error>}
    {note && <Label.Note>{note}</Label.Note>}
  </Label>
);

export default memo(forwardRef(Input));
