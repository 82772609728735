import { PostProcessorModule, TOptions } from "i18next";

const addNbspAroundPunctuation = (str: string): string =>
  str.replace(/\s+([:;»!?/])|([«])\s+/g, (_, left: string, right: string): string => {
    if (left) {
      return `\xa0${left}`;
    }
    if (right) {
      return `${right}\xa0`;
    }

    return str;
  });

const addNbspAroundPunctuationPostProcessor: PostProcessorModule = {
  type: 'postProcessor',
  name: 'addNbspAroundPunctuationPostProcessor',
  process: function (value: string, key: string, options: TOptions<any>, translator: any): string {
    return addNbspAroundPunctuation(value);
  }
}

export default addNbspAroundPunctuationPostProcessor;