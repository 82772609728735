
export function convertTimeToPercent(time: number) {
  const base = 1;
  return time * 4;
}

export function convertPercentToTime(percent: number) {
  const base = 1;
  return percent / 4;
}

export function convertValueToTime(value: number, cumulative = false, short = false, sep = ':') {
  const time = value / 4;

  let hours = Math.floor(time);
  let minutes = Math.round((time - hours) * 60);

  if (!cumulative) {
    if (hours >= 24) {
      hours = Math.abs(hours - 24);
    }
  }

  if (short) {
    if (hours < 1) {
      return minutes + ' min';
    } else {
      return (hours) + sep + (minutes > 0 ? minutes: '00');
    }
  }
 

  return (hours < 10 ? '0' + hours: hours) + sep + (minutes > 0 ? minutes: '00');
}

//@ts-ignore
window.convertValueToTime = (value: number) => {
  return convertValueToTime(value);
}



export function formatShort(date: Date, fullDate:boolean = false) {
  let options: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit' };
  if ( fullDate ) options = { day: '2-digit', month: '2-digit', year: 'numeric'};
  const formattedDate = date.toLocaleDateString('fr-FR', options);
  return formattedDate;
}

export function getDaysForWeek(week: number, year: number) {
  var date = new Date(year, 0, 1);
  var dayOfWeek = date.getDay();
  var daysInFirstWeek = 7 - dayOfWeek;
  var dayOfYear = (week - 1) * 7 + daysInFirstWeek;
  date.setDate(dayOfYear);
  
  var days = [];
  for (var i = 0; i < 7; i++) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  
  return days;
}


export function getWeekNumber(d: any) {
  // Copy date so don't modify original
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
  // Get first day of year
  var yearStart: any = new Date(Date.UTC(d.getUTCFullYear(),0,1));
  // Calculate full weeks to nearest Thursday
  var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
  // Return array of year and week number
  return [d.getUTCFullYear(), weekNo];
}
