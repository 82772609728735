import { atom } from "recoil";


export interface DebugOptionsAtom {
  floatingMenu: boolean;
  autoSave: boolean;
  persistantLogin: boolean
}

export const defaultState: DebugOptionsAtom = {
  floatingMenu: false,
  autoSave: false,
  persistantLogin: false
};


// const syncStorageEffect = (number:userID) => ({setSelf, trigger}) => {
//   // Initialize atom value to the remote storage state
//   if (trigger === 'get') { // Avoid expensive initialization
//     setSelf(myRemoteStorage.get(userID)); // Call synchronously to initialize
//   }

//   // Subscribe to remote storage changes and update the atom value
//   myRemoteStorage.onChange(userID, userInfo => {
//     setSelf(userInfo); // Call asynchronously to change value
//   });

//   // Cleanup remote storage subscription
//   return () => {
//     myRemoteStorage.onChange(userID, null);
//   };
// };

//@ts-ignore
const localStorageEffect = key => ({setSelf, onSet}) => {
;  const savedValue = localStorage.getItem(key)
  if (savedValue != null) {
    setSelf(JSON.parse(savedValue));
  }
  //@ts-ignore
  onSet(newValue => {
    localStorage.setItem(key, JSON.stringify(newValue));
  });
};



export const debugOptionAtom = atom<DebugOptionsAtom>({
  key: "debugOptionsAtom",
  default: defaultState,
  // @ts-ignore
  effects_UNSTABLE: [
    localStorageEffect('debug_options'),
  ]
});