export const ADDRESS_FIXTURE: Address = {
  "@context": "string",
  "@id": "string",
  "@type": "string",
  id: 1,
  address_name: "address_name",
  country: "/countries/1",
  city: "city",
  postal_code: 73000,
  street_type: "street_type",
  street_suffix: "/street_suffixes/2",
  street_name: "street_name",
  street_number: "62",
  address_supplement_1: "address_supplement_1",
  address_supplement_2: "address_supplement_2",
  commune: "/communes/1",
};
