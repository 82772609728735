import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";
import {
  fetchFields,
  fetchField,
  patchField,
  postFields,
  removeFields,
} from "../api";

export function useFields(companyId: string, fieldId?: string) {
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey: ["fields"],
    queryFn: () => fetchFields(companyId as string),
    enabled: Boolean(companyId),
  });

  const fieldQuery = useQuery({
    queryKey: ["fields", fieldId],
    queryFn: () =>
      fetchField({ companyId: companyId as string, id: fieldId as string }),
    enabled: Boolean(fieldId) && Boolean(companyId),
  });

  const patch = useMutation({
    mutationFn: (newField: PatchFieldsParams) => patchField(newField),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["fields"] });
      queryClient.invalidateQueries({ queryKey: ["company_parameters"] });
    },
  });

  const post = useMutation({
    mutationFn: (newField: PostFieldsParam) =>
      postFields(companyId as string, newField),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["fields"] });
      queryClient.invalidateQueries({ queryKey: ["company_parameters"] });
    },
  });

  const remove = useMutation({
    mutationFn: (id: string) =>
      removeFields({ companyId: companyId as string, id: id as string }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["fields"] });
      queryClient.invalidateQueries({ queryKey: ["company_parameters"] });
    },
  });

  return {
    fields: query.data,
    fieldQuery,
    query,
    patch,
    post,
    remove,
    isLoading: patch.isPending || post.isPending || remove.isPending,
    isError: patch.isError || post.isError || remove.isError,
    isSuccess: patch.isSuccess || post.isSuccess || remove.isSuccess,
  };
}
