import { Button } from "react-aria-components";

interface Props {
  onPress?: () => void;
  children: React.ReactNode;
  isActive?: boolean;
  activeColor?: string;
}

function PaginationNavigationButton({ onPress = () => {}, children, isActive = false, activeColor = '#207263' }: Props) {
  return (
    <Button
      className="w-8 h-8 bg-white flex items-center justify-center border-4 rounded-full p-0"
      onPress={onPress}
    >
     {children}
    </Button>
  );
}


export default PaginationNavigationButton;
