import { useLocation, Navigate, Outlet } from "react-router-dom";
import { ROUTE_PATH } from "../../constants";
import { useAuth } from "../../hooks";

const RequireAuth = ({ isLoading }: { isLoading: boolean }) => {
  const { auth } = useAuth();
  const location = useLocation();

  return isLoading ? (
    <div className="absolute inset-0 h-screen w-screen cursor-wait bg-gray-500/70"></div>
  ) : auth ? (
    <Outlet />
  ) : (
    <Navigate to={ROUTE_PATH.LOGIN} state={{ from: location }} replace />
  );
};

export default RequireAuth;
