import { API_BASE_URL, AUTH_END_POINT } from "../constants";
import { getIdByIriSelector } from "../utils/selectors";
import { axiosPrivate, axiosPrivatePatch } from "./axios";

export async function fetchTags() {
  const { data } = await axiosPrivate.get<GetTagsResponse>(
    `${API_BASE_URL}${AUTH_END_POINT.TAGS}`
  );

  return data["hydra:member"];
}

export async function fetchTag(id: string) {
  const { data } = await axiosPrivate.get<Tag>(
    `${API_BASE_URL}${AUTH_END_POINT.TAGS}/${id}`
  );

  return data;
}

export async function patchTag(tag: Tag) {
  const id = getIdByIriSelector(tag["@id"]);
  const response = await axiosPrivatePatch.patch<Tag>(
    `${API_BASE_URL}${AUTH_END_POINT.TAGS}/${id}`,
    tag
  );

  return response.data;
}

export async function postTag(tag: Tag) {
  const response = await axiosPrivate.post<Tag>(
    `${API_BASE_URL}${AUTH_END_POINT.TAGS}`,
    tag
  );

  return response.data;
}

export async function removeTag(id: string) {
  const response = await axiosPrivate.delete(
    `${API_BASE_URL}${AUTH_END_POINT.TAGS}/${id}`
  );

  return response.data;
}
