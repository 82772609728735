import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../hooks";
import { memo } from "react";
import { Alert } from "../../components/core";
import { CompanyHeader } from "../../components/CompanyHeader";

export interface Props { }

const DashboardUserPage = ({ }: Props) => {
  const { t } = useTranslation();
  const { auth } = useAuth();

  return (
    <>
      <Helmet>
        <title>{`${t("DashboardComedian.pageTitle")} - ${t("MyRole")}`}</title>
      </Helmet>


      <CompanyHeader
        className="-mt-5 mb-7"
        title={t("DashboardComedian.pageTitle")}
        role={""}
        companyName="Indiana Jones"
        productionName="Jones prod"
        isProject
        IconFilm={false}
      />
      <Alert>
        <p>{t("DashboardComedian.dashboardText")}</p>
      </Alert>


    </>
  );
};

export default memo(DashboardUserPage);
