import { atom } from "recoil";

interface ProfilePictureModal {
  open: boolean;
}

const ProfilePictureModal = atom<ProfilePictureModal>({
  key: "ProfilePictureModal",
  default: { open: false },
});

export default ProfilePictureModal;
