import { HiCog } from "react-icons/hi";
import { MenuItem } from "../types/menu";

export const MENU_ITEM_FIXTURE: MenuItem = {
  i18nKey: "i18nKey",
  Icon: HiCog,
  to: "",
};

export const MENU_ITEMS_FIXTURE: MenuItem[] = [
  MENU_ITEM_FIXTURE,
  MENU_ITEM_FIXTURE,
  MENU_ITEM_FIXTURE,
  MENU_ITEM_FIXTURE,
];
