import { memo } from "react";
import Button, { Props } from "./Button";
import { twMerge } from "tailwind-merge";

const PrimaryButton = ({ children, className = "", ...props }: Props) => (
  <Button
    className={twMerge(
      "bg-mr-orange-400 text-gray-50 hover:enabled:bg-mr-orange-500 disabled:cursor-not-allowed disabled:opacity-50 focus:ring-0 focus:outline-none border border-mr-orange-400 focus:border-black",
      className,
    )}
    data-testid="PrimaryButton"
    {...props}
  >
    {children}
  </Button>
);

export default memo(PrimaryButton);
