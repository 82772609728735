import { faker } from "@faker-js/faker";
import { ADDRESS_FIXTURE } from "./address";
import { CIVILITIES_FIXTURE } from "./civilities";
import { COUNTRIES_FIXTURE } from "./countries";

export const USER_PROFILE_FIXTURE: Profile = {
  civility: CIVILITIES_FIXTURE["hydra:member"][0]["@id"],
  first_name: "first_name",
  last_name: "last_name",
  email: "email@email.com",
  birth_name: "birth_name",
  phone: "phone",
  birthday: "1999-12-31",
  birth_country: COUNTRIES_FIXTURE["hydra:member"][0]["@id"],
  nationality: COUNTRIES_FIXTURE["hydra:member"][0]["@id"],
  birth_city: "paris",

  fiscal_address: ADDRESS_FIXTURE,
  fiscal_country: COUNTRIES_FIXTURE["hydra:member"][0]["@id"],

  social_security_number: "012345678901234",
  social_security_center_address: "1 road of Paris 12345 Paris",

  emergency_contact_name: "first_name last_name",
  emergency_contact_phone: "0606060606",

  family_situation: "Married",
  number_of_children: 2,
  number_of_dependents: 1,
  retirement_date: "01/01/0101",

  legal_rep_1_family_relationship: "Père",
  legal_representative_1: {
    first_name: "Jon",
    last_name: "Doe",
    phone: "0606060606",
    email: "jhon@doe.com",
    profile_picture: faker.image.avatar(),
  },

  legal_rep_2_family_relationship: "Père",
  legal_representative_2: {
    first_name: "Jona",
    last_name: "Doea",
    phone: "0707070707",
    email: "jona@doea.com",
    profile_picture: faker.image.avatar(),
  },
};
