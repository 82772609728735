import useTableContext from "./useTableContext";
import BasicPagination from "../Pagination/Pagination";

function Pagination() {
  const { setPage, page, perPage, totalItems } = useTableContext();

  if (totalItems < 1) {
    return null;
  }

  return (
    <BasicPagination
      totalItems={totalItems}
      pageSize={perPage}
      currentPage={page}
      onPageChange={setPage}
    />
  );
}

export default Pagination;
