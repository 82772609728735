import { ReactNode, memo } from "react";
import {TabList as AriaTabList} from 'react-aria-components';


export interface Props {
  ariaLabel?: string;
  children?: ReactNode;
}


const TabList = ({ ariaLabel="tab-list", children }: Props) => {
  return (
    <AriaTabList aria-label={ariaLabel}>
      {children}
    </AriaTabList>
  );
}



export default memo(TabList);
