import { useAuth } from "./";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getProfile, postProfile } from "../api";

export function useProfile() {
  const { auth } = useAuth();
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: ["profile"],
    queryFn: () => getProfile(auth?.id as string),
    enabled: Boolean(auth?.id),
  });

  const post = useMutation({
    mutationFn: (newProfile: Profile) =>
      postProfile(auth?.id as string, newProfile),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["profile"] });
    },
  });

  return { profile: query.data, query, post };
}
