import { OptionHTMLAttributes, useCallback, useMemo } from "react";
import { useQueries } from "@tanstack/react-query";
import {
  getUrssafCenters,
  getCaisseCongesSpectacles,
  getCompanyTypes,
  getBillingFrequency,
  getConventions,
  getStreetSuffixes,
  getFieldTypes,
  getCivilities,
  getLegalEntityTypes,
  getCountries,
  getFamilySituations,
  getEyeColors,
  getFamilyRelationships,
  getEthnicities,
  getHairColors,
  getHairTypes,
  getHairLengths,
  getColors,
  getCommunes,
} from "../api/common_options";
import { useTranslation } from "react-i18next";

const APE_CODES: ApeCode[] = [
  { id: "5911C", name: "5911C" },
  { id: "5911A", name: "5911A" },
];

export function useCommonOptions(): CommonOptions {
  const { t } = useTranslation();

  // ----------------------------------
  // -            QUERIES
  // ----------------------------------
  const ape_codes = APE_CODES;
  const [
    urssaf_centers,
    caisse_conges_spectacles,
    company_types,
    legal_entity_types,
    billing_frequencies,
    conventions,
    street_suffixes,
    civilities,
    field_types,
    countries,
    family_situation,
    family_relationships,
    eye_colors,
    ethnicities,
    hair_colors,
    hair_types,
    hair_lengths,
    colors,
    communes,
  ] = useQueries({
    queries: [
      {
        queryKey: ["urssaf_centers"],
        queryFn: getUrssafCenters,
      },
      {
        queryKey: ["caisse_conges_spectacles"],
        queryFn: getCaisseCongesSpectacles,
      },
      {
        queryKey: ["company_types"],
        queryFn: getCompanyTypes,
      },
      {
        queryKey: ["legal_entity_types"],
        queryFn: getLegalEntityTypes,
      },
      {
        queryKey: ["billing_frequencies"],
        queryFn: getBillingFrequency,
      },
      {
        queryKey: ["conventions"],
        queryFn: getConventions,
      },
      {
        queryKey: ["street_suffixes"],
        queryFn: getStreetSuffixes,
      },
      {
        queryKey: ["civilities"],
        queryFn: getCivilities,
      },
      {
        queryKey: ["field_types"],
        queryFn: getFieldTypes,
      },
      {
        queryKey: ["countries"],
        queryFn: getCountries,
      },
      {
        queryKey: ["family_situation"],
        queryFn: getFamilySituations,
      },
      {
        queryKey: ["family_relationships"],
        queryFn: getFamilyRelationships,
      },
      {
        queryKey: ["eye_colors"],
        queryFn: getEyeColors,
      },
      {
        queryKey: ["ethnicities"],
        queryFn: getEthnicities,
      },
      {
        queryKey: ["hair_colors"],
        queryFn: getHairColors,
      },
      {
        queryKey: ["hair_types"],
        queryFn: getHairTypes,
      },
      {
        queryKey: ["hair_lengths"],
        queryFn: getHairLengths,
      },
      { queryKey: ["colors"], queryFn: getColors },
      {
        queryKey: ["communes"],
        queryFn: getCommunes,
      },
    ],
  });

  // ----------------------------------
  // -            OPTIONS
  // ----------------------------------
  const getOptions = (
    options: { "@id": string; name: string }[] | undefined
  ): OptionHTMLAttributes<HTMLOptionElement>[] =>
    options
      ? options.map((opt) => ({
        value: opt["@id"],
        label: opt.name,
      }))
      : [];

  const urssaf_centers_options = useMemo(
    () => getOptions(urssaf_centers.data),
    [urssaf_centers.data]
  );

  const caisse_conges_spectacles_options = useMemo(
    () => getOptions(caisse_conges_spectacles.data),
    [caisse_conges_spectacles.data]
  );

  const legal_entity_types_options = useMemo(
    () => getOptions(legal_entity_types.data),
    [legal_entity_types.data]
  );

  const billing_frequencies_options = useMemo(
    () => getOptions(billing_frequencies.data),
    [billing_frequencies.data]
  );

  const convention_options = useMemo(
    () => getOptions(conventions.data),
    [conventions.data]
  );

  const company_types_options = useMemo(
    () => getOptions(company_types.data),
    [company_types.data]
  );

  const street_suffixes_options = useMemo(
    () => [{ value: "", label: "" }, ...getOptions(street_suffixes.data)],
    [street_suffixes.data]
  );

  const civilities_options = useMemo(
    () =>
      civilities.data
        ? civilities.data.map((civility: any) => ({
          value: civility["@id"],
          label: t(`civility.${civility.value}`),
        }))
        : [],
    [civilities.data]
  );

  const field_types_options = useMemo(
    () =>
      field_types.data
        ? field_types.data.map(({ value, ...fieldType }) => ({
          value: fieldType["@id"],
          label: value,
        }))
        : [],
    [field_types.data]
  );

  const countries_options = useMemo(
    () =>
      countries.data // @ts-ignore
        ? countries.data.map(({ name, ...country }) => ({
          value: country["@id"],
          label: name,
        }))
        : [],
    [countries.data]
  );

  const family_situation_options = useMemo(
    () => getOptions(family_situation.data),
    [family_situation.data]
  );

  const family_relationships_options = useMemo(
    () => getOptions(family_relationships.data),
    [family_relationships.data]
  );

  const eye_colors_options = useMemo(
    () => getOptions(eye_colors.data),
    [eye_colors.data]
  );

  const ethnicities_options = useMemo(
    () => getOptions(ethnicities.data),
    [ethnicities.data]
  );

  const hair_colors_options = useMemo(
    () => getOptions(hair_colors.data),
    [hair_colors.data]
  );

  const hair_types_options = useMemo(
    () => getOptions(hair_types.data),
    [hair_types.data]
  );

  const hair_lengths_options = useMemo(
    () => getOptions(hair_lengths.data),
    [hair_lengths.data]
  );

  const colors_options = useMemo(
    () =>
      // @ts-ignore
      colors.data ? colors.data.map(({ name, ...color }) => ({
        value: color["@id"],
        label: t(name),
      }))
        : [],
    [colors.data]
  );

  const communes_options = useMemo(
    () =>
      communes.data
        ? communes.data.map((commune: any) => ({
          value: commune["@id"],
          label: commune.name_maj,
        }))
        : [],
    [communes.data]
  );

  const ape_codes_options = useMemo(
    () =>
      ape_codes.map(({ name, id }) => ({
        value: id,
        label: name,
      })),
    [ape_codes]
  );

  // ----------------------------------
  // -            FUNCTIONS
  // ----------------------------------
  const getFieldType = useCallback(
    (fieldId: string) =>
      field_types.data &&
      field_types.data?.find((fieldType: any) => fieldType["@id"] === fieldId)?.value,
    [field_types.data]
  );

  const getColorHexCode = useCallback(
    (colorUuid: string) =>
      colors.data?.find((c: any) => c["@id"] === colorUuid)?.hex_code,
    [colors.data]
  );

  return {
    urssaf_centers: urssaf_centers.data,
    caisse_conges_spectacles: caisse_conges_spectacles.data,
    company_types: company_types.data,
    legal_entity_types: legal_entity_types.data,
    billing_frequencies: billing_frequencies.data,
    conventions: conventions.data,
    street_suffixes: street_suffixes.data,
    civilities: civilities.data,
    field_types: field_types.data,
    countries: countries.data,
    family_situation: family_situation.data,
    family_relationships: family_relationships.data,
    eye_colors: eye_colors.data,
    ethnicities: ethnicities.data,
    hair_colors: hair_colors.data,
    hair_types: hair_types.data,
    hair_lengths: hair_lengths.data,
    colors: colors.data,
    communes: communes.data,
    ape_codes,

    // ----------------------------- OPTIONS
    urssaf_centers_options,
    caisse_conges_spectacles_options,
    legal_entity_types_options,
    billing_frequencies_options,
    convention_options,
    company_types_options,
    street_suffixes_options,
    civilities_options,
    field_types_options,
    countries_options,
    family_situation_options,
    family_relationships_options,
    eye_colors_options,
    ethnicities_options,
    hair_colors_options,
    hair_types_options,
    hair_lengths_options,
    colors_options,
    communes_options,
    ape_codes_options,

    // ----------------------------- FUNCTIONS
    getFieldType,
    getColorHexCode,
  };
}
