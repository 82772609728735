import { useTranslation } from 'react-i18next';
import { memo, useState } from 'react';
import { CompanyHeader } from '../../components/CompanyHeader';
import {
    Alert,
    AlertBase,
    ComboBox,
    Select,
    ToggleButton,
} from '../../components/core';
import { Helmet } from 'react-helmet';
import { BsInfoCircleFill } from 'react-icons/bs';
import { SecondaryButton } from '../../components/core';
import { useAuth } from '../../hooks';
import ApplicationBox from '../../components/ApplicationBox/ApplicationBox';
import { useApplications } from '../../hooks/applications';
import { useParams } from 'react-router-dom';

export interface Props { }

const ApplicationsPage = ({ }: Props) => {
    const { t } = useTranslation();
    const { auth } = useAuth();
    const [specific_questions, setSpecificQuestions] = useState<boolean>(false);
    const [tags, setTags] = useState<boolean>(false);
    const [decors, setDecors] = useState<boolean>(false);
    const { companyId } = useParams();
    const { applications } = useApplications(companyId ?? '');

    return (
        <div className="flex flex-col space-y-10">
            <Helmet>
                <title>{`Candidatures - ${t('MyRole')}`}</title>
            </Helmet>
            <CompanyHeader
                title={t("Page.Applications")}
                role={auth?.roles[0] || ""}
                isProject={true}
                IconFilm={true}
            />
            <Alert>{t('ApplicationPage.alert')}</Alert>
            <AlertBase
                className={`border-mr-green-700 bg-mr-green-500 text-white`}
                icon={<BsInfoCircleFill className="h-6 w-6 bg-mr-green-500" />}
            >
                {t('ApplicationPage.info')}
            </AlertBase>
            <div className="grid grid-cols-4 gap-x-10 gap-y-4">
                <Select
                    labelProps={{ title: t('ApplicationPage.employee_group') }}
                    options={[
                        { label: "Tous les groupes d'employés", value: 'all' },
                        { label: 'Figurants', value: 'figu' },
                        { label: 'Techniciens', value: 'tech' },
                    ]}
                // defaultSelected="all" // TODO : Need latest changes of Select component
                />
                <Select
                    labelProps={{ title: t('ApplicationPage.status') }}
                    options={[
                        { label: '! A traiter', value: 'todo' },
                        { label: 'En attente', value: 'pending' },
                        { label: 'Refusé', value: 'declined' },
                        { label: 'Supprimées du film', value: 'deleted' },
                    ]}
                // defaultSelected="todo" // TODO : Need latest changes of Select component
                />
                <Select
                    labelProps={{ title: t('ApplicationPage.sort') }}
                    options={[
                        {
                            label: 'Ordre Chronologique',
                            value: 'chronological_order',
                        },
                        {
                            label: 'Dernières candidatures en premier',
                            value: 'last_first',
                        },
                    ]}
                // defaultSelected="chronological_order" // TODO : Need latest changes of Select component
                />
                <ComboBox
                    label={t('ApplicationPage.search')}
                    placeholder="Nom, prénom, mail"
                />

                {applications?.some(
                    (app) => app.specificQuestions?.length > 0
                ) && ( // TODO : This is not the right way but i'm waiting on the good back-end to do it correctly
                        <ToggleButton
                            defaultSelected={specific_questions}
                            onChange={setSpecificQuestions}
                        >
                            {t('ApplicationPage.specific_questions')}
                        </ToggleButton>
                    )}
                {applications?.some((app) => app.movie_tags?.length > 0) && (
                    <ToggleButton defaultSelected={tags} onChange={setTags}>
                        {t('ApplicationPage.tags')}
                    </ToggleButton>
                )}
                {applications?.some((app) => app.sets?.length > 0) && (
                    <ToggleButton defaultSelected={decors} onChange={setDecors}>
                        {t('ApplicationPage.sets')}
                    </ToggleButton>
                )}
                {/* PAGINATION  Nécessite le back */}
            </div>
            {applications &&
                applications.length > 0 &&
                applications?.map((application, idx) => (
                    <ApplicationBox
                        key={idx}
                        showDecors={decors}
                        showTags={tags}
                        showSpecificQuestions={specific_questions}
                        application={application}
                        pictureSize={`${210 - idx}`}
                    />
                ))}
            {applications && applications.length === 0 && (
                "Aucune candidature pour le moment"
            )}

            <SecondaryButton className="w-1/4">
                {t('ApplicationPage.keep_all')}
            </SecondaryButton>
        </div>
    );
};

export default memo(ApplicationsPage);
