import { FocusEvent, ForwardedRef, forwardRef, memo, useState } from "react";
import { ChangeHandler } from "react-hook-form";
import Input from "../Input/OldInput";
import { Props as InputProps } from "../Input/OldInput";

// TODO: Rework for new Input component

export interface OptionsData {
  id: string;
  text: string;
}

export interface Props extends InputProps {
  options?: OptionsData[];
  onClickItem?: (arg0: OptionsData["id"]) => void;
  onBlur?: ChangeHandler;
}

export interface ItemListProps {
  options?: OptionsData[];
  onClickItem?: (arg0: string) => void;
}

const ItemsList = ({ options = [], onClickItem }: ItemListProps) =>
  options ? (
    <div>
      {options.map((options, key) => {
        return (
          <li
            key={key}
            className="w-full cursor-pointer p-2 hover:bg-mr-gray-100"
            onClick={() => onClickItem && onClickItem(options.id)}
          >
            {options.text}
          </li>
        );
      })}
    </div>
  ) : (
    <div />
  );

const ComboBox = (
  {
    label,
    groupClass = "",
    options = [],
    type,
    onClickItem,
    onBlur,
    ...props
  }: Props,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const [displayDropDown, setDisplayDropDown] = useState(false);
  let canBlur: boolean = true;

  const handleClickItem = (id: OptionsData["id"]) => {
    setDisplayDropDown(false);
    if (onClickItem) {
      onClickItem(id);
    }
  };

  const handleOnBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (canBlur) {
      if (onBlur) {
        // @ts-ignore
        onBlur(e);
      }
      setDisplayDropDown(false);
    }
  };

  return (
    <div data-testid="ComboBox" className={`${groupClass} relative`}>
      <Input
        label={label}
        type={type}
        ref={ref}
        onFocus={() => {
          setDisplayDropDown(true);
        }}
        onBlur={handleOnBlur}
        {...props}
      />
      {options && options.length > 0 && displayDropDown && (
        <ul
          data-testid="ComboBoxDropdown"
          className="absolute z-10 max-h-96 w-full overflow-y-auto rounded border border-mr-gray-500 bg-white"
          onMouseEnter={() => (canBlur = false)}
          onMouseLeave={() => (canBlur = true)}
        >
          <ItemsList options={options} onClickItem={handleClickItem} />
        </ul>
      )}
    </div>
  );
};

export default memo(forwardRef(ComboBox));
