import axiosModule from "axios";
import { useEffect, useState } from "react";
import { axiosPrivate } from "../api/axios";
import { AUTH_END_POINT } from "../constants";
import { faker } from "@faker-js/faker";
import { EMPLOYEES_FIXTURE } from "../fixtures";

export default function useRegistrations(filters: any) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<any[]>();

  useEffect(() => {}, []);

  async function search() {
    setIsLoading(true);
    try {
      // const { data } = await axiosPrivate.get<any>(
      //   `${AUTH_END_POINT.COMPANIES.COMPANIES}/${companyId}${AUTH_END_POINT.REGISTRATION}/status`
      // );
      // setIsLoading(false);
      console.log("search with filters ", filters);
      setSearchResults(EMPLOYEES_FIXTURE);
    } catch (error) {
      if (axiosModule.isAxiosError(error)) {
        console.error(error);
        setIsLoading(false);
        return error.message;
      }
    }
  }

  return {
    isLoading,
    search,
    searchResults,
  };
}
