import {
  ForwardedRef,
  forwardRef,
  useState,
  memo,
  InputHTMLAttributes,
  MouseEvent,
  useId,
} from "react";
import { OptionsData } from "../ComboBox/ComboBox";
import { twMerge } from "tailwind-merge";

export interface Props {
  className?: string;
  containerClassName?: string;
  containerStyle?: React.CSSProperties;
  defaultSelected?: string;
  values: OptionsData[];
  name: string;
  label?: string;
  onChangedValue?: (id: number) => {} | any
  variant?: {
    size?: "sm" | "md";
  };
}

export interface RadioItemProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  text: string;
  id: string;
  selected: boolean;
  variant?: {
    size?: "sm" | "md";
  };
}

const RadioItems = forwardRef(
  (
    {
      className = "",
      text,
      selected,
      variant = { size: "md" },
      ...props
    }: RadioItemProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => (
    <>
      <input
        className="hidden"
        type="radio"
        value={props.id}
        ref={ref}
        defaultChecked={selected}
        {...props}
      />
      <label
        className={twMerge(
          "m-1 w-full cursor-pointer select-none truncate rounded-full text-center",
          variant.size === "md" && "px-4 py-2",
          variant.size === "sm" && "!overflow-visible px-4 py-0 text-xs",
          selected
            ? "bg-mr-cyan-400 text-white hover:!text-white"
            : "hover:bg-mr-gray-100",
          className
        )}
        htmlFor={props.id}
      >
        {text}
      </label>
    </>
  )
);

const ToggleButton = (
  {
    containerClassName,
    containerStyle,
    className = "",
    variant = { size: "md" },
    values,
    defaultSelected,
    label,
    onChangedValue = (id: number) => { },
    ...inputProps
  }: Props,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const id = useId();
  const [selectedId, setSelectedId] = useState(
    defaultSelected ? defaultSelected : ""
  );


  const onClickItem = (evt: MouseEvent<HTMLInputElement>) => {
    evt.currentTarget && setSelectedId(evt.currentTarget.id);
    onChangedValue(+evt.currentTarget.id);
  };

  return (
    <div data-testid="ToggleButton" className={containerClassName} style={containerStyle}>
      {label && (
        <span className="mb-2 block text-sm font-bold text-mr-gray-900">
          {label}
        </span>
      )}
      <div
        className={twMerge(
          "flex grow flex-col items-center justify-between rounded-lg border-2 px-1 lg:flex-row lg:rounded-full lg:px-0",
          className
        )}
      >
        {values &&
          values.length > 0 &&
          values.map((elem, index) => (
            <RadioItems
              variant={variant}
              key={`${id}/${index}`}
              text={elem.text}
              id={elem.id}
              ref={ref}
              onClick={onClickItem}
              selected={elem.id === selectedId}
              {...inputProps}
            />
          ))}
      </div>
    </div>
  );
};

export default memo(forwardRef(ToggleButton));
