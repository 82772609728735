import { PROJECT_FIXTURE } from "./project";

export const PROJECT_FORM_FIXTURE = {
  ...PROJECT_FIXTURE,
  new_production_director: {
    civility: 0,
    first_name: "Leonardo",
    last_name: "DiCaprio",
    email: "franck.fdz@proton.com",
    phone: "1234567890",
    birth_date: "2022-01-12",
    birth_city: "Aurillac",
    nationality: "FRA",
    fiscal_address: "23 Avenue Thiers 33100 Bordeaux",
  },
};
