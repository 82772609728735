import { Agent } from "../hooks/useFilm";

export const AGENT_FIXTURE: Agent = {
  id: "id",
  firstName: "firstName",
  lastName: "lastName",
  email: "email@email.com",
  assistant: "assistant",
  phone: "0123344556",
  fix_phone: "0123344556",
};
