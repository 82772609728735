export const FAMILY_SITUATIONS_FIXTURE = {
  "@context": "/contexts/FamilySituation",
  "@id": "/family_situations",
  "@type": "hydra:Collection",
  "hydra:member": [
    {
      "@id": "/family_situations/1",
      "@type": "FamilySituation",
      id: 1,
      name: "Célibataire",
    },
    {
      "@id": "/family_situations/2",
      "@type": "FamilySituation",
      id: 2,
      name: "Marié(e)",
    },
    {
      "@id": "/family_situations/3",
      "@type": "FamilySituation",
      id: 3,
      name: "Pacsé(e)",
    },
    {
      "@id": "/family_situations/4",
      "@type": "FamilySituation",
      id: 4,
      name: "Divorcé(e)",
    },
    {
      "@id": "/family_situations/5",
      "@type": "FamilySituation",
      id: 5,
      name: "Veuf(ve)",
    },
    {
      "@id": "/family_situations/6",
      "@type": "FamilySituation",
      id: 6,
      name: "En concubinage",
    },
  ],
  "hydra:totalItems": 6,
};
