import { OptionHTMLAttributes, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getProductions,
  getProduction,
  patchProduction,
  postProduction,
} from "../api";

export interface CreateProductionResponse {
  code: number;
  production_id: string;
  Message: string;
}

export function useProduction(productionId?: string) {
  const [status, setStatus] = useState<string>("");

  const {
    isLoading: isProductionsLoading,
    error: errorOnProductions,
    status: statusOnProductions,
    data: productions,
  } = useQuery({
    queryKey: ["productions"],
    queryFn: () => getProductions(),
  });

  const {
    isLoading: isProductionLoading,
    error: errorOnProduction,
    status: statusOnProduction,
    data: production,
  } = useQuery({
    queryKey: ["productions", productionId],
    queryFn: () => getProduction(productionId ? productionId : ""),
  });

  const post = useMutation({
    mutationFn: (production: ProductionForm) => postProduction(production),
  });

  const patch = useMutation({
    mutationFn: (args: { id: string; production: ProductionForm }) =>
      patchProduction(args.id, args.production),
  });

  // TODO : Ask this to back
  // This make productions available in Selects (productionForm)
  const productionsOptions: OptionHTMLAttributes<HTMLOptionElement>[] = useMemo(
    () =>
      (productions ? productions : []).map((p) => ({
        value: p["@id"],
        label: p.company.name,
      })),
    [productions]
  );

  return {
    production,
    productions,
    post,
    patch,
    productionsOptions,

    // Loadings
    isProductionsLoading,
    isProductionLoading,
    isPatching: patch.isPending,
    isPosting: post.isPending,

    // Status
    statusOnProduction,
    statusOnProductions,
    statusOnPatch: patch.status,
    statusOnPost: post.status,

    // Errors
    errorOnProductions,
    errorOnProduction,
  };
}
