export const STREET_SUFFIXES_FIXTURE: GetStreetSuffixResponse = {
  "@context": "/contexts/StreetSuffix",
  "@id": "/street_suffixes",
  "@type": "hydra:Collection",
  "hydra:member": [
    {
      "@id": "/street_suffixes/1",
      "@type": "StreetSuffix",
      id: 1,
      name: "bis",
    },
    {
      "@id": "/street_suffixes/2",
      "@type": "StreetSuffix",
      id: 2,
      name: "ter",
    },
    {
      "@id": "/street_suffixes/3",
      "@type": "StreetSuffix",
      id: 3,
      name: "quater",
    },
  ],
  "hydra:totalItems": 3,
};
