import { CastingProfile } from "../types/casting_profile";
import { ETHNICITIES_FIXTURE } from "./ethnicities";
import { EYE_COLORS_FIXTURE } from "./eye_colors";
import { HAIR_COLORS_FIXTURE } from "./hair_colors";
import { HAIR_TYPES_FIXTURE } from "./hair_types";

export const CASTING_PROFILE_FIXTURE: CastingProfile = {
  "@context": "/contexts/CastingProfile",
  "@id": "/casting_profile/1ed45845-1123-618a-b5d6-25cac5bbd083",
  "@type": "CastingProfile",
  appear_in_search_engine: true,
  profile_completion: 75,
  visible_age: 23,
  tatoos: false,
  ethnicity: ETHNICITIES_FIXTURE["hydra:member"][0]["@id"],
  eye_color: EYE_COLORS_FIXTURE["hydra:member"][0]["@id"],
  physical_characteristics: ["Slim"],
  chest_size: 55,
  hip_size: 55,
  waist_size: 55,
  size: 175,
  weight: 70,
  shoe_size: 42,
  hair_length: "Long",
  hair_type: HAIR_TYPES_FIXTURE["hydra:member"][0]["@id"],
  hair_color: HAIR_COLORS_FIXTURE["hydra:member"][0]["@id"],
  nude_scenes: false,
  voice_casting: false,
  look_alike: "Brad Pitt",
  languages_spoken: [
    { id: "1", stars: 3, comboItem: "English" },
    { id: "12", stars: 3, comboItem: "French" },
    { id: "13", stars: 3, comboItem: "Spanish" },
  ],
  practiced_sport: [{ id: "12", stars: 3, comboItem: "Football" }],
  special_talent: [{ id: "13", stars: 3, comboItem: "Singing" }],
  has_car: true,
  car_details: "SUV",
  two_wheels_details: "Scooter",
  has_motorbike: true,
  has_scooter: true,
  resume: "test resume",
  job_seeker: true,
  url_website: "https://www.google.com",
  url_demo: "https://www.google.com",
  animals: "cat and dogs",
};
