import { forwardRef, useRef, useState } from "react";
import UpdateFileModal from "../../components/UpdateFileModal/UpdateFileModal";
import { useModal } from "../../components/modals";
import { geExtractedFilePage } from "../../api/deposited_files";
import { Column, TableFetchArgs } from "../../components/core/Table/TableUI";
import { Alert, Button } from "../../components/core";
import { BsCheck, BsEnvelopeFill, BsFilePdfFill, BsFillPencilFill, BsFillTrash3Fill, BsPersonFill, BsX } from "react-icons/bs";
import { Cell, Table } from "../../components/core/Table";
import { useRequiredParams } from "../../hooks/useRequiredParams";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import { Link, generatePath } from "react-router-dom";
import { ROUTE_PATH } from "../../constants";


interface ExtractedFilePage {
    doctype: string;
    createdAt: string;
    documentNumber: string;
    employee: string;
    id: string;
    pageNumber: number;
    fileId: string;
    position: string;
    date_from: string;
    date_to: string;
    read: boolean;
}

interface UserRequest {
    page: number;
    itemsPerPage: number;
    search?: string;
    searchType?: string;
    [key: `order[${string}]`]: string;
    "tagUsers.tag.name"?: string;
}


function ExtractedFilePageTable(props: any, ref: any) {

    const { companyId } = useRequiredParams();

    const [extractedFilePageData, setExtractedFilePageData] = useState<any>([]);
    const [modal, setOpen] = useModal(<UpdateFileModal />);

    const queryFnAllDocuments = async ({
        page,
        perPage,
    }: TableFetchArgs) => {

        const params: UserRequest = {
        page: page,
        itemsPerPage: perPage,
        };

        const ExtractedFilePage = geExtractedFilePage(companyId);

        return ExtractedFilePage;
    };

    const allFilesColumns: Column[] = [
        { id: "id", title: "ID", hidden: true, isSorting: false, className: "w-24" },
        { id: "employee", title: "Employé", isSorting: false },
        { id: "doctype", title: "Type", isSorting: false },
        { id: "document_number", title: "Numéro du doc.", isSorting: false },
        { id: "position", title: "Poste", isSorting: false },
        { id: "dates", title: "Dates", isSorting: false },
        { id: "pageNumber", title: "Page", isSorting: false, className: "w-12"},
        { id: "upload_date", title: "Ajout", isSorting: false},
        { id: "read", title: "Lu", className: "w-12"},
        { id: "tools", title: ""},
    ];

    const tableRef = useRef();

    const [deleteModal, setOpenDeleteModal] = useModal(<ConfirmModal />);


    const handleDeleteExtractedFile = (id: string, fileId: string) => {
        setOpenDeleteModal(true, 
        { id: id, 
        title:"SUPPRIMER LE DOCUMENT EXPORTÉ", 
        infos: "En confirmant, vous supprimerez le document ci-dessous ! Ils ne pourront plus y acccéder via leur profil. Cette opération est irréversible !",
        fileName: "Consulter le document",
        fileId: fileId,
        isExtractedFilePage: true,
        onConfirm: () => {
            if (ref.current) {
            // @ts-ignore
            ref.current.refresh();
            }
        }
        }); 
    }

    const handleSendFile = () => {
        console.log("Send file");
    }

    return (
        <>
            {modal}
            {deleteModal}
            <Table
                ref={ref}
                id="ExtractedFilePage"
                columns={allFilesColumns}
                queryFn={queryFnAllDocuments}
                keyExtractor={(item) => item.id}
                dataExtrator={(data) => data['hydra:member']}
                totalItemsExtractor={(data) => data['hydra:totalItems']}
                renderEmptyResult={() => (
                <Alert className="mt-4">
                    Aucun document n'a été extrait pour le moment, revenez plus tard !
                </Alert>
            )}>
                {(item: ExtractedFilePage) => {
                return (
                    <>
                    <Cell columnId="employee">
                        {item.employee === "Undefined" ? 
                        <>
                        <Button
                            className="bg-mr-cyan-400 text-white"
                            onClick={() => setOpen(true, { file_number: item.documentNumber, date_from: item.date_from, date_to: item.date_to, position: item.position, page: item.pageNumber, upload_date: item.createdAt})}
                            startIcon={<BsPersonFill size={"16"}/>}
                        >
                            Sélectionner
                        </Button>
                        </>
                        : item.employee}
                    </Cell>
                    <Cell columnId="doctype">
                        {item.doctype}
                    </Cell>
                    <Cell columnId="document_number">
                        {item.documentNumber}
                    </Cell>
                    <Cell columnId="position">
                        {item.position}
                    </Cell>
                    <Cell columnId="dates">
                        Du {item.date_from}
                        <br />
                        Au {item.date_to}
                    </Cell>
                    <Cell columnId="pageNumber">
                        {item.pageNumber}
                    </Cell>
                    <Cell columnId="upload_date">
                        {`${new Date(item.createdAt).toLocaleDateString("fr-FR", {hour: '2-digit', minute: '2-digit'})}`}
                    </Cell>
                    <Cell columnId="read">
                        {item.read ? <BsCheck size={"24"}/> : <BsX size={"24"}/>}
                    </Cell>
                    <Cell columnId="tools">
                        <div className={"flex items-center justify-center gap-3"}>
                        <BsFillTrash3Fill size={"16"} className="flex-none cursor-pointer" onClick={() => handleDeleteExtractedFile(item.id, item.fileId)}/>
                        <BsEnvelopeFill size={"16"} className="flex-none" onClick={handleSendFile}/>
                        <BsFillPencilFill size={"16"} className="flex-none cursor-pointer" onClick={() => setOpen(true, { file_number: item.documentNumber, date_from: item.date_from, date_to: item.date_to, position: item.position, page: item.pageNumber, upload_date: item.createdAt})}/>
                        <Link to={generatePath(ROUTE_PATH.FILE, { fileId: item.fileId})} target="_blank"><BsFilePdfFill size={"16"} className="flex-none"/></Link>
                        </div>
                    </Cell>
                    </>
                );
                }}
            </Table>
        </>
    )

}

export default forwardRef(ExtractedFilePageTable);