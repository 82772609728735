import { API_BASE_URL } from "../constants";
import { axiosPrivate } from "./axios";

export async function fetchWorkingDaysByDates(
  companyId: string,
  workingDaysDateStart: string,
  workingDaysDateStop: string
) {
  const { data } = await axiosPrivate.get<GetWorkingDaysResponse>(
    `${API_BASE_URL}/prepapaie/${companyId}?dateStart[after]=${workingDaysDateStart}T00:00:00&dateStart[before]=${workingDaysDateStop}T23:59:59`
  );

  return data;
}
