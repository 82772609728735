import { memo, useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconType } from "react-icons";
import { Link } from "react-router-dom";
import { MenuItem } from "../../types/menu";
import { Popover } from "../core";
import { PopoverOrigin } from "../core/Popover/Popover";

export interface Props {
  className?: string;
  list: MenuItem[];
  label?: string;
  i18nKey?: string;
  Icon?: IconType;
  popoverProps?: {
    className?: string;
    anchorOrigin: PopoverOrigin;
    transformOrigin: PopoverOrigin;
  };
}

const MenuPopover = ({
  className = "",
  list = [],
  label,
  i18nKey,
  Icon,
  popoverProps = {
    className: "",
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
  },
}: Props) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const handleClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <>
      <button
        className={`${className} flex items-center justify-center gap-2 rounded-full`}
        ref={ref}
        onClick={handleClick}
        data-testid="MenuPopover"
      >
        {Icon && <Icon className="h-5 w-5" />}
        {label && <span>{label}</span>}
        {i18nKey && <span>{t(i18nKey)}</span>}
      </button>

      <Popover
        className={`${popoverProps.className} rounded bg-white shadow-xl z-50`}
        anchorEl={ref}
        open={isOpen}
        close={handleClose}
        anchorOrigin={popoverProps.anchorOrigin}
        transformOrigin={popoverProps.transformOrigin}
        excludeRefs={[ref]}
        extraOffset={{ top: 6, left: 18 }}
      >
        <ul className="flex flex-col text-gray-700">
          {list &&
            list.map(({ Icon, i18nKey, to }, idx) => (
              <li key={idx} className="">
                <Link
                  className="flex cursor-pointer items-center gap-3 truncate px-6 py-2 leading-4 hover:bg-gray-200 [&_*]:cursor-pointer"
                  to={to}
                >
                  {Icon && <Icon className="h-5 w-5" />}
                  {i18nKey && t(i18nKey)}
                </Link>
              </li>
            ))}
        </ul>
      </Popover>
    </>
  );
};

export default memo(MenuPopover);
