import { API_BASE_URL, AUTH_END_POINT } from "../constants";
import { axiosPrivate, axiosPrivatePatch } from "./axios";

export async function fetchXotisNumber(companyId: string) {
  const { data } = await axiosPrivate.get<GetXotisResponse>(
    `${API_BASE_URL}${AUTH_END_POINT.COMPANIES.FIND_XOTIS_NUMBER(companyId)}`
  );

  return data;
}
export async function setXotisNumber(companyId: string, data: SetXotisNumber) {
  const response = await axiosPrivatePatch.patch<SetXotisNumber>(
    `${API_BASE_URL}${AUTH_END_POINT.COMPANIES.FIND_XOTIS_NUMBER(companyId)}`
  );

  return response.data;
}