import {Button, ComboBox, Header, Input, Label, ListBox, ListBoxItem, Popover, Section, FieldError, Text, ComboBoxProps, ListBoxItemProps, ValidationResult} from 'react-aria-components';
import "./ComboBox.css";
import { BsSearch } from "react-icons/bs";
import { Alert, AlertBase } from '../Alert';


interface MyComboBoxProps<T extends object>
  extends Omit<ComboBoxProps<T>, 'children'> {
  label?: string;
  description?: string | null;
  errorMessage?: string | ((validation: ValidationResult) => string);
  children: React.ReactNode | ((item: T) => React.ReactNode);
}

export default function MyComboBox<T extends object>(
  { label, description, errorMessage, children, ...props }: MyComboBoxProps<T>
) {
  return (
    <ComboBox {...props}>
      <Label className="font-bold leading-tight text-mr-gray-900 text-sm">{label}</Label>
      <div className="my-combobox-container relative mt-2">
        <Input className={"py-2 px-5 border border-mr-grey-1000 rounded appearance-none w-full text-lg"}/>
        <Button><BsSearch/></Button>
      </div>
      {description && <Alert>{description}</Alert>}
      <FieldError>{errorMessage}</FieldError>
      <Popover>
        <ListBox>
          {children}
        </ListBox>
      </Popover>
    </ComboBox>
  );
}

function MyItem(props: ListBoxItemProps) {
  return (
    <ListBoxItem
      {...props}
      className={({ isFocused, isSelected }) =>
        `my-item ${isFocused ? 'focused' : ''} ${isSelected ? 'selected' : ''}`}
    />
  );
}
