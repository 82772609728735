import {
  memo,
  MutableRefObject,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useClickAway } from "../../../hooks";

// ------------------------------------------------ UTILS
function getOffsetTop(rect: DOMRect | undefined, vertical: string | number) {
  let offset = 0;
  if (!rect) {
    return offset;
  }

  if (typeof vertical === "number") {
    offset = vertical;
  } else if (vertical === "center") {
    offset = rect.height / 2;
  } else if (vertical === "bottom") {
    offset = rect.height;
  }

  return offset;
}

function getOffsetLeft(rect: DOMRect | undefined, horizontal: string | number) {
  let offset = 0;
  if (!rect) {
    return offset;
  }

  if (typeof horizontal === "number") {
    offset = horizontal;
  } else if (horizontal === "center") {
    offset = rect.width / 2;
  } else if (horizontal === "right") {
    offset = rect.width;
  }

  return offset;
}

const getTransformOriginValue = (transformOrigin: {
  horizontal: number;
  vertical: number;
}) =>
  [transformOrigin.horizontal, transformOrigin.vertical]
    .map((n) => (typeof n === "number" ? `${n}px` : n))
    .join(" ");

// ------------------------------------------------ Popover
export interface Props {
  className?: string;
  open: boolean;
  anchorEl: RefObject<HTMLButtonElement> | null;
  children: ReactNode;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  style?: any;
  close?: () => void;
  excludeRefs?: MutableRefObject<any>[];
  extraOffset?: PopoverPositionOffset;
}

export interface PopoverOrigin {
  vertical: "bottom" | "center" | "top";
  horizontal: "center" | "left" | "right";
}

export interface PopoverPositionOffset {
  top: number;
  left: number;
}

const Popover = ({
  open = false,
  className = "",
  close,
  anchorEl = null,
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin = {
    vertical: "top",
    horizontal: "center",
  },
  style,
  children,
  excludeRefs = [],
  extraOffset = {
    top: 0,
    left: 0,
  },
}: Props) => {
  const popoverRef = useRef<HTMLDivElement>(null);
  const [positioning, setPositioning] = useState<{
    top: number;
    left: number;
    transformOrigin: string | number;
  }>({ top: 0, left: 0, transformOrigin: "center" });

  useEffect(() => {
    const handleScroll = () => {
      setPositioningStyles();
    };

    window.addEventListener("mousewheel", handleScroll);

    return () => {
      window.removeEventListener("mousewheel", handleScroll);
    };
  }, []);

  useEffect(() => {
    setPositioningStyles();
  }, [popoverRef]);

  useEffect(() => {
    if (open) {
      setPositioningStyles();
    }
  }, [open]);

  const handleClose = useCallback(() => {
    if (open === true) {
      close && close();
    }
  }, [close, open]);

  useClickAway([popoverRef, ...excludeRefs], handleClose);

  // Returns the top/left offset of the position
  // to attach to on the anchorEl element
  const getAnchorOffset = useCallback(() => {
    const anchorRect = anchorEl?.current?.getBoundingClientRect();
    const top = anchorRect?.top || 0;
    const left = anchorRect?.left || 0;

    return {
      top: top + getOffsetTop(anchorRect, anchorOrigin.vertical),
      left: left + getOffsetLeft(anchorRect, anchorOrigin.horizontal),
    };
  }, [anchorEl, anchorOrigin.horizontal, anchorOrigin.vertical]);

  const getPositioningStyle = useCallback(
    (element: HTMLElement) => {
      if (!element) {
        return {
          top: 0,
          left: 0,
          transformOrigin: 0,
        };
      }

      const elemRect = {
        width: element.offsetWidth,
        height: element.offsetHeight,
      };

      // Get the transform origin point on the element itself
      const elemTransformOrigin = getTransformOrigin(elemRect);

      // Get the offset of the anchoring element
      const anchorOffset = getAnchorOffset();

      // Calculate element positioning
      let top = anchorOffset.top - elemTransformOrigin.vertical;
      let left = anchorOffset.left - elemTransformOrigin.horizontal;

      return {
        top: Math.round(top),
        left: Math.round(left),
        transformOrigin: getTransformOriginValue(elemTransformOrigin),
      };
    },
    [getAnchorOffset],
  );

  // Returns the base transform origin using the element
  const getTransformOrigin = useCallback(
    (elemRect: any) => {
      return {
        vertical: getOffsetTop(elemRect, transformOrigin.vertical),
        horizontal: getOffsetLeft(elemRect, transformOrigin.horizontal),
      };
    },
    [transformOrigin.horizontal, transformOrigin.vertical],
  );

  const setPositioningStyles = useCallback(() => {
    const element = popoverRef.current;

    if (!element) {
      return;
    }

    const { left, top, transformOrigin } = getPositioningStyle(element);

    if (left !== null && top !== null) {
      setPositioning({ left, top, transformOrigin });
    }
  }, [getPositioningStyle, popoverRef, setPositioning]);

  return (
    <div
      className={`${open ? "fixed z-60" : "hidden"} ${className}`}
      data-testid="Popover"
      ref={popoverRef}
      style={{
        top: `${positioning.top + extraOffset.top}px`,
        left: `${positioning.left + extraOffset.left}px`,
        transformOrigin: positioning.transformOrigin,
        ...style,
      }}
    >
      {open && children}
    </div>
  );
};

export default memo(Popover);
