import {
  DialogTrigger,
  Key,
  ListBox,
  ListBoxItem,
  Menu,
  MenuItem,
  MenuTrigger,
  Popover,
  Select,
} from "react-aria-components";
import Button from "../NewButton";
import { BsArrowCounterclockwise, BsFunnel } from "react-icons/bs";
import FilterModal from "./FilterModal";
import { useModal } from "../../modals";
import useTableContext from "./useTableContext";


export interface Filter{
  id: string;
  label: string;
  icon: JSX.Element;
  modal: JSX.Element;
}


function Filters({ filters = [] }: { filters: Filter[]}) {

  const { setCurrentFilter } = useTableContext();
  const [modal, setOpen] = useModal(<FilterModal />);


  const handleClickFilterItem = (id: Key) => {
    const filter = filters.find((filter) => filter.id === id);
    setCurrentFilter(filter);
    if (filter) {
      setOpen(true, {
        title: filter.label,
        children: filter.modal
      });
    }
  }

  return (
    <>
      {modal}
      <div className="flex">
        <MenuTrigger>
          <Button className="rounded-r-none border-r-[0.5px] bg-[#BBBBBB]">
            <BsArrowCounterclockwise />
          </Button>
          <Button className="rounded-l-none border-l-[0.5px] flex items-center gap-2">
            <BsFunnel />
            Filtrer par...
            <span aria-hidden="true">▼</span>
          </Button>
          <Popover>
            <Menu onAction={handleClickFilterItem}>
              {filters.map((filter) => (
                <MenuItem key={filter.id} id={filter.id}>
                  <div className="flex gap-2 items-center">
                  {filter.icon}
                    <span className="text-sm">{filter.label}</span>
                  </div>
                </MenuItem>
              ))}
            </Menu>
          </Popover>
        </MenuTrigger>
      </div>
    </>
  );
}

export default Filters;
