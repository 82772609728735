import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GoDot } from "react-icons/go";
import { HiOutlineChevronDown } from "react-icons/hi";
import { NavLink, useLocation } from "react-router-dom";
import { useMenu } from "../../hooks";
import { SubMenuCategory } from "../../types/menu";
import { TertiaryTooltip } from "../Tooltip";
import SubSideNavbarCategoryLink from "./SubSideNavbarCategoryLink";

export interface Props {
  className?: string;
  category: SubMenuCategory;
}

const SubSideNavbarCategory = ({
  className = "",
  category: { Icon, title, routes, to },
}: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const {
    menu: { subMenu },
  } = useMenu();

  const hasLinkCollapsed =
    typeof routes !== "undefined" &&
    routes?.findIndex((route) => route.to === location.pathname) !== -1;

  const [collapsed, setCollapsed] = useState<boolean>(!hasLinkCollapsed);

  // When we collapse sub menu
  // auto collapse categories
  useEffect(() => {
    subMenu.isCollapsed && setCollapsed(true);
  }, [subMenu.isCollapsed]);

  const handleClickCollapse = useCallback(() => {
    !subMenu.isCollapsed && setCollapsed((v) => !v);
  }, [setCollapsed, subMenu.isCollapsed]);

  useEffect(() => {
    const hasLinkCollapsed =
      routes?.findIndex((route) => route.to === location.pathname) !== -1;
    !subMenu.isCollapsed && hasLinkCollapsed && setCollapsed(false);
  }, [location, subMenu.isCollapsed]);

  return (
    <TertiaryTooltip
      direction="right"
      enabled={subMenu.isCollapsed}
      text={t(title)}
      className="text-xs font-normal"
    >
      <div
        className={`${className} px-5 py-4`}
        data-testid="SubSideNavbarCategory"
      >
        <div
          className="flex items-center text-sm hover:text-white [&_*]:cursor-pointer"
          onClick={handleClickCollapse}
          data-testid="Header"
        >
          <SubSideNavbarCategoryLink as={to ? NavLink : "button"} to={to}>
            <Icon className="h-4 w-4" data-testid="Icon" />
            {!subMenu.isCollapsed && <span>{t(title)}</span>}
          </SubSideNavbarCategoryLink>
          {!subMenu.isCollapsed && routes && (
            <HiOutlineChevronDown
              className="ml-auto h-4 w-4"
              data-testid="Chevron"
            />
          )}
        </div>
        {routes && (
          <ul
            className={`${collapsed ? "hidden" : ""
              } mt-4 pl-6 text-xs text-gray-400`}
            data-testid="List"
          >
            {routes.map((route, key) => (
              <li className="mt-2" key={key}>
                <NavLink
                  className={({ isActive }) =>
                    `flex cursor-pointer items-center gap-1 leading-4 hover:text-white [&_*]:cursor-pointer ${isActive ? "text-white [&_svg]:block" : ""
                    }`
                  }
                  to={route.to}
                >
                  <GoDot className="-ml-4 hidden h-3 w-3 text-mr-cyan-300" />
                  <label className="truncate">{t(route.i18nKey)}</label>
                </NavLink>
              </li>
            ))}
          </ul>
        )}
      </div>
    </TertiaryTooltip>
  );
};

export default memo(SubSideNavbarCategory);
