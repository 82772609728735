import Table from "./Table";

export const TABLE_FIXTURE = {
  headers: ["Modalité", "Mineurs", "Figurants", "Techniciens"],
  rows: [
    [
      "Afficher à l'employé ses jours prévus :",
      "Obligatoire/Facultatif",
      "Obligatoire/Facultatif",
      "Obligatoire/Facultatif",
    ],
    [
      "Afficher à l'employé ses horaires :",
      "Obligatoire/Facultatif",
      "Obligatoire/Facultatif",
      "Obligatoire/Facultatif",
    ],
    [
      "Utilisation d'un pseudonyme professionnel :",
      "Obligatoire/Facultatif",
      "Obligatoire/Facultatif",
      "Obligatoire/Facultatif",
    ],
  ],
};

export { Table };
