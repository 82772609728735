import { ReactElement, ReactNode, cloneElement } from "react";

export interface Props {
  children: ReactNode;
  className?: string;
  icon?: ReactElement;
}

const AlertBase = ({ children, className = "", icon, ...props }: Props) => (
  <div
    className={`${className} flex items-center gap-3 rounded-md border px-4 py-3 text-sm`}
    data-testid="AlertBase"
    {...props}
  >
    {icon &&
      (cloneElement(icon, {
        className: `${icon.props?.className ?? ""} flex-none`,
      }) as ReactElement)}
    {children && children}
  </div>
);

export default AlertBase;
