import { ButtonHTMLAttributes, Component, memo, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: "small" | "medium" | "big";
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  as?: string | Component | any;
  href?: string 
  to?: string
}

const Button = ({
  as = 'button',
  className = "",
  children,
  size = "small",
  startIcon,
  endIcon,
  ...props
}: Props) => {
  const navigate = useNavigate();
  const As = as;


  return (
    <As
      className={twMerge(
        "inline-flex h-fit items-center justify-center gap-2 rounded-full shadow",
        size === "small" && "px-3 py-1 text-sm",
        size === "big" && "px-9 py-3 text-base",
        size === "medium" && "px-6 py-2 text-base",
        className
      )}
      data-testid="Button"
      type="button"
      {...props}
    >
      {startIcon && startIcon}
      {children}
      {endIcon && endIcon}
    </As>
  );
};

export default memo(Button);
