import cn from "mxcn";
import { Cell as AriaCell, type CellProps } from "react-aria-components";
import useTableContext from "./useTableContext";
import { Column } from "./TableUI";

interface Props extends CellProps {
  columnId: string;
}

const isColumnShown = (id: string, columns: Column[]): boolean => {
  return columns.some((column) => column.id === id && !column.hidden);
};

function Cell({ className, columnId, ...props }: Props) {
  const { columns } = useTableContext();

  return (
    <>
      {isColumnShown(columnId, columns) && (
        <AriaCell {...props} className={cn("px-4 py-2 text-center leading-tight whitespace-nowrap", className)} />
      )}
    </>
  );
}

export default Cell;
