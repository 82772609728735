import { memo, ReactNode } from "react";

export interface Props {
  className?: string;
  children: ReactNode;
  enabled?: boolean;
  direction: "right" | "left" | "top" | "bottom";
  text: string | ReactNode;
}

const Tooltip = ({
  className = "",
  children,
  enabled = true,
  text,
  direction = "right",
}: Props) => {
  // Tooltip Base Styles
  const tooltipStyle = `whitespace-nowrap py-2 px-3 rounded-md text-center transform text-white`;

  // Arrow Base Styles
  const arrowBaseStyle = `before:absolute before:border-8 before:border-solid before:border-transparent`;

  // Arrows Styles by direction
  const arrowRightStyle = `${arrowBaseStyle} before:-ml-6 before:left-0 before:top-1/2 before:-translate-y-1/2`;
  const arrowLeftStyle = `${arrowBaseStyle} before:-mr-6 before:right-0 before:top-1/2 before:-translate-y-1/2`;
  const arrowTopStyle = `${arrowBaseStyle} before:-mb-6 before:bottom-0 before:left-1/2 before:-translate-x-1/2`;
  const arrowBottomStyle = `${arrowBaseStyle} before:-mt-6 before:top-0 before:left-1/2 before:-translate-x-1/2`;

  function createDirectionToolTipStyle() {
    switch (direction) {
      case "right":
        return `${tooltipStyle} ${arrowRightStyle} top-1/2 -translate-y-1/2 left-full ml-3 m-0`;
      case "left":
        return `${tooltipStyle} ${arrowLeftStyle} top-1/2 -translate-y-1/2 right-full mr-3 m-0`;
      case "top":
        return `${tooltipStyle} ${arrowTopStyle} left-1/2 -translate-x-1/2 bottom-full mb-3 m-0`;
      case "bottom":
        return `${tooltipStyle} ${arrowBottomStyle} left-1/2 -translate-x-1/2 top-full mt-3 m-0`;
    }
  }

  return enabled ? (
    <div className="group relative inline-block z-10" data-testid="TooltipBlock">
      {children}
      <div
        data-testid="Tooltip"
        className={`z-1 invisible absolute group-hover:visible ${className} ${createDirectionToolTipStyle()}`}
      >
        {text}
      </div>
    </div>
  ) : (
    <>{children}</>
  );
};

export default memo(Tooltip);
