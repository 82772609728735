import { atom } from "recoil";
import { MenuItem, SubMenu } from "../../types/menu";

export interface MenuArray {
  title: string;
  items: MenuItem[];
}

export interface MenuAtom {
  isCollapsed: boolean;
  items: MenuItem[];
  menus: MenuArray[];
  subMenu: {
    isCollapsed: boolean;
    item: SubMenu | null;
  };
}

export const defaultState = {
  isCollapsed: false,
  items: [],
  subMenu: {
    isCollapsed: false,
    item: null,
  },
  menus: [],
};

export const menuAtom = atom<MenuAtom>({
  key: "menuAtom",
  default: defaultState,
});
