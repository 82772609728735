import { RowSelectionState } from "@tanstack/react-table";
import { atom, selector } from "recoil";

export const signaturePadTableDataAtom = atom<SignaturePadContract[]>({
  key: "signaturePadTableDataAtom",
  default: [],
});

export const signaturePadTableSelectionAtom = atom<RowSelectionState>({
  key: "signaturePadTableSelectionAtom",
  default: {},
});

export const selectedIriSelector = selector<string[]>({
  key: "signaturePadTableSelectedUsersIriSelector",
  get: ({ get }) => {
    const data = get(signaturePadTableDataAtom);
    const list = get(signaturePadTableSelectionAtom);

    return Object.entries(list).map((s) => `${data[parseInt(s[0])].id}`);
  },
});

export const contractDetailsIdAtom = atom<string[]>({
  key: "contractDetailsIdSelector",
  default: [],
});

export const contractsSummaryAtom = atom<SignaturePadContract[]>({
  key: "contractsSummarySelector",
  default: [],
});
