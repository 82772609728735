import { axiosPrivate, axiosPrivatePatch } from "../api/axios";
import { API_BASE_URL, AUTH_END_POINT } from "../constants/constant";
// Temporary solution
import {
  transformEmployeeGroupReceived,
  transformEmployeeGroupToBeSent,
} from "./project";

export async function postProduction(newProduction: ProductionForm) {
  if (!newProduction) return;

  newProduction.company.employee_group =
    transformEmployeeGroupToBeSent(newProduction);

  const response = await axiosPrivate.post<GetProductionsResponse>(
    AUTH_END_POINT.PRODUCTIONS,
    newProduction
  );

  console.log(response);

  return response;
}

export async function getProduction(id: string) {
  const { data } = await axiosPrivate.get<Production>(
    `${AUTH_END_POINT.PRODUCTIONS}/${id}`
  );

  // Issue in back, postal_code should be a string but api return number
  // https://gitlab.com/ikuzo/myrole-3/-/issues/17

  const employee_group_options = transformEmployeeGroupReceived(data);

  const production_reconstruction = {
    ...data,
    observers: employee_group_options.observers,
    mail_groups: employee_group_options.mail_groups,
    contract_annexes: employee_group_options.contract_annexes,
    signatories: employee_group_options.signatories,
    sms_groups: employee_group_options.sms_groups,
    employee_groups: employee_group_options.employee_groups_final,
  } as ProductionForm;

  return production_reconstruction;
}

export async function getProductions(): Promise<Production[]> {
  const { data } = await axiosPrivate.get<GetProductionsResponse>(
    AUTH_END_POINT.PRODUCTIONS
  );

  return data["hydra:member"];
}

export async function patchProduction(
  id: string,
  patchedProduction: ProductionForm
) {
  if (id && patchedProduction) {
    patchedProduction.company.employee_group =
      transformEmployeeGroupToBeSent(patchedProduction);

    const response = await axiosPrivatePatch.patch<PatchProductionResponse>(
      `${API_BASE_URL}${AUTH_END_POINT.PRODUCTIONS}/${id}`,
      patchedProduction
    );

    return response;
  }

  // Issue in back, postal_code should be a string but api return number
  // https://gitlab.com/ikuzo/myrole-3/-/issues/17
}
