import { RefObject, useEffect } from "react";

export function useClickAway(
  ref: RefObject<HTMLElement> | RefObject<HTMLElement>[],
  handler: (e: MouseEvent | TouchEvent) => void
) {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (event.target) {
        const target = event.target as HTMLElement;

        const refs = Array.isArray(ref) ? ref : [ref];

        let isAway: boolean = true;
        refs.forEach((r) => {
          if (!r.current || r.current?.contains(target)) {
            isAway &&= false;
          }
        });

        if (!isAway) {
          return;
        }

        handler(event);
      }
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}
