import { Label } from "../../core";
import { Switch as AriaSwitch, SwitchProps } from "react-aria-components";
import "./switch.css";
import { useTranslation } from "react-i18next";
import cn from "mxcn";
import { LabelWrapperProps } from "../Label";

export interface Props extends SwitchProps {
  // Primary Props
  className?: string;
  containerClassName?: string;
  labelsClassNames?: string;

  // True False Props
  trueLabel?: string;
  falseLabel?: string;
  trueIcon?: React.ReactNode;
  falseIcon?: React.ReactNode;

  // Label Props
  label?: string;
  labelClassName?: string;
  errorLabel?: string;
  note?: string;
  labelsContainerClassName?: string;
  info?: string;
  containerClass?: string;

  isRequired?: boolean;
  isInvalid?: boolean;
  labelProps?: any;
}

const Switch = ({
  // Primary Props
  className = "",
  labelsClassNames = "",
  children,
  containerClassName = "",
  containerClass,
  // True False Props
  trueLabel,
  trueIcon,
  falseLabel,
  falseIcon,

  // Label Props
  label,
  note,
  info,
  labelClassName = "",
  labelsContainerClassName,
  errorLabel,
  ...props
}: Props) => {
  labelsClassNames = cn(
    "flex flex-row text-center rounded-full py-[6px] px-4 justify-center items-center gap-4 relative z-10",
    labelsClassNames,
  );
  const { t } = useTranslation();

  const labelsProps: LabelWrapperProps = {
    title: label,
    info: info,
    labelClass: labelClassName,
    errorLabel: errorLabel,
    note: note,
    disabled: props.isDisabled,
    labelsContainerClassName,
  };

  const extraProps: { defaultSelected?: boolean } = {};

  if (props.value) {
    extraProps.defaultSelected = Boolean(props.value);
  }

  return (
    <Label.Wrapper {...labelsProps}>
      <div>
        <AriaSwitch
          {...props}
          {...extraProps}
          className={cn(
            "react-aria-Switch group min-w-[240px] inline-block disabled:opacity-50",
            containerClassName,
          )}
          data-testid="Switch"
        >
          {({ isSelected, isFocused }) => (
            <>
              <span
                className={cn(
                  "inline-block p-[4px] rounded-full border bg-white group-focus:border-mr-cyan-300",
                  "border-mr-gray-1000",
                  containerClass || "h-[46px]", // Utilisez `containerClass` ici
                  {
                    "border-mr-orange-400": props.isInvalid,
                    "border-mr-cyan-400": isFocused,
                  },
                )}
              >
                <span
                  className={cn(
                    "flex flex-row react-aria-Switch-container h-full",
                    className,
                    { selected: isSelected },
                  )}
                >
                  <button
                    type="button"
                    className={cn("w-1/2 text-mr-gray-900", labelsClassNames, {
                      active: isSelected,
                    })}
                  >
                    {trueIcon ?? trueIcon}
                    {trueLabel ? trueLabel : t("yes")}
                  </button>

                  <button
                    type="button"
                    className={cn("w-1/2 text-mr-gray-900", labelsClassNames, {
                      active: !isSelected,
                    })}
                  >
                    {falseIcon ?? falseIcon}
                    {falseLabel ? falseLabel : t("no")}
                  </button>
                </span>
              </span>
            </>
          )}
        </AriaSwitch>
      </div>
    </Label.Wrapper>
  );
};

export default Switch;
