import { ReactNode } from "react";

export interface Props {
  className?: string;
  wrapperClassName?: string;
  titleClassName?: string;
  childContainerClassName?: string;
  title: string;
  icon?: ReactNode;
  children: ReactNode;
}

const TitledBox = ({
  className = "",
  titleClassName = "",
  wrapperClassName = "",
  childContainerClassName,
  title,
  icon,
  children,
}: Props) => (
  <div className={`${className} rounded-md`} data-testid="TitledBox">
    <div className={wrapperClassName}>
      <div className={`${titleClassName} flex items-center justify-start p-4`}>
        {icon && icon}
        <p className="ml-4">{title}</p>
      </div>
      <div className={childContainerClassName}>{children && children}</div>
    </div>
  </div>
);

export default TitledBox;
