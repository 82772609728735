import { memo } from 'react';
import {
    Box,
    PrimaryButton,
    SecondaryButton,
    TertiaryButton,
} from '../../components/core';
import {
    BsGenderFemale,
    BsGenderMale,
    BsGenderNeuter,
    BsEjectFill,
    BsXLg,
} from 'react-icons/bs';
import GalleryModal from './GalleryModal';
import { useModal } from '../modals';
import HorizontalGallery from '../Gallery/HorizontalGallery';
import { useTranslation } from 'react-i18next';
import { API_BASE_URL } from '../../constants';
import { useApplicationsMutation } from '../../hooks/applications';
import { useParams } from 'react-router-dom';

export interface Props {
    application: Application;
    // TODO : Delete when API, it's purpose is only to have different pictures
    pictureSize: string;
    showTags: boolean;
    showDecors: boolean;
    showSpecificQuestions: boolean;
}

const ApplicationBox = ({
    application,
    pictureSize,
    showDecors,
    showTags,
    showSpecificQuestions,
    ...props
}: Props) => {
    const { t } = useTranslation();
    const { patch } = useApplicationsMutation();
    const { companyId } = useParams();

    console.log('application', application);

    const handleStatusProdChange = (status: number) => {
        patch.mutate({
            company_id: companyId ?? '',
            registration_id: application.registration_id,
            data: { status_prod: `inscription_statuses/${status}` },
        });
    };

    function selectGenderIcon(gender: string) {
        switch (gender) {
            // case 'neutral':
            //     return <BsGenderNeuter className="h-4 w-4" />; // NOT used for now
            case 'MADAME':
                return <BsGenderFemale className="h-4 w-4" />;
            case 'MONSIEUR':
                return <BsGenderMale className="h-4 w-4" />;
        }
    }

    const [galleryModal, setOpenGalleryModal] = useModal(
        <GalleryModal galleryImages={application.gallery} />
    );

    const applicationDate = new Date(application.candidateDate);

    return (
        <Box>
            <Box.Content contentClassName="flex flex-row justify-between items-center">
                <div className="flex flex-row space-x-8">
                    <div
                        className="block h-full group hover:cursor-pointer"
                        onClick={() => {
                            setOpenGalleryModal(true);
                        }}
                    >
                        {application.profilePicture ? (
                            <img
                                src={
                                    API_BASE_URL +
                                    application.profilePicture.slice(1)
                                } // TODO : Needed from back
                                alt={`${t('ApplicationPage.Bo.profile_picture')} ${application.lastName} ${application.firstName}`}
                                className="rounded"
                            />
                        ) : (
                            <div className="w-full h-36 bg-mr-cyan-200 flex items-center justify-center text-white">
                                <span>Nothing to see</span>
                            </div>
                        )}
                        <div className="flex flex-col items-center justify-center group-hover:bg-mr-cyan-200 py-4 px-2 rounded text-center">
                            <BsEjectFill className="w-3 h-3 text-mr-cyan-500" />
                            <span>{t('ApplicationPage.Box.see_gallery')}</span>
                        </div>
                    </div>
                    <div className="flex flex-col space-y-6">
                        <h3>
                            {t(`civility.${application.civility['@id']}`)}{' '}
                            {application.lastName} {application.firstName}
                        </h3>
                        <div className="flex flex-row items-center space-x-2">
                            <span>
                                {selectGenderIcon(application.civility.value)}{' '}
                            </span>
                            {application.job && <span>{application.job} </span>}
                            <span>{application.age} ans, </span>
                            <span>{application.city} </span>
                            <span>{application.email}</span>
                        </div>
                        <div className="flex flex-row space-x-4">
                            {application.employeeGroup && (
                                <span className="bg-mr-gray-200 rounded-full px-4 py-1">
                                    {application.employeeGroup.name}
                                </span>
                            )}
                            {application.user_tags?.map((tag, idx) => (
                                <span
                                    key={idx}
                                    className="bg-mr-gray-200 rounded-full px-4 py-1"
                                >
                                    {tag.name}
                                </span>
                            ))}
                        </div>
                        {application.specificQuestions.length > 0 &&
                            showSpecificQuestions && (
                                <div className="space-y-1">
                                    <p className="font-bold">
                                        {t(
                                            'ApplicationPage.Box.spec_questions'
                                        )}
                                    </p>
                                    <div className="flex flex-row space-x-4">
                                        {application.specificQuestions.map(
                                            (question, idx) => (
                                                <div
                                                    key={idx}
                                                    className="flex flex-row space-x-4 bg-mr-gray-200"
                                                >
                                                    <span>{question}</span>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            )}
                        {/* Tags are a toggle button that is added to an array and sent to the back // TODO : Do when back has added the functionnality */}
                        {showTags && (
                            <div className="space-y-1">
                                <p className="font-bold">
                                    {t('ApplicationPage.Box.tags')}
                                </p>
                                <div className="flex flex-row space-x-4">
                                    {application.movie_tags.map((tag, idx) => (
                                        <SecondaryButton key={idx}>
                                            {tag}
                                        </SecondaryButton>
                                    ))}
                                </div>
                            </div>
                        )}
                        {showDecors && (
                            <div className="space-y-1">
                                <p className="font-bold">
                                    {t('ApplicationPage.Box.sets')}
                                </p>
                                <div className="flex flex-row space-x-4">
                                    {application.sets.map((set, idx) => (
                                        <SecondaryButton key={idx}>
                                            {set}
                                        </SecondaryButton>
                                    ))}
                                </div>
                            </div>
                        )}
                        <HorizontalGallery
                            images={application.gallery}
                            className="gap-2"
                            imageClassName="max-h-20"
                        />
                        <div className="flex flex-row space-x-4">
                            <SecondaryButton
                                onClick={() => handleStatusProdChange(1)}
                            >
                                {t('ApplicationPage.Box.keep')}
                            </SecondaryButton>
                            <PrimaryButton
                                onClick={() => handleStatusProdChange(2)}
                            >
                                {t('ApplicationPage.Box.later')}
                            </PrimaryButton>
                            <TertiaryButton
                                onClick={() => handleStatusProdChange(3)}
                                startIcon={<BsXLg className="w-4 h-4" />}
                            >
                                {t('ApplicationPage.Box.decline')}
                            </TertiaryButton>
                        </div>
                    </div>
                </div>
                {/* TODO FORMAT DATE */}
                <div className="self-start min-w-32">
                    <p>
                        {t('ApplicationPage.Box.the_date')}{' '}
                        {applicationDate.toLocaleDateString()}
                    </p>
                </div>
                {galleryModal}
            </Box.Content>
        </Box>
    );
};

export default memo(ApplicationBox);
