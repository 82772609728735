import { ReactNode } from "react";
import { Modal } from "../../modals";



export interface Props {
  title?: string;
  open?: boolean;
  onClose?: () => void;
  children?: ReactNode;
}

function FilterModal({
  title = '',
  open = false,
  onClose = () => {},
  children = true,
}: Props) {
  return (
    <Modal
      close={onClose}
      open={open}
      title={title}
    >
      {children}
    </Modal>
  );
}
export default FilterModal;