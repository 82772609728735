import { ADDRESS_FIXTURE } from "./address";
import { CONVENTIONS_FIXTURE } from "./conventions";

export const COMPANY_FIXTURE: Company = {
  "@id": "/companies/1",
  id: "",
  name: "Production name",
  address: ADDRESS_FIXTURE,
  phone: "01234567890",
  siret: "09879872634234",
  billing_frequency: "/billing_frequencies/1",
  // createdAt: "2023-01-24T10:23:37+00:00", // Not sent by the API
  // createdBy: "/users/1ed9bcfc-da1e-6460-82eb-fd50972fd8b3", // Not sent by the API
  employee_group: [],
  agreement: CONVENTIONS_FIXTURE["hydra:member"][0]["@id"],
  companyType: {
    "@id": "/company_types/1",
    "@type": "CompanyType",
    id: 1,
    name: "Production",
  },
  mandatory_second_legal_rep: true,
  mandatory_legal_rep_marital_situation: true,
  mandatory_legal_rep_birth_date: true,
  mandatory_legal_rep_birth_location: true,
  mandatory_legal_rep_nationality: true,
  mandatory_foreign_id_card: true,
  mandatory_foreign_residence_permit: true,
  mandatory_foreign_notice_of_assesment: true,
  use_red_for_minors: false,
  digitalSafeDenyPointer: false,
  ape_code: "5911C",
  use_eletronic_signature: false,
};

export const COMPANYFORM_FIXTURE: CompanyForm = {
  "@id": "/companies/1",
  name: "Production name",
  address: ADDRESS_FIXTURE,
  phone: "01234567890",
  siret: "09879872634234",
  billing_frequency: "/billing_frequencies/1",
  agreement: CONVENTIONS_FIXTURE["hydra:member"][0]["@id"],
  employee_group: [
    {
      name: "Employee group name",
      code: "Employee group code",
      sender_mail: "string",
      sender_name: "string",
      sender_sms_number: "string",
      observers: [],
      signatories: [],
      contract_annexes: [],
    },
  ],
  delegate: "string",
  declaredName: "string",
  urssaf_subscriber_last_name: "string",
  urssaf_subscriber_first_name: "string",
  canReplyEmail: false,
  enable_sms_response: false,
  canReplyEmailInvitation: false,
  safe_box: false,
  contract_signature_order: "string",
  contractPendingTime: "string",
  enableAvenantNewDpae: false,
  productionManagerEmailFrequency: "string",
  autoDpae: false,
  ape_code: "string",
  sst: "string",
  leave_fund: "string",
  pension_fund: "string",
  use_contract_signature: false,
  dpaeStartHours: "string",
  dpaeEndHours: "string",
};
