import { memo } from "react";
import Button, { Props } from "./Button";
import { twMerge } from "tailwind-merge";

export interface AdditionalProps extends Props {
  dark?: boolean;
}

const SecondaryButton = ({
  children,
  dark = false,
  className = "",
  ...props
}: AdditionalProps) => (
  <Button
    className={twMerge(
      "text-gray-50 disabled:cursor-not-allowed disabled:opacity-50",
      dark
        ? "bg-mr-cyan-500 hover:enabled:text-mr-cyan-200 hover:enabled:shadow-md"
        : "bg-mr-cyan-400 hover:enabled:bg-mr-cyan-300",
      className,
    )}
    data-testid="SecondaryButton"
    {...props}
  >
    {children}
  </Button>
);

export default memo(SecondaryButton);
