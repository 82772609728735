import { Ref, memo, useMemo, useState } from "react";
import { RadioGroup, Radio, RadioGroupProps } from "react-aria-components";
import { twMerge } from "tailwind-merge";
import Label, { LabelWrapperProps } from "../Label";
import cn from "mxcn";
import "./RadioButton.css";

interface RadioOptionsDatas {
  id: string;
  text: string;
  disabled?: boolean;
}

export interface Props extends RadioGroupProps {
  className?: string;
  containerClassName?: string;
  defaultSelected?: string;
  values: RadioOptionsDatas[];
  labelProps?: Omit<LabelWrapperProps, "children, required, disabled">;
  description?: string;
  variant?: { size?: "sm" | "md" };
}

const RadioButton = ({
  containerClassName,
  className = "",
  values,
  defaultSelected,
  labelProps,
  description,
  variant = { size: "md" },
  ...props
}: Props) => {
  const [selected, setSelected] = useState<string | null>(props.value ?? null);
  const [isFocused, setFocused] = useState<boolean>(false);
  const handleOnChange = (value: string) => {
    if (props.onChange) props.onChange(value);
    setSelected(value);
  };

  const selectedIndex = useMemo(
    () =>
      selected !== null ? values.findIndex((elem) => elem.id === selected) : -1,
    [selected],
  );

  return (
    <RadioGroup
      {...props}
      onChange={handleOnChange}
      className={
        "react-aria-RadioGroup flex flex-col space-y-2 disabled:opacity-50"
      }
    >
      {({}) => (
        <>
          <Label.Wrapper {...labelProps}>
            <div>
              <div
                className={cn(
                  "inline-block p-[4px] h-[46px] flex-none rounded-full border bg-white group-focus:border-mr-cyan-300",
                  "border-mr-gray-1000",
                  {
                    "border-mr-orange-400": props.isInvalid,
                    "border-mr-cyan-400": isFocused,
                  },
                )}
              >
                <div
                  className={cn(
                    "react-aria-RadioGroup-container",
                    "h-full inline-flex items-center",
                    containerClassName,
                    { active: selectedIndex !== -1 },
                  )}
                  style={
                    {
                      "--radio-count": values.length,
                      "--radio-selected": selectedIndex,
                    } as React.CSSProperties
                  }
                >
                  {values &&
                    values.length > 0 &&
                    values.map((elem, index) => (
                      <Radio
                        className={twMerge(
                          variant.size === "md" ? "px-4 py-2" : "px-2 py-1",
                          " focus:border-mr-cyan-400 selected:text-white transition-colors text-mr-gray-900 lg:rounded-full cursor-pointer text-center m-1 rounded-sm relative z-10",
                          className,
                        )}
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                        key={index}
                        value={elem.id}
                      >
                        {elem.text}
                      </Radio>
                    ))}
                </div>
              </div>
            </div>
          </Label.Wrapper>
        </>
      )}
    </RadioGroup>
  );
};

export default memo(RadioButton);
