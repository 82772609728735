import {
  PostBatchEmployeeGroupData,
  fetchCompanyParameters,
  getAllCompanies,
  getCompany,
  patchCompany,
  postBatchEmployeeGroup,
  postRegisterField,
  postUnregisterField,
} from "../api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export function useGetCompanies() {
  const {
    isLoading: isCompaniesLoading,
    error: errorOnCompanies,
    data: companies,
  } = useQuery({
    queryKey: ["companies"],
    queryFn: () => getAllCompanies()
  });

  return {
    companies: companies?.["hydra:member"],
    totalItems: companies?.["hydra:totalItems"],
    isCompaniesLoading,
    errorOnCompanies,
  };
}

export function useCompany(
  companyId: string,
  option: { enable_parameters: boolean } = { enable_parameters: false }
) {

  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey: ["companies", companyId],
    queryFn: ({ signal }) => getCompany(companyId, signal),
  });

  const parametersQuery = useQuery({
    queryKey: ["company_parameters", companyId],
    queryFn: () => fetchCompanyParameters(companyId),
    enabled: option.enable_parameters,
  });

  const batchEmployeeGroup = useMutation({
    mutationFn: (data: PostBatchEmployeeGroupData) =>
      postBatchEmployeeGroup(companyId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["companies", companyId] });
      queryClient.invalidateQueries({ queryKey: ["registrations"] });
    },
  });

  const patch = useMutation({
    mutationFn: ({ id, ...data }: RequireAtLeastOne<CompanyParameters>) =>
      patchCompany({ id: id || companyId, ...data }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["companies", companyId] });
    },
  });

  const postRegisterFieldQuery = useMutation({
    mutationFn: (data: PostRegisterFieldParam) => postRegisterField(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["companies", companyId] });
      queryClient.invalidateQueries({ queryKey: ["company_parameters"] });
    },
  });

  const postUnregisterFieldQuery = useMutation({
    mutationFn: (data: PostUnregisterFieldParam) => postUnregisterField(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["companies", companyId] });
      queryClient.invalidateQueries({ queryKey: ["company_parameters"] });
    },
  });

  return {
    company: query.data,
    query,
    parametersQuery,
    batchEmployeeGroup,
    patch,
    postRegisterFieldQuery,
    postUnregisterFieldQuery,
  };
}
