import { UseQueryResult, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getDPAECollection, getDPAE, patchDPAE } from "../api/dpae";


export function useDPAE(
  companyId: string
) {
  const queryClient = useQueryClient();

  return {
    /**
     * Get a single DPAE, if no DPAEid given, empty DPAE will be returned.
     * @param DPAEId - The expected ID of the DPAE
     * @returns DPAEDetail
     */ 
    Get : (dpaeId: string|undefined): UseQueryResult => {
      return useQuery({
        queryKey: ["dpae", companyId, dpaeId ? dpaeId : "empty"],
        queryFn: ({ signal }) => getDPAE(companyId, dpaeId, signal),
      });
    },
    /**
     * Patch a single DPAE, will replace all informations inside with the DEFINED informations.
     * @param dpae<DPAE> - The expected ID of the DPAE
     * @returns DPAE
     */ 
    Patch : (dpae: DPAEWithId) => useMutation({
      mutationFn: (existingDPAE: DPAEWithId) => patchDPAE(existingDPAE),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["dpae", companyId, dpae.id] });
      },
    }),
    Post : () => {
      alert('not implemented yet')
    },
    Remove : () => {
      alert('not implemented yet')
    }
  }
}

export function useDPAECollection(
  companyId: string
) {
  return { 
    Get: (): UseQueryResult => {
      return useQuery({
        queryKey: ["dpae", companyId],
        queryFn: ({ signal }) => getDPAECollection(companyId, signal),
      })
    }
   };
}