import axios from 'axios';
import { useMemo } from 'react';
import { ADDRESS_END_POINT } from '../constants/constant';
import { useCommonOptions } from './useCommonOptions';
import { useQuery } from '@tanstack/react-query';

interface GetGouvAddress {
    type: string;
    version: string;
    features: GouvAddress[];
    attribution: string;
    licence: string;
    query: string;
    limit: number;
}

interface GouvAddress {
    type: string;
    geometry: {
        type: string;
        coordinates: number[];
    };
    properties: {
        city: string;
        postcode: string;
        name: string;
        label: string;
        id: string;
        citycode: string;
        context: string;
        importance: number;
        score: number;
        type: string;
        x: number;
        y: number;
        housenumber: string;
        street: string;
        street_suffix?: string;
        street_suffix_id?: string;
    };
}

export function useAddress(address: string) {
    const { street_suffixes } = useCommonOptions();

    const query = useQuery({
        queryKey: ['gouv_address', address],
        queryFn: () => getAddresses(address.replace(/^\s+/g, '')),
        enabled: address.replace(/\s/g, '').length >= 3,
    });

    const address_options: { id: string; text: string }[] = query.data
        ? query.data.map((address) => ({
              id: address.properties.id,
              text: `${address.properties.label}`,
          }))
        : [];

    console.log('address', address_options);

    const getSuffixId = (suffix: string) =>
        street_suffixes?.find(
            ({ name }) => suffix.toLowerCase() === name.toLowerCase()
        )?.['@id'];

    async function getAddresses(address: string) {
        const {
            data: { features },
        } = await axios.get<GetGouvAddress>(
            `${ADDRESS_END_POINT.ADDRESSES}${encodeURIComponent(address)}`
        );

        return features.map((addr) => {
            return {
                ...addr,
                properties: {
                    ...addr.properties,
                    street_suffix: '',
                    street_suffix_id: '',
                },
            };
        });
    }

    return {
        addresses: query.data,
        address_options,
        isLoading: query.isLoading,
    };
}
