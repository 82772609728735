import axiosModule, { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { AGENT_FIXTURE } from "../fixtures";
import { axiosPrivate, axiosPrivatePatch } from "../api/axios";
import { AUTH_END_POINT } from "../constants";
import { useAuth } from "./useAuth";
import { RegistrationData } from "../types/employee";

export interface Steps {
  step: number;
  title: string;
}
export interface RegistrationFromApi {
  "hydra:member": Registration[];
}

export interface Registration {
  "@id": string;
  "@type": string;
  abattement: string;
  company: {
    "@id": string;
    name: string;
  };
  createdBy: string;
  created_at: string;
  current_step: number;
  employee_group: string;
  is_removed: boolean;
  status_actor: boolean;
  status_prod: string;
  user: string;
}

export interface Agent {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  assistant: string;
  phone: string;
  fix_phone: string;
}

export interface Picture {
  id: string;
  thumbnail: string;
  type: string;
  alt: string;
}

export interface Question {
  id: string;
  title: string;
  name: string;
  type: string;
}

export interface Document {
  id: string;
  thumbnail?: string;
  date: string;
  pdf?: string;
}

export interface FormOptions {
  isSecondGuardRequired: boolean;
}

const DEFAULT_DATA_FORM_OPTIONS = {
  isSecondGuardRequired: true,
};

const DEFAULT_DATA_DOCUMENTS = [
  {
    id: "1",
    thumbnail:
      "https://www.carpentras.fr/fileadmin/_processed_/a/8/csm_specimen-cni_6a9ab5695d.png",
    date: "01/01/0101 01:01",
  },
  {
    id: "2",
    thumbnail:
      "https://upload.wikimedia.org/wikipedia/commons/2/2b/French_Identity_card_1988_-_1994_Back.jpg",
    date: "01/01/0101 01:01",
  },
  {
    id: "3",
    date: "01/01/0101 01:01",
    pdf: "https://www.africau.edu/images/default/sample.pdf",
  },
  {
    id: "4",
    date: "01/01/0101 01:01",
    pdf: "https://www.africau.edu/images/default/sample.pdf",
  },
];

const DEFAULT_DATA_QUESTIONS = [
  {
    id: "1",
    title: "Salarié handicapé",
    name: "salarie_handicape",
    type: "switch",
  },
  {
    id: "2",
    title: "Carte d'abonnement SNCF",
    name: "carte_dabonnement_sncf",
    type: "date",
  },
  {
    id: "3",
    title: "Fidelité SNCF",
    name: "fidelite_sncf",
    type: "num",
  },
  {
    id: "4",
    title: "Notes",
    name: "notes",
    type: "text",
  },
];

const DEFAULT_DATA_PICTURES = [
  {
    id: "1",
    thumbnail:
      "https://us.123rf.com/450wm/photosvit/photosvit2009/photosvit200908702/156171181-entretien-de-la-barbe-barbier-%C3%A0-barbe-concept-de-salon-de-coiffure-salon-de-coiffure-produit-pour-co.jpg?ver=6",
    type: "profile",
    alt: "profile picture",
  },
  {
    id: "1",
    thumbnail: "https://thumbs.dreamstime.com/b/homme-debout-24865757.jpg",
    type: "body",
    alt: "profile picture",
  },
  {
    id: "2",
    thumbnail:
      "https://www.radiofrance.fr/s3/cruiser-production/2022/06/5f6ac5ab-37d9-4ca6-8f79-3694fcfec071/560x315_paysage-monet.jpg",
    type: "gallery",
    alt: "gallery photo",
  },
  {
    id: "3",
    thumbnail:
      "https://www.gastronomiac.com/wp/wp-content/uploads/2022/02/Montagne-1.jpg",
    type: "gallery",
    alt: "gallery photo",
  },
  {
    id: "4",
    thumbnail:
      "https://cache.magazine-avantages.fr/data/photo/w1000_ci/5p/randonnees-vers-lacs-de-montagnes2.jpg",
    type: "gallery",
    alt: "gallery photo",
  },
  {
    id: "5",
    thumbnail: "https://thumbs.dreamstime.com/b/homme-debout-24865757.jpg",
    type: "gallery",
    alt: "gallery photo",
  },
];

const DEFAULT_DATA_AGENTS = [AGENT_FIXTURE, AGENT_FIXTURE];

export default function useFilm(registrationId: string | undefined) {
  const { auth } = useAuth();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [regStep, setRegStep] = useState<number>(0);
  const [status, setStatus] = useState<string>("");
  const [registrations, setRegistrations] = useState<Registration[]>();
  const [registration, setRegistration] = useState<Registration>();
  const [agents, setAgents] = useState<Agent[] | undefined>(undefined);
  const [pictures, setPictures] = useState<Picture[] | undefined>(undefined);
  const [questions, setQuestions] = useState<Question[] | undefined>(undefined);
  const [documents, setDocuments] = useState<Document[] | undefined>(undefined);
  const [registrationData, setRegistrationData] = useState<RegistrationData>();
  const [formOptions, setFormOptions] = useState<FormOptions | undefined>(
    undefined
  );

  useEffect(() => {
    getRegistrations();
    getRegistration();
    getAgents();
    getPictures();
    getQuestions();
    getDocuments();
    getFormOptions();
    getRegistrationData();
  }, []);

  useEffect(() => {
    getRegistrationData();
  }, [registration]);

  async function getRegistrations() {
    if (!auth) {
      return;
    }

    setIsLoading(true);
    try {
      const { data } = await axiosPrivate.get<RegistrationFromApi>(
        `${AUTH_END_POINT.USERS}/${auth.id}${AUTH_END_POINT.REGISTRATION.ALL}`
      );
      setIsLoading(false);
      setRegistrations(data["hydra:member"]);
    } catch (error) {
      if (axiosModule.isAxiosError(error)) {
        console.error(error);
        setIsLoading(false);
        return error.message;
      }
    }
  }

  async function getRegistration() {
    if (!auth || registrationId === undefined) {
      return;
    }

    setIsLoading(true);
    try {
      const { data } = await axiosPrivate.get<Registration>(
        `${AUTH_END_POINT.REGISTRATION.ALL}/${registrationId}`
      );
      setIsLoading(false);
      setRegistration(data);
      setRegStep(data.current_step);
    } catch (error) {
      if (axiosModule.isAxiosError(error)) {
        console.error(error);
        setIsLoading(false);
        return error.message;
      }
    }
  }

  async function getRegistrationData() {
    if (!auth || registration === undefined) {
      return;
    }

    setIsLoading(true);
    try {
      const { data } = await axiosPrivate.get<RegistrationData>(
        `${registration.company["@id"]}${registration["@id"]}`
      );
      setIsLoading(false);
      setRegistrationData(data);
      console.log("registration data : ", data);
    } catch (error) {
      if (axiosModule.isAxiosError(error)) {
        console.error(error);
        setIsLoading(false);
        return error.message;
      }
    }
  }

  // --- STEP GETTERS ---

  async function getStep1() {
    if (!auth) {
      return;
    }

    setIsLoading(true);
    try {
      const { data } = await axiosPrivate.get<RegistrationFromApi>(
        `${AUTH_END_POINT.USERS}/${auth.id}${AUTH_END_POINT.REGISTRATION.ALL}`
      );
      setIsLoading(false);
      // setRegistrations(data["hydra:member"][0]);
      console.log("reg ->", data["hydra:member"]);
    } catch (error) {
      if (axiosModule.isAxiosError(error)) {
        console.error(error);
        setIsLoading(false);
        return error.message;
      }
    }
  }

  async function getStep2() {
    if (!auth) {
      return;
    }

    setIsLoading(true);
    try {
      const { data } = await axiosPrivate.get<RegistrationFromApi>(
        `${AUTH_END_POINT.USERS}/${auth.id}${AUTH_END_POINT.REGISTRATION.ALL}`
      );
      setIsLoading(false);
      // setRegistrations(data["hydra:member"][0]);
      console.log("reg ->", data["hydra:member"]);
    } catch (error) {
      if (axiosModule.isAxiosError(error)) {
        console.error(error);
        setIsLoading(false);
        return error.message;
      }
    }
  }

  async function getStep3() {
    if (!auth) {
      return;
    }

    setIsLoading(true);
    try {
      const { data } = await axiosPrivate.get<RegistrationFromApi>(
        `${AUTH_END_POINT.USERS}/${auth.id}${AUTH_END_POINT.REGISTRATION.ALL}`
      );
      setIsLoading(false);
      // setRegistrations(data["hydra:member"][0]);
      console.log("reg ->", data["hydra:member"]);
    } catch (error) {
      if (axiosModule.isAxiosError(error)) {
        console.error(error);
        setIsLoading(false);
        return error.message;
      }
    }
  }

  async function getStep4() {
    if (!auth) {
      return;
    }

    setIsLoading(true);
    try {
      const { data } = await axiosPrivate.get<RegistrationFromApi>(
        `${AUTH_END_POINT.USERS}/${auth.id}${AUTH_END_POINT.REGISTRATION.ALL}`
      );
      setIsLoading(false);
      // setRegistrations(data["hydra:member"][0]);
      console.log("reg ->", data["hydra:member"]);
    } catch (error) {
      if (axiosModule.isAxiosError(error)) {
        console.error(error);
        setIsLoading(false);
        return error.message;
      }
    }
  }

  async function getStep5() {
    if (!auth) {
      return;
    }

    setIsLoading(true);
    try {
      const { data } = await axiosPrivate.get<RegistrationFromApi>(
        `${AUTH_END_POINT.USERS}/${auth.id}${AUTH_END_POINT.REGISTRATION.ALL}`
      );
      setIsLoading(false);
      // setRegistrations(data["hydra:member"][0]);
      console.log("reg ->", data["hydra:member"]);
    } catch (error) {
      if (axiosModule.isAxiosError(error)) {
        console.error(error);
        setIsLoading(false);
        return error.message;
      }
    }
  }

  async function getStep6() {
    if (!auth) {
      return;
    }

    setIsLoading(true);
    try {
      const { data } = await axiosPrivate.get<RegistrationFromApi>(
        `${AUTH_END_POINT.USERS}/${auth.id}${AUTH_END_POINT.REGISTRATION.ALL}`
      );
      setIsLoading(false);
      // setRegistrations(data["hydra:member"][0]);
      console.log("reg ->", data["hydra:member"]);
    } catch (error) {
      if (axiosModule.isAxiosError(error)) {
        console.error(error);
        setIsLoading(false);
        return error.message;
      }
    }
  }

  async function getAgents() {
    setIsLoading(true);
    try {
      // PUT HERE AXIOS REQUEST
      setTimeout(() => {
        setIsLoading(false);
        setAgents(DEFAULT_DATA_AGENTS);
      }, 500);
    } catch (error) {
      // TODO Handle Error
      // if (axiosModule.isAxiosError(error)) {
      //   const err = error as AxiosError;
      //   setIsLoading(false);
      //   console.error(err);
      //   const errorText = getFilmError(err);
      //   setStatus(errorText);
      // }
    }
  }

  async function getPictures() {
    setIsLoading(true);
    try {
      // PUT HERE AXIOS REQUEST
      setTimeout(() => {
        setIsLoading(false);
        setPictures(DEFAULT_DATA_PICTURES);
      }, 500);
    } catch (error) {
      // TODO Handle Error
      // if (axiosModule.isAxiosError(error)) {
      //   const err = error as AxiosError;
      //   setIsLoading(false);
      //   console.error(err);
      //   const errorText = getFilmError(err);
      //   setStatus(errorText);
      // }
    }
  }

  async function getQuestions() {
    setIsLoading(true);
    try {
      // PUT HERE AXIOS REQUEST
      setTimeout(() => {
        setIsLoading(false);
        setQuestions(DEFAULT_DATA_QUESTIONS);
      }, 500);
    } catch (error) {
      // TODO Handle Error
      // if (axiosModule.isAxiosError(error)) {
      //   const err = error as AxiosError;
      //   setIsLoading(false);
      //   console.error(err);
      //   const errorText = getFilmError(err);
      //   setStatus(errorText);
      // }
    }
  }

  async function getDocuments() {
    setIsLoading(true);
    try {
      // PUT HERE AXIOS REQUEST
      setTimeout(() => {
        setIsLoading(false);
        setDocuments(DEFAULT_DATA_DOCUMENTS);
      }, 500);
    } catch (error) {
      // TODO Handle Error
      // if (axiosModule.isAxiosError(error)) {
      //   const err = error as AxiosError;
      //   setIsLoading(false);
      //   console.error(err);
      //   const errorText = getFilmError(err);
      //   setStatus(errorText);
      // }
    }
  }

  async function getFormOptions() {
    setIsLoading(true);
    try {
      // PUT HERE AXIOS REQUEST
      setTimeout(() => {
        setIsLoading(false);
        setFormOptions(DEFAULT_DATA_FORM_OPTIONS);
      }, 500);
    } catch (error) {
      // TODO Handle Error
      // if (axiosModule.isAxiosError(error)) {
      //   const err = error as AxiosError;
      //   setIsLoading(false);
      //   console.error(err);
      //   const errorText = getFilmError(err);
      //   setStatus(errorText);
      // }
    }
  }

  async function submitStep1(data: any, isMinor: boolean) {
    setIsLoading(true);
    console.log("isMinor ", isMinor);
    console.log("data ", data);

    if (registration) {
      try {
        const response = await axiosPrivate.post<any>(
          `${registration.company["@id"]}${registration["@id"]}/step_1`,
          {
            civility: data.civility,
            birthday: data.birthday,
            nationality: data.nationality,
            birth_country: data.birth_country,
            birth_city: data.birth_city,
            social_security_number: data.social_security_number,
            social_security_center_address: data.social_security_center_address,
            legal_representative_1: isMinor
              ? {
                email: data.guard1_email,
                first_name: data.guard1_firstName,
                last_name: data.guard1_lastName,
                phone: data.guard1_phone,
              }
              : undefined,
            legal_representative_2: isMinor
              ? {
                email: data.guard2_email,
                first_name: data.guard2_firstName,
                last_name: data.guard2_lastName,
                phone: data.guard2_phone,
              }
              : undefined,
            legal_rep_1_family_relationship: isMinor
              ? data.guard1_familyTie
              : undefined,
            legal_rep_2_family_relationship: isMinor
              ? data.guard2_familyTie
              : undefined,
          }
        );
        setIsLoading(false);
        console.log("SUCCESS s1", response);
        if (response.status === 201) {
          getRegistration();
        }
      } catch (error) {
        // @ts-ignore
        console.error(error.response.data["hydra:description"]);
        setIsLoading(false);
      }
    }
  }

  async function submitStep2(data: any) {
    setIsLoading(true);

    if (registration) {
      try {
        const response = await axiosPrivate.post<any>(
          `${registration.company["@id"]}${registration["@id"]}/step_2`,
          {
            family_situation: data.family_status,
            number_of_children: parseInt(data.children_number),
            number_of_dependents: parseInt(data.dependant_number),
            emergency_contact_name: data.emergency_contact_name,
            emergency_contact_phone: data.emergency_contact_phone,
            retired: data.is_retired,
            retirement_date: data.is_retired ? data.retirement_date : undefined,
            // "fiscal_address": {
            //   "address_name": data.address.address_name,
            //   "country": data.address.country,
            //   "postal_code": data.address.postal_code,
            //   "street_type": data.address.street_type,
            //   "street_name": data.address.street_name,
            //   "street_number": data.address.street_number,
            //   "address_supplement_1": data.address.address_supplement_1,
            //   "address_supplement_2": data.address.address_supplement_1,
            //   "street_suffix": data.address.street_suffix,
            //   "commune": data.address.commune
            // }
          }
        );
        setIsLoading(false);
        console.log("SUCCESS s2", response);
        if (response.status === 201) {
          getRegistration();
        }
      } catch (error) {
        // @ts-ignore
        console.error(error.response.data["hydra:description"]);
        setIsLoading(false);
      }
    }
  }

  async function submitStep3(data: any) {
    setIsLoading(true);

    if (registration) {
      try {
        const response = await axiosPrivate.post<any>(
          `${registration.company["@id"]}${registration["@id"]}/step_3`,
          {
            abattement: data.is_deduction,
            spectacle_leaves_number: data.entertainment_number,
            intermittency: data.is_entertainment_worker,
            pseudo: data.professional_nickname,
            medical_exam_validity_date: data.last_medical_check_up,
            // "agents": {
            //   "@context": "string",
            //   "@id": "string",
            //   "@type": "string"
            // },
          }
        );
        setIsLoading(false);
        console.log("SUCCESS s3 ", response);
        if (response.status === 201) {
          getRegistration();
        }
      } catch (error) {
        // @ts-ignore
        console.error(error.response.data["hydra:description"]);
        setIsLoading(false);
      }
    }
  }

  async function submitStep4(data: any) {
    setIsLoading(true);
    console.log("received data ", data);

    if (registration) {
      try {
        const response = await axiosPrivate.post<any>(
          `${registration.company["@id"]}${registration["@id"]}/step_4`,
          {
            has_driver_license_a: data.is_bike_driving_license,
            has_driver_license_b: data.is_car_driving_license,
          }
        );
        setIsLoading(false);
        console.log("SUCCESS s4 ", response);
        if (response.status === 201) {
          getRegistration();
        }
      } catch (error) {
        // @ts-ignore
        console.error(error.response.data["hydra:description"]);
        setIsLoading(false);
      }
    }
  }

  async function submitStep5(data: any) {
    setIsLoading(true);
    console.log("received data 5 ", data);

    if (registration) {
      try {
        const response = await axiosPrivate.post<any>(
          `${registration.company["@id"]}${registration["@id"]}/step_5`,
          {
            // "gallery": {},
            // "specificQuestions": [
            //   "string"
            // ],
            // "castingQuestions": [
            //   "string"
            // ]
          }
        );
        setIsLoading(false);
        console.log("response returned ", response);
      } catch (error) {
        // @ts-ignore
        console.error(error.response.data["hydra:description"]);
        setIsLoading(false);
      }
    }
  }

  async function submitStep6() {
    setIsLoading(true);

    if (registration) {
      try {
        const response = await axiosPrivate.post<any>(
          `${registration.company["@id"]}${registration["@id"]}/step_6`,
          {
            // no params
          }
        );
        setIsLoading(false);
        console.log("response returned ", response);
      } catch (error) {
        // @ts-ignore
        console.error(error.response.data["hydra:description"]);
        setIsLoading(false);
      }
    }
  }

  return {
    registrations,
    registration,
    registrationData,
    regStep,
    submitStep1,
    submitStep2,
    submitStep3,
    submitStep4,
    submitStep5,
    submitStep6,
    agents,
    pictures,
    questions,
    documents,
    isLoading,
    status,
    formOptions,
  };
}
