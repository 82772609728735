import React, {
    useState,
    useRef,
    forwardRef,
    ReactNode,
    useImperativeHandle,
} from 'react';
import { useDrop } from 'react-aria';
import {
    BsFileEarmarkCheckFill,
    BsFileEarmarkExcel,
    BsCloudArrowUpFill,
    BsFilePdf,
    BsFiletypeJpg,
    BsFiletypePdf,
    BsFiletypePng,
} from 'react-icons/bs';
import { Button, FileTrigger } from 'react-aria-components';
import './DropZone.css';
import { set } from 'lodash';

interface DropZoneProps {
    imageType: string[];
    onChange?: (files: File[]) => any;
    allowsMultiple?: boolean;
    maxfileSize?: number;
    fileName?: string;
    uploadDate?: string;
    format?: string;
    fileId?: string;
    disabled?: boolean;
}

function DropZone(
    {
        allowsMultiple,
        maxfileSize,
        imageType,
        format,
        uploadDate,
        fileName,
        onChange = () => {},
        disabled = false,
        fileId,
    }: DropZoneProps,
    ref: any
) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFilesAccepted, setIsFilesAccepted] = useState<boolean>(true);
    const [uploaded, setUploaded] = useState<boolean>(false);
    const [lengthError, setLengthError] = useState<boolean>(false); // Corrected typo
    const [sizeError, setSizeError] = useState<boolean>(false);
    const dropRef = useRef(null);

    const fileSize = maxfileSize || 5000000;

    useImperativeHandle(ref, () => ({
        setLoading: setIsLoading,
    }));

    const { dropProps, isDropTarget } = useDrop({
        ref: dropRef,
        async onDrop(e) {
            const acceptedTypes = imageType;
            let hasInvalidFormat = false;
            let sizeError = false;
            const items = await Promise.all(
                e.items.map(async (item) => {
                    if (
                        item.kind === 'file' &&
                        acceptedTypes.includes(item.type)
                    ) {
                        const file = await item.getFile();
                        if (file.size <= fileSize) {
                            return file;
                        } else {
                            sizeError = true;
                            return;
                        }
                    } else {
                        hasInvalidFormat = true;
                    }
                })
            );
            if (!allowsMultiple && items.length > 1) {
                setLengthError(true);
                setIsFilesAccepted(false);
                return;
            }

            if (sizeError) {
                setSizeError(true);
                setIsFilesAccepted(false);
                return;
            }

            if (hasInvalidFormat) {
                setIsFilesAccepted(false);
                return;
            }

            setIsFilesAccepted(true);
            setUploaded(true);
            onChange(items.filter((item) => item !== undefined) as File[]);
        },
        //@ts-ignore
	isDisabled: disabled,
    });

    return (
        <div
            {...dropProps}
            role="button"
            tabIndex={0}
            ref={dropRef}
            className={`droppable ${isDropTarget ? 'target' : ''} ${isFilesAccepted ? '' : 'type-error'} react-aria-DropZone`}
        >
            <FileTrigger
                allowsMultiple={allowsMultiple}
                acceptedFileTypes={imageType}
                onSelect={(e) => {
                    if (e !== null) {
                        const files = Array.from(e) as File[];
                        onChange(files);
                        setUploaded(true);
                        setIsFilesAccepted(true);
                    }
                }}
            >
                <Button isDisabled={disabled}>
                    {isFilesAccepted ? (
                        <>
                            {isLoading ? (
                                <div className="loading-container">
                                    <div className="custom-loader"></div>
                                    Dépôt en cours
                                </div>
                            ) : (
                                <>
                                    {uploaded || fileId ? (
                                        fileName && uploadDate ? (
                                            <>
                                                {format ? (
                                                    <>
                                                        {format ===
                                                            'image/png' && (
                                                            <BsFiletypePng className="dropzone_icon" />
                                                        )}
                                                        {format ===
                                                            'image/jpeg' && (
                                                            <BsFiletypeJpg className="dropzone_icon" />
                                                        )}
                                                        {format ===
                                                            'application/pdf' && (
                                                            <BsFiletypePdf className="dropzone_icon" />
                                                        )}
                                                    </>
                                                ) : (
                                                    <BsFileEarmarkCheckFill className="dropzone_icon" />
                                                )}

                                                <h3 className="font-bold text-md">
                                                    {fileName}
                                                </h3>
                                                <p>Déposé le {uploadDate}</p>
                                            </>
                                        ) : (
                                            <>
                                                <BsFileEarmarkCheckFill className="dropzone_icon" />
                                                Vous pouvez modifier le fichier
                                                en glissant-déposant un nouveau
                                                fichier ou{' '}
                                                <span>
                                                    {' '}
                                                    parcourir l'ordinateur
                                                </span>
                                            </>
                                        )
                                    ) : (
                                        <>
                                            <BsCloudArrowUpFill className="dropzone_icon" />
                                            Glissez-déposez un fichier ou{' '}
                                            <span> parcourez l'ordinateur</span>
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <BsFileEarmarkExcel
                                size={'30'}
                                className="m-auto mb-2"
                            />
                            {lengthError && (
                                <>
                                    Vous ne pouvez pas uploader plusieurs
                                    fichiers
                                </>
                            )}
                            {sizeError && (
                                <>
                                    Fichier trop volumineux, veuillez à ce que
                                    le fichier ne dépasse pas 5Mo
                                </>
                            )}
                            {!lengthError && !sizeError && (
                                <>Format de fichier invalide</>
                            )}
                        </>
                    )}
                </Button>
            </FileTrigger>
        </div>
    );
}

export default forwardRef(DropZone);
