import React from "react";

function Loading() {
  return (
    <div className="absolute top-0 left-0 w-full h-full bg-table-grey bg-opacity-40 flex pt-60 justify-center">
      Chargement...
    </div>
  );
}

export default Loading;
