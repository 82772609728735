export const CONVENTIONS_FIXTURE: GetConventionsResponse = {
  "@context": "/contexts/Convention",
  "@id": "/conventions",
  "@type": "hydra:Collection",
  "hydra:member": [
    {
      "@id": "/conventions/1",
      "@type": "Convention",
      id: 1,
      name: "Audiovisuel",
      projects: [
        "/projects/1ed2aa09-54c7-6274-bf62-e523c7bce7b5",
        "/projects/1ed2abbd-e112-61c4-9420-39823a9cd8fc",
      ],
    },
    {
      "@id": "/conventions/2",
      "@type": "Convention",
      id: 2,
      name: "Cinéma",
      projects: [],
    },
    {
      "@id": "/conventions/3",
      "@type": "Convention",
      id: 3,
      name: "Sans convention",
      projects: [],
    },
  ],
  "hydra:totalItems": 3,
};
