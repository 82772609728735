import { Input, Label, SearchField } from "react-aria-components";
import Button from "../NewButton";
import useTableContext from "./useTableContext";

function SearchText() {

  const {searchText, setSearchText, setPage, page } = useTableContext();

  const handleChangeSearchField = (value: string) => {

    if (page !== 1 && setPage) {
      setPage(1);
    }

    if (setSearchText) {
      setSearchText(value)
    }
  };

  return (
    <>
      <SearchField onChange={handleChangeSearchField} value={searchText} className="react-aria-SearchField flex items-center">
        <Label className="pr-2 text-sm font-normal">Search</Label>
        <Input />
        <Button>✕</Button>
      </SearchField>
    </>
  );
}

export default SearchText;
