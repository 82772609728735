import DPAE from "../components/ProjectForm/DPAE";
import { API_BASE_URL, AUTH_END_POINT } from "../constants";
import { axiosPrivate } from "./axios";
import axiosModule, { AxiosError } from "axios";

/**
 * Please not that none of these hooks are called getDPAE 
 * because an og dpae is not used as it self, it always has 
 * structured / bound data.
 */

/* Used for any DPAE related API call, inherits DPAEDetail definition */
interface DPAEApiResponseDefinition extends DPAEDetail {
  "@context": string,
  "@id": string,
  "@type": string,
}
/* Used for a collection of DPAEs related API call */
interface DPAECollectionApiResponseDefinition {
  "@context": string,
  "@id": string,
  "@type": string,
  "hydra:member": DPAE[],
  "hydra:totalItems": number,
  "hydra:view"?: {},
  "hydra:search"?: {}
}

/**
 * Retrieves DPAEs for a specific company.
 *
 * @param {string} companyId - The ID of the company
 * @param {AbortSignal} [signal] - An optional signal to abort the request
 */
export async function getDPAECollection(companyId: string, signal?: AbortSignal) {
  const { data } = await axiosPrivate.get<DPAECollectionApiResponseDefinition>(
    `${API_BASE_URL}${AUTH_END_POINT.COMPANIES.DPAES(companyId)}`,
    { signal }
  ).then(res => {
    /**
     * Date comes from server as String but Typescript doesn't recognize it as a Date
     */
    if (res.data["hydra:member"]) {
      res.data["hydra:member"].forEach((dpae: DPAE) => {
        if (dpae.dateNaissanceSalarie) {
          dpae.dateNaissanceSalarie = new Date(dpae.dateNaissanceSalarie);
        }
        if (dpae.dateDebutContrat) {
          dpae.dateDebutContrat = new Date(dpae.dateDebutContrat);
        }
        if (dpae.dateFinContrat) {
          dpae.dateFinContrat = new Date(dpae.dateFinContrat);
        }
      })
    }
    return res;
  });

  return data["hydra:member"]; 
} 


export async function getDPAEs(params: any) {
  try {
    const response = await axiosPrivate.get(
      `${API_BASE_URL}${AUTH_END_POINT.COMPANIES.DPAES(params.companyId)}`,
      { params }
    ).then(res => {
      /**
       * Date comes from server as String but Typescript doesn't recognize it as a Date
       */
      if (res.data["hydra:member"]) {
        res.data["hydra:member"].forEach((dpae: DPAE) => {
          if (dpae.dateNaissanceSalarie) {
            dpae.dateNaissanceSalarie = new Date(dpae.dateNaissanceSalarie);
          }
          if (dpae.dateDebutContrat) {
            dpae.dateDebutContrat = new Date(dpae.dateDebutContrat);
          }
          if (dpae.dateFinContrat) {
            dpae.dateFinContrat = new Date(dpae.dateFinContrat);
          }
        })
      }
      return res;
    });
    return response?.data;
  } catch (err) {
    if (axiosModule.isAxiosError(err)) {
      const error = err as AxiosError;
      throw error?.response?.status;
    } else {
      throw err;
    }
  }
}

/**
 * Retrieves DPAE Detail for a specific DPAE ID.
 *
 * @param {string} companyId - The ID of the company
 * @param {string} DPAEId - The ID of the DPAE
 * @param {AbortSignal} [signal] - An optional AbortSignal for cancelling the request
 * @returns {Promise<DPAEApiResponseDefinition>|undefined}
 */
export async function getDPAE(companyId: string, DPAEId?: string, signal?: AbortSignal) {
  if (!DPAEId) {
    return {} as DPAEDetail;
  }

  const { data: dpaeDetail } = await axiosPrivate.get<DPAEApiResponseDefinition>(
    `${API_BASE_URL}${AUTH_END_POINT.COMPANIES.DPAES(companyId, DPAEId)}`,
    { signal }
  );

  return dpaeDetail;
}

/**
 * Patch a DPAE with new details
 * @param dpaeDetail - The DPAE detail to be updated
 * @returns boolean
 */
export async function patchDPAE(
  dpae: DPAE
) {
  // @TODO: Implement endpoint
  alert('Not implemented yet, endpoint does not exists');
  /* const { data } = await axiosPrivate.patch<DPAEDetail>(
    `${API_BASE_URL}${AUTH_END_POINT.COMPANIES.DPAES}`,
    dpaeDetail
  ); */
  return true;
};