import { ReactNode } from "react";
import { AlertBase } from "./index";
import { HiOutlineExclamationCircle } from "react-icons/hi";

export interface Props {
  children: ReactNode;
  className?: string;
}

const AlertWarning = ({ children, className = "" }: Props) => (
  <AlertBase
    className={`${className} border-mr-orange-200 bg-mr-orange-100 text-mr-orange-400`}
    icon={<HiOutlineExclamationCircle className="h-6 w-6" />}
    data-testid="AlertWarning"
  >
    {children && children}
  </AlertBase>
);

export default AlertWarning;
