import { Control, Controller, useFormContext } from "react-hook-form";
import Switch, { Props as SwitchProps } from "./Switch";
import { useTranslation } from "react-i18next";
import { isRequired } from "../Input/InputController";

export interface Props extends SwitchProps {
  name: string;
  rules?: any;
  isRequired?: boolean;
  labelProps?: any;
  containerClass?: string;
  switchStyle?: any;
}

const SwitchController = ({ name, rules, onChange, containerClass, switchStyle, ...props }: Props) => {  const { control } = useFormContext();
  const { t } = useTranslation();

  if (props.isRequired || typeof rules !== "undefined") {
    rules = { ...rules, ...isRequired(rules, t) };
  }

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={props.defaultSelected ?? false}
      render={({ field, fieldState: { invalid, error } }) => (
        <Switch
          {...props}
          {...field}
          onChange={(value) => { // Ici, `value` est la nouvelle valeur booléenne du switch
            field.onChange(value); // Informe react-hook-form du changement
            if (onChange) onChange(value); // Exécute le gestionnaire `onChange` personnalisé avec la valeur booléenne
          }}
          // switchStyle
          className={switchStyle}

          containerClass={containerClass}
          isDisabled={props.isDisabled ?? rules?.disabled ?? false}
          isReadOnly={props.isReadOnly ?? rules?.readOnly ?? false}
          isRequired={rules?.required ?? props?.isRequired ?? false}
          isInvalid={invalid}
          labelProps={{
            ...props.labelProps,
            errorLabel: props?.labelProps?.errorLabel
              ? props.labelProps.errorLabel
              : error?.message,
          }}
        />
      )}
    />
  );
};

export default SwitchController;
