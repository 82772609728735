import React from "react";
import { BsTable } from "react-icons/bs";
import Button from "../NewButton";
import useTableContext from "./useTableContext";
import {
  Dialog,
  DialogTrigger,
  Heading,
  ListBox,
  ListBoxItem,
  Modal,
} from "react-aria-components";

function ColumnSelector() {
  const { columns, setColumns } = useTableContext();
  const [errorMessage, setErrorMessage] = React.useState<string>("");

  let allColumns = new Set(columns.filter((c) => !c.hidden).map((c) => c.id));

  // new set from columns that are not hidden

  return (
    <DialogTrigger>
      <Button>
        <BsTable />
      </Button>
      <Modal isDismissable>
        <Dialog>
          <ListBox
            selectionMode="multiple"
            selectedKeys={allColumns}
            onSelectionChange={(n) => {
              //@ts-ignore
              if (n.size === 0) {
                setErrorMessage(
                  "Vous devez avoir au moins une colonne affichée."
                );
                return;
              }

              setErrorMessage("");

              columns.forEach((c) => {
                //@ts-ignore
                c.hidden = !n.has(c.id);
              });

              //@ts-ignore
              setColumns([...columns]);
            }}
          >
            {columns.map((column) => (
              <ListBoxItem key={column.id} id={column.id}>
                {column.title}
              </ListBoxItem>
            ))}
          </ListBox>
          {errorMessage && <p className="text-red-500">{errorMessage}</p>}
        </Dialog>
      </Modal>
    </DialogTrigger>
  );
}

export default ColumnSelector;
