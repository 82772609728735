import { CAISSE_CONGES_SPECTACLES_FIXTURE } from "./caisse_conges_spectacles";
import { COMPANY_FIXTURE } from "./company";
import { CONVENTIONS_FIXTURE } from "./conventions";
import { PRODUCTION_FIXTURE } from "./production";

export const PROJECT_FIXTURE: Project = {
  "@id": "/projects/1ed45845-1123-618a-b5d6-25cac5bbd081",
  "@type": "Project",
  // TODO: Fix this if i'm right
  // @ts-ignore This is not true the back is not sending the production_company object it's sending the UUID
  production_company: PRODUCTION_FIXTURE,
  object_number: "1234567890",
  director: "Mélanie LAURENT",
  production_manager: "Eric HUBERT",
  urssaf_movie_name: "Blade Runner 2049",
  leave_fund: CAISSE_CONGES_SPECTACLES_FIXTURE["hydra:member"][0]["@id"],
  pension_fund: "31 rue de la liberté",
  start_shooting: "2022-10-25T00:00:00+00:00",
  end_shooting: "2022-11-25T00:00:00+00:00",
  signatory_id: "string",
  label: "Film",
  last_update: "12-02-2022",
  last_update_user: "Nicolas Pierre",
  employee: 10,
  profile: 10,
  shot: 10,
  bulletin: 10,
  contract_in_progress: 10,
  contract_done: 10,
  days_off: 10,
  aem: 10,
  // TODO: This type is wrong following the refactor
  // @ts-ignore
  company: COMPANY_FIXTURE,
};

export const PROJECT_REQUEST_FIXTURE: GetProjectResponse = PROJECT_FIXTURE;
