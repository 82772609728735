import { useCallback, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { MENU, MENU_IS_COLLAPSE_DEFAULT, ROLE, SUB_MENU, SUB_MENU_PRODUCTION } from "../constants";
import { MenuArray, defaultState, menuAtom } from "../recoil/menu";
import { useAuth } from "./useAuth";

export function useMenu() {
  const { companyId, projectId, productionId } = useParams();
  const id = companyId || projectId || productionId || "";
  const [menu, setMenu] = useRecoilState(menuAtom);
  const windowWidth = window.innerWidth;
  const location = useLocation();
  const { auth } = useAuth();

  // ---------------------------------------------
  // -               USE_EFFECT
  // ---------------------------------------------
  // If innerWidth < MENU_IS_COLLAPSE_DEFAULT (md breakpoint)
  // collapse menu by default
  useEffect(() => {
    if (windowWidth < MENU_IS_COLLAPSE_DEFAULT) {
      setMenu({ ...menu, isCollapsed: windowWidth < MENU_IS_COLLAPSE_DEFAULT });
    }
  }, []);

  // Update menu items according to user role
  useEffect(() => {
    if (!auth) {
      return;
    }

    const menus: MenuArray[] = auth.roles.map((role): MenuArray => {
      switch (role) {
        case ROLE.STAFF:
          return { title: "Menu staff", items: MENU.STAFF };

        case ROLE.CHARGE_CASTING:
          return { title: "Menu chargé casting", items: MENU.CHARGE_CASTING };

        case ROLE.CHARGE_PRODUCTION:
          return { title: "Menu production", items: MENU.CHARGE_PRODUCTION };

        case ROLE.COMEDIEN:
          return { title: "Menu comédien", items: MENU.COMEDIEN };

        case ROLE.TECHNICIEN:
          return { title: "Menu technicien", items: MENU.TECHNICIEN };

        default:
          return { title: "Menu", items: MENU.COMEDIEN };
      }
    });

    // console.log(menus);
    setMenu((m) => ({
      ...m,
      items: [],
      menus,
    }));

    // if (auth?.roles.find((r) => r === ROLE.CHARGE_CASTING)) {
    //   setMenu((m) => ({
    //     ...m,
    //     items: MENU.CHARGE_CASTING,
    //   }));
    // }
    // if (auth?.roles.find((r) => r === ROLE.CHARGE_PRODUCTION)) {
    //   setMenu((m) => ({
    //     ...m,
    //     items: MENU.CHARGE_PRODUCTION,
    //   }));
    // }
    // if (auth?.roles.find((r) => r === ROLE.COMEDIEN)) {
    //   setMenu((m) => ({
    //     ...m,
    //     items: MENU.COMEDIEN,
    //   }));
    // }
    // if (auth?.roles.find((r) => r === ROLE.TECHNICIEN)) {
    //   setMenu((m) => ({
    //     ...m,
    //     items: MENU.TECHNICIEN,
    //   }));
    // }
  }, [auth]);

  // Update sub menu according to location.pathname
  useEffect(() => {
    if (!id) return;
    const subMenu = SUB_MENU({
      companyId: companyId || "",
      projectId: projectId || "",
      productionId: productionId || "",
    });

    subMenu
      ? setMenu((m) => ({
        ...m,
        subMenu: { ...m.subMenu, item: subMenu },
      }))
      : setMenu((m) => ({
        ...m,
        subMenu: { ...m.subMenu, item: null },
      }));
  }, [location.pathname]);

  // Auto collapse/expand menu when there is a sub menu or not
  useEffect(() => {
    if (menu.subMenu.item && !menu.isCollapsed) {
      setMenu((m) => ({ ...m, isCollapsed: true }));
    }
  }, [menu.subMenu.item]);

  useEffect(() => {
    if (!id && menu.subMenu) {
      setMenu((m) => ({
        ...m,
        isCollapsed: false,
        subMenu: defaultState.subMenu,
      }));
    }
  }, [id]);

  // ---------------------------------------------
  // -               FUNCTION
  // ---------------------------------------------
  const toggleCollapseMenu = useCallback(() => {
    setMenu((m) => ({ ...m, isCollapsed: !m.isCollapsed }));
  }, [setMenu]);

  const toggleCollapseSubMenu = useCallback(() => {
    setMenu((m) => ({
      ...m,
      subMenu: { ...m.subMenu, isCollapsed: !m.subMenu.isCollapsed },
    }));
  }, [setMenu]);

  return { menu, toggleCollapseMenu, toggleCollapseSubMenu };
}
