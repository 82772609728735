import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { ADDRESS_END_POINT } from "../constants/constant";

export interface City {
  nom: string;
  codesPostaux: string[];
  code: string;
  codeDepartement: string;
  codeRegion: string;
  population: string;
}

export function useCity(city: string) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cities, setCities] = useState<City[]>();
  const cities_options = useMemo(
    () =>
      cities &&
      cities.map((city) => ({
        id: city.code,
        text: `${city.codesPostaux[0]} ${city.nom}`,
      })),
    [cities]
  );

  useEffect(() => {
    if (city) {
      getCities(city);
    }
  }, [city]);

  async function getCities(city: string) {
    setIsLoading(true);
    try {
      const response = await axios.get<City[]>(
        `${ADDRESS_END_POINT.CITY}${city}`
      );

      setIsLoading(false);
      setCities(response.data);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setIsLoading(false);
        throw error;
      }
    }
  }

  return { cities, cities_options, isLoading };
}
