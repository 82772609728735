import axios from "axios";
import { useEffect, useState } from "react";
import { ADDRESS_END_POINT } from "../constants/constant";
import { City } from "./useCity";

export function usePostalCode(postalCode: string) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cities, setCities] = useState<City[]>();

  useEffect(() => {
    if (postalCode !== "") {
      getCities(postalCode);
    }
  }, [postalCode]);

  async function getCities(postalCode: string) {
    setIsLoading(true);
    try {
      const response = await axios.get<City[]>(
        `${ADDRESS_END_POINT.POSTAL_CODE}${postalCode}`
      );

      setIsLoading(false);
      setCities(response.data);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setIsLoading(false);
        throw error;
      }
    }
  }

  return { cities, isLoading };
}
