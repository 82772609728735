import { CheckboxProps } from "react-aria-components";
import Checkbox from "../Checkbox/Checkbox";
import cn from "mxcn";
import { InputController } from "../Input";

interface Props extends CheckboxProps {
  text: string;
  labelProps?: any;
}

export default function InlineCheckbox({ text, labelProps, ...props }: Props) {
  return (
    <InputController.Checkbox
      name="checkbox"
      containerClassName="mt-2 flex items-center gap-3"
      labelProps={labelProps}
      {...props}
    >
      <span className="select-none text-sm text-gray-500">{text}</span>
    </InputController.Checkbox>
  );
}
