import { memo } from "react";
import { useMenu } from "../../hooks";
import SubSideNavbarCategory from "./SubSideNavbarCategory";
import { useRecoilState } from "recoil";
import { debugOptionAtom } from "../../recoil/DebugOptions";
import classNames from "classnames";

export interface Props {
  className?: string;
}

const SubSideNavbar = ({ className = "" }: Props) => {
  const {
    menu: { subMenu },
  } = useMenu();

  const [options] = useRecoilState(debugOptionAtom);

  return subMenu.item ? (
    <div
      className={classNames(
        "flex shrink-0 flex-col divide-y divide-gray-700 bg-mr-gray-900 pb-7 pt-[22px] text-gray-300 transition-all relative z-0",
        className,
        {
          "w-[56px]": subMenu.isCollapsed,
          "w-[240px]": !subMenu.isCollapsed,
          "floating_menu": options.floatingMenu,
        }
      )}
      data-testid="SubSideNavbar"
    >
      {subMenu.item?.categories &&
        subMenu.item.categories.map((category, key) => (
          <SubSideNavbarCategory key={key} category={category} />
        ))}
    </div>
  ) : (
    <></>
  );
};

export default memo(SubSideNavbar);
