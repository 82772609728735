export const HAIR_LENGTHS_FIXTURE: GetHairLengthsResponse = {
  "@context": "/contexts/HairLength",
  "@id": "/hair_lengths",
  "@type": "hydra:Collection",
  "hydra:member": [
    {
      "@id": "/hair_lengths/1",
      "@type": "HairLength",
      id: 1,
      name: "long",
    },
    {
      "@id": "/hair_lengths/2",
      "@type": "HairLength",
      id: 2,
      name: "short",
    },
  ],
  "hydra:totalItems": 2,
};
