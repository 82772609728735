import { useMutation, useQuery } from "@tanstack/react-query";
import { getProjects, getProject, patchProject, postProject } from "../api";

export function useProject(projectId?: string) {
  const {
    isLoading: isProjectsLoading,
    error: errorOnProjects,
    data: projects,
  } = useQuery({
    queryKey: ["projects"],
    queryFn: () => getProjects(),
  });

  const {
    isLoading: isProjectLoading,
    error: errorOnProject,
    data: project,
  } = useQuery({
    queryKey: ["projects", projectId],
    queryFn: () => getProject(projectId ?? ''),
    enabled: typeof projectId !== 'undefined',
  });

  const post = useMutation({
    mutationFn: (project: ProjectForm) => postProject(project),
  });

  const patch = useMutation({
    mutationFn: (args: { id: string; project: ProjectForm }) =>
      patchProject(args.id, args.project),
  });

  return {
    // Requests
    projects,
    project,
    patch,
    post,

    // Loadings
    isProjectsLoading,
    isProjectLoading,
    isPatching: patch.isPending,
    isPosting: post.isPending,

    // Errors
    errorOnProjects,
    errorOnProject,
    patchProjectError: patch.error,
    postProjectError: post.error,
  };
}
