import { memo } from "react";
import Tooltip, { Props } from "./Tooltip";

const TertiaryTooltip = ({
  className = "",
  children,
  direction,
  ...props
}: Props) => {
  function getArrowStyleForDirection() {
    switch (direction) {
      case "right":
        return `before:border-r-mr-gray-900 after:border-r-mr-gray-900`;
      case "left":
        return `before:border-l-mr-gray-900 after:border-l-mr-gray-900`;
      case "top":
        return `before:border-t-mr-gray-900 after:border-t-mr-gray-900`;
      case "bottom":
        return `before:border-b-mr-gray-900 after:border-b-mr-gray-900`;
    }
  }
  return (
    <Tooltip
      direction={direction}
      className={`${className} ${getArrowStyleForDirection()} bg-mr-gray-900`}
      data-testid="TertiaryTooltip"
      {...props}
    >
      {children}
    </Tooltip>
  );
};

export default memo(TertiaryTooltip);
