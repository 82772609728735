export const HAIR_TYPES_FIXTURE: GetHairTypesResponse = {
  "@context": "/contexts/HairType",
  "@id": "/hair_type",
  "@type": "hydra:Collection",
  "hydra:member": [
    {
      "@id": "/hair_type/1",
      "@type": "HairType",
      name: "long",
    },
    {
      "@id": "/hair_type/2",
      "@type": "HairType",
      name: "curly",
    },
  ],
  "hydra:totalItems": 2,
};
