export const APP_URL = process.env.REACT_APP_APP_URL;
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const AUTH_BASE_URL = API_BASE_URL;
export const AUTH_END_POINT = {
  LOGIN: "/login_check",
  LOGOUT: "/users/me/logout",
  ME: "/users/me/info",
  RESET_PASSWORD: "/reset-password",
  CHANGE_PASSWORD: "/users/change-password-with-token",
  USERS: "/users",
  PROFILE: (id: string) => `/users/${id}/profile`,
  VERIFY_EMAIL: "verify-email",
  PROJECT: "/projects",
  PRODUCTIONS: "/productions",
  POSITIONS: "/filter_positions",
  INDEMNITIES: "/filter_indemnities",
  SST: "/filter_ssts",
  BILLING_FREQUENCIES: "/billing_frequencies",
  URSSAF_CENTERS: "/urssaf_centers",
  CAISSE_CONGES_SPECTACLE: "/caisse_conges_spectacles",
  COMPANY_TYPES: "/company_types",
  LEGAL_ENTITY_TYPES: "/legal_entity_types",
  CONVENTIONS: "/conventions",
  STREET_SUFFIXES: "/street_suffixes",
  CIVILITIES: "/civilities",
  FIELD_TYPES: "/field_types",
  EMPLOYEE_GROUPS: (id?: string) =>
    id ? `/companies/${id}/employee_groups` : "/employee_groups",
  COUNTRIES: "/countries",
  FAMILY_SITUATIONS: "/family_situations",
  FAMILY_RELATIONSHIPS: "/family_relationships",
  MAILS: "/mails",
  FIELDS_EMPLOYEE_GROUPS: "/field_employee_groups",
  DEFAULT_FILE_BEHAVIORS: "/default_file_behaviors",
  COMPANIES: {
    COMPANIES: "/companies",
    ALL_COMPANIES: "/filter_companies",
    REGISTRATIONS: (id: string) => `/companies/${id}/registrations`,
    REGISTER_FIELD: (id: string) => `/companies/${id}/register_field`,
    QUICK_VIEW: (company_id: string, id: string) =>
      `/companies/${company_id}/registrations/${id}/quick_view`,
    BATCH_EMPLOYEE_GROUP: (id: string) =>
      `/companies/${id}/registrations/batch_employee_group`,
    PARAMETERS: (id: string) => `/companies/${id}/registration_modality`,
    EMPLOYEE_GROUPS_MODALITY: (id: string) => `/employee_groups/${id}/registration_modality/users`,
    UNREGISTER_FIELD: (id: string) => `/companies/${id}/unregister_field`,
    FIND_XOTIS_NUMBER: (id: string) => `/companies/${id}/findXotisNumber`,
    SET_XOTIS_NUMBER: (id: string) => `/companies/${id}/findXotisNumber`,
    DPAES: (company_id: string, id?: string) => `/companies/${company_id}/dpaes` + (id ? `/${id}` : ""),
    TAGS: (company_id: string) => `/companies/${company_id}/tags`,
  },
  ADMINISTRATION_PARAMETERS: (id: string) => `/companies/${id}/administration_parameters`,
  REGISTRATION: {
    ONE: (id: string) => `/registrations/${id}`,
    ALL: `/registrations`,
    STEP: (company_id: string, registration_id: string, step_id: string, manual: boolean = false) => `/companies/${company_id}/registrations/${registration_id}/step_${step_id}${manual ? '/manual' : ''}`,
    DATA: (company_id: string, registration_id: string) => `/companies/${company_id}/registrations/${registration_id}`
  },
  DEPOSITED_FILES: {
    UPLOAD: (company_id: string) => `/companies/${company_id}/payroll/upload`,
    LIST: (company_id: string) => `/companies/${company_id}/employee_file_extractions/all`,
    FILE_PAGE_LIST: (company_id: string) => `/companies/${company_id}/employee_file_extraction_pages`,
    DELETE: (id: string) => `/employee_file_extractions/${id}`,
    DELETE_PAGE: (id: string) => `/employee_file_extraction_pages/${id}`,
    EXTRACT: () => `/payroll/extract`,
  },
  FILE: {
    FILE_DATA: (id: string) => `/files/${id}`,
    RIB_METADATA: (id: string) => `/files/${id}/rib`,
  },
  CASTING_INVITATIONS: "/casting_invitations",
  EYE_COLORS: "/eye_colors",
  ETHNICITIES: "/ethnicities",
  HAIR_COLORS: "/hair_colors",
  HAIR_TYPES: "/hair_types",
  HAIR_LENGTHS: "/hair_lengths",
  SETS: (companyId: string) => `/companies/${companyId}/sets`,
  CASTING_PROFILES: (id: string) => `/users/${id}/casting_profile`,
  CREATE_CONTRACTS_PARAMETERS: (companyId: string) => `/companies/${companyId}/create_contracts_parameters`,
  WAGE_BY_POSITION_AND_CATEGORY: (companyId: string, positionId: string, categoryId: string) => `/companies/${companyId}/wage_by_position_and_category/${positionId}/${categoryId}`,
  FIELDS: (companyId: string) => `/companies/${companyId}/fields`,
  TAGS: "/tags",
  SET_USERS: "/set_users",
  SET_EMPLOYEE_GROUP: "/set_employee_groups",
  COLORS: "/colors",
  COMMUNES: "/communes",
  APPLICATIONS: (companyId: string) => `/companies/${companyId}/candidatures`,
  SPECIFIC_QUESTIONS: {
    UPLOAD: (companyId: string) => `/companies/${companyId}/specific_questions`,
    EDIT: (companyId: string, specificQuestionId: string) => `/companies/${companyId}/specific_questions/${specificQuestionId}`,
  }
};

export const ADDRESS_END_POINT = {
  POSTAL_CODE: "https://geo.api.gouv.fr/communes?codePostal=",
  ADDRESSES: "https://api-adresse.data.gouv.fr/search/?q=",
  CITY: "https://geo.api.gouv.fr/communes?nom=",
};

export const SOCIAL_NETWORKS = {
  FACEBOOK: "https://www.facebook.com/myrole.fr",
  TWITTER: "https://twitter.com/teamMyRole",
  LINKEDIN: "https://fr.linkedin.com/company/myrole",
};

export const isDev = process.env.NODE_ENV === "development";
export const isProd = process.env.NODE_ENV === "production";
export const isTest = process.env.NODE_ENV === "test";

export const AUDIENS_URL = "https://www.audiens.org/accueil.html";
export const URSSAF_URL = "https://www.urssaf.fr/portail/home.html";

export const BEARER_TOKEN_COOKIE_NAME = "BEARER";
export const BEARER_TOKEN_EXPIRATION_TIME = 3600;

export const REFRESH_TOKEN_COOKIE_NAME = "refresh_token";
export const REFRESH_TOKEN_EXPIRATION_TIME = 2592000;
