import {
  memo,
  ReactNode,
} from "react";
import { HiOutlineX } from "react-icons/hi";
import classNames from "classnames";


import { Dialog, Modal as AriaModal } from "react-aria-components";
import "./Modal.css";

export interface Props {
  children: ReactNode;
  title?: string;
  open?: boolean;
  close?: () => void;
  onClose?: () => void;
  fullscreen?: boolean;
  containerClassName?: string;
  modalClassName?: string;
  noInnerPadding?: boolean;
}

const Modal = ({
  children,
  title,
  open = false,
  close,
  fullscreen = false,
  noInnerPadding = false,
  modalClassName = "",
  containerClassName = "",
}: Props) => {

  return (
    <>
      <AriaModal
        isDismissable
        isOpen={open}
        onOpenChange={close}
        className={classNames(
          `react-aria-Modal relative max-h-full w-full md:my-20 md:max-w-2xl`,
          modalClassName,
        )
        }
      >
        <Dialog>
          {/* Modal header */}
          <div className="flex items-center justify-between rounded-t bg-mr-cyan-300 px-5 py-4">
            {title && (
              <h3 className="pl-2 text-lg font-semibold text-gray-50">
                {title}
              </h3>
            )}
            <button
              className="rounded-full bg-gray-100 p-1 text-gray-900"
              onClick={close}
              type="button"
            >
              <HiOutlineX className="h-5 w-5" />
            </button>
          </div>
          {/* Modal body */}
          <div
            className={classNames(
              `overflow-y-auto max-h-[80vh] overflow-x-hidden overscroll-none ${containerClassName}`,
              containerClassName,
              { "px-12 py-14": !noInnerPadding }
            )}
          >
            {children && children}
          </div>
        </Dialog>
      </AriaModal>
    </>
  );
};

export default memo(Modal);
