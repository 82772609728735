import { memo, useCallback, useRef, useState } from "react";
import { useAuth } from "../../hooks";
import { HiUser } from "react-icons/hi";
import { Popover } from "../core";
import { PopoverOrigin } from "../core/Popover/Popover";
import ProfileCard from "../ProfileCard";

export interface Props {
  className?: string;
  popoverClassName?: string;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
}

const ProfilePopover = ({
  className = "",
  popoverClassName = "",
  anchorOrigin,
  transformOrigin,
}: Props) => {
  const userIconRef = useRef(null);
  const [isProfilePopoverOpen, setProfilePopover] = useState(false);
  const { auth } = useAuth();

  const handleClickUserIcon = useCallback(() => {
    setProfilePopover(!isProfilePopoverOpen);
  }, [isProfilePopoverOpen]);

  const closeProfilePopover = useCallback(() => {
    setProfilePopover(false);
  }, [setProfilePopover]);

  return (
    auth && (
      <div className={className}>
        <button
          className="block aspect-square w-8 overflow-hidden rounded-full bg-mr-cyan-400 text-white hover:bg-mr-cyan-300"
          ref={userIconRef}
          onClick={handleClickUserIcon}
        >
          {auth?.user_field.profile_picture ? (
            <img
              className="h-full w-full object-cover"
              src={auth.user_field.profile_picture}
              alt="profile picture"
            />
          ) : (
            <HiUser className="m-auto h-6 w-6" data-testid="UserIcon" />
          )}
        </button>

        <Popover
          className={popoverClassName}
          anchorEl={userIconRef}
          open={isProfilePopoverOpen}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          close={closeProfilePopover}
          excludeRefs={[userIconRef]}
        >
          <ProfileCard
            user={{
              first_name: auth?.user_field?.first_name || "",
              last_name: auth?.user_field?.last_name || "",
              profile_picture: auth?.user_field?.profile_picture,
            }}
          />
        </Popover>
      </div>
    )
  );
};

export default memo(ProfilePopover);
