export const COUNTRIES_FIXTURE: GetCountriesResponse = {
  "@context": "/contexts/Country",
  "@id": "/countries",
  "@type": "hydra:Collection",
  "hydra:member": [
    {
      "@id": "/countries/1",
      "@type": "Country",
      cog: "dunno",
      actual: "1",
      copray: "dunno",
      crpay: "dunno",
      name: "France",
      full_name: "France",
      is_eu: true,
      codeiso2: "fr",
      codeiso3: "fra",
      codenum3: "170",
      nat_name_fr: "Français",
      nat_name_fem: "Française",
    },
    {
      "@id": "/countries/2",
      "@type": "Country",
      cog: "dunno",
      actual: "1",
      copray: "dunno",
      crpay: "dunno",
      name: "Italie",
      full_name: "Italie",
      is_eu: true,
      codeiso2: "it",
      codeiso3: "ita",
      codenum3: "170",
      nat_name_fr: "Italie",
      nat_name_fem: "Italie",
    },
  ],
  "hydra:totalItems": 2,
};
