import { memo } from "react";
import Badge, { Props } from "./Badge";
import { twMerge } from "tailwind-merge";

const TertiaryBadge = ({
  className = "",
  children,
  outline = false,
  ...props
}: Props) => {
  return (
    <Badge
      className={twMerge(
        "border border-mr-gray-900",
        outline ? "bg-transparent text-current" : "bg-mr-gray-900 text-white",
        className
      )}
      data-testid="TertiaryBadge"
      {...props}
    >
      {children && children}
    </Badge>
  );
};

export default memo(TertiaryBadge);
