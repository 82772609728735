import { useQuery } from "@tanstack/react-query";
import { fetchRegistrations } from "../api";

export function useEmployeeManagement(companyId: string) {
  const query = useQuery({
    queryKey: ["registrations", companyId],
    queryFn: ({ signal }) => fetchRegistrations(companyId, signal),
    enabled: Boolean(companyId),
  });

  return {
    employees: query.data,
    query,
  };
}
