export const COLORS_FIXTURE: GetColorsResponse = {
  "@context": "/contexts/Color",
  "@id": "/colors",
  "@type": "hydra:Collection",
  "hydra:member": [
    {
      "@id": "/colors/1",
      "@type": "Color",
      name: "bleu",
      hex_code: "#354BCF",
    },
    {
      "@id": "/colors/2",
      "@type": "Color",
      name: "cyan",
      hex_code: "#3AA2AD",
    },
    {
      "@id": "/colors/3",
      "@type": "Color",
      name: "vert",
      hex_code: "#74AF20",
    },
    {
      "@id": "/colors/4",
      "@type": "Color",
      name: "jaune",
      hex_code: "#DDA20E",
    },
    {
      "@id": "/colors/5",
      "@type": "Color",
      name: "orange",
      hex_code: "#EA754B",
    },
    {
      "@id": "/colors/6",
      "@type": "Color",
      name: "rouge",
      hex_code: "#D81D1D",
    },
    {
      "@id": "/colors/7",
      "@type": "Color",
      name: "violet",
      hex_code: "#9618A0",
    },
    {
      "@id": "/colors/8",
      "@type": "Color",
      name: "noir",
      hex_code: "#212529",
    },
  ],
  "hydra:totalItems": 8,
};
