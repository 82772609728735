export const PRODUCTION_TEAM_FIXTURE: ProductionTeam[] = [
  {
    img_src: "https://picsum.photos/500/500",
    full_name: "John doe",
    role: "admin",
  },
  {
    img_src: "https://picsum.photos/500/501",
    full_name: "John doe",
    role: "admin",
  },
  {
    img_src: "https://picsum.photos/500/502",
    full_name: "John doe",
    role: "admin",
  },
];
