import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getSetEmployeeGroups,
  postSetEmployeeGroups,
  deleteSetEmployeeGroups,
} from "../api";

export function useSetEmployeeGroups() {
  const queryClient = useQueryClient();
  const setEmployeeGroupsQuery = useQuery({
    queryKey: ["set_employee_groups"],
    queryFn: getSetEmployeeGroups,
  });

  const postQuery = useMutation({
    mutationFn: (setEmployeeGroups: PostSetEmployeeGroups) =>
      postSetEmployeeGroups(setEmployeeGroups),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["sets"] });
      queryClient.invalidateQueries({ queryKey: ["set_users"] });
      queryClient.invalidateQueries({ queryKey: ["set_employee_groups"] });
    },
  });

  const deleteQuery = useMutation({
    mutationFn: (id: number) => deleteSetEmployeeGroups(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["sets"] });
      queryClient.invalidateQueries({ queryKey: ["set_users"] });
      queryClient.invalidateQueries({ queryKey: ["set_employee_groups"] });
    },
  });

  return {
    setEmployeeGroupsQuery,
    postQuery,
    deleteQuery,
  };
}
