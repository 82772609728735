import { Mail, MailSend, GetMailResponse } from "../types/messaging";

export const MAIL_FIXTURE: Mail = {
  "@id": "/mails/1edad3be-ae4e-6a5e-80fc-efcbaeb85ea1",
  "@type": "Mail",
  author: {
    "@id": "/users/1edad3ba-b0ea-60c8-898e-f9c7a9c704fe",
    "@type": "user",
    email: "testmail@user.user",
    firstName: "John",
    lastName: "Doe",
    userFields: [{ value: "John" }, { value: "Doe" }],
  },
  date: "2023-02-15T14:20:37+00:00",
  mail_recipients: [
    {
      "@id": "/mail_recipients/52",
      "@type": "MailRecipient",
      read_date: "2023-03-06T13:58:23+00:00",
      recipient_user: {
        "@id": "/users/1edad3ba-b0ea-60c8-898e-f9c7a9c704fe",
        "@type": "user",
        email: "testmail@user.user",
        firstName: "John",
        lastName: "Doe",
        userFields: [{ value: "John" }, { value: "Doe" }],
      },
    },
  ],
  message: "content content content",
  subject: "Test subject blue",
};

export const MAILS_FIXTURE: GetMailResponse = {
  "@context": "/contexts/Mail",
  "@id": "/mails",
  "@type": "hydra:Collection",
  "hydra:totalItems": 4,
  "hydra:member": [
    {
      "@id": "/mails/1edad3be-ae4e-6a5e-80fc-efcbaeb85ea1",
      "@type": "Mail",
      author: {
        "@id": "/users/1edad3ba-b0ea-60c8-898e-f9c7a9c704fe",
        "@type": "user",
        email: "testmail@user.user",
        firstName: "John",
        lastName: "Doe",
        userFields: [{ value: "John" }, { value: "Doe" }],
      },
      date: "2023-02-15T14:20:37+00:00",
      mail_recipients: [
        {
          "@id": "/mail_recipients/52",
          "@type": "MailRecipient",
          read_date: "2023-03-06T13:58:23+00:00",
          recipient_user: {
            "@id": "/users/1edad3ba-b0ea-60c8-898e-f9c7a9c704fe",
            "@type": "user",
            email: "testmail@user.user",
            firstName: "John",
            lastName: "Doe",
            userFields: [{ value: "John" }, { value: "Doe" }],
          },
        },
      ],
      message: "<p>Lorem lorem lorem lorem lorem lorem Lorem lorem lorem<p>",
      subject: "Test subject blue",
    },
    {
      "@id": "/mails/1edad3be-ae4e-6a5e-80fc-efcbaeb85ea2",
      "@type": "Mail",
      author: {
        "@id": "/users/1edad3ba-b0ea-60c8-898e-f9c7a9c704fe",
        "@type": "user",
        email: "testmail@user.user",
        firstName: "John",
        lastName: "Doe",
        userFields: [{ value: "John" }, { value: "Doe" }],
      },
      date: "2023-02-15T14:20:37+00:00",
      mail_recipients: [
        {
          "@id": "/mail_recipients/52",
          "@type": "MailRecipient",
          read_date: "2023-03-06T13:58:23+00:00",
          recipient_user: {
            "@id": "/users/1edad3ba-b0ea-60c8-898e-f9c7a9c704fe",
            "@type": "user",
            email: "testmail@user.user",
            firstName: "John",
            lastName: "Doe",
            userFields: [{ value: "John" }, { value: "Doe" }],
          },
        },
      ],
      message: "<p>Lorem lorem lorem lorem lorem lorem Lorem lorem lorem<p>",
      subject: "Test subject blue",
    },
    {
      "@id": "/mails/1edad3be-ae4e-6a5e-80fc-efcbaeb85ea3",
      "@type": "Mail",
      author: {
        "@id": "/users/1edad3ba-b0ea-60c8-898e-f9c7a9c704fe",
        "@type": "user",
        email: "testmail@user.user",
        firstName: "John",
        lastName: "Doe",
        userFields: [{ value: "John" }, { value: "Doe" }],
      },
      date: "2023-02-15T14:20:37+00:00",
      mail_recipients: [
        {
          "@id": "/mail_recipients/52",
          "@type": "MailRecipient",
          read_date: "2023-03-06T13:58:23+00:00",
          recipient_user: {
            "@id": "/users/1edad3ba-b0ea-60c8-898e-f9c7a9c704fe",
            "@type": "user",
            email: "testmail@user.user",
            firstName: "John",
            lastName: "Doe",
            userFields: [{ value: "John" }, { value: "Doe" }],
          },
        },
      ],
      message: "<p>Lorem lorem lorem lorem lorem lorem Lorem lorem lorem<p>",
      subject: "Test subject blue",
    },
    {
      "@id": "/mails/1edad3be-ae4e-6a5e-80fc-efcbaeb85ea4",
      "@type": "Mail",
      author: {
        "@id": "/users/1edad3ba-b0ea-60c8-898e-f9c7a9c704fe",
        "@type": "user",
        email: "testmail@user.user",
        firstName: "John",
        lastName: "Doe",
        userFields: [{ value: "John" }, { value: "Doe" }],
      },
      date: "2023-02-15T14:20:37+00:00",
      mail_recipients: [
        {
          "@id": "/mail_recipients/52",
          "@type": "MailRecipient",
          read_date: "2023-03-06T13:58:23+00:00",
          recipient_user: {
            "@id": "/users/1edad3ba-b0ea-60c8-898e-f9c7a9c704fe",
            "@type": "user",
            email: "testmail@user.user",
            firstName: "John",
            lastName: "Doe",
            userFields: [{ value: "John" }, { value: "Doe" }],
          },
        },
      ],
      message: "<p>Lorem lorem lorem lorem lorem lorem Lorem lorem lorem<p>",
      subject: "Test subject blue",
    },
  ],
  "hydra:view": {
    "@id": "/mails?page=1",
    "@type": "hydra:PartialCollectionView",
    "hydra:first": "/mails?page=1",
    "hydra:last": "/mails?page=3",
    "hydra:next": "/mails?page=2",
  },
};

export const SEND_MAIL_FIXTURE: MailSend = {
  mail: MAIL_FIXTURE,
  response_of: "",
};

export const MAILS_SENT_FIXTURE: GetMailResponse = {
  //@ts-ignore
  currentPage: 2,
  totalPage: 3,
  totalMails: 230,
  mails: [
    {
      id: "1",
      expeditor: "Marie",
      object: "Re: Documents signés",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur enim, quia nostrum ipsum sit nesciunt. Necessitatibus eaque ducimus commodi officia obcaecati dolorem quibusdam! Sit, fugiat sequi cumque dignissimos repellat minus?",
      date: "2022-10-25T00:00:00+00:00",
      status: "sent",
    },
    {
      id: "2",
      expeditor: "Bob",
      object: "Re: Documents signés",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur enim, quia nostrum ipsum sit nesciunt. Necessitatibus eaque ducimus commodi officia obcaecati dolorem quibusdam! Sit, fugiat sequi cumque dignissimos repellat minus?",
      date: "2022-10-25T00:00:00+00:00",
      status: "sent",
    },
    {
      id: "3",
      expeditor: "Jhon",
      object: "Re: Documents signés",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur enim, quia nostrum ipsum sit nesciunt. Necessitatibus eaque ducimus commodi officia obcaecati dolorem quibusdam! Sit, fugiat sequi cumque dignissimos repellat minus?",
      date: "2022-10-25T00:00:00+00:00",
      status: "sent",
    },
  ],
};

export const MAILS_TRASH_FIXTURE: GetMailResponse = {
  //@ts-ignore
  currentPage: 1,
  totalPage: 1,
  totalMails: 3,
  mails: [
    {
      id: "1",
      expeditor: "Pierre",
      object: "test trash",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur enim, quia nostrum ipsum sit nesciunt. Necessitatibus eaque ducimus commodi officia obcaecati dolorem quibusdam! Sit, fugiat sequi cumque dignissimos repellat minus?",
      date: "2022-10-25T00:00:00+00:00",
      status: "seen",
    },
    {
      id: "2",
      expeditor: "Pierre",
      object: "test trash",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur enim, quia nostrum ipsum sit nesciunt. Necessitatibus eaque ducimus commodi officia obcaecati dolorem quibusdam! Sit, fugiat sequi cumque dignissimos repellat minus?",
      date: "2022-10-25T00:00:00+00:00",
      status: "seen",
    },
    {
      id: "3",
      expeditor: "Pierre",
      object: "test trash",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur enim, quia nostrum ipsum sit nesciunt. Necessitatibus eaque ducimus commodi officia obcaecati dolorem quibusdam! Sit, fugiat sequi cumque dignissimos repellat minus?",
      date: "2022-10-25T00:00:00+00:00",
      status: "unSeen",
    },
    {
      id: "4",
      expeditor: "Pierre",
      object: "test trash",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur enim, quia nostrum ipsum sit nesciunt. Necessitatibus eaque ducimus commodi officia obcaecati dolorem quibusdam! Sit, fugiat sequi cumque dignissimos repellat minus?",
      date: "2022-10-25T00:00:00+00:00",
      status: "unSeen",
    },
  ],
};
