import { API_BASE_URL, AUTH_END_POINT } from "../constants";
import { setCompanyIRI } from "../utils";
import { axiosPrivate, axiosPrivatePatch } from "./axios";

export async function fetchSets(companyId: string) {
  const { data } = await axiosPrivate.get<GetSetsResponse>(
    `${API_BASE_URL}${AUTH_END_POINT.SETS(companyId)}`
  );

  return data["hydra:member"];
}

export async function fetchSet({
  id,
  companyId,
}: {
  id: string;
  companyId: string;
}) {
  const { data } = await axiosPrivate.get<MRSet>(
    `${API_BASE_URL}${AUTH_END_POINT.SETS(companyId)}/${id}`
  );

  return data;
}

export async function patchSet(data: MRSet) {
  const response = await axiosPrivatePatch.patch<PatchSetsResponse>(
    `${API_BASE_URL}${data["@id"]}`,
    data
  );

  return response.data;
}

export async function postSet(companyId: string, data: PostSetsParam) {
  const response = await axiosPrivate.post<PostSetsResponse>(
    `${API_BASE_URL}/sets`,
    { ...data, company: setCompanyIRI(companyId) }
  );

  return response.data;
}

export async function removeSet({
  id,
  companyId,
}: {
  id: string;
  companyId: string;
}) {
  const response = await axiosPrivate.delete(
    `${API_BASE_URL}${AUTH_END_POINT.SETS(companyId)}/${id}`
  );

  return response.data;
}
