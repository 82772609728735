import { memo } from "react";
import Badge, { Props } from "./Badge";
import { twMerge } from "tailwind-merge";

const SecondaryBadge = ({
  className = "",
  children,
  outline = false,
  ...props
}: Props) => {
  return (
    <Badge
      className={twMerge(
        "border border-mr-cyan-300",
        outline ? "bg-transparent text-current" : "bg-mr-cyan-300 text-white",
        className
      )}
      data-testid="SecondaryBadge"
      {...props}
    >
      {children && children}
    </Badge>
  );
};

export default memo(SecondaryBadge);
