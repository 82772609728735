import React, { memo, useEffect } from 'react';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';
import Masonry from 'react-masonry-css';
import './masonry.css';
import { API_BASE_URL } from '../../constants';

export interface Props {
    images: Gallery[];
}

const Gallery = ({ images }: Props) => {
    useEffect(() => {
        let lightbox = new PhotoSwipeLightbox({
            gallery: '#my-gallery',
            children: 'a',
            pswpModule: () => import('photoswipe'),
        });
        lightbox.init();

        return () => {
            lightbox.destroy();
        };
    }, []);

    const breakpointCols = {
        default: 3,
        1100: 2,
        700: 1,
    };

    return (
        <Masonry
            breakpointCols={breakpointCols}
            className="masonry-grid pswp-gallery"
            columnClassName="masonry-grid_column"
            id="my-gallery"
        >
            {images.map((image, idx) => (
                <div key={idx} className="relative">
                    <a
                        href={API_BASE_URL + image.file.slice(1)}
                        // data-pswp-width={image.width}
                        // data-pswp-height={image.height} // TODO : Needed from Back
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            src={API_BASE_URL + image.thumbnailFile.slice(1)}
                            //alt={image.name}
                        />
                    </a>
                    <div className="absolute bottom-0 w-full bg-mr-cyan-500/[.5] text-white pl-2">
                        {/* {image.name} */} Missing Image names from Back
                    </div>
                </div>
            ))}
        </Masonry>
    );
};

export default memo(Gallery);
