import { Modal, useModal } from "../../components/modals";
import { FormProvider, useForm } from "react-hook-form";
import { BsCheck2Circle } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { useAsyncList } from "react-stately";
import MyComboBox from "../core/AriaComboBox/AriaComboBox";
import { ListBoxItem } from "react-aria-components";
import { InputController, SecondaryButton } from "../core";
import { getCompanyUsers } from "../../api/company_users";
import { useRequiredParams } from "../../hooks/useRequiredParams";


interface Employee {
    name: string;
  }

export interface Props {
    open?: boolean;
    onClose?: () => void;
    addEmployee?: boolean;
    file_number?: string;
    date_from?: string;
    date_to?: string;
    position?: string;
    page?: string;
    upload_date?: string;
    defaultValues?: FilmRegistration;
  }

function UpdateFileModal({
  open = false,
  onClose = () => {},
  addEmployee = false,
  file_number = "",
  date_from = "",
  date_to = "",
  position = "",
  page = "",
  upload_date = "",
  defaultValues,
}: Props) {
  const { t } = useTranslation();

  const { companyId } = useRequiredParams();

  let formValues = {
    ...defaultValues,
  };

  const methods = useForm<FilmRegistration>({
    defaultValues: formValues,
  });

  const formSubmit = (data: any) => {
    console.log("submitStep");
  };

  // let list = useAsyncList<Employee>({
  //   async load({ signal, filterText }) {
  //     let res = await fetch(
  //       `https://swapi.py4e.com/api/people/?search=${filterText}`,
  //       { signal }
  //     );
  //     let json = await res.json();

  //     return {
  //       items: json.results,
  //     };
  //   },
  // });


  const list = useAsyncList<Employee>({
    async load() {
      let res = await getCompanyUsers(companyId);
      
      let json = await res.json();

      return {
        items: json.results,
      };
    },
  });

  // const ExtractedFile = await getCompanyUsers(companyId);
  

  return (
    <Modal close={onClose} open={open} title={"Modifier le document employé"}>
      <h2 className="uppercase text-lg font-semibold mb-8">
        Document en page {page}
      </h2>
      <div className={"Hello"} data-testid="FilmRegistrationFormStep1">
        <FormProvider {...methods}>
          <form
            className="flex flex-col gap-y-6"
            onSubmit={methods.handleSubmit(formSubmit)}
          >
            <MyComboBox
              label="Employé"
              items={list.items}
              inputValue={list.filterText}
              onInputChange={list.setFilterText}
              description="Merci de taper au moins deux lettres"
            >
              {(item) => <ListBoxItem id={item.name}>{item.name}</ListBoxItem>}
            </MyComboBox>

            <InputController
              value={file_number}
              name="first_name"
              label="Numéro"
              type="text"
              rules={{ required: true }}
            />

            <InputController
              value={"2024-02-01"}
              label={"Date de début"}
              type="date"
              name="date_from"
              rules={{ required: true }}
            />

            <InputController
              value={"2024-02-01"}
              label={"Date de fin"}
              type="date"
              name="date_to"
              rules={{ required: true }}
            />

            <InputController
              value={position}
              name="position"
              label="Poste"
              type="text"
              rules={{ required: true }}
            />

            <div className="flex justify-end gap-3">
              <SecondaryButton
                type="submit"
                startIcon={<BsCheck2Circle className="h-7 w-7" />}
              >
                {t("FilmRegistration.continue")}
              </SecondaryButton>
            </div>
          </form>
        </FormProvider>
      </div>
    </Modal>
  );
}

export default UpdateFileModal;
