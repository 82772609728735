import {
  ReactElement,
  cloneElement,
  useCallback,
  useId,
  useState,
} from "react";

type ModalHookReturnType = [
  modal: React.ReactElement,
  updateOpen: (isOpen?: boolean, overrideProps?: object | null) => void
];

function useModal(component: ReactElement): ModalHookReturnType {
  const id = useId();
  const [isOpen, setIsOpen] = useState(false);
  const [extraProps, setExtraProps] = useState({});

  const handleClose = () => {
    setIsOpen(false);
  };

  const updateOpen = useCallback(
    (isOpen?: boolean, overrideProps: object | null = null) => {
      typeof isOpen === "boolean" ? setIsOpen(isOpen) : setIsOpen((v) => !v);

      if (overrideProps !== null) {
        setExtraProps(overrideProps);
      }
    },
    [setIsOpen]
  );

  const modal = cloneElement(component, {
    ...extraProps,
    key: id,
    open: isOpen,
    close: handleClose,
    onClose: handleClose,
  }) as ReactElement;

  return [modal, updateOpen];
}

export default useModal;
