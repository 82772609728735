export const COMMUNES_FIXTURE: GetCommunesResponse = {
  "@context": "/contexts/Commune",
  "@id": "/communes",
  "@type": "hydra:Collection",
  "hydra:member": [
    {
      "@id": "/communes/1",
      "@type": "Commune",
      id: 1,
      departement: "/departements/1",
      arrondissement: "02",
      name_maj: "TEST COMMUNE",
      name_min: "test commune",
      xotisEmployeeBudgetPosts: [],
      xotisEmployeeSections: [],
    },
  ],
  "hydra:totalItems": 1,
};
