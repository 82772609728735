import {
  HiOutlineHome,
  HiOutlineFilm,
  HiOutlineInbox,
  HiSearch,
  HiOutlineUsers,
  HiCog,
  HiUsers,
  HiOutlineDocumentText,
  HiHome,
  HiUserGroup,
  HiCalendar,
  HiThumbUp,
  HiExclamationCircle,
  HiPencil,
  HiUserCircle,
} from "react-icons/hi";
import { BiBox } from "react-icons/bi";
import { MenuItem, SubMenu } from "../types/menu";
import {
  BsBriefcaseFill,
  BsBrushFill,
  BsFillTagsFill,
  BsListUl,
} from "react-icons/bs";
import { AiFillMessage, AiFillPicture, AiOutlineYoutube } from "react-icons/ai";
import { IoHammerSharp } from "react-icons/io5";
import { MdPayments } from "react-icons/md";
import { RoleKey } from "../types/role";
import { generatePath } from "react-router-dom";

// -----------------------------------------
// -              PATHS
// -----------------------------------------
export const ROUTE_PATH = {
  HOME: "/",
  LOGIN: "/login",
  CREATE_ACCOUNT: "/creer-un-compte",
  CREATE_PRODUCTION: "/creer-une-production",
  PRODUCTION_SETTINGS: "/parametres-production/:companyId",
  PROJECTS: "/projets",
  PROJECT: "/projet",
  PROJECT_SETTINGS: "/parametres-projet/:companyId",
  CREATE_PROJECT: "/creer-un-projet",
  REGISTER_SETTING: "/modalite-inscription/:companyId",
  REGISTER_SETTING_GUIDE: "", // PAGE TODO
  VERIFY_EMAIL: "/verifier-email",
  RESET_PASSWORD: "/reinitialiser-mot-de-passe",
  DASHBOARD: "/dashboard",
  DEPOSITED_FILES: "/documents-deposes/:companyId/",

  DASHBOARD_PRODUCTION: "/tableau-de-bord-production/",
  DASHBOARD_USER: "/tableau-de-bord-utilisateur/",
  DASHBOARD_STAFF: "/tableau-de-bord-staff/",

  PROJECT_DASHBOARD: "/projet/dashboard/:companyId",
  PROJECT_CALENDAR: "/agenda/:companyId/",
  PROJECT_CALENDAR_DAY: "/agenda/:companyId/:day/",
  PROJECT_INFORMATIONS: "/projet/details/:companyId/",
  PROJECT_EXPORT_XOTIS: "/projet/export/xotis/:companyId/",
  PROJECT_DPAE: "/dpae/:companyId/",
  PREPAPAIE: "/prepapaie/:companyId",
  EMPLOYEE_MANAGEMENT: "/gestion-salaries/:companyId",
  EMPLOYEE_NEW_CONTRACTS: "/nouveaux-contrats/:companyId",
  EMPLOYEE_REGISTRATION: "/gestion-recrutement/:companyId",
  APPLICATIONS: "/candidatures/:companyId",
  EMPLOYEE_NUMBER_GENERATION: "/numeros-salaries/generation",

  TUTORIAL: { REGISTER: "/", RIB: "/" }, // PAGE TODO
  PROFILE: "/profil",
  UPDATE_CASTING_PROFILE: "/profil-casting",
  USER_INFORMATIONS: "/profil/informations",
  SETTINGS: "/parametre",
  MESSAGING: "/messagerie",
  EMPLOYEE_SAFEBOX: "/coffre-fort",
  FILM_REGISTRATION: "/inscription-film/:registrationId?/etape/:stepId?",
  FILM_REGISTRATION_MANUAL: "/inscription-manuelle/:companyId",
  FILM_REGISTRATION_CODE: "/candidature/:employeeGroupCode?",
  SEARCH_ENGINE: "/moteur-de-recherche",
  NOT_FOUND: "*",
  SETS: "/decors/:companyId/",
  SET: "/decors/:companyId//:setId",
  TAGS: "/tags/:companyId/",
  EMPLOYEE_GROUP: "/groupe-salaries/:companyId/",
  ADMINISTRATION_PARAMETERS: "/parametres-administration/:companyId",
  VIRTUAL_FOLDER: "/classeur-virtuel/:companyId",
  CONTRACTS_MANAGEMENT: "/gestion-contrats/:companyId",
  SIGNATURE_PAD: "/parapheur/:companyId/",
  SIGNATURE_PAD_DETAILS: "/parapheur-details/:companyId/",
  SIGNATURE_PAD_SUMMARY: "/parapheur-recapitulatif/:companyId/",
  SIGNATURE_PAD_END: "/parapheur-fin/:companyId/",
  SANDBOX: "/sandbox/:companyId?",
  TEAM_MANAGEMENT: "/equipe-du-filmm/:companyId?",
  DOCUMENT_TEMPLATES: "/templates-de-documents/:companyId",
  SMS_HISTORY: "/historique-sms/:companyId",
  FILE: '/protected-user-files/:fileId',
  BOOK: '/book',
  EMPLOYEE_ON_YOUR_PROJECTS: '/salaries-sur-vos-projets',
  FILMOGRAPHY: '/filmographie',

  USER_MANAGEMENT: '/bo/users',
  POSITION_MANAGEMENT: '/bo/postes',
  INDEMNITY_MANAGEMENT: '/bo/indemnites',
  SST_MANAGEMENT: '/bo/sst',
  COMPANY_MANAGEMENT: '/bo/companies',
} as const;

// define the dashboard to role mapping
export const DASHBOARD_TO_ROLE = {
  DASHBOARD: [],
  DASHBOARD_PRODUCTION: ['ROLE_CHARGE_CASTING', 'ROLE_CHARGE_PRODUCTION', 'ROLE_DIRECTEUR_PRODUCTION'],
  DASHBOARD_USER: ['ROLE_TECHNICIEN', 'ROLE_COMEDIEN', 'ROLE_USER'],
  DASHBOARD_STAFF: ['ROLE_SUPERUSER']
}

// define the order of the dashboard
export const DASHBOARD_ORDER_PRIORITY = [
  ROUTE_PATH.DASHBOARD_STAFF,
  ROUTE_PATH.DASHBOARD_PRODUCTION,
  ROUTE_PATH.DASHBOARD_USER,
  ROUTE_PATH.DASHBOARD
];


export function getDashboardPathFromRole(roles: string[]): string {
  const found_dashboard: string[] = [];
  Object.entries(DASHBOARD_TO_ROLE).forEach(([key, value]) => {
    if (value.some((role) => roles.includes(role))) {
      //@ts-ignore
      found_dashboard.push(ROUTE_PATH[`${key}`]);
    }
  });

  // @ts-ignore
  found_dashboard.sort((a, b) => {
    // @ts-ignore
    return DASHBOARD_ORDER_PRIORITY.indexOf(a) - DASHBOARD_ORDER_PRIORITY.indexOf(b);
  });


  if (found_dashboard.length > 0) {
    return found_dashboard[0];
  }


  return ROUTE_PATH.DASHBOARD;
}


// -----------------------------------------
// -              MENU COMEDIEN
// -----------------------------------------
export const MENU_IS_COLLAPSE_DEFAULT = 768;
export const COMEDIAN_MENU: MenuItem[] = [
  {
    i18nKey: "SideNavbar.Dashboard",
    Icon: HiOutlineHome,
    to: ROUTE_PATH.DASHBOARD_USER,
  },
  {
    i18nKey: "SideNavbar.MovieRegistration",
    Icon: HiOutlineFilm,
    // TEMPORAIRE ---- A RENDRE DYNAMIQUE !!!
    to: "/inscription-film/1/etape/2",
  },
  {
    i18nKey: "SideNavbar.YourFilmography",
    Icon: AiOutlineYoutube,
    to: generatePath(ROUTE_PATH.FILMOGRAPHY),
  },
  // {
  //   i18nKey: "SideNavbar.YourDocuments",
  //   Icon: HiOutlineInbox,
  //   to: "/404",
  // },
  {
    i18nKey: "SideNavbar.SafeBox",
    Icon: HiOutlineInbox,
    to: generatePath(ROUTE_PATH.EMPLOYEE_SAFEBOX),
  },
];

// -----------------------------------------
// -              MENU CHARGE DE PROD
// -----------------------------------------
export const CHARGE_PRODUCTION_MENU: MenuItem[] = [
  {
    i18nKey: "SideNavbar.Dashboard",
    Icon: HiOutlineHome,
    to: ROUTE_PATH.DASHBOARD_PRODUCTION,
  },
  {
    i18nKey: "SideNavbar.YourProjects",
    Icon: HiOutlineFilm,
    to: ROUTE_PATH.PROJECTS,
  },
  {
    i18nKey: "SideNavbar.Search",
    Icon: HiSearch,
    to: ROUTE_PATH.SEARCH_ENGINE,
  },
  {
    i18nKey: "SideNavbar.YourBook",
    Icon: HiOutlineInbox,
    to: generatePath(ROUTE_PATH.BOOK),
  },
  {
    i18nKey: "SideNavbar.EmployeeOnYourProjects",
    Icon: HiOutlineUsers,
    to: generatePath(ROUTE_PATH.EMPLOYEE_ON_YOUR_PROJECTS),
  },
];

// -----------------------------------------
// -              MENU STAFF
// -----------------------------------------
export const STAFF_MENU: MenuItem[] = [
  {
    i18nKey: "SideNavbar.Dashboard",
    Icon: HiOutlineHome,
    to: ROUTE_PATH.DASHBOARD_PRODUCTION,
  },
  {
    i18nKey: "SideNavbar.YourProjects",
    Icon: HiOutlineFilm,
    to: ROUTE_PATH.PROJECTS,
  },
  {
    i18nKey: "SideNavbar.Search",
    Icon: HiSearch,
    to: ROUTE_PATH.SEARCH_ENGINE,
  },
  {
    i18nKey: "SideNavbar.YourBook",
    Icon: HiOutlineInbox,
    to: generatePath(ROUTE_PATH.BOOK),
  },
  {
    i18nKey: "SideNavbar.EmployeeOnYourProjects",
    Icon: HiOutlineUsers,
    to: generatePath(ROUTE_PATH.EMPLOYEE_ON_YOUR_PROJECTS),
  },
  {
    i18nKey: "Page.UserManagement",
    Icon: HiOutlineUsers,
    to: generatePath(ROUTE_PATH.USER_MANAGEMENT),
  },
  {
    i18nKey: "Page.PositionManagement",
    Icon: HiOutlineInbox,
    to: generatePath(ROUTE_PATH.POSITION_MANAGEMENT),
  },
  {
    i18nKey: "Page.IndemnityManagement",
    Icon: HiOutlineInbox,
    to: generatePath(ROUTE_PATH.INDEMNITY_MANAGEMENT),
  },
  {
    i18nKey: "Page.SstManagement",
    Icon: HiOutlineInbox,
    to: generatePath(ROUTE_PATH.SST_MANAGEMENT),
  },
  {
    i18nKey: "Page.CompanyManagement",
    Icon: HiOutlineFilm,
    to: generatePath(ROUTE_PATH.COMPANY_MANAGEMENT),
  }
];


export const MENU: { [key in RoleKey]: MenuItem[] } = {
  COMEDIEN: COMEDIAN_MENU,
  CHARGE_PRODUCTION: CHARGE_PRODUCTION_MENU,
  STAFF: STAFF_MENU,
  CHARGE_CASTING: [],
  TECHNICIEN: [],
  DIRECTEUR_PRODUCTION: [],
  RESP_LEGAL: [],
  AGENT: [],
  USER: [],
  CHEF_DE_POSTE: [],
};


// -----------------------------------------
// -         SUB_MENU D'UN PROJET
// -----------------------------------------
export const SUB_MENU = ({
  productionId,
  projectId,
  companyId,
}: {
  productionId: string;
  projectId: string;
  companyId: string;
}): SubMenu => ({
  key: ROUTE_PATH.PROJECT,
  title: "SubMenu.Movie.Title",
  categories: [
    {
      title: "SubMenu.Movie.Categories.Dashboard.Title",
      Icon: HiOutlineHome,
      to: generatePath(ROUTE_PATH.PROJECT_DASHBOARD, { companyId }),
    },
    {
      title: "SubMenu.Movie.Categories.Settings.Title",
      Icon: HiCog,
      routes: [
        {
          to: generatePath(ROUTE_PATH.PROJECT_SETTINGS, { companyId }),
          i18nKey: "SubMenu.Movie.Categories.Settings.MovieSettings",
        },
        {
          to: generatePath(ROUTE_PATH.ADMINISTRATION_PARAMETERS, {
            companyId,
          }),
          i18nKey: "SubMenu.Movie.Categories.Settings.AdminSettings",
        },
        {
          to: generatePath(ROUTE_PATH.TEAM_MANAGEMENT, { companyId }),
          i18nKey: "SubMenu.Movie.Categories.Settings.TeamManagement",
        },
        {
          to: generatePath(ROUTE_PATH.EMPLOYEE_GROUP, {
            companyId,
          }),
          i18nKey: "SubMenu.Movie.Categories.Settings.EmployeeGroup",
        },
        {
          to: generatePath(ROUTE_PATH.REGISTER_SETTING, {
            companyId,
          }),
          i18nKey: "SubMenu.Movie.Categories.Settings.Register",
        },
        {
          to: generatePath(ROUTE_PATH.DOCUMENT_TEMPLATES, { companyId }),
          i18nKey: "SubMenu.Movie.Categories.Settings.DocumentTemplates",
        },
        {
          to: generatePath(ROUTE_PATH.SMS_HISTORY, { companyId }),
          i18nKey: "SubMenu.Movie.Categories.Settings.SMSHistory",
        },
      ],
    },
    {
      title: "SubMenu.Movie.Categories.Employees.Title",
      Icon: HiUsers,
      routes: [
        {
          to: generatePath(ROUTE_PATH.SETS, { companyId }),
          i18nKey: "SubMenu.Movie.Categories.Employees.Sets",
        },
        {
          to: generatePath(ROUTE_PATH.TAGS, { companyId }),
          i18nKey: "SubMenu.Movie.Categories.Employees.Tags",
        },
        {
          to: generatePath(ROUTE_PATH.APPLICATIONS, {
            companyId,
          }),
          i18nKey: "SubMenu.Movie.Categories.Employees.Applications",
        },
        {
          to: generatePath(ROUTE_PATH.EMPLOYEE_REGISTRATION, {
            companyId,
          }),
          i18nKey: "SubMenu.Movie.Categories.Employees.Staff",
        },
        {
          to: generatePath(ROUTE_PATH.EMPLOYEE_MANAGEMENT, {
            companyId,
          }),
          i18nKey: "SubMenu.Movie.Categories.Employees.ManagementEmployee",
        },
      ],
    },
    {
      title: "SubMenu.Movie.Categories.Documents.Title",
      Icon: HiOutlineDocumentText,
      routes: [
        {
          to: generatePath(ROUTE_PATH.PROJECT_CALENDAR, { companyId }),
          i18nKey: "SubMenu.Movie.Categories.Documents.Agenda",
        },
        {
          to: generatePath(ROUTE_PATH.PROJECT_DPAE, { companyId }),
          i18nKey: "SubMenu.Movie.Categories.Documents.DUE_DPAE"
        },
        {
          to: generatePath(ROUTE_PATH.CONTRACTS_MANAGEMENT, { companyId }),
          i18nKey: "SubMenu.Movie.Categories.Documents.Contracts",
        },
        {
          to: generatePath(ROUTE_PATH.DEPOSITED_FILES, { companyId }),
          i18nKey: "SubMenu.Movie.Categories.Documents.DepositedFiles",
        },
      ],
    },
    {
      to: generatePath(ROUTE_PATH.VIRTUAL_FOLDER, { companyId }),
      title: "SubMenu.Movie.Categories.Binder.Title",
      Icon: BiBox,
    },
  ],
});

// -----------------------------------------
// -         SUB_MENU D'UNE PROD
// -----------------------------------------

// todo: revoir les sous-menus

export const SUB_MENU_PRODUCTION = ({
  productionId,
  projectId,
  companyId,
}: {
  productionId: string;
  projectId: string;
  companyId: string;
}): SubMenu => ({
  key: ROUTE_PATH.PROJECT,
  title: "SubMenu.Production.Title",
  categories: [
    {
      title: "SubMenu.Production.Categories.Dashboard.Title",
      Icon: HiOutlineHome,
      to: generatePath(ROUTE_PATH.PROJECT_DASHBOARD, { companyId }),
    },
    {
      title: "SubMenu.Production.Categories.Settings.Title",
      Icon: HiCog,
      routes: [
        {
          to: generatePath(ROUTE_PATH.PROJECT_INFORMATIONS, { companyId }),
          i18nKey: "SubMenu.Production.Categories.Settings.ProductionSettings",
        },
        {
          to: generatePath(ROUTE_PATH.ADMINISTRATION_PARAMETERS, { companyId }),
          i18nKey: "SubMenu.Movie.Categories.Settings.AdminSettings",
        },
        {
          to: generatePath(ROUTE_PATH.TEAM_MANAGEMENT, { companyId }),
          i18nKey: "SubMenu.Movie.Categories.Settings.TeamManagement",
        },
        {
          to: generatePath(ROUTE_PATH.EMPLOYEE_GROUP, { companyId }),
          i18nKey: "SubMenu.Movie.Categories.Settings.EmployeeGroup",
        },
        {
          to: generatePath(ROUTE_PATH.REGISTER_SETTING, { companyId }),
          i18nKey: "SubMenu.Movie.Categories.Settings.Register",
        },
        {
          to: generatePath(ROUTE_PATH.DOCUMENT_TEMPLATES, { companyId }),
          i18nKey: "SubMenu.Movie.Categories.Settings.DocumentTemplates",
        },
        {
          to: generatePath(ROUTE_PATH.SMS_HISTORY, { companyId }),
          i18nKey: "SubMenu.Movie.Categories.Settings.SMSHistory",
        },
      ],
    },
    {
      title: "SubMenu.Movie.Categories.Employees.Title",
      Icon: HiUsers,
      routes: [
        {
          to: generatePath(ROUTE_PATH.SETS, { companyId }),
          i18nKey: "SubMenu.Movie.Categories.Employees.Sets",
        },
        {
          to: ROUTE_PATH.TAGS,
          i18nKey: "SubMenu.Movie.Categories.Employees.Tags",
        },
        {
          to: generatePath(ROUTE_PATH.EMPLOYEE_REGISTRATION, { companyId }),
          i18nKey: "SubMenu.Movie.Categories.Employees.Staff",
        },
        {
          to: generatePath(ROUTE_PATH.EMPLOYEE_MANAGEMENT, { companyId }),
          i18nKey: "SubMenu.Movie.Categories.Employees.ManagementEmployee",
        },
      ],
    },
    {
      title: "SubMenu.Movie.Categories.Documents.Title",
      Icon: HiOutlineDocumentText,
      routes: [
        {
          to: generatePath(ROUTE_PATH.PROJECT_CALENDAR, { companyId }),
          i18nKey: "SubMenu.Movie.Categories.Documents.Agenda",
        },
        {
          to: generatePath(ROUTE_PATH.PROJECT_DPAE, { companyId }),
          i18nKey: "SubMenu.Movie.Categories.Documents.DUE_DPAE"
        },
        {
          to: generatePath(ROUTE_PATH.CONTRACTS_MANAGEMENT, {
            companyId,
          }),
          i18nKey: "SubMenu.Movie.Categories.Documents.Contracts",
        },
        {
          to: generatePath(ROUTE_PATH.DEPOSITED_FILES, { companyId }),
          i18nKey: "SubMenu.Movie.Categories.Documents.DepositedFiles",
        },
        {
          to: generatePath(ROUTE_PATH.SIGNATURE_PAD, { companyId }),
          i18nKey: "SubMenu.Movie.Categories.Documents.signature_pad",
        },
      ],
    },
    {
      to: generatePath(ROUTE_PATH.VIRTUAL_FOLDER, { companyId }),
      title: "SubMenu.Movie.Categories.Binder.Title",
      Icon: BiBox,
    },
  ],
});


// ------------------------------------------------------
// -        PRODUCTION DIRECTOR POPOVER MENU
// ------------------------------------------------------
export const PRODUCTION_DETAILS_POPOVER_MENU = ({
  productionId,
  companyId,
}: {
  productionId: string;
  companyId: string;
}): MenuItem[] => [
    {
      // to: generatePath(ROUTE_PATH.PROJECT_DASHBOARD, { projectId: id }),
      to: "/404",
      i18nKey: "ProductionDetails.PopoverMenu.MovieDashboard",
      Icon: HiHome,
    },
    {
      to: generatePath(ROUTE_PATH.EMPLOYEE_MANAGEMENT, { companyId }),
      i18nKey: "ProductionDetails.PopoverMenu.EmployeeManagement",
      Icon: HiUserGroup,
    },
    {
      to: generatePath(ROUTE_PATH.EMPLOYEE_REGISTRATION, {
        companyId,
      }),
      i18nKey: "ProductionDetails.PopoverMenu.Registration",
      Icon: HiThumbUp,
    },
    {
      to: generatePath(ROUTE_PATH.APPLICATIONS, {
        companyId,
      }),
      i18nKey: "ProductionDetails.PopoverMenu.Applications",
      Icon: HiThumbUp,
    },

    {
      to: generatePath(ROUTE_PATH.EMPLOYEE_GROUP, {
        companyId,
      }),
      i18nKey: "ProductionDetails.PopoverMenu.EmployeeGroups",
      Icon: HiUserGroup,
    },
    {
      to: generatePath(ROUTE_PATH.PROJECT_CALENDAR, {
        companyId,
      }),
      i18nKey: "ProductionDetails.PopoverMenu.Agenda",
      Icon: HiCalendar,
    },
    {
      to: generatePath(ROUTE_PATH.CONTRACTS_MANAGEMENT, {
        companyId,
      }),
      i18nKey: "ProductionDetails.PopoverMenu.Contracts",
      Icon: BsBriefcaseFill,
    },
    {
      to: generatePath(ROUTE_PATH.TAGS, { companyId }),
      i18nKey: "ProductionDetails.PopoverMenu.Tags",
      Icon: BsFillTagsFill,
    },
    {
      to: generatePath(ROUTE_PATH.SETS, { companyId }),
      i18nKey: "ProductionDetails.PopoverMenu.Sets",
      Icon: AiFillPicture,
    },
    {
      to: generatePath(ROUTE_PATH.PROJECT_DPAE, { companyId }),
      i18nKey: "ProductionDetails.PopoverMenu.DUE",
      Icon: BsListUl
    },
    {
      to: generatePath(ROUTE_PATH.DEPOSITED_FILES, { companyId }),
      i18nKey: "SubMenu.Movie.Categories.Documents.DepositedFiles",
      Icon: MdPayments,
    },
    {
      to: generatePath(ROUTE_PATH.REGISTER_SETTING, { companyId }),
      i18nKey: "ProductionDetails.PopoverMenu.RegistrationTerms",
      Icon: IoHammerSharp,
    },
    {
      to: generatePath(ROUTE_PATH.SMS_HISTORY, { companyId }),
      i18nKey: "ProductionDetails.PopoverMenu.SMSHistory",
      Icon: AiFillMessage,
    },
    {
      to: generatePath(ROUTE_PATH.ADMINISTRATION_PARAMETERS, {
        companyId,
      }),
      i18nKey: "ProductionDetails.PopoverMenu.AdminConfiguration",
      Icon: HiExclamationCircle,
    },
    {
      to: generatePath(ROUTE_PATH.TEAM_MANAGEMENT, { companyId }),
      i18nKey: "ProductionDetails.PopoverMenu.TeamManagement",
      Icon: HiUserCircle,
    },
    {
      to: generatePath(ROUTE_PATH.PRODUCTION_SETTINGS, { companyId }),
      i18nKey: "ProductionDetails.PopoverMenu.EditProduction",
      Icon: HiPencil,
    },
    {
      to: generatePath(ROUTE_PATH.DOCUMENT_TEMPLATES, { companyId }),
      i18nKey: "ProductionDetails.PopoverMenu.DocumentTemplates",
      Icon: BsBrushFill,
    },
    {
      to: generatePath(ROUTE_PATH.VIRTUAL_FOLDER, { companyId }),
      i18nKey: "ProductionDetails.PopoverMenu.VirtualFolder",
      Icon: AiFillMessage,
    },
  ];


export const PROJECT_CARD_POPOVER_MENU = ({
  projectId,
  companyId,
}: {
  projectId: string;
  companyId: string;
}): MenuItem[] => [
    {
      to: generatePath(ROUTE_PATH.PROJECT_DASHBOARD, { companyId }),
      i18nKey: "ProjectCard.PopoverMenu.MovieDashboard",
      Icon: HiHome,
    },
    {
      to: generatePath(ROUTE_PATH.EMPLOYEE_MANAGEMENT, { companyId }),
      i18nKey: "ProjectCard.PopoverMenu.EmployeeManagement",
      Icon: HiUserGroup,
    },
    {
      to: generatePath(ROUTE_PATH.APPLICATIONS, {
        companyId,
      }),
      i18nKey: "ProjectCard.PopoverMenu.Applications",
      Icon: HiThumbUp,
    },
    {
      to: generatePath(ROUTE_PATH.EMPLOYEE_GROUP, {
        companyId,
      }),
      i18nKey: "ProjectCard.PopoverMenu.EmployeeGroups",
      Icon: HiUserGroup,
    },
    {
      to: generatePath(ROUTE_PATH.PROJECT_CALENDAR, { companyId }),
      i18nKey: "ProjectCard.PopoverMenu.Agenda",
      Icon: HiCalendar,
    },
    {
      to: generatePath(ROUTE_PATH.CONTRACTS_MANAGEMENT, {
        companyId,
      }),
      i18nKey: "ProjectCard.PopoverMenu.Contracts",
      Icon: BsBriefcaseFill,
    },
    {
      to: generatePath(ROUTE_PATH.TAGS, { companyId }),
      i18nKey: "ProjectCard.PopoverMenu.Tags",
      Icon: BsFillTagsFill,
    },
    {
      to: generatePath(ROUTE_PATH.SETS, { companyId }),
      i18nKey: "ProjectCard.PopoverMenu.Sets",
      Icon: AiFillPicture,
    },
    {
      to: generatePath(ROUTE_PATH.PROJECT_DPAE, { companyId }),
      i18nKey: "ProjectCard.PopoverMenu.DUE",
      Icon: BsListUl
    },
    {
      to: generatePath(ROUTE_PATH.DEPOSITED_FILES, { companyId }),
      i18nKey: "SubMenu.Movie.Categories.Documents.DepositedFiles",
      Icon: MdPayments,
    },
    {
      to: generatePath(ROUTE_PATH.REGISTER_SETTING, { companyId }),
      i18nKey: "ProjectCard.PopoverMenu.RegistrationTerms",
      Icon: IoHammerSharp,
    },
    {
      to: generatePath(ROUTE_PATH.SMS_HISTORY, { companyId }),
      i18nKey: "ProjectCard.PopoverMenu.SMSHistory",
      Icon: AiFillMessage,
    },
    {
      to: generatePath(ROUTE_PATH.ADMINISTRATION_PARAMETERS, {
        companyId,
      }),
      i18nKey: "ProjectCard.PopoverMenu.AdminConfiguration",
      Icon: HiExclamationCircle,
    },
    {
      to: generatePath(ROUTE_PATH.TEAM_MANAGEMENT, { companyId }),
      i18nKey: "ProjectCard.PopoverMenu.TeamManagement",
      Icon: HiUserCircle,
    },
    {
      to: generatePath(ROUTE_PATH.PROJECT_SETTINGS, { companyId }),
      i18nKey: "ProjectCard.PopoverMenu.EditMovie",
      Icon: HiPencil,
    },
    {
      to: generatePath(ROUTE_PATH.DOCUMENT_TEMPLATES, { companyId }),
      i18nKey: "ProjectCard.PopoverMenu.DocumentTemplates",
      Icon: BsBrushFill,
    },
    {
      to: generatePath(ROUTE_PATH.VIRTUAL_FOLDER, { companyId }),
      i18nKey: "ProjectCard.PopoverMenu.VirtualFolder",
      Icon: AiFillMessage,
    },
  ];
