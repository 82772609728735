import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { resetPassword } from "../../api";
import { Input, InputController, PrimaryButton } from "../core";

export interface Props {
  className?: string;
  onSuccess?: () => void;
}

interface IFormInput {
  email: string;
}

const ForgotPasswordForm = ({ className = "", onSuccess }: Props) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState<string>("");
  const { control, handleSubmit } = useForm<IFormInput>({
    defaultValues: {
      email: "",
    },
  });

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    const status = await resetPassword(data.email);

    if (status === 200) {
      setMessage(t("ForgotPasswordSuccess"));
      onSuccess && onSuccess();
    }
  };

  return (
    <>
      {message && <p className="text-center text-gray-500">{message}</p>}
      {!message && (
        <form
          className={className}
          onSubmit={handleSubmit(onSubmit)}
          data-testid="ForgotPasswordForm"
        >
          <p className="mx-3 mb-10 text-gray-500">
            {t("ForgotPasswordEmailLabel")}
          </p>
          <InputController
            name="email"
            label={t("LoginFormEmailLabel")}
            type="email"
            autoComplete="email"
            rules={{ required: true }}
          />
          <div className="mt-10 flex items-center justify-center">
            <PrimaryButton size="big">
              {t("ForgotPasswordButton")}
            </PrimaryButton>
          </div>
        </form>
      )}
    </>
  );
};

export default ForgotPasswordForm;
