import { memo, useCallback, useMemo } from 'react';
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import useSearch from "../../../hooks/useSearch";

import GeneralFilters from "../../SearchFilters/GeneralFilters";
import { SearchFormFooter } from "../../blocks";

export interface Props {
  className?: string;
}

const SearchFormLight = ({ className = "" }: Props) => {

  const { t } = useTranslation();
  const methods = useForm<any>();
  const { search } = useSearch("test");

  const handleSubmit = useCallback(
    (data: any) => {
      // onSearch && onSearch(data);
      search();
      console.log("search", data);
    },
    [methods]
  );

  return (
    <>
      <FormProvider {...methods}>
        <form className="" onSubmit={methods.handleSubmit(handleSubmit)}>
          <GeneralFilters variant="light" />
          <SearchFormFooter />
        </form>
      </FormProvider>
    </>)
}

export default memo(SearchFormLight);
