import { memo } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { AiOutlineCheck, AiOutlineHourglass, AiOutlineMenu } from "react-icons/ai";
import { BlockIconBorder } from "../../blocks";
import { MenuPopover } from "../../MenuPopover";
import { ProjectCard } from "../../ProjectCard";
import { PROJECT_FIXTURE } from "../../../fixtures";

export interface Props {
  className?: string;
  production: Production;
}

const SectionProduction = ({ className = "", production }: Props) => {

  const { t } = useTranslation();

  production.projects = [
    PROJECT_FIXTURE
  ]

  return (
    <section className={className}>
      {/* HEADER */}
      <div className="flex items-center">
        <div>
          <span className="text-sm">{t("SectionProduction.title")}</span>
          {production?.company?.name && (
            <h2 className="font-bold">{production.company.name}</h2>
          )}
        </div>

        <MenuPopover
          className="rounded-full bg-white p-2 text-mr-orange-400 shadow-md hover:text-gray-900 ml-10 z-50"
          list={[{
            to: '/404',
            i18nKey: "ProductionDetails.PopoverMenu.MovieDashboard",
            Icon: HiOutlineOfficeBuilding,
          }]}
          Icon={AiOutlineMenu}
          popoverProps={{
            className: "",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          }}
        />
      </div>

      {/* CONTENT */}
      {/* TODO check in project how to check ended / ongoing projects : use end_date vs today_date or a flag ?  */}
      <div className="">
        <BlockIconBorder
          title={t('SectionProduction.shootingsOngoing')}
          Icon={AiOutlineHourglass}>
           <ProjectCard
             className="mt-4"
             project={PROJECT_FIXTURE}
             variant="dashboard"
             status="ongoing" />
           <ProjectCard
             className="mt-4"
             project={PROJECT_FIXTURE}
             variant="dashboard"
             status="ongoing" />
        </BlockIconBorder>
        <BlockIconBorder
          title={t('SectionProduction.shootingsCompleted')}
          Icon={AiOutlineCheck}>
           <ProjectCard
             className="mt-4"
             project={PROJECT_FIXTURE}
             variant="dashboard"
             status="completed" />
        </BlockIconBorder>
      </div>
    </section>
  )
};

export default memo(SectionProduction);
